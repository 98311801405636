import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as commonActions from './common.actions';
import { SharedService } from '../services/shared.service';
import { UploadFileResponse } from '../appdata/fileupload.model';
import { MediaServerList } from '../appdata/fileserver.model';
import { AnalyticsData } from '../appdata/analytics.model';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class CommonEffects {
  constructor(
    private actions$: Actions,
    private shareservice: SharedService,
    private alertMsg: AlertService
  ) {}

  initiateFileUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.initiateuploadFile),
      exhaustMap((action) =>
        this.shareservice
          .fileUpload(
            action.file,
            action.accountid,
            action.mobileno,
            action.channel,
            action.createdby,
            action.is_active
          )
          .pipe(
            map((response: UploadFileResponse) => {
              return commonActions.initiateuploadFileSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.initiateuploadFileFailure(error))
            )
          )
      )
    );
  });

  initiateUrlUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.initiateuploadUrl),
      exhaustMap((action) =>
        this.shareservice
          .fileUrlUpload(
            action.url,
            action.accountid,
            action.mobileno,
            action.channel,
            action.createdby
          )
          .pipe(
            map((response: UploadFileResponse) => {
              return commonActions.initiateuploadUrlSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.initiateuploadUrlFailure(error))
            )
          )
      )
    );
  });

  fetchFileServerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchMediaServerData),
      exhaustMap((action) =>
        this.shareservice.fetchmediaServer(action.payload).pipe(
          map((response: MediaServerList) => {
            return commonActions.fetchMediaServerDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.fetchMediaServerDataFailure(error))
          )
        )
      )
    );
  });

  deleteFileServerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.deleteMediaServerData),
      exhaustMap((action) =>
        this.shareservice.deleteMediaServerData(action.payload).pipe(
          map((response: any) => {
            return commonActions.deleteMediaServerDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.deleteMediaServerDataFailure(error))
          )
        )
      )
    );
  });

  fetchAnalyticsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchanalyticsdata),
      exhaustMap((action) =>
        this.shareservice
          .getAnalyticsData(
            action.accountid,
            action.bspid,
            action.startdate,
            action.enddate,
            action.page,
            action.size,
            action.campaign_id
          )
          .pipe(
            map((response: AnalyticsData) => {
              return commonActions.fetchanalyticsdataSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.fetchanalyticsdataFailure(error))
            )
          )
      )
    );
  });

  getWblData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.getWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.getWlbdatabyaccountid(action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              return commonActions.getWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.getWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  fetchWblData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchWhiteLabelData),
      exhaustMap(() =>
        this.shareservice.fetchWlbData().pipe(
          map((response: any) => {
            return commonActions.fetchWhiteLabelDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.fetchWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblCreateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.createWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.createWlbData(action.payload, action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              return commonActions.createWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.createWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblUpdateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.updateWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.updateWlbData(action.payload, action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              this.alertMsg.alertSuccess('Success', response.message);
              return commonActions.updateWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.updateWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblFileUploadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.uploadFileData),
      exhaustMap((action) =>
        this.shareservice.wlbUpload(action.file, action.accountid).pipe(
          map((response: any) => {
            return commonActions.uploadFileDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.uploadFileDataFailure(error))
          )
        )
      )
    );
  });

  changedefault$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.changeDefault),
      exhaustMap((action) =>
        this.shareservice
          .changeProductDefault(action.profileid, action.payload)
          .pipe(
            map((response: any) => {
              return commonActions.changeDefaultSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.changeDefaultFailure(error))
            )
          )
      )
    );
  });

  getLicenseData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.getLicenseDetails),
      exhaustMap((action) =>
        this.shareservice.getLicenseDetails(action.accountid).pipe(
          map((response: any) => {
            return commonActions.getLicenseDetailsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.getLicenseDetailsFailure(error))
          )
        )
      )
    );
  });
}
