import { createAction, props, createActionGroup } from '@ngrx/store';
import { ConsentData } from 'src/app/appdata/consent.model';
import { ConsentDataPayload } from 'src/app/services/consent.service';

export const getconsentAction = createActionGroup({
  source: 'Fetch Consent Data',
  events: {
    fetchconsentdata: props<{
      payload: ConsentDataPayload;
    }>(),
    fetchconsentdataSuccess: props<{ data: ConsentData[] }>(),
    fetchconsentdataFailure: props<{ error: any }>(),
  },
});

export const initiatebulkupload = createActionGroup({
  source: 'Bulk Optin Data',
  events: {
    bulkupload: props<{
      file: File;
      consent_status: string;
      accountid: string;
      wabano: string;
      created: string;
      bsp: string;
      channel: string;
      bspstatus: string;
      bspid: string;
    }>(),
    bulkuploadSuccess: props<{ data: any }>(),
    bulkuploadFailure: props<{ error: any }>(),
  },
});

export const queuestatus = createActionGroup({
  source: 'Queue Status',
  events: {
    getqueuestatus: props<{
      id: string;
    }>(),
    getqueuestatusSuccess: props<{ data: any }>(),
    getqueuestatusFailure: props<{ error: any }>(),
  },
});

export const initiatebulkupdate = createAction(
  '[Consent] Bulk Update Data',
  props<{ updatedata: any }>()
);
export const initiatebulkupdateSuccess = createAction(
  '[Consent] Bulk Update Data Success',
  props<{ data: any }>()
);
export const initiatebulkupdateFailure = createAction(
  '[Consent] Bulk Update Data Failure',
  props<{ error: any }>()
);

export const searchdata = createActionGroup({
  source: 'Search Filter Data',
  events: {
    sendsearchdata: props<{
      payload: ConsentDataPayload;
    }>(),
    searchdataSuccess: props<{ data: any }>(),
    searchdataFailure: props<{ error: any }>(),
  },
});

export const searchMobileNo = createActionGroup({
  source: 'Search Mobile Number',
  events: {
    searchmobileno: props<{
      payload: ConsentDataPayload;
    }>(),
    searchmobilenoSuccess: props<{ data: any }>(),
    searchmobilenoFailure: props<{ error: any }>(),
  },
});
