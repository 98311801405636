import { createAction, createActionGroup, props } from '@ngrx/store';
import { CampaginListData } from 'src/app/appdata/campaign.model';
import { CampaignVariables } from 'src/app/services/campaign.service';

export const fetchcampaignslist = createAction(
  '[Campaigns] Fetch Campaigns List',
  props<{ payload: CampaignVariables }>()
);
export const fetchcampaignslistSuccess = createAction(
  '[Campaigns] Fetch Campaigns List Success',
  props<{ data: CampaginListData }>()
);
export const fetchcampaignslistFailure = createAction(
  '[Campaigns] Fetch Campaigns List Failure',
  props<{ error: any }>()
);

export const createCampaign = createAction(
  '[Campaigns] Create Campaign',
  props<{ datapayload: any }>()
);
export const createCampaignSuccess = createAction(
  '[Campaigns] Create Campaign Success',
  props<{ data: any }>()
);
export const createCampaignFailure = createAction(
  '[Campaigns] Create Campaign Failure',
  props<{ error: any }>()
);

export const editCampaignData = createAction(
  '[Campaigns] Edit Campaign Data',
  props<{ data: any }>()
);

export const updateCampaignAction = createActionGroup({
  source: 'Update Campaign Data',
  events: {
    updateCampaign: props<{ datapayload: any }>(),
    updateCampaignSuccess: props<{ data: any }>(),
    updateCampaignFailure: props<{ error: any }>(),
  },
});

export const saveCampaignAction = createActionGroup({
  source: 'Save Campaign Data',
  events: {
    saveCampaign: props<{ datapayload: any }>(),
    saveCampaignSuccess: props<{ data: any }>(),
    saveCampaignFailure: props<{ error: any }>(),
  },
});

export const FetchCampaignDetails = createAction(
  '[Campaigns] Fetch Campaign Details',
  props<{ id: string; accountid: string }>()
);
export const FetchCampaignDetailsSuccess = createAction(
  '[Campaigns] Fetch Campaign Details Success',
  props<{ data: any; payload: any }>()
);
export const FetchCampaignDetailsFailure = createAction(
  '[Campaigns] Fetch Campaign Details Failure',
  props<{ error: any }>()
);

export const FetchCampaignAnalyticsDtls = createAction(
  '[Campaigns] Fetch Campaign Analytics Details',
  props<{ data: any }>()
);
export const FetchCampaignAnalyticsDtlsSuccess = createAction(
  '[Campaigns] Fetch Campaign Analytics Details Success',
  props<{ data: any }>()
);
export const FetchCampaignAnalyticsDtlsFailure = createAction(
  '[Campaigns] Fetch Campaign Analytics Details Failure',
  props<{ error: any }>()
);

export const ClearFetchCampaignError = createAction(
  '[Campaigns] Clear Fetch Campaign Error'
);

export const CampaignViewDtls = createAction(
  '[Campaigns] Campaign View Details',
  props<{ id: string; accountid: string }>()
);

export const CampaignViewDtlsSuccess = createAction(
  '[Campaigns] Campaign View Details Success',
  props<{ data: any; payload: any }>()
);

export const CampaignViewDtlsFailure = createAction(
  '[Campaigns] Campaign View Details Failure',
  props<{ error: any }>()
);

export const FetchCampaignValidation = createAction(
  '[Campaigns] Fetch Campaign Validation',
  props<{ id: string }>()
);
export const FetchCampaignValidationSuccess = createAction(
  '[Campaigns] Fetch Campaign Validation Success',
  props<{ data: any }>()
);
export const FetchCampaignValidationFailure = createAction(
  '[Campaigns] Fetch Campaign Validation Failure',
  props<{ error: any }>()
);

export const campaignPreprocessingData = createAction(
  '[Campaigns] Campaign Preprocessing Data',
  props<{ datapayload: any }>()
);
export const campaignPreprocessingDataSuccess = createAction(
  '[Campaigns] Campaign Preprocessing Data Success',
  props<{ data: any }>()
);
export const campaignPreprocessingDataFailure = createAction(
  '[Campaigns] Campaign Preprocessing Data Failure',
  props<{ error: any }>()
);

export const resetCampaignData = createAction(
  '[Campaigns] Reset Campaign Data'
);

export const campaignPreviewData = createAction(
  '[Campaigns] Campaign Preview Data',
  props<{ campaignPreview: any }>()
);

export const resetCampaignPreviewData = createAction(
  '[Campaigns] Reset Campaign Preview Data'
);

export const CampaignViewTemplate = createAction(
  '[Campaigns] Campaign View Template',
  props<{ payload: any }>()
);

export const resetCampaignViewData = createAction(
  '[Campaigns] Reset Campaign View Template'
);

export const deleteCampaignAction = createActionGroup({
  source: 'Delete Campaign Data',
  events: {
    deleteCampaign: props<{ datapayload: any }>(),
    deleteCampaignSuccess: props<{ data: any }>(),
    deleteCampaignFailure: props<{ error: any }>(),
  },
});
