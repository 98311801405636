<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader class="skeleton_loader" *ngFor="let item of skeletonData" [theme]="item"></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->

<!-- View Role -->
<ng-template #roletemp>
  <div class="card">
    <div class="top_header_options">
      <div class="header_left">
        <div class="d-flex align-items-center justify-content-between gap-2">
          <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
            <em class="bi bi-chevron-left"></em>
          </button>
          <div class="d-flex align-items-center gap-1">
            <h4>{{ viewRoleItem.role_name | titlecase }}</h4>
            <small>{{ getControls(viewRoleItem.rbac_policies) }}</small>
            <div class="btn btn-sm btn-icon" (click)="onEditRole(viewRoleItem)">
              <em class="bi-pencil-fill"></em>
            </div>
          </div>
        </div>
      </div>
      <div class="header_right">
        <!-- <div class="act_status">
          <span class="me-2">Inactive</span>
          <mat-slide-toggle [(ngModel)]="viewRoleItem.is_active"> Active</mat-slide-toggle>
          <span class="ms-2">Active</span>
        </div> -->
      </div>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-body px-6">
      <div class="role_view_details">
        <div class="row justify-content-between mt-4">
          <div class="col-6">
            <div class="item_detail"><label>Description</label>
              <h5 class="m-0">{{ viewRoleItem.desc }}</h5>
            </div>
            <!-- <h5 for="desc">Description</h5>
            <p id="desc">
              {{ viewRoleItem.desc }}
            </p> -->
          </div>
          <div class="col-6 justify-content-end d-flex">
            <div class="d-none ">
              <label>Role Users</label>
              <div class="avatar-group avatar-group-sm avatar-circle d-block">
                <span class="avatar" data-toggle="tooltip" data-placement="top" title="Ella Lauda">
                  <img class="avatar-img" src="assets/img/u1.jpg" alt="Image Description" />
                </span>
                <span class="avatar" data-toggle="tooltip" data-placement="top" title="David Harrison">
                  <img class="avatar-img" src="assets/img/u2.jpg" alt="Image Description" />
                </span>
                <span class="avatar avatar-soft-dark" data-toggle="tooltip" data-placement="top" title="Antony Taylor">
                  <span class="avatar-initials">A</span>
                </span>
                <span class="avatar avatar-soft-info" data-toggle="tooltip" data-placement="top" title="Sara Iwens">
                  <span class="avatar-initials">S</span>
                </span>
                <span class="avatar" data-toggle="tooltip" data-placement="top" title="Finch Hoot">
                  <img class="avatar-img" src="assets/img/u3.jpg" alt="Image Description" />
                </span>
                <span class="avatar avatar-light avatar-circle" data-toggle="tooltip" data-placement="top"
                  title="Sam Kart, Amanda Harvey and 1 more">
                  <span class="avatar-initials">+3</span>
                </span>
              </div>
            </div>
            <!-- <div class="role_users text-end">
              <label for="roleUsers">Role Users</label> -->

            <!-- <div class="users_list">
                  <img
                    class="img-fluid avatar-img rounded-circle"
                    src="assets/img/u1.jpg"
                    alt="User 1"
                  />
                  <img
                    class="img-fluid avatar-img rounded-circle"
                    src="assets/img/u2.jpg"
                    alt="User 1"
                  />
                  <img
                    class="img-fluid avatar-img rounded-circle"
                    src="assets/img/u3.jpg"
                    alt="User 1"
                  />
                  <img
                    class="img-fluid avatar-img rounded-circle"
                    src="assets/img/u4.jpg"
                    alt="User 1"
                  />
                  <img
                    class="img-fluid avatar-img rounded-circle"
                    src="assets/img/u5.jpg"
                    alt="User 1"
                  />
                </div> -->
            <!-- </div> -->
          </div>
        </div>

        <mat-divider class="my-4"></mat-divider>

        <div class="role_persmissions">
          <div class="sec_title mb-3">
            <h3>Permissions</h3>
          </div>

           <!-- Multi Roles List -->
           <div class="row row-cols-4 row-gap-4">
            <div class="col" *ngFor="let item of multiRoles | keyvalue">
              <div class="card">
                <div class="card-body p-1 d-flex align-items-center justify-content-between">
                  <h4>{{ item.key | roleCase }}</h4>
                  <circle-progress
                    [percent]="100"
                    [radius]="15"
                    [showSubtitle]="false"
                    [outerStrokeWidth]="2"
                    [innerStrokeWidth]="0"
                    [outerStrokeColor]="'var(--app-primary)'"
                    [animation]="true"
                    [animationDuration]="300"
                    [titleFontSize]="8"
                    [titleColor]="'var(--app-dark)'"
                  ></circle-progress>
                  
                </div>
                <mat-divider class="my-3"></mat-divider>
                <div class="permission_set" *ngIf="item.value || !item.value">
                  <ul class="list-inline-flex list-unstyled list-divider small mb-0">
                    <li class="list-inline-item" *ngIf="item.value['can_create']">Create</li>
                    <li class="list-inline-item" *ngIf="item.value['can_view']">Read</li>
                    <li class="list-inline-item" *ngIf="item.value['can_delete']">Delete</li>
                    <li class="list-inline-item" *ngIf="item.value['can_update']">Update</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- END Multi Roles List -->

          <!-- Single Role List -->
          <div class="row row-cols-4 row-gap-4 mt-4">
            <div class="col" *ngFor="let item of singleRoles | keyvalue">
              <div class="card">
                <div class="card-body p-1 d-flex align-items-center justify-content-between">
                  <h4>{{ item.key | roleCase }}</h4>
                  <i class="bi bi-check2-circle fs-2 text-success"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- END Single Role List -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END View Role -->