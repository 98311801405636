import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError } from 'rxjs';

export interface CampaignVariables {
  accountid: string;
  channel: string;
  bspid: string;
  wabano: string;
  campaign_name?: string;
  template_category?: string;
  page?: number | null;
  size?: number | null;
  category?: string | null;
  type?: string | null;
  tag?: string | null;
  profile?: string | null;
  status?: string | null;
  startdate?: string | null;
  enddate?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private http: HttpClient) {}

  fetchcampaigns(payload: CampaignVariables) {
    const url =
      environment.backendurl +
      `/api/campaign/${payload.accountid}/${payload.channel}/${payload.bspid}/${payload.wabano}`;

    let params = new HttpParams();
    if (payload.campaign_name) {
      params = params.set('campaign_name', payload.campaign_name);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.status) {
      params = params.set('status', payload.status);
    }
    if (payload.category) {
      params = params.set('category', payload.category);
    }
    if (payload.type) {
      params = params.set('type', payload.type);
    }
    if (payload.tag) {
      params = params.set('tags', payload.tag);
    }
    if (payload.template_category) {
      params = params.set('template_category', payload.template_category);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createcampaign(payload: any) {
    const url = environment.backendurl + `/api/campaign/send-campaign`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updatecampaign(payload: any) {
    const url = environment.backendurl + `/api/campaign/update-campaign`;
    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  savecampaign(payload: any) {
    const url = environment.backendurl + `/api/campaign/save-campaign`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getCampaginById(id: string) {
    const url = environment.backendurl + `/api/campaign/${id}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getCampaignAnalyticsData(payload: any) {
    const url =
      environment.backendurl +
      `/api/analytics/view-analytics/${payload.accountid}`;

    let params = new HttpParams();
    if (payload.id) {
      params = params.set('campaign_id', payload.id);
    }

    if (payload?.startdate) {
      params = params.set('start_date', payload.startdate);
    }

    if (payload?.enddate) {
      params = params.set('end_date', payload.enddate);
    }

    if (payload?.status) {
      params = params.set('status', payload.status);
    }

    if (payload?.page) {
      params = params.set('page', payload.page);
    }

    if (payload?.size) {
      params = params.set('size', payload.size);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  campaignValidataionStatus(id: string) {
    const url = environment.backendurl + `/api/campaign/validation/${id}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  campaignPreprocessingData(payload: any) {
    const url = environment.backendurl + `/api/campaign/data-preprocessing`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteCampaigns(payload: Array<string>) {
    const url = environment.backendurl + `/api/campaign`;
    let params = new HttpParams();
    if (payload) {
      params = params.set('campaignids', payload.toString());
    }
    return this.http.delete(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
