import { createAction, props } from '@ngrx/store';
import { ChannelData } from 'src/app/appdata/channeldata.model';

export const getChannelCredentials = createAction(
  '[Dashboard] Fetch Channel Credentials',
  props<{ payload: any }>()
);
export const getChannelCredentialsSuccess = createAction(
  '[Dashboard] Fetch Channel Credentials Success',
  props<{ data: ChannelData }>()
);
export const getChannelCredentialsFailure = createAction(
  '[Dashboard] Fetch Channel Credentials Failure',
  props<{ error: any }>()
);

export const updateChannelCredentials = createAction(
  '[Dashboard] Update Channel Credentials',
  props<{ payload: any }>()
);
export const updateChannelCredentialsSuccess = createAction(
  '[Dashboard] Update Channel Credentials Success',
  props<{ data: ChannelData }>()
);
export const updateChannelCredentialsFailure = createAction(
  '[Dashboard] Update Channel Credentials Failure',
  props<{ error: any }>()
);

export const resetChannelCredentials = createAction(
  '[Dashboard] Reset Channel Credentials'
);
