import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCustAnalytics from './analytics.reducers';

export const selectControlCenterAnalyticsData =
  createFeatureSelector<fromCustAnalytics.State>('cust_analytics');

export const selectCCAnalyticsData = createSelector(
  selectControlCenterAnalyticsData,
  fromCustAnalytics.getCustAnalytics
);
