import { Action, createReducer, on } from '@ngrx/store';
import * as campaignactions from './campaign.actions';
import { CampaginListData } from 'src/app/appdata/campaign.model';
import { AnalyticsData } from 'src/app/appdata/analytics.model';
import { deleteCampaignAction } from './campaign.actions';

export interface State {
  campaignList: CampaginListData;
  campaignValidationDtls: any;
  campaign_data: {
    individual_data: any;
    analytics_data: AnalyticsData;
  };
  preprocessingRes: any;
  campaignSaveRes: any;
  campaignUpdateRes: any;
  campaignRes: any;
  deleteCampaignRes: any;
  error: any;
}

export const initialState: State = {
  campaignList: null,
  campaignValidationDtls: null,
  campaign_data: {
    individual_data: null,
    analytics_data: null,
  },
  preprocessingRes: null,
  campaignSaveRes: null,
  campaignUpdateRes: null,
  campaignRes: null,
  deleteCampaignRes: null,
  error: null,
};

export interface CampaignPreviewState {
  campaignTemplateData: any;
  campaignPreview: any;
}

export const CampaignPreviewInitialState: CampaignPreviewState = {
  campaignTemplateData: null,
  campaignPreview: null,
};

const campaignpreviewReducer = createReducer(
  CampaignPreviewInitialState,
  on(
    campaignactions.campaignPreviewData,
    (state, { campaignPreview }): CampaignPreviewState => ({
      ...state,
      campaignPreview: campaignPreview,
      campaignTemplateData: null,
    })
  ),
  on(
    campaignactions.resetCampaignPreviewData,
    (state): CampaignPreviewState => ({
      ...state,
      campaignPreview: null,
      campaignTemplateData: null,
    })
  ),
  on(
    campaignactions.CampaignViewTemplate,
    (state, { payload }): CampaignPreviewState => ({
      ...state,
      campaignPreview: null,
      campaignTemplateData: payload,
    })
  ),
  on(
    campaignactions.resetCampaignViewData,
    (state): CampaignPreviewState => ({
      ...state,
      campaignPreview: null,
      campaignTemplateData: null,
    })
  )
);

const campaignReducer = createReducer(
  initialState,
  on(
    campaignactions.fetchcampaignslistSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: data,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.fetchcampaignslistFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.FetchCampaignValidationSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: data,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.FetchCampaignValidationFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.FetchCampaignDetailsSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: {
        individual_data: data,
        analytics_data: null,
      },
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.FetchCampaignDetailsFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.CampaignViewDtlsSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: {
        individual_data: data,
        analytics_data: null,
      },
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.CampaignViewDtlsFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.FetchCampaignAnalyticsDtlsSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: {
        individual_data: state.campaign_data.individual_data,
        analytics_data: data,
      },
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.FetchCampaignAnalyticsDtlsFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: {
        individual_data: state.campaign_data.individual_data,
        analytics_data: null,
      },
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(campaignactions.ClearFetchCampaignError,
    (state, action) => {
      return {
        ...state,
        error: null,
      };
    }),
  on(
    campaignactions.createCampaignSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: data,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.createCampaignFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.updateCampaignAction.updateCampaignSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: data,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.updateCampaignAction.updateCampaignFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.saveCampaignAction.saveCampaignSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: data,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.saveCampaignAction.saveCampaignFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.campaignPreprocessingDataSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      preprocessingRes: data,
      campaignSaveRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.campaignPreprocessingDataFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  ),
  on(
    campaignactions.resetCampaignData,
    (state): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: null,
    })
  ),
  on(
    campaignactions.deleteCampaignAction.deleteCampaignSuccess,
    (state, { data }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: data,
      error: null,
    })
  ),
  on(
    campaignactions.deleteCampaignAction.deleteCampaignFailure,
    (state, { error }): State => ({
      ...state,
      campaignList: null,
      campaignValidationDtls: null,
      campaign_data: null,
      campaignSaveRes: null,
      preprocessingRes: null,
      campaignUpdateRes: null,
      campaignRes: null,
      deleteCampaignRes: null,
      error: error,
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return campaignReducer(state, action);
}

export function campaignpreviewreducer(
  state: CampaignPreviewState | undefined,
  action: Action
): any {
  return campaignpreviewReducer(state, action);
}

export const getCampaginState = (state: State) => {
  return {
    campaignList: state.campaignList,
    campaignValidationDtls: state.campaignValidationDtls,
    campaign_data: state.campaign_data,
    campaignSaveRes: state.campaignSaveRes,
    preprocessingRes: state.preprocessingRes,
    campaignUpdateRes: state.campaignUpdateRes,
    campaignRes: state.campaignRes,
    deleteCampaignRes: state.deleteCampaignRes,
    error: state.error,
  };
};

export const getCampaginPreview = (state: CampaignPreviewState) => {
  return {
    campaignPreview: state.campaignPreview,
    campaignTemplateData: state.campaignTemplateData,
  };
};
