import { createAction, props } from '@ngrx/store';
import { AnalyticsData } from 'src/app/appdata/analytics.model';

export const fetchccanalytics = createAction(
  '[Analytics] Fetch Customer Center Analytics',
  props<{
    accountid: string;
    wabano?: string;
    startdate?: string;
    enddate?: string;
    page?: number;
    size?: number;
    campaign_id?: string;
  }>()
);
export const fetchccanalyticsSuccess = createAction(
  '[Analytics] Fetch Customer Center Analytics Success',
  props<{ data: AnalyticsData }>()
);
export const fetchccanalyticsFailure = createAction(
  '[Analytics] Fetch Customer Center Analytics Failure',
  props<{ error: any }>()
);
