import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskText',
})
export class MaskTextPipe implements PipeTransform {
  transform(value: string, maskSymbol: string = '*'): string {
    if (!value) return '';

    return maskSymbol.repeat(value.length);
  }
}
