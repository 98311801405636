import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleCase',
})
export class RoleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Split the string into words based on underscore
    const words = value.split('_');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      if (
        word.toLowerCase() === 'waba' ||
        word.toLowerCase() === 'acl' ||
        word.toLowerCase() === 'bsp'
      ) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });

    // Join the words back together with a space between them
    return capitalizedWords.join(' ');
  }
}
