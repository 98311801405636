import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthUserData, RbacPolicies } from 'src/app/appdata/auth.model';
import { ReportTemplateVariables } from 'src/app/services/reports.service';
import { AlertService } from 'src/app/alert/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import * as reportactions from 'src/app/customer/reports/store/reports.actions';
import { selectReportDetails } from '../store/reports.selectors';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { skeleton_data } from '../../whatsapp-preview/whatsapp-preview.data';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-summary-reports',
  templateUrl: './summary-reports.component.html',
  styleUrls: ['./summary-reports.component.scss'],
})
export class SummaryReportsComponent implements OnInit, OnDestroy {
  userDetails: AuthUserData;
  permissions: RbacPolicies;
  reportVariableData: ReportTemplateVariables = {
    account_id: null,
    channel: null,
    wabano: null,
    template_name: null,
    report_name: null,
    category: null,
    startdate: null,
    enddate: null,
    page: null,
    size: null,
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  channelSub: Subscription;
  isLoading = true;
  summaryDataSubscription: Subscription;
  skeletonData = skeleton_data;
  showDownloadOption = false;
  isFirst: boolean = true;

  reportdata: any = {
    marketing: {
      submitted: 0,
      sent: 0,
      optout: 0,
      delivered: 0,
      read: 0,
      failed: 0,
      pending: 0,
      unread: 0,
    },
    utility: {
      submitted: 0,
      sent: 0,
      optout: 0,
      delivered: 0,
      read: 0,
      failed: 0,
      pending: 0,
      unread: 0,
    },
    authentication: {
      submitted: 0,
      sent: 0,
      optout: 0,
      delivered: 0,
      read: 0,
      failed: 0,
      pending: 0,
      unread: 0,
    },
    // file_name: null,
    // file_url: null,
  };

  constructor(
    private readonly store: Store,
    private alertMsg: AlertService,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(reportactions.clearreportsdata());
    this.shareservice.unsubscribe();
    this.shareservice.customDateAction.next(30);

    this.subscribeToProfileData();
    this.subscribeToBA();
    this.subscribeToReportsData();
    // this.shareservice.datePickerObject.next(null);
    this.subscribeToDatePicker();
  }

  subscribeToProfileData() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
        }
      });
  }

  subscribeToBA() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.reportVariableData = JSON.parse(
          JSON.stringify(this.reportVariableData)
        );
        this.reportVariableData.account_id =
          value.channel_credentials.account_id;
          this.reportVariableData.wabano =
          value.channel_credentials.waba_number;
        this.reportVariableData.channel = value.channel_credentials.channel;
        this.isLoading = true;

        if (!this.isFirst) {
          this.store.dispatch(
            reportactions.summaryreports.viewSummaryReports({
              payload: this.reportVariableData,
            })
          );
        }
        this.isFirst = false;
      }
    });
  }

  subscribeToReportsData() {
    this.store
      .select(selectReportDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.summaryreport) {
          this.isLoading = false;
          if (res.summaryreport.status_code === 200) {
            const stats = res.summaryreport.data.status_counts;
            if (stats.authentication) {
              this.reportdata.authentication = stats.authentication;
            }
            if (stats.marketing) {
              this.reportdata.marketing = stats.marketing;
            }
            if (stats.utility) {
              this.reportdata.utility = stats.utility;
            }
            if (stats.total) {
              this.reportdata.total = stats.total;
            }

            // this.reportdata.file_name = res.summaryreport.data.file_name;
            // this.reportdata.file_url = res.summaryreport.data.file_url;
            this.showDownloadOption = true;
          }
        } else if (res.error) {
          this.isLoading = false;
          this.resetReportData();
          if (res.error?.message) {
            this.alertMsg.alertDanger('Error!', res.error.message);
          }
        }
      });
  }

  subscribeToDatePicker() {
    const datepicker = this.shareservice.datePickerObject.subscribe(
      (value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;

            this.reportVariableData = JSON.parse(
              JSON.stringify(this.reportVariableData)
            );
            this.reportVariableData.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.reportVariableData.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
            this.isLoading = true;
            this.store.dispatch(
              reportactions.summaryreports.viewSummaryReports({
                payload: this.reportVariableData,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.reportVariableData = JSON.parse(
              JSON.stringify(this.reportVariableData)
            );
            this.reportVariableData.startdate = null;
            this.reportVariableData.enddate = null;
            this.isLoading = true;
            this.store.dispatch(
              reportactions.summaryreports.viewSummaryReports({
                payload: this.reportVariableData,
              })
            );
          }
        }
      }
    );
    this.shareservice.subscribe(datepicker);
  }

  getSummaryData() {
    this.isLoading = true;
    this.store.dispatch(
      reportactions.summaryreports.viewSummaryReports({
        payload: this.reportVariableData,
      })
    );
  }

  downloadData() {
    // FileSaver.saveAs(this.reportdata.file_url, this.reportdata.file_name);
    // setTimeout(() => {
    //   this.store.dispatch(
    //     reportactions.summaryreports.deleteSummaryReportFile({
    //       fiename: this.reportdata.file_name,
    //     })
    //   );
    // }, 5000);

    const gcsvData = this.generateCSVData();
    this.shareservice.exportFile(
      gcsvData,
      "text/csv;charset=utf-8;",
      "summaryreport"
    );
  }

  generateCSVData(): string {
    let csvData =
      "Conversations,Submitted,Sent,Delivered,Read,Failed,Pending,Unread\n";
    Object.keys(this.reportdata).forEach((category: string) => {
      const data = this.reportdata[category];
      csvData += `"${category}",${data?.submitted},${data?.sent},${data?.delivered},${data?.read},${data?.failed},${data?.pending},${data?.unread}\n`;
    });
    return csvData;
  }


  resetReportData() {
    this.reportdata = JSON.parse(JSON.stringify(this.reportdata));
    // Loop through each property of the reportdata object
    for (const category in this.reportdata) {
      if (Object.prototype.hasOwnProperty.call(this.reportdata, category)) {
        // Loop through each property of the category object
        for (const metric in this.reportdata[category]) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.reportdata[category],
              metric
            )
          ) {
            // Set the value of the metric to 0
            this.reportdata[category][metric] = 0;
          }
        }
      }
    }
    this.showDownloadOption = false;
  }

  ngOnDestroy(): void {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.summaryDataSubscription.unsubscribe();
    // this.shareservice.datePickerObject.next(null);
    this.showDownloadOption = false;
  }
}
