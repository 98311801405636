import { createAction, props } from '@ngrx/store';
import { AuthData } from 'src/app/appdata/auth.model';

export const authenticateUser = createAction(
  '[Login] Authenticate User',
  props<{ payload: any }>()
);
export const authenticateUserSuccess = createAction(
  '[Login] Authenticate User Success',
  props<{ data: AuthData }>()
);
export const authenticateUserFailure = createAction(
  '[Login] Authenticate User Failure',
  props<{ error: any }>()
);

export const logoutUser = createAction('[Logout] Logout User');

export const setUserProfile = createAction(
  '[User] Set User Profile',
  props<{ payload: any }>()
);

export const setLogoutTimer = createAction(
  '[Logout] Set Logout Timer User',
  props<{ time: any }>()
);

export const forgotPassword = createAction(
  '[Login] Forgot Password',
  props<{ payload: any }>()
);
export const forgotPasswordSuccess = createAction(
  '[Login] Forgot Password Success',
  props<{ data: any }>()
);
export const forgotPasswordFailure = createAction(
  '[Login] Forgot Password Failure',
  props<{ error: any }>()
);

export const requestForAccess = createAction(
  '[Login] Request For Access',
  props<{ payload: any }>()
);
export const requestForAccessSuccess = createAction(
  '[Login] Request For Access Success',
  props<{ request_access: any }>()
);
export const requestForAccessFailure = createAction(
  '[Login] Request For Access Failure',
  props<{ error: any }>()
);
