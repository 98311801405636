<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<!-- Main Content Container -->
<main role="main" class="main" id="cust_dashboard">
  <div class="content container-fluid">
    <div class="card">
      <div class="header">
        <div class="top_header_options top_header_stats_options">
          <h3 class="main_title">Overall Performance Trends</h3>

          <!-- Calender Date Range Selector -->
          <app-datepicker [last]="'7'"></app-datepicker>
          <!-- END Calender Date Range Selector -->
        </div>

        <!-- Stats Section -->
        <div class="stats_sec">
          <!-- Submitted Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon submitted">
                <i class="bi bi-clock"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Submit option typically refers to the action of creating or preparing a campaign. This excludes Opt-out and Blocklist recipients."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Submitted</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.submitted
                : 0
            }}</span>
          </div>
          <!-- END Submitted Stats -->

          <!-- Sent Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon sent">
                <i class="bi bi-check2"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Sent section usually displays campaigns that have already been dispatched or delivered to the intended recipient"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Sent</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.sent
                : 0
            }}</span>
          </div>
          <!-- END Sent Stats -->

          <!-- Delivered Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon delivered">
                <i class="bi bi-check2-all"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Delivered status for comprehensive tracking of successfully sent services or messages, ensuring efficient delivery management."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Delivered</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.delivered
                : 0
            }}</span>
          </div>
          <!-- END Delivered Stats -->

          <!-- Read Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon read">
                <i class="bi bi-check2-all"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Messages or notifications that have been viewed or accessed by the user or the system."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Read</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.read
                : 0
            }}</span>
          </div>
          <!-- END Read Stats -->

          <!-- Unread Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon unread">
                <i class="bi bi-eye-slash"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Messages or notifications that haven't been opened or viewed yet, indicating pending or new items requiring attention or action."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Unread</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.unread
                : 0
            }}</span>
          </div>
          <!-- END Unread Stats -->

          <!-- Pending Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon pending">
                <i class="bi bi-stopwatch-fill"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Pending option usually refers to messages or tasks that are queued or awaiting processing."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Pending</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.pending
                : 0
            }}</span>
          </div>
          <!-- END Pending Stats -->

          <!-- Failed Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon failed">
                <i class="bi bi-x-circle-fill"></i>
              </div>
              <em
                class="bi-info-circle"
                matTooltip="Failed option typically represents messages or tasks that were unsuccessful in delivery. This excludes Opt-out and Blocklist recipients."
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Failed</h6>
            <span class="lead">{{
              analytics_data
                ? analytics_data.data.status_counts.overall.failed
                : 0
            }}</span>
          </div>
          <!-- END Failed Stats -->
        </div>
        <!-- END Stats Section -->
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="mainTemplate | async"></ng-container>
    <ng-template #chartTemp>
      <div class="card mt-2">
        <!-- Trend Analysis Graphically -->
        <div class="header">
          <div class="top_header_options">
            <h3 class="main_title">Trend Analysis</h3>
            <div class="d-flex align-items-center">
              <ul class="list-inline list-inline-flex mb-0">
                <!-- Graph Type Selection -->
                <li class="list-inline-item">
                  <ng-select
                    class="selection"
                    [items]="allCharts"
                    [(ngModel)]="selectedChart"
                    [closeOnSelect]="true"
                    [clearable]="false"
                    [searchable]="false"
                    placeholder="Filters"
                    (change)="onChartSwitch($event)"
                  >
                  </ng-select>
                </li>
                <!-- END Graph Type Selection -->

                <!-- Stats Type Selection -->
                <li class="list-inline-item">
                  <ng-select
                    class="selection"
                    [items]="datasets"
                    bindValue="label"
                    [(ngModel)]="selectedDataStat"
                    [closeOnSelect]="true"
                    [clearable]="true"
                    [searchable]="true"
                    placeholder="Stat Type"
                    (ngModelChange)="onStatsSwitch($event)"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <span
                        class="stat_color"
                        [ngStyle]="{ background: item.backgroundColor }"
                      ></span>
                      {{ item.label }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                      <span
                        class="stat_color"
                        [ngStyle]="{ background: item.backgroundColor }"
                      ></span>
                      {{ item.label }}
                    </ng-template>
                  </ng-select>
                </li>
                <!-- END Stats Type Selection -->
              </ul>
            </div>
          </div>
          <div class="stats_graph mt-4 mb-5">
            <canvas
              class="chart"
              baseChart
              [data]="chartData | async"
              [options]="chartOptions | async"
              [type]="chartType | async"
            >
            </canvas>
          </div>
        </div>
        <!-- END Trend Analysis Graphically -->
      </div>
    </ng-template>
    <ng-template #skeletonTemp>
      <div class="card mt-2">
        <ngx-skeleton-loader
          class="skeleton_loader"
          *ngFor="let item of skeletonData"
          [theme]="item"
        ></ngx-skeleton-loader>
      </div>
    </ng-template>
    <ng-template #noanalyticsdata>
      <div class="card mt-2">
        <div class="empty_template text-center my-4">
          <img class="w-30" src="assets/img/no_data.svg" alt="No Data" />
          <h4 class="mb-3">No Data Found</h4>
        </div>
      </div>
    </ng-template>
  </div>
</main>
<!-- END Main Content Container -->
