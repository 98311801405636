<!-- Main Content Container -->
<main role="main" class="main" id="business_settings">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Business Profile View -->
<ng-template #showBusinessProfile>
  <!-- Title Section -->
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Business Settings</h3>

      <!-- Business Profile Edit Button -->
      <!-- <button class="btn btn-secondary" (click)="onEdit()">
        <em class="bi-pencil-fill"></em>
        Edit
      </button> -->
      <!-- END Business Profile Edit  Button -->
    </div>
  </div>
  <!-- END Title Section -->

  <div class="card mt-2">
    <div class="card-body">
      <!-- Business Profile Image -->
      <div class="row">
        <div class="col">
          <div class="avatar avatar-xl avatar-circle">
            <img
              class="avatar-img"
              [src]="channel_credentails?.profile_pic"
              alt="Business Profile Picture"
            />
          </div>
        </div>
      </div>
      <!-- END Business Profile Image -->

      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 my-4">
        <!-- Business Profile -->
        <div class="col">
          <label>Business Profile</label>
          <h5>
            {{ channel_credentails.business_name }}
          </h5>
        </div>
        <!-- END Business Profile -->

        <!-- Business Email ID -->
        <div class="col">
          <label class="content-label">Business Email ID</label>
          <h5>{{ channel_credentails.email }}</h5>
        </div>
        <!-- END Business Email ID -->

        <!-- Business Industry -->
        <div class="col">
          <label class="content-label">Industry</label>
          <h5>{{ channel_credentails.industry }}</h5>
        </div>
        <!-- END Business Industry -->
      </div>

      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 my-4">
        <!-- Business Description -->
        <div class="col">
          <label class="content-label">Business Description</label>
          <h5>
            {{ channel_credentails?.business_description }}
          </h5>
        </div>
        <!-- END Business Description -->

        <!-- Business Address -->
        <div class="col">
          <label class="content-label">Address</label>
          <h5>N/A</h5>
        </div>
        <!-- END Business Address -->

        <!-- Business Callback URL -->
        <div class="col">
          <div class="callback">
            <label for="cb_url">Call Back URL</label>
            <h5 id="cb_url">{{ channel_credentails.callback_url }}</h5>
            <small>(Onetime generate, not editable)</small>
          </div>
        </div>
        <!-- END Business Callback URL -->
      </div>

      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 my-4">
        <!-- Business Website -->
        <div class="col">
          <label class="content-label">Website</label>
          <h5>{{ channel_credentails.website }}</h5>
        </div>
        <!-- END Business Website -->
      </div>

      <mat-divider class="mb-4"></mat-divider>

      <!-- Integrate with WhatsApp API -->
      <h4>Integrate with WhatsApp API</h4>
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 my-4">
        <!-- Business WhatsApp Number -->
        <div class="col">
          <label>WhatsApp Number</label>
          <h5>
            {{ channel_credentails?.waba_number }}
          </h5>
        </div>
        <!-- END Business WhatsApp Number -->

        <!-- Business Source ID -->
        <div class="col">
          <label>Source ID</label>
          <h5>
            {{ channel_credentails?.id }}
          </h5>
        </div>
        <!-- END Business Source ID -->

        <!-- Business API Key -->
        <div class="col api-key-column">
          <label>API Key</label>
          <h5>N/A</h5>
          <!-- <h5>
            <span class="text-secondary">{{
              getmaskedDate(allBusinessSettingsData.apiKey, "*")
            }}</span>
            <i
              class="ms-1 bi-files text-light fs-6 pointer"
              [cdkCopyToClipboard]="allBusinessSettingsData.apiKey"
              *ngIf="!isCopied"
              (click)="copyCode()"
            ></i>
            <i
              class="ms-1 bi-check-circle-fill text-success fs-6 pointer"
              *ngIf="isCopied"
            ></i>
          </h5> -->
        </div>
        <!-- END Business API Key -->
      </div>
      <!-- END Integrate with WhatsApp API -->

      <mat-divider class="mb-4"></mat-divider>

      <!-- Webhook Details -->
      <h4>Webhook Details</h4>
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 my-4">
        <!-- Delivery URL -->
        <div class="col">
          <label for="deliveryUrl">Delivery URL</label>
          <h5 id="deliveryUrl">N/A</h5>
        </div>
        <!-- END Delivery URL -->

        <!-- Business Fallback Message -->
        <div class="col">
          <label for="defaultMsg">Default Message (Fallback)</label>
          <h5 id="defaultMsg">N/A</h5>
        </div>
        <!-- BEND usiness Fallback Message -->
      </div>
      <!-- END Webhook Details -->
    </div>
  </div>
</ng-template>
<!-- END Business Profile View -->

<!-- Edit Business Profile -->
<ng-template #editBusinessProfile>
  <div class="card p-3">
    <div class="top_header_options justify-content-start gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoView()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <!-- <span class="go_back" (click)="gotoView()">
        <em class="bi-chevron-left"></em>
      </span> -->
      <h3 class="main_title">Edit - Business Settings</h3>
    </div>
  </div>

  <div class="card mt-2">
    <form
      [formGroup]="businessProfile"
      (ngSubmit)="onSubmit(businessProfileFG)"
    >
      <div class="row m-0 justify-content-between">
        <div class="col">
          <!-- Business Profile Avatar Upload -->
          <div class="avatar avatar-xl avatar-circle avatar-uploader">
            <!-- Upload Avatar -->
            <ng-container
              *ngIf="
                !bpFC['profileImg'].value &&
                  bpFC['profileImg'].value !== 'undefined';
                else profileIconTemp
              "
            >
              <img
                id="avatarImg"
                class="avatar-img"
                src="assets/img/customer/default_avatar.jpg"
                alt="User Image"
              />

              <span class="avatar-uploader-trigger" (click)="fileInput.click()">
                <em
                  class="bi-camera-fill avatar-uploader-icon shadow-soft avatar_icon"
                ></em>
                <input
                  type="file"
                  accept="image/*"
                  class="d-none"
                  id="mp_upload_input"
                  name="userImage"
                  (change)="onAvatarUpload($event)"
                  #fileInput
                />
              </span>
            </ng-container>
            <!-- END Upload Avatar -->

            <!-- Remove Uploaded Avatar -->
            <ng-template #profileIconTemp>
              <a class="delete avatar-uploader" (click)="onAvatarRemove()">
                <em class="bi-trash-fill"></em>
              </a>
              <img
                id="avatarImg"
                class="avatar-img"
                [src]="channel_credentails.profile_pic"
                alt="profile Image"
              />
            </ng-template>
            <!-- END Remove Uploaded Avatar -->
          </div>
          <!-- END Business Profile Avatar Upload -->

          <!-- Display Name -->
          <div class="form-group mt-2">
            <label class="input-label" for="displayName"
              >Display Name
              <span class="text-danger" matTooltip="Display Name is mandatory"
                >*</span
              >
            </label>
            <input
              class="form-control"
              id="displayName"
              type="text"
              placeholder="Display Name"
              formControlName="displayName"
            />
            <!-- Display Name Error -->
            <div class="invalid-feedback help-block">
              <div
                *ngIf="bpFC['displayName'].touched || bpFC['displayName'].dirty"
              >
                <ng-container
                  *ngIf="
                    bpFC['displayName'].value === '' ||
                      bpFC['displayName'].value === null;
                    else fcInvalid
                  "
                >
                  Display name can not be empty.
                </ng-container>
                <ng-template #fcInvalid>
                  <span *ngIf="bpFC['displayName'].errors">
                    Please enter a valid display name.
                  </span>
                </ng-template>
              </div>
            </div>
            <!-- END Display Name Error -->
          </div>
          <!-- END Display Name -->

          <!-- Business Number -->
          <div class="form-group">
            <label class="input-label" for="businessNo"
              >Business Number
              <span
                class="text-danger"
                matTooltip="Business Number is mandatory"
                >*</span
              >
            </label>
            <input
              class="form-control"
              id="businessNo"
              type="tel"
              placeholder="Enter Business Number"
              formControlName="businessNo"
            />
          </div>
          <!-- END Business Number -->
        </div>
        <div class="col">
          <!-- Business Description -->
          <div class="form-group">
            <label class="input-label" for="businessDesc"
              >Business Description
              <span
                class="text-danger"
                matTooltip="Business Description is mandatory"
                >*</span
              >
            </label>
            <textarea
              class="form-control"
              formControlName="businessDesc"
              id="businessDesc"
              rows="3"
              placeholder="Enter Business Description"
              aria-label="Enter Business Description"
            >
            </textarea>

            <!-- Display Description Error -->
            <div class="invalid-feedback help-block">
              <div
                *ngIf="
                  bpFC['businessDesc'].touched || bpFC['businessDesc'].dirty
                "
              >
                <ng-container
                  *ngIf="
                    bpFC['businessDesc'].value === '' ||
                      bpFC['businessDesc'].value === null;
                    else fcInvalid
                  "
                >
                  Business description can not be empty.
                </ng-container>
                <ng-template #fcInvalid>
                  <span *ngIf="bpFC['businessDesc'].errors">
                    Please enter a valid Business description.
                  </span>
                </ng-template>
              </div>
            </div>
            <!-- END Display Description Error -->
          </div>
          <!-- END Business Description -->
        </div>
      </div>

      <mat-divider class="my-2"></mat-divider>

      <div class="form_action_btns">
        <div class="options_left"></div>
        <div class="options_right">
          <button class="btn btn-gradient" type="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- END Edit Business Profile -->
