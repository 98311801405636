<app-header [ForModule]="'Super-Admin'"></app-header>
<app-sidenav [ForModule]="'Super-Admin'"></app-sidenav>

<!-- Main Content Container -->
<main role="main" class="main" id="cust_dashboard">
  <div class="content container-fluid">
    <div class="card">
      <div class="header">
        <div class="top_header_options top_header_stats_options">
          <h3 class="main_title">Overall Performance Trends</h3>

          <!-- Calender Date Range Selector -->
            <app-datepicker></app-datepicker>
          <!-- END Calender Date Range Selector -->
        </div>

        <!-- Stats Section -->
        <div class="stats_sec">
          <!-- Submitted Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon submitted">
                <i class="bi bi-clock"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Submitted</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Submitted Stats -->

          <!-- Opt-Out Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon opt-out">
                <i class="bi bi-slash-circle"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Opt-Out</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Opt-Out Stats -->

          <!-- Sent Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon sent">
                <i class="bi bi-check2"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Sent</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Sent Stats -->

          <!-- Delivered Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon delivered">
                <i class="bi bi-check2-all"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Delivered</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Delivered Stats -->

          <!-- Read Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon read">
                <i class="bi bi-check2-all"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Read</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Read Stats -->

          <!-- Unread Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon unread">
                <i class="bi bi-eye-slash"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Unread</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Unread Stats -->

          <!-- Pending Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon pending">
                <i class="bi bi-stopwatch-fill"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Pending</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Pending Stats -->

          <!-- Failed Stats -->
          <div class="stats_item">
            <div class="stats_info_icon">
              <div class="stats_icon failed">
                <i class="bi bi-x-circle-fill"></i>
              </div>
              <em class="bi-info-circle"
                matTooltip="some info about this stats"
                matTooltipPosition="above"
              ></em>
            </div>
            <h6 class="mb-0 stats_title">Failed</h6>
            <span class="lead">260</span>
          </div>
          <!-- END Failed Stats -->
        </div>
        <!-- END Stats Section -->
      </div>
    </div>
    <div class="card mt-2">
      <!-- Trend Analysis Graphically -->
      <div class="header">
        <div class="top_header_options">
          <h3 class="main_title">Trend Analysis</h3>
          <div class="d-flex align-items-center">
            <!-- <ng-select
              class="selection"
              [items]="allCharts"
              [(ngModel)]="selectedChart"
              [closeOnSelect]="true"
              [clearable]="false"
              [searchable]="false"
              placeholder="Filters"
              (change)="onChartSwitch($event)"
            >
            </ng-select> -->
          </div>
        </div>
        <div class="stats_graph mt-4 mb-5">
          <!-- <ng-container [ngTemplateOutlet]="mainTemplate"></ng-container> -->
          <ng-template #chartTemp>
            <canvas
              class="chart"
              baseChart
              [data]="chartData"
              [options]="chartOptions"
              [type]="chartType"
            >
            </canvas>
          </ng-template>
          <ng-template #skeletonTemp>
            <ngx-skeleton-loader
              class="skeleton_loader"
              *ngFor="let item of skeletonData"
              [theme]="item"
            ></ngx-skeleton-loader>
          </ng-template>
        </div>
      </div>
      <!-- END Trend Analysis Graphically -->
    </div>
  </div>
</main>
<!-- END Main Content Container -->