import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as dashboardActions from './dashboard.actions';
import { SharedService } from 'src/app/services/shared.service';
import { ChannelData } from 'src/app/appdata/channeldata.model';

@Injectable()
export class DashboardEffects {
  constructor(private actions$: Actions, private apiservice: SharedService) {}

  getChannelCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.getChannelCredentials),
      exhaustMap((action) =>
        this.apiservice.channelCredentials(action.payload).pipe(
          map((response: ChannelData) => {
            return dashboardActions.getChannelCredentialsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(dashboardActions.getChannelCredentialsFailure(error))
          )
        )
      )
    );
  });

  updateChannelCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.updateChannelCredentials),
      exhaustMap((action) =>
        this.apiservice.updatechannelCredentials(action.payload).pipe(
          map((response: ChannelData) => {
            return dashboardActions.updateChannelCredentialsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(dashboardActions.updateChannelCredentialsFailure(error))
          )
        )
      )
    );
  });
}
