import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cc-support',
  templateUrl: './cc-support.component.html',
  styleUrls: ['./cc-support.component.scss']
})
export class CcSupportComponent implements AfterViewInit {
  displayTemplate: TemplateRef<any>;

  @ViewChild('support') private support: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
  ){}

  ngAfterViewInit(): void {
    this.displayTemplate = this.support;
    this.cd.detectChanges();
  }
}
