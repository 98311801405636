<!-- Main Content Container -->
<main role="main" class="main" id="cust_support">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Request for Support -->
<ng-template #support>
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Support</h3>
    </div>
  </div>
  <div class="card card-vh-100 mt-2 success_screen">
    <div class="text-center">
      <img
        src="assets/img/support.svg"
        alt="Request Support"
        class="success_img w-40 mb-3"
      />
      <p>Support Email : <span class="h3 text-primary"><a href="mailto:support.waba@smartping.ai">support.waba&#64;smartping.ai</a></span></p>
      <p>Support Number : <span class="h3 text-primary"><a href="tel:1800-911-1111">1800-911-1111</a></span></p>
    </div>
  </div>
</ng-template>
<!-- End Request for Support -->
