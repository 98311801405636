import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as analyticsctions from './analytics.actions';
import { SharedService } from 'src/app/services/shared.service';
import { AnalyticsData } from 'src/app/appdata/analytics.model';

@Injectable()
export class CCAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private sharedservice: SharedService
  ) {}

  getControlCenterAnalyticsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(analyticsctions.fetchccanalytics),
      exhaustMap((action) =>
        this.sharedservice
          .getAnalyticsData(
            action.accountid,
            action.wabano,
            action.startdate,
            action.enddate,
            action.page,
            action.size,
            action.campaign_id
          )
          .pipe(
            map((response: AnalyticsData) => {
              return analyticsctions.fetchccanalyticsSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(analyticsctions.fetchccanalyticsFailure(error))
            )
          )
      )
    );
  });
}
