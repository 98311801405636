<div class="content container-fluid">
  <ng-container [ngTemplateOutlet]="displayTemplate | async"></ng-container>
</div>

<!-- Empty Reports Template -->
<ng-template #noReportsTemp>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <h3 class="main_title">Reports</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Content -->
  <div class="card card-vh-100 card-flex mt-2">
    <!-- template Content -->
    <div class="empty_template text-center my-4">
      <img src="assets/img/no_report.svg" alt="No Reports Available" />
      <p class="fs-5 my-2 text-dark">No reports available</p>
      <button
        type="button"
        class="btn btn-lg btn-gradient btn-primary"
        (click)="createReport()"
      >
        <em class="bi-plus me-2"></em>Create New Report
      </button>
    </div>
    <!-- END template Content -->
  </div>
  <!-- END Content -->
</ng-template>
<!-- END Empty Reports Template -->

<!-- Display Reports Template -->
<ng-template #showReportsTemp let-data="data">
  <!-- Header -->
  <div class="card card-sticky">
    <div class="top_header_options">
      <h3 class="main_title">Reports</h3>

      <div class="btn_options">
        <!-- Create Report Button -->
        <button
          class="btn btn-primary"
          (click)="createReport()"
          *ngIf="active === 1"
        >
          <em class="bi-plus me-2"></em>Create New Report
        </button>
        <!-- END Create Report Button -->

        <!-- Create Template Button -->
        <button
          class="btn btn-primary"
          (click)="createTemplate()"
          *ngIf="active === 2"
        >
          <em class="bi-plus me-2"></em>Create Template
        </button>
        <!-- END Create Template Button -->
      </div>
    </div>
  </div>
  <!-- END Header -->

  <!-- Content -->
  <div class="card mt-2">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <!-- Report Logs -->
      <li [ngbNavItem]="1">
        <button ngbNavLink>Report Logs</button>
        <ng-template ngbNavContent>
          <app-reports-list></app-reports-list>
        </ng-template>
      </li>
      <!-- END Report Logs -->

      <!-- Templates -->
      <li [ngbNavItem]="2">
        <button ngbNavLink>Templates</button>
        <ng-template ngbNavContent>
          <app-report-templates></app-report-templates>
        </ng-template>
      </li>
      <!-- END Templates -->

      <!-- Summary Reports -->
      <li [ngbNavItem]="3">
        <button ngbNavLink>Summary Reports</button>
        <ng-template ngbNavContent>
          <app-summary-reports></app-summary-reports>
        </ng-template>
      </li>
      <!-- END Summary Reports -->
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <!-- END Content -->
</ng-template>
<!-- END Display Reports Template -->
