import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from './profiles.reducers';

export const selectProfile =
  createFeatureSelector<fromProfile.State>('profiledata');

export const selectProfileActionsData = createSelector(
  selectProfile,
  fromProfile.getProfileData
);
