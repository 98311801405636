export const skeleton_data = [
    {
        width: '60%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    },
    {
        width: '90%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    },
    {
        width: '60%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    },
    {
        width: '90%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    },
    {
        width: '60%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    },
    {
        width: '90%',
        'border-radius': '25px',
        height: '24px',
        'margin-bottom': '36px',
    }
];