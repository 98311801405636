import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-reports',
  templateUrl: './sa-reports.component.html',
  styleUrls: ['./sa-reports.component.scss']
})
export class SaReportsComponent {

}
