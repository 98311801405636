import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { Datum } from 'src/app/appdata/channeldata.model';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-business-settings',
  templateUrl: './business-settings.component.html',
  styleUrls: ['./business-settings.component.scss'],
})
export class BusinessSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  displayTemplate!: TemplateRef<any>;
  isCopied = false;
  businessProfile: FormGroup;
  channel_credentails: Datum;
  imgURL: any;
  isEdited: boolean = false;
  channelSub: Subscription;
  imagePath: FileList;

  @ViewChild('showBusinessProfile')
  private showBusinessProfile!: TemplateRef<any>;
  @ViewChild('editBusinessProfile')
  private editBusinessProfile!: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private cv: CustomValidators,
    private shareservice: SharedService
  ) {}

  get bpFC() {
    return this.businessProfile.controls;
  }

  ngOnInit(): void {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
      }
    });
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.showBusinessProfile;
    this.cd.detectChanges();
  }

  onEdit() {
    this.displayTemplate = this.editBusinessProfile;

    this.imgURL = this.channel_credentails.profile_pic;

    this.businessProfile = this.fb.group({
      profileImg: [{ value: this.channel_credentails.profile_pic }],
      displayName: [
        { value: this.channel_credentails.business_name, disabled: false },
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      businessNo: [
        { value: this.channel_credentails.waba_number, disabled: true },
        [Validators.required, Validators.pattern(this.cv.contactRegex)],
      ],
      businessDesc: [
        {
          value: this.channel_credentails.business_description,
          disabled: false,
        },
        [Validators.required],
      ],
    });
  }

  gotoView() {
    this.displayTemplate = this.showBusinessProfile;
  }

  // Function to generate an array of icons based on the length of the API key
  // getIconArray() {
  //   const apiKeyLength = this.channel_credentails;
  //   return Array(apiKeyLength).fill(0);
  // }

  getmaskedDate(data: string, maskChar: string) {
    if (data.length <= 1) {
      return data;
    }

    let maskedString = '';
    for (const element of data) {
      maskedString += maskChar;
    }

    return maskedString;
  }

  copyCode() {
    this.isCopied = !this.isCopied;
    setTimeout(() => {
      this.isCopied = !this.isCopied;
    }, 2000);
  }

  onAvatarUpload(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files || files.length === 0) return;
    const mimeType = files[0].type;
    if (!mimeType.startsWith('image/')) return;
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.businessProfile.controls['profileImg'].setValue(reader.result);
    };
  }

  onAvatarRemove() {
    this.businessProfile.controls['profileImg'].setValue(null);
  }

  onSubmit(form: FormGroup) {
    return;
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
  }
}
