import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-my-profile',
  templateUrl: './sa-my-profile.component.html',
  styleUrls: ['./sa-my-profile.component.scss']
})
export class SaMyProfileComponent {

}
