<!-- Report Templates Header Options -->
<div class="row justify-content-between my-3">
  <div class="col-3">
    <!-- Search by name input -->
    <div class="input-group input-group-merge mb-0">
      <div class="input-group-prepend input-group-text">
        <em class="bi bi-search"></em>
      </div>
      <input
        type="search"
        class="form-control"
        placeholder="Search by report template name"
        [(ngModel)]="searchData"
        (ngModelChange)="onSearchNameChange($event)"
        (keyup.enter)="searchName(searchData)"
        [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
      />
    </div>
    <!-- END Search by name input -->
  </div>

  <div class="col-9 pr-2 d-flex align-items-center justify-content-end">
    <ul class="list-inline list-inline-flex mb-0">
      <!-- Category Filter -->
      <li class="list-inline-item">
        <ng-select
          [items]="allCategories"
          bindLabel="name"
          bindValue="value"
          [closeOnSelect]="true"
          [clearable]="true"
          [clearOnBackspace]="true"
          [(ngModel)]="selectedCategory"
          (ngModelChange)="onCategoryChange($event)"
          [searchable]="true"
          [tabIndex]="2"
          [multiple]="false"
          placeholder="Category"
        >
        </ng-select>
      </li>
      <!-- END Category Filter -->

      <!-- Calender Date Range Selector -->
      <li class="list-inline-item">
        <app-datepicker></app-datepicker>
      </li>
      <!-- END Calender Date Range Selector -->
    </ul>
  </div>
</div>
<!-- END Report Templates Header Options -->

<!-- Show Report Templates Listing -->
<ng-container>
  <div class="show_reports_listing">
    <!-- Report Templates Listing Table -->
    <div class="table-wrapper my-2">
      <div class="table-container mb-0">
        <mat-table [dataSource]="reportTemplatesList">
          <!-- Report Name Col -->
          <ng-container matColumnDef="report_template_name" sticky>
            <mat-header-cell *matHeaderCellDef>
              Report Templates Name
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="d-flex justify-content-between"
            >
              <div
                class="comp_name fw-bold text-truncate w-95"
                matTooltip="{{ element.template_name }}"
                matTooltipPosition="above"
              >
                {{ element.template_name | titlecase }}
              </div>

              <div class="d-flex">
                <!-- Export Template Button -->
                <button
                  class="btn btn-option"
                  matTooltip="Export Template"
                  (click)="initiateExportTemplate(element.template_name)"
                >
                  <!-- openModal(exportTemplateModal, 'exportTemplate', element) -->
                  <em class="bi bi-box-arrow-up-right"></em>
                </button>
                <!-- END Export Template Button -->

                <!-- More Options -->
                <div class="card-unfold" matTooltip="More options">
                  <button
                    class="btn btn-option"
                    [matMenuTriggerFor]="menu"
                    aria-label="Report Templates Action Options"
                  >
                    <em class="bi bi-three-dots-vertical"></em>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div
                      class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                    >
                      <a
                        class="dropdown-item"
                        matTooltip="Clone template"
                        matTooltipPosition="above"
                        (click)="openCloneAction(element.template_name)"
                      >
                        <i class="bi-files"></i> Clone
                      </a>
                      <!-- openModal(cloneTemplateModal, 'cloneTemplate') -->
                      <a
                        class="dropdown-item text-danger"
                        matTooltip="Delete template"
                        matTooltipPosition="above"
                        (click)="
                          openModal(
                            deleteTemplateModal,
                            'deleteTemplate',
                            element
                          )
                        "
                      >
                        <i class="bi-trash3-fill"></i> Delete
                      </a>
                    </div>
                  </mat-menu>
                </div>
                <!-- END More Options -->
              </div>
            </mat-cell>
          </ng-container>
          <!-- END Report Name Col -->

          <!-- Report Type Col -->
          <ng-container matColumnDef="report_type">
            <mat-header-cell *matHeaderCellDef> Report Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.report_type | titlecase }}
            </mat-cell>
          </ng-container>
          <!-- END Report Type Col -->

          <!-- Category Col -->
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.category | titlecase }}
            </mat-cell>
          </ng-container>
          <!-- END Category Col -->

          <!-- Report Parameters Col -->
          <ng-container matColumnDef="report_parameters">
            <mat-header-cell *matHeaderCellDef>
              Report Parameters
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <ng-container
                  *ngFor="
                    let t of getReportProperties(element.properties)
                      | slice : 0 : 3;
                    index as i
                  "
                >
                  {{ t | titlecase }}{{ i === t.length - 1 ? "" : ", " }}
                </ng-container>

                <div
                  *ngIf="getReportProperties(element.properties).length > 3"
                  class="ms-2"
                >
                  <span class="badge badge-pill badge-primary">
                    <i class="bi-plus"></i>
                    {{ getReportProperties(element.properties).length - 1 }}
                  </span>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <!-- END Report Parameters Col -->

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <!-- Row shown when there is no matching data. -->
          <ng-container *matNoDataRow>
            <div *ngIf="isLoading">
              <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader>
            </div>

            <!-- Opt-Filters Empty Image -->
            <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
              <img
                class="w-35"
                src="assets/img/no_data.svg"
                alt="No data found matching the filters"
              />
              <p>No data found matching the filters</p>
            </div>
            <!-- End Filters Empty Image -->
          </ng-container>
        </mat-table>
      </div>
    </div>
    <!-- END Report Templates Listing Table -->

    <!-- Pagination -->
    <div class="pagination-wrapper mt-5" *ngIf="reportTemplatesCount > pageSize">
      <ngb-pagination
        class="ngb-pagination"
        [collectionSize]="reportTemplatesCount"
        [(page)]="page"
        [pageSize]="pageSize"
        aria-label="pagination"
        (pageChange)="onPageEvent($event)"
        [rotate]="true"
        [maxSize]="3"
        [ellipses]="true"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <i class="bi-chevron-left"></i>
          Previous</ng-template
        >
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationNext>
          Next
          <i class="bi-chevron-right"></i
        ></ng-template>
      </ngb-pagination>
    </div>
    <!-- END Pagination -->
  </div>
</ng-container>
<!-- END Show Report Templates Listing -->

<!-- Export Report Template Modal -->
<ng-template #exportTemplateModal let-c="close" let-d="dismiss">
  <form [formGroup]="exportReportTempForm">
    <div class="modal-body">
      <h3 class="mb-4">Export</h3>

      <!-- Report Name -->
      <div class="form-group">
        <label class="input-label" for="reportname"
          >Report Name
          <span class="text-danger" matTooltip="Report Name is mandatory">*</span>
          <span class="text-muted float-end" *ngIf="exportTempCharCount > 0">
            {{ exportTempCharCount }}/{{ maxCharCount }}
          </span>
        </label>

        <input
          class="form-control"
          type="text"
          id="reportname"
          [maxlength]="maxCharCount"
          placeholder="Report Name"
          formControlName="reportName"
        />
        <!-- Report Name Error -->
        <div class="invalid-feedback help-block">
          <div
            *ngIf="
              erTempFC['reportName'].touched || erTempFC['reportName'].dirty
            "
          >
            <ng-container
              *ngIf="
                erTempFC['reportName'].value === '' ||
                  erTempFC['reportName'].value === null;
                else fcInvalid
              "
              >Report template name can not be empty.</ng-container
            >
            <ng-template #fcInvalid>
              <span *ngIf="erTempFC['reportName'].errors"
                >Please enter a valid report template name.</span
              >
            </ng-template>
          </div>
        </div>
        <!-- END Report Name Error -->
      </div>
      <!-- ENd Report Name -->

      <!-- Date Range Selection -->
      <div class="form-group">
        <label class="input-label" for="daterange"
          >Select Date Range
          <span class="text-danger" matTooltip="Date Range is mandatory"
            >*</span
          >
        </label>
        <app-datepicker></app-datepicker>
      </div>
      <!-- END Date Range Selection -->

      <!-- Campaign List Selection -->
      <div class="form-group">
        <label class="input-label" for="campaignList"
          >Campaign List
          <span class="text-danger" matTooltip="Campaign List is mandatory"
            >*</span
          >
        </label>

        <ng-select
          [items]="campaignsList"
          [placeholder]="'Select Campaigns'"
          [multiple]="true"
          [searchable]="false"
          groupBy="selectedAllGroup"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          formControlName="campaignlist"
          [closeOnSelect]="false"
          name="campaignList"
          id="campaignList"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice : 0 : 2">
              <span class="ng-value-label"> {{ item.name }}</span>
              <span
                class="ng-value-icon right"
                (click)="clear(item)"
                (keydown)="clear(item)"
                aria-hidden="true"
                >×</span
              >
            </div>
            <div
              class="ng-value"
              *ngIf="exportReportTempForm.get('campaignList').value.length > 2"
            >
              <span class="ng-value-label"
                >{{
                  exportReportTempForm.get("campaignList").value.length - 2
                }}
                more...</span
              >
            </div>
          </ng-template>
          <ng-template
            ng-optgroup-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
            />
            Select All
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
            />
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
      <!-- END Campaign List Selection -->
    </div>

    <div class="modal-footer text-center">
      <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
      <button class="btn btn-primary" [disabled]="exportReportTempForm.invalid">
        Save
      </button>
    </div>
  </form>
</ng-template>
<!-- END Export Report Template Modal -->

<!-- Clone Report Template Modal -->
<ng-template #cloneTemplateModal let-c="close" let-d="dismiss">
  <form [formGroup]="cloneReportTempForm">
    <div class="modal-body">
      <h3 class="mb-4">Clone Template</h3>

      <!-- Name -->
      <div class="form-group">
        <label class="input-label" for="name"
          >Name
          <span class="text-danger" matTooltip="Name is mandatory">*</span>
          <span class="text-muted float-end" *ngIf="cloneTempCharCount > 0">
            {{ cloneTempCharCount }}/{{ maxCharCount }}
          </span>
        </label>

        <input
          class="form-control"
          type="text"
          id="name"
          [maxlength]="maxCharCount"
          placeholder="Name"
          formControlName="tempName"
        />
        <!-- Name Error -->
        <div class="invalid-feedback help-block">
          <div
            *ngIf="crTempFC['tempName'].touched || crTempFC['tempName'].dirty"
          >
            <ng-container
              *ngIf="
                crTempFC['tempName'].value === '' ||
                  crTempFC['tempName'].value === null;
                else fcInvalid
              "
              >Template name can not be empty.</ng-container
            >
            <ng-template #fcInvalid>
              <span *ngIf="crTempFC['tempName'].errors"
                >Please enter a valid template name.</span
              >
            </ng-template>
          </div>
        </div>
        <!-- END Name Error -->
      </div>
      <!-- ENd Name -->

      <!-- Template Type -->
      <div class="form-group">
        <label class="input-label" for="reporttype"
          >Type
          <span class="text-danger" matTooltip="Type is mandatory">*</span>
        </label>

        <ng-select
          [items]="reportTypeList"
          [placeholder]="'Select Report Type'"
          [multiple]="false"
          [searchable]="false"
          bindLabel="name"
          bindValue="value"
          formControlName="reportType"
          [closeOnSelect]="true"
          name="reporttype"
          id="reporttype"
        >
        </ng-select>
      </div>
      <!-- END Template Type-->

      <!-- Categories Selection -->
      <div class="form-group">
        <label class="input-label" for="tempCategory"
          >Categories
          <span class="text-danger" matTooltip="Categories is mandatory"
            >*</span
          >
        </label>

        <ng-select
          [items]="tempCategories"
          [placeholder]="'Select Category'"
          [multiple]="true"
          [searchable]="false"
          groupBy="selectedAllGroup"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          formControlName="tempCategory"
          [closeOnSelect]="false"
          name="tempCategory"
          id="tempCategory"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice : 0 : 2">
              <span class="ng-value-label"> {{ item.name }}</span>
              <span
                class="ng-value-icon right"
                (click)="clear(item)"
                (keydown)="clear(item)"
                aria-hidden="true"
                >×</span
              >
            </div>
            <div
              class="ng-value"
              *ngIf="createReportForm.get('tempCategory').value.length > 2"
            >
              <span class="ng-value-label"
                >{{
                  createReportForm.get("tempCategory").value.length - 2
                }}
                more...</span
              >
            </div>
          </ng-template>
          <ng-template
            ng-optgroup-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
            />
            Select All
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
            />
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
      <!-- END Categories Selection -->

      <!-- Parameters -->
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label class="input-label" for="parameters"
              >Parameters
              <span class="text-danger" matTooltip="Parameters is mandatory"
                >*</span
              >
            </label>

            <div
              class="parameters-wrapper d-flex align-items-center justify-content-between"
            >
              <div class="selected_chips_group">
                <mat-chip-listbox aria-label="template parameters">
                  <mat-chip-option *ngFor="let item of campaigns">
                    {{ item.name }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'remove ' + item.name"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-option>
                </mat-chip-listbox>

                <!-- More Campaigns -->
                <button class="btn btn-secondary btn-xs">
                  <em class="bi bi-plus-circle"></em> 11 More
                </button>
                <!-- END More Campaigns -->

                <!-- Add Campaign Selection -->
                <button
                  class="btn btn-outline-secondary btn-xs"
                  [matMenuTriggerFor]="menu"
                >
                  <em class="bi bi-plus-circle"></em> Add
                </button>
                <!-- END Add Campaign Selection -->

                <mat-menu #menu="matMenu">
                  <section class="list_selection_panel">
                    <span class="select_all_option">
                      <mat-checkbox class="list-checkboxes" [checked]="true">
                        Select All
                      </mat-checkbox>
                    </span>
                    <span class="list-section">
                      <ul class="list-unstyled">
                        <li>
                          <mat-checkbox class="list-checkboxes">
                            Credit_Campaign
                          </mat-checkbox>
                        </li>
                        <li>
                          <mat-checkbox class="list-checkboxes">
                            Media_Campaign
                          </mat-checkbox>
                        </li>
                        <li>
                          <mat-checkbox class="list-checkboxes">
                            Credit_Campaign
                          </mat-checkbox>
                        </li>
                        <li>
                          <mat-checkbox class="list-checkboxes">
                            Media_Campaign
                          </mat-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Parameters -->
    </div>

    <div class="modal-footer text-center">
      <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
      <button class="btn btn-primary" [disabled]="cloneReportTempForm.invalid">
        Save
      </button>
    </div>
  </form>
</ng-template>
<!-- END Clone Report Template Modal -->

<!-- Delete Report Template Modal -->
<ng-template #deleteTemplateModal let-c="close" let-d="dismiss">
  <div class="modal-body text-center">
    <div
      class="avatar avatar-circle avatar-xl d-inline-flex align-items-center justify-content-center"
    >
      <em class="bi bi-exclamation-triangle fs-1 text-danger"></em>
    </div>

    <h2>Are you sure?</h2>
    <p class="my-2">
      want to delete this
      <span class="text-primary">"{{ selectedTemp.template_name }}"</span> Report
      Template
    </p>
    <div class="d-flex justify-content-center gap-2">
      <button class="btn btn-outline-secondary" (click)="d('Cross click')">
        Cancel
      </button>
      <button class="btn btn-danger" type="button" (click)="deleteTemplate()">
        Yes, Confirm
      </button>
    </div>
  </div>
</ng-template>
<!-- END Delete Report Template Modal -->
