import { Action, createReducer, on } from '@ngrx/store';
import * as roleactions from './role.actions';
import { RolesDataList } from 'src/app/appdata/roles.model';

export interface State {
  roleList: RolesDataList;
  createRoleRes: any;
  viewroleRes: any;
  updateroleRes: any;
  deleteRoleRes: any;
  error: any;
}

export const initialState: State = {
  roleList: null,
  createRoleRes: null,
  viewroleRes: null,
  updateroleRes: null,
  deleteRoleRes: null,
  error: null,
};

const roleReducer = createReducer(
  initialState,
  on(
    roleactions.initiateCreateRoleSuccess,
    (state, { data }): State => ({
      ...state,
      roleList: null,
      createRoleRes: data,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: null,
    })
  ),
  on(
    roleactions.initiateCreateRoleFailure,
    (state, { error }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: error,
    })
  ),
  on(
    roleactions.viewRoleDetailsSuccess,
    (state, { data }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: data,
      updateroleRes: null,
      deleteRoleRes: null,
      error: null,
    })
  ),
  on(
    roleactions.viewRoleDetailsFailure,
    (state, { error }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: error,
    })
  ),
  on(
    roleactions.updateRoleDetailsSuccess,
    (state, { data }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: data,
      deleteRoleRes: null,
      error: null,
    })
  ),
  on(
    roleactions.updateRoleDetailsFailure,
    (state, { error }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: error,
    })
  ),
  on(
    roleactions.initiateDeleteRoleSuccess,
    (state, { data }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: data,
      error: null,
    })
  ),
  on(
    roleactions.initiateDeleteRoleFailure,
    (state, { error }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: error,
    })
  ),
  on(
    roleactions.fetchRoleslistSuccess,
    (state, { data }): State => ({
      ...state,
      roleList: data,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: null,
    })
  ),
  on(
    roleactions.fetchRoleslistFailure,
    (state, { error }): State => ({
      ...state,
      roleList: null,
      createRoleRes: null,
      viewroleRes: null,
      updateroleRes: null,
      deleteRoleRes: null,
      error: error,
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return roleReducer(state, action);
}

export const getRoleData = (state: State) => {
  return {
    roleList: state.roleList,
    createRoleRes: state.createRoleRes,
    viewroleRes: state.viewroleRes,
    updateroleRes: state.updateroleRes,
    deleteRoleRes: state.deleteRoleRes,
    error: state.error,
  };
};
