import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDashboard from './dashboard.reducers';

export const selectChannelData =
  createFeatureSelector<fromDashboard.State>('dashboarddata');

export const selectChannelCredentials = createSelector(
  selectChannelData,
  fromDashboard.getDashboardData
);
