<!-- Main Content Container -->
<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
<!-- END Main Content Container -->

<!-- Empty Template -->
<ng-template #noTemplates>
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Profiles Management</h3>
    </div>
  </div>

  <!-- template Content -->
  <div class="card p-3 mt-2">
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="New Profile" />
      <h4 class="mb-3">There are no profiles added in this organization</h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient btn-primary"
        (click)="onCreateProfile()"
        *ngIf="permissions.profile_management.can_create"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Add New Profile
      </button>
    </div>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Empty Template -->

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->

<!-- All Profiles -->
<ng-template #allProfilesTemp let-data="data">
  <div class="card card-sticky">
    <div class="top_header_options">
      <h3 class="main_title">
        Profiles Management
        <span
          class="badge badge-primary rounded-pill"
          matTooltip="Total Profiles Count"
          >{{ totalProfilesList }}</span
        >
      </h3>

      <!-- Add New Profile Button -->
      <button
        class="btn btn-primary"
        (click)="onCreateProfile()"
        *ngIf="permissions.profile_management.can_create"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Add New Profile
      </button>
      <!-- END Add New Profile Button -->
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-body p-0" *ngIf="!isLoading">
      <!-- Header Options -->
      <div class="row justify-content-between">
        <!-- SearchBar -->
        <div class="col-4">
          <div class="input-group input-group-merge mb-0">
            <div class="input-group-prepend input-group-text">
              <em class="bi bi-search"></em>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
            />
          </div>
        </div>
        <!-- END SearchBar -->

        <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
          <ul class="list-inline list-inline-flex mb-0">
            <!-- Filters -->
            <li class="list-inline-item">
              <div
                ngbDropdown
                display="dynamic"
                placement="bottom-end"
                class="d-inline-block filter-dropdown"
                #myDrop="ngbDropdown"
              >
                <button
                  type="button"
                  class="btn btn-lg btn-default"
                  ngbDropdownToggle
                  id="ProfilefiltersDropDown"
                  (click)="getFiltersData()"
                >
                  <i class="bi bi-funnel me-1"></i>
                  Filters
                </button>
                <div
                  class="dropdown-menu"
                  ngbDropdownMenu
                  aria-labelledby="ProfilefiltersDropDown"
                >
                  <div class="card p-0 shadow-none">
                    <!-- Header -->
                    <div class="card-header">
                      <h4 class="title">Filters</h4>
                    </div>
                    <!-- END Header -->
                    <!-- Body -->
                    <div
                      class="card-body py-2 px-3"
                      (click)="stopPropagation($event)"
                      (keyup)="stopPropagation($event)"
                    >
                      <!-- BSP -->
                      <div class="form-group">
                        <div class="label">Select BSP</div>
                        <ng-select
                          id="bsplist"
                          [items]="bspsList"
                          [closeOnSelect]="true"
                          [clearable]="false"
                          [searchable]="false"
                          bindValue=""
                          [(ngModel)]="filterCriteria.bsp"
                          placeholder="-- Select --"
                        >
                          <ng-template
                            ng-label-tmp
                            ng-option-tmp
                            let-item="item"
                          >
                            <span>{{item.bspName === 'RML' ? 'Routemobile' : item.bspName}}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                      <!-- END BSP -->

                      <!-- Business Account Number -->
                      <div class="form-group">
                        <div class="label">Select Business Account Number</div>
                        <ng-select
                          id="baNo"
                          [items]="channel_credentails"
                          [closeOnSelect]="true"
                          [clearable]="false"
                          [searchable]="false"
                          [(ngModel)]="filterCriteria.bs_acc_no"
                          placeholder="-- Select --"
                        >
                          <ng-template
                            ng-label-tmp
                            ng-option-tmp
                            let-item="item"
                          >
                            <div class="aban_items">
                              <div class="avatar avatar-circle">
                                <img
                                  class="avatar-img"
                                  [src]="item.channel_credentials.profile_pic"
                                  [alt]="item.channel_credentials.business_name"
                                />
                              </div>
                              <span class="ms-3">
                                <h5 class="aban_name m-0">
                                  {{ item.channel_credentials.business_name }}
                                </h5>
                                <p class="aban_number m-0">
                                  {{ item.channel_credentials.waba_number }}
                                </p>
                              </span>
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                      <!-- END Business Account Number -->

                      <!-- Role -->
                      <div class="form-group">
                        <div class="label">Select Role</div>
                        <ng-select
                          [items]="roleList"
                          id="role"
                          bindLabel="role_name"
                          [closeOnSelect]="true"
                          [clearable]="false"
                          [searchable]="false"
                          [(ngModel)]="filterCriteria.role"
                          placeholder="-- Select --"
                        >
                        </ng-select>
                      </div>
                      <!-- END Role -->
                    </div>
                    <!-- Body -->

                    <!-- Footer -->
                    <div class="card-footer">
                      <button
                        type="button"
                        class="btn btn-block btn-gradient"
                        (click)="
                          applyFilter();
                          $event.stopPropagation();
                          myDrop.close()
                        "
                        *ngIf="
                          filterCriteria.bsp ||
                          filterCriteria.bs_acc_no ||
                          filterCriteria.role
                        "
                      >
                        Apply
                      </button>
                    </div>
                    <!-- Footer -->
                  </div>
                </div>
              </div>
            </li>
            <!-- END Filters -->
          </ul>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <!-- Filter Selected Values -->
    <div
      class="selected_filters mt-2 p-2 d-flex align-items-center justify-content-between"
      *ngIf="!checkFilteredItems()"
    >
      <!-- Selected Filter Options -->
      <div class="selected_items">
        <mat-chip-listbox
          aria-label="Selected BSP, Business Account Number and Role"
        >
          <mat-chip *ngIf="filterCriteria.bsp">
            BSP: <b>Routemobile</b>
            <button matChipRemove (click)="clearFilter('bsp')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="filterCriteria.bs_acc_no">
            Business Account Number:
            <b>{{
              filterCriteria.bs_acc_no.channel_credentials.waba_number
            }}</b>
            <button matChipRemove (click)="clearFilter('bsaccno')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="filterCriteria.role">
            Role: <b>{{ filterCriteria.role.role_name }}</b>
            <button matChipRemove (click)="clearFilter('role')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Selected Filter Options -->

      <!-- Clear Filters Option -->
      <div class="clear_filters">
        <mat-chip-listbox aria-label="Clear selected filters">
          <mat-chip 
            (click)="clearFilter('clearfilter')"
            (keyup)="clearFilter('clearfilter')"
          > Clear All </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Clear Filters Option -->
    </div>
    <!-- END Filter Selected Values -->

    <!-- Profiles Table -->
    <div class="table-wrapper table-responsive mt-3">
      <div class="table-container mb-0">
        <mat-table [dataSource]="tabledata | async">
          <ng-container matColumnDef="name" sticky>
            <mat-header-cell *matHeaderCellDef>
              Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex align-items-center gap-2 w-85">
                  <div class="avatar avatar-circle avatar-xs">
                    <img
                      class="avatar-img"
                      [src]="
                        element.profile_image_url
                          ? element.profile_image_url
                          : 'assets/img/customer/default_avatar.jpg'
                      "
                      [alt]="
                        shortName(element.first_name + ' ' + element.last_name)
                          | uppercase
                      "
                      (error)="handleImageError($event)"
                    />
                  </div>
                  <h5
                    class="mb-0 pointer text-truncate"
                    (click)="onViewProfile(element)"
                    (keyup)="onViewProfile(element)"
                    matTooltip="{{ element.first_name | titlecase }} {{
                      element.last_name
                    }}"
                    matTooltipPosition="above"
                  >
                    {{ element.first_name | titlecase }}
                    {{ element.last_name }}
                  </h5>
                  <span>
                    <em
                      class="bi bi-info-circle ml-1 small"
                      matTooltip="Created On: {{
                        element.created_at | date : 'longDate'
                      }} &#10; Updated On: {{
                        element.updated_at | date : 'longDate'
                      }}"
                      matTooltipPosition="above"
                    ></em>
                  </span>
                </div>
                <!-- More Options -->
                <button
                  class="btn btn-option"
                  [matMenuTriggerFor]="table_menu"
                  aria-label="Profiles Action Options"
                >
                  <i class="bi bi-three-dots-vertical"></i>
                </button>

                <mat-menu #table_menu="matMenu" xPosition="before">
                  <div
                    class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                  >
                    <a
                      class="dropdown-item"
                      (click)="onUpdateProfile(element)"
                      *ngIf="permissions.profile_management.can_update"
                    >
                      <i class="bi-pencil-fill text-primary"></i> Edit
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="onViewProfile(element)"
                      *ngIf="permissions.profile_management.can_view"
                    >
                      <i class="bi-eye-fill text-primary"></i> View
                    </a>
                    <a
                      class="dropdown-item text-danger"
                      (click)="
                        openModal(
                          deleteTemplateModal,
                          'deleteTemplate',
                          element
                        )
                      "
                      *ngIf="permissions.profile_management.can_delete"
                    >
                      <i class="bi-trash-fill"></i> Delete
                    </a>
                  </div>
                </mat-menu>
                <!-- END More Options -->
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.email }}
            </mat-cell>
          </ng-container>
         
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div
                class="status_indicator"
                [ngClass]="element.is_active ? 'success' : 'failed'"
              >
                <em class="status_icon"></em>
                <span class="status_text">
                  {{ element.is_active ? 'Active' : 'In-active' | titlecase }}
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.roles && element.roles.length > 0; else noRoles"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="let item of element.roles | slice : 0 : 1"
                >
                  {{ item?.role_name }} <span>{{element.roles.length > 1 ? ',':''}}</span>
                </div>
              </ng-container>
              <div
                *ngIf="element.roles.length > 1"
                class="ms-2"
              >
                <span
                  class="badge badge-pill badge-primary"
                  [matTooltip]="element.toolTipText"
                >
                  <i class="bi-plus"></i>
                  {{ element.roles.length - 1 }}
                </span>
              </div>
              <ng-template #noRoles>
                <span>No Roles</span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="channels">
            <mat-header-cell *matHeaderCellDef> Channels </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div
                *ngIf="
                  element.channels && element.channels.length > 0;
                  else noChannels
                "
              >
                <div *ngFor="let item of element.channels">
                  <div
                    class="avatar avatar-circle avatar-sm"
                    matTooltip="WhatsApp"
                    matTooltipPosition="above"
                    *ngIf="item.channel === 'waba_policies'"
                  >
                    <img
                      class="avatar-img"
                      src="assets/img/whatsapp.svg"
                      alt="waba_policies"
                    />
                  </div>
                </div>
              </div>
              <ng-template #noChannels>
                <span>No Channels</span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="bsp">
            <mat-header-cell *matHeaderCellDef> BSP </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.bsps && element.bsps.length > 0; else noBsp"
              >
                <div *ngFor="let item of element.bsps">
                  {{item.bsp_name === 'RML'?'Routemobile':item.bsp_name}}
                </div>
              </ng-container>
              <ng-template #noBsp>
                <span>No BSP</span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accounts">
            <mat-header-cell *matHeaderCellDef>
              Business Account Number
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.business_accounts &&
                    element.business_accounts.length > 0;
                  else noBusinessAccounts
                "
              >
                <div class="aban_items align-items-center">
                  <ng-container
                    *ngFor="let t of element.business_accounts | slice : 0 : 1"
                  >
                    <div class="avatar avatar-circle">
                      <img
                        class="avatar-img"
                        [src]="t.channel_credentials.profile_pic"
                        [alt]="t.channel_credentials.business_name"
                      />
                    </div>

                    <div class="ml-2">
                      <p class="aban_number m-0">
                        {{ t.channel_credentials.waba_number }}
                      </p>
                      <h5 class="aban_name m-0">
                        {{ t.channel_credentials.business_name }}
                      </h5>
                    </div>
                  </ng-container>

                  <div
                    *ngIf="element.business_accounts.length > 1"
                    class="ms-2"
                  >
                    <span class="badge badge-pill badge-primary">
                      <i class="bi-plus"></i>
                      {{ element.business_accounts.length - 1 }}
                    </span>
                  </div>
                </div>
              </ng-container>
              <ng-template #noBusinessAccounts
                ><span>No business accounts</span></ng-template
              >
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <!-- Row shown when there is no matching data. -->
          <ng-container *matNoDataRow>
            <div *ngIf="isLoading">
              <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader>
            </div>
            <!-- Opt-Filters Empty Image -->
            <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
              <img
                class="w-35"
                src="assets/img/no_data.svg"
                alt="No data found matching the filters"
              />
              <p>No data found matching the filters</p>
            </div>
            <!-- End Filters Empty Image -->
          </ng-container>
        </mat-table>
      </div>
    </div>
    <!-- END Profiles Table -->

    <!-- Pagination -->
    <div class="pagination-wrapper mt-3 mb-0" *ngIf="totalProfilesList > pageSize">
      <ngb-pagination
        class="ngb-pagination"
        [collectionSize]="totalProfilesList"
        [(page)]="page"
        [pageSize]="pageSize"
        aria-label="pagination"
        (pageChange)="onPageEvent($event)"
        [rotate]="true"
        [maxSize]="3"
        [ellipses]="true"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <i class="bi-chevron-left"></i>
          Previous</ng-template
        >
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationNext>
          Next
          <i class="bi-chevron-right"></i
        ></ng-template>
      </ngb-pagination>
    </div>
    <!-- END Pagination -->
  </div>
</ng-template>
<!-- END All Profiles -->

<!-- Modals -->
<!-- Delete Template Modal -->
<ng-template #deleteTemplateModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Delete Profile</h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>
        Are you sure want to delete?
        <span class="text-danger"
          >“{{ deleteItem.first_name }} {{ deleteItem.last_name }}”</span
        >
      </h4>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" type="button" (click)="d('Cross click')">
      Cancel
    </button>
    <button
      class="btn btn-outline-danger"
      type="button"
      (click)="deleteProfile()"
    >
      Delete
    </button>
  </div>
</ng-template>
<!-- END Delete Template Modal -->
<!-- END Modals -->
