import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError, BehaviorSubject } from 'rxjs';

export interface ConsentDataPayload {
  account_id: string | null;
  bsp_id: string | null;
  waba_no: string | null;
  channel: string | null;
  bsp_name: string | null;
  createdby: string | null;
  mobileno: string | null;
  consent_status: string | null;
  source: string | null;
  startdate: string | null;
  enddate: string | null;
  page: number;
  size: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  totalconsents = new BehaviorSubject<number>(0);
  constructor(private http: HttpClient) {}

  fetchallconsents(payload: ConsentDataPayload) {
    const url =
      environment.backendurl +
      `/api/consents/fetch/${payload.channel}/${payload.account_id}/${payload.waba_no}`;

    let params = new HttpParams();
    if (payload.consent_status) {
      params = params.set('consent_status', payload.consent_status);
    }
    if (payload.source) {
      params = params.set('source', payload.source);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  bulkUpload(
    file: File,
    consent_status: string,
    accountid: string,
    wabano: string,
    created: string,
    bsp: string,
    channel: string,
    bspstatus: string,
    bspid: string
  ) {
    const url = environment.backendurl + `/api/consents/consent`;

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('consent_status', consent_status);
    formData.append('account_id', accountid);
    formData.append('waba_number', wabano);
    formData.append('created_by', created);
    formData.append('bsp_name', bsp);
    formData.append('channel', channel);
    formData.append('bsp_status', bspstatus);
    formData.append('bsp_id', bspid);

    return this.http.post(url, formData).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  bulkUpdate(data: any) {
    const url = environment.backendurl + `/api/consents/update`;
    return this.http.put(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  queueStatus(id: string) {
    const url = environment.backendurl + `/api/consents/` + id;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  searchMobile(payload: ConsentDataPayload) {
    const url =
      environment.backendurl +
      `/api/consents/fetch_mobile/${payload.channel}/${payload.account_id}/${payload.waba_no}/${payload.mobileno}`;

    let params = new HttpParams();
    if (payload.consent_status) {
      params = params.set('consent_status', payload.consent_status);
    }
    if (payload.source) {
      params = params.set('source', payload.source);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  searchData(payload: ConsentDataPayload) {
    const url =
      environment.backendurl +
      `/api/consents/${payload.channel}/${payload.account_id}/${payload.waba_no}`;
    if (payload.mobileno) {
      url + `/${payload.mobileno}`;
    } else if (payload.source) {
      url + `/${payload.source}`;
    } else if (payload.consent_status) {
      url + `/${payload.consent_status}`;
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
