import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, exhaustMap, tap } from 'rxjs/operators';
import * as authactions from '../login/store/login.actions';
import { Store } from '@ngrx/store';
import { selectProfilesDtlsData } from '../store/common.selectors';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  tokenName: string = 'waba_token';
  modifiedReq: any;
  constructor(private alertMsg: AlertService, private readonly store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectProfilesDtlsData).pipe(
      take(1),
      exhaustMap((user) => {
        if (!user.token) {
          return next.handle(req);
        }

        const uploadUrl = req.url.includes('upload');

        if (uploadUrl || req.body instanceof FormData) {
          this.modifiedReq = req.clone({
            headers: new HttpHeaders().set(
              'Authorization',
              'Bearer ' + localStorage.getItem(this.tokenName)
            ),
          });
        } else {
          this.modifiedReq = req.clone({
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set(
                'Authorization',
                'Bearer ' + localStorage.getItem(this.tokenName)
              ),
          });
        }

        return next.handle(this.modifiedReq).pipe(
          tap({
            error: (err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.store.dispatch(authactions.logoutUser());
                this.alertMsg.alertDanger(
                  'Error',
                  'Session timed out, please re-login to continue'
                );
              }
              if (err.status === 413) {
                this.alertMsg.alertDanger('Error', err.statusText);
              }
              if (err.status === 504) {
                this.alertMsg.alertDanger('Error', 'Something Went Wrong');
              }
            },
          })
        );
      })
    );
  }
}
