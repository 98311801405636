import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {
  BarChartData,
  LineChartData,
  PieChartData,
} from '../appdata/analytics.data';
import { NgChartsModule } from 'ng2-charts';
import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { SaDashboardComponent } from './sa-dashboard/sa-dashboard.component';
import { SaChannelsComponent } from './sa-channels/sa-channels.component';
import { SaBspComponent } from './sa-bsp/sa-bsp.component';
import { SaRequestsComponent } from './sa-requests/sa-requests.component';
import { SaReportsComponent } from './sa-reports/sa-reports.component';
import { SaProfileManagementComponent } from './sa-profile-management/sa-profile-management.component';
import { SaRoleManagementComponent } from './sa-role-management/sa-role-management.component';
import { SaSupportComponent } from './sa-support/sa-support.component';
import { SaSettingsComponent } from './sa-settings/sa-settings.component';
import { SaMyProfileComponent } from './sa-my-profile/sa-my-profile.component';

@NgModule({
  declarations: [
    SaDashboardComponent,
    SaChannelsComponent,
    SaBspComponent,
    SaRequestsComponent,
    SaReportsComponent,
    SaProfileManagementComponent,
    SaRoleManagementComponent,
    SaSupportComponent,
    SaSettingsComponent,
    SaMyProfileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SuperAdminRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    FormsModule,
    AngularMaterialModule,
    NgChartsModule,
  ],
  exports: [],
  providers: [BarChartData, LineChartData, PieChartData],
})
export class SuperAdminModule {}
