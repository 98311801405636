import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/services/shared.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertService } from 'src/app/alert/alert.service';
import { Store } from '@ngrx/store';
import * as consentactions from './store/consent.actions';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { Consent } from 'src/app/appdata/consent.model';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { ChannelCredentials, RbacPolicies } from 'src/app/appdata/auth.model';
import {
  ConsentDataPayload,
  ConsentService,
} from 'src/app/services/consent.service';
import { selectConsentDataList } from './store/consent.selectors';

@Component({
  selector: 'app-consent-management',
  templateUrl: './consent-management.component.html',
  styleUrls: ['./consent-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentManagementComponent implements OnInit, OnDestroy {
  active = 1;
  page = 1;
  pageSize = 10;

  file: File;

  permissions: RbacPolicies;
  channelSub: Subscription;
  queue_status: any = null;
  channel_credentials: ChannelCredentials;

  isAnalysing: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  selection = new SelectionModel<Consent>(true, []);
  
  account_dtls: ConsentDataPayload = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    bsp_name: null,
    createdby: null,
    mobileno: null,
    consent_status: null,
    source: null,
    startdate: null,
    enddate: null,
    page: this.page,
    size: this.pageSize,
  };

  @ViewChild('dropzoneRef', { static: false }) dropzoneRef: any;

  constructor(
    public consentservice: ConsentService,
    private shareservice: SharedService,
    private alertMsg: AlertService,
    private store: Store,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initProfileDataSubscription();
    this.initChannelDataSubscription();

  }

  initProfileDataSubscription() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.account_dtls.createdby = res.loggedInUserDetails.profile.id;
          this.permissions =
            res.loggedInUserDetails.profile.acl.rbac.rbac_policies;
        }
      });

    this.store
      .select(selectConsentDataList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (!res) return;
        this.isAnalysing = res.isAnalysing;
    });
  }

  initChannelDataSubscription() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (!value) return;

      const { account_id, bsp_id, waba_number, channel_name, bsp_name } =
        value.channel_credentials;

      this.account_dtls.account_id = account_id;
      this.account_dtls.bsp_id = bsp_id;
      this.account_dtls.waba_no = waba_number;
      this.account_dtls.channel = channel_name;
      this.account_dtls.bsp_name = bsp_name;

      this.channel_credentials = value.channel_credentials;
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));

      this.account_dtls.consent_status = {
        1: 'optin',
        2: 'optout',
        3: 'blocklist',
      }[this.active];

      // this.store.dispatch(
      //   consentactions.getconsentAction.fetchconsentdata({
      //     payload: this.account_dtls,
      //   })
      // );
    });
  }

  initqueueStatusSubscription() {
    this.shareservice.queuestatus$.subscribe((value) => {
      if (value) {
        this.queue_status = value;
      } else {
        this.queue_status = null;
      }
    });
  }

  downloadSampleFile(action: string) {
    const url =
      'https://ocmp.zupiq.com/backend/api/drive/uploads/da5a9881-f858-49a5-ad3f-96d1e620f91d/1710931598845-508-Sample_consent_file.csv';
    const filename = 'consent_sample_file_ocmp.csv';
    this.shareservice.downloadSampleFile(url, action, filename);
  }

  openModal(content: TemplateRef<string>) {
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
    });
  }

  onSelect(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.alertMsg.alertWarning(
          'Warning!',
          "You can't upload documents which are greater than 2MB"
        );
        return;
      } else {
        this.file = event.target.files[0];
      }
    }
  }

  onBulkFileUpload() {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    let consentstatus = '';
    if (this.active === 1) {
      consentstatus = 'optin';
    } else if (this.active === 3) {
      consentstatus = 'blocklist';
    }
    this.store.dispatch(
      consentactions.initiatebulkupload.bulkupload({
        file: this.file,
        consent_status: consentstatus,
        created: this.account_dtls.createdby,
        bsp: this.account_dtls.bsp_name,
        bspstatus: 'pending',
        channel: this.account_dtls.channel,
        accountid: this.account_dtls.account_id,
        wabano: this.account_dtls.waba_no,
        bspid: this.channel_credentials.id,
      })
    );

    this.file = null;
  }

  formatFileSize = (size: any) => {
    const kiloByte = 1024;
    const megaByte = kiloByte * 1024;
    const gigaByte = megaByte * 1024;

    if (size >= gigaByte) {
      return `${(size / gigaByte).toFixed(2)} GB`;
    } else if (size >= megaByte) {
      return `${(size / megaByte).toFixed(2)} MB`;
    } else if (size >= kiloByte) {
      return `${(size / kiloByte).toFixed(2)} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  bulkUpdateData(action: string) {
    const updateList: any = [];
    if (action) {
      this.selection.selected.forEach((t) => {
        updateList.push({ id: t.id, consent_status: action });
      });
      this.store.dispatch(
        consentactions.initiatebulkupdate({ updatedata: updateList })
      );
    }
  }

  ngOnDestroy() {
    // this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.consentservice.totalconsents.next(0);
    this.shareservice.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.shareservice.customDateAction.next(null);
  }
}
