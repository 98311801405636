import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bsp',
  templateUrl: './bsp.component.html',
  styleUrls: ['./bsp.component.scss'],
})
export class BspComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  allbspList: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  userDetails: any;

  @ViewChild('bspListing') private bspListing: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private readonly store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          abac_policies.waba_policies.forEach((t: any) => {
            if (t.bsp_name === 'RML') {
              this.allbspList.push(t);
            }
          });
        }
      });
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.bspListing;
    this.cd.detectChanges();
  }

  navigateToBA() {
    this.router.navigate([
      'cc/' + this.userDetails.profile.id + '/business-accounts',
    ]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
