import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectProfilesDtlsData } from '../store/common.selectors';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard {
  constructor(private readonly store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectProfilesDtlsData).pipe(
      map((res) => {
        const permissions = this.getPermissions(res);
        if (!permissions) {
          // Handle case where permissions are not available
          return false;
        }
        return (
          this.checkCompRouteAccess(permissions, state.url) ||
          this.checkDashboardRouteAccess(permissions, state.url) ||
          this.checkControlCenterRouteAccess(permissions, state.url) ||
          this.checkChildRoutePermissions(permissions, state.url)
        );
      })
    );
  }

  private getPermissions(data: any): any {
    if (
      !data ||
      !data.loggedInUserDetails ||
      !data.loggedInUserDetails.profile ||
      !data.loggedInUserDetails.profile.acl.rbac
    ) {
      return null;
    }
    const accountType = data.loggedInUserDetails.account.account_type;
    return data.loggedInUserDetails.profile.acl.rbac_list.find(
      (t: any) => t.account_type === accountType
    ).rbac_policies;
  }

  private checkDashboardRouteAccess(permissions: any, url: string) {
    if (url.includes('cc') && url.includes('dashboard')) {
      return permissions.control_centre_dashboard_analytics;
    }
  }

  private checkCompRouteAccess(permissions: any, url: string): boolean {
    const routePermissions = this.getRoutePermissions(permissions, url);
    return routePermissions
      ? routePermissions.full_control || routePermissions.can_view
      : false;
  }

  private checkControlCenterRouteAccess(
    permissions: any,
    url: string
  ): boolean {
    const routePermissions = this.getControlCenterRoutePermissions(
      permissions,
      url
    );
    return routePermissions
      ? routePermissions.full_control || routePermissions.can_view
      : false;
  }

  private getRoutePermissions(permissions: any, url: string): any {
    if (url.includes('customer') && url.includes('campaign-manager')) {
      return permissions.campagin_management;
    } else if (url.includes('customer') && url.includes('templates')) {
      return permissions.template_management;
    } else if (url.includes('customer') && url.includes('consent-management')) {
      return permissions.consent_management;
    } else if (url.includes('customer') && url.includes('media')) {
      return permissions.drive_management;
    } else if (url.includes('customer') && url.includes('my-profile')) {
      return permissions.profile_management;
    }
    return null;
  }

  private getControlCenterRoutePermissions(permissions: any, url: string): any {
    if (url.includes('cc') && url.includes('business-accounts')) {
      return permissions.waba_business_account_management;
    } else if (url.includes('cc') && url.includes('profiles')) {
      return permissions.profile_management;
    } else if (url.includes('cc') && url.includes('roles')) {
      return permissions.acl_management;
    }
    return null;
  }

  private checkChildRoutePermissions(permissions: any, url: string): any {
    if (url.includes('campaign-manager') && url.includes('create')) {
      return permissions.campagin_management.can_create;
    } else if (url.includes('templates') && url.includes('create')) {
      return permissions.template_management.can_create;
    } else if (url.includes('campaign-manager') && url.includes('edit')) {
      return permissions.campagin_management.can_update;
    } else if (url.includes('templates') && url.includes('edit')) {
      return permissions.template_management.can_update;
    } else if (url.includes('campaign-manager') && url.includes('view')) {
      return permissions.campagin_management.can_view;
    } else if (url.includes('customer') && url.includes('analytics')) {
      return permissions.settings.full_control || permissions.settings.can_view;
    } else if (url.includes('customer') && url.includes('support')) {
      return permissions._support;
    }
    return null;
  }
}
