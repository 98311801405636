<!-- Content Preview -->
<div class="preview_inside">
  <div class="your-brand">
    <div class="avatar avatar-circle avatar-xs me-1">
      <img
        class="avatar-img"
        [src]="
          channel_credentails?.profile_pic
            ? channel_credentails?.profile_pic
            : 'assets/img/default_avatar.jpg'
        "
        alt="Organisation Image"
      />
    </div>
    <span>{{ channel_credentails?.business_name | uppercase }}</span>
  </div>
  <div class="preview_card">
    <div class="card p-2">
      <ng-container
        [ngTemplateOutlet]="previewTemplateData | async"
        [ngTemplateOutletContext]="{ data: currentTemplateData }"
      >
      </ng-container>
    </div>
  </div>
</div>
<!-- END Content Preview -->

<!-- Skeleton -->
<ng-template #skeletonTemp>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- END Skeleton -->

<!-- View Template -->
<ng-template #viewTemp let-data="data">
  <div class="preview">
    <ng-container *ngIf="data.header && data.header.header_type === 'text'">
      <p class="m-0">{{ data.header.data[0].text }}</p>
    </ng-container>
    <ng-container *ngIf="data.header && data.header.header_type === 'media'">
      <div
        class="preview_header"
        *ngIf="data.header.data[0].url === null; else mediatemplate"
      >
        <i
          class="bi fs-1"
          [ngClass]="{
            'bi-image-alt':
              data.header.data[0].type === 'image' ||
              data.header.data[0].type === 'Image',
            'bi-play-fill':
              data.header.data[0].type === 'video' ||
              data.header.data[0].type === 'Video',
            'bi-file-earmark-text-fill':
              data.header.data[0].type === 'document' ||
              data.header.data[0].type === 'Document'
          }"
        >
        </i>
      </div>
      <ng-template #mediatemplate>
        <div class="preview_header">
          <ng-container [ngSwitch]="data.header.data[0].type">
            <ng-container *ngSwitchCase="'image'">
              <img
                class="content_preview"
                alt="Image"
                [src]="data.header.data[0].url"
              />
            </ng-container>
            <ng-container *ngSwitchCase="'video'">
              <i
                class="bi bi-play-fill fs-5 play_btn"
                (click)="togglePlay()"
                [class.hide]="videoPlaying"
              ></i>
              <video
                class="content_preview"
                (click)="togglePlay()"
                (ended)="videoEnded()"
                aria-describedby="uploadvideo"
                #videoElement
              >
                <source [src]="data.header.data[0].url" type="video/mp4" />
              </video>
            </ng-container>
            <ng-container *ngSwitchCase="'document'">
              <img
                class="content_preview avatar-sm"
                alt="Image"
                *ngIf="data.header.data[0].url"
                src="assets/img/customer/pdf.svg"
              />
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <div
      class="preview_body small"
      *ngIf="data.body.text"
      [innerHTML]="templateservice.formatToHtml(data.body.text)"
    ></div>
    <p class="preview_footer" *ngIf="data.footer && data.footer.text">
      {{ data.footer.text }}
    </p>
  </div>

  <!-- Call to action -->
  <ng-container
    *ngIf="data.button_details && data.button_details.type === 'call_to_action'"
  >
    <ng-container *ngFor="let item of data.button_details.buttons">
      <button
        class="btn btn-sm btn-outline-secondary text-nowrap"
        *ngIf="item.type_of_action === 'phone_no'"
      >
        <i class="bi-telephone-fill me-2"></i>
        {{ item.button_text }}
      </button>
      <button
        class="btn btn-sm btn-outline-secondary text-nowrap"
        *ngIf="item.type_of_action === 'website'"
      >
        <i class="bi-box-arrow-up-right me-2"></i>
        {{ item.button_text }}
      </button>
    </ng-container>
  </ng-container>
  <!-- Call to action -->

  <!-- Quick Reply -->
  <ng-container
    *ngIf="data.button_details && data.button_details.type === 'quick_reply'"
  >
    <div class="row justify-content-between m-0">
      <button
        class="btn btn-sm btn-outline-secondary text-nowrap mb-2"
        *ngFor="let item of data.button_details.buttons"
      >
        {{ item.button_text }}
      </button>
    </div>
  </ng-container>
  <!-- END Quick Reply -->
</ng-template>
<!-- View Template -->

<!-- Create Template -->
<ng-template #createTemp let-data="data">
  <div class="preview">
    <ng-container *ngIf="tempType === 'Text'">
      <p class="m-0">{{ data.tempHeaderText }}</p>
    </ng-container>
    <ng-container *ngIf="tempType === 'Media'">
      <div class="preview_header" *ngIf="data.tempHeaderMedia">
        <i
          class="bi fs-1"
          [ngClass]="{
            'bi-image-alt': data.tempHeaderMedia?.toLowerCase() === 'image',
            'bi-play-fill': data.tempHeaderMedia?.toLowerCase() === 'video',
            'bi-file-earmark-text-fill': data.tempHeaderMedia?.toLowerCase() === 'document'
          }"
        >
        </i>
      </div>
    </ng-container>
    <p
      class="preview_body small"
      *ngIf="data.tempBody"
      [innerHTML]="templateservice.formatToHtml(data.tempBody)"
    ></p>
    <p class="preview_footer" *ngIf="data.footerText">
      {{ data.footerText }}
    </p>
  </div>

  <!-- Call to action -->
  <ng-container *ngFor="let item of data.ctoaButtons">
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap"
      *ngIf="item.type === 'Phone Number'"
    >
      <i class="bi-telephone-fill me-2"></i>
      {{ item.text }}
    </button>
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap"
      *ngIf="item.type === 'Visit Website'"
    >
      <i class="bi-box-arrow-up-right me-2"></i>
      {{ item.text }}
    </button>
  </ng-container>
  <!-- Call to action -->

  <!-- Quick Reply -->
  <div class="row justify-content-between m-0">
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap mb-2"
      *ngFor="let item of data.quickreplyBtns"
    >
      {{ item.quickreplyText }}
    </button>
  </div>
  <!-- END Quick Reply -->
</ng-template>
<!-- Create Template -->

<!-- Add Sample -->
<ng-template #addSampleTemp let-data="data">
  <div class="preview">
    <ng-container *ngIf="data.headerTemplateType === 'Text'">
      <p class="m-0">{{ data.headertext }}</p>
    </ng-container>
    <ng-container *ngIf="data.headerTemplateType === 'Media'">
      <div class="preview_header" *ngIf="data.headerMediaType">
        <i
          class="bi fs-1"
          [ngClass]="{
            'bi-image-alt': data.headerMediaType.toLowerCase() === 'image',
            'bi bi-play-fill fs-5 play_btn':
              data.headerMediaType.toLowerCase() === 'video',
            'bi-file-earmark-text-fill':
              data.headerMediaType.toLowerCase() === 'document'
          }"
          *ngIf="data.uploadMedia === null"
        >
        </i>
        <img
          class="content_preview"
          alt="Image"
          *ngIf="
            data.headerMediaType.toLowerCase() === 'image' && data.uploadMedia
          "
          [src]="data.uploadMedia"
        />
        <video
          controls
          [src]="data.uploadMedia"
          poster="assets/img/video_placeholder.svg"
          width="100%"
          height="100%"
          *ngIf="
            data.headerMediaType.toLowerCase() === 'video' && data.uploadMedia
          "
        >
          Sorry, your browser doesn't support embedded videos, but don't worry,
          you can
        </video>
        <img
          *ngIf="
            data.headerMediaType.toLowerCase() === 'document' &&
            data.uploadMedia
          "
          src="assets/img/customer/pdf.svg"
          class="img-fluid"
          alt="pdf"
        />
        <!-- <pdf-viewer
          *ngIf="
            data.headerMediaType.toLowerCase() === 'document' &&
            data.uploadMedia
          "
          [src]="data.uploadMedia"
          [render-text]="true"
          [original-size]="false"
          style="width: 200px; height: 120px"
        ></pdf-viewer> -->
      </div>
    </ng-container>
    <p
      class="preview_body small"
      *ngIf="data.bodyText"
      [innerHTML]="templateservice.formatToHtml(data.bodyText)"
    ></p>
    <p class="preview_footer" *ngIf="data.footerText">
      {{ data.footerText }}
    </p>
  </div>

  <!-- Call to action -->
  <ng-container *ngFor="let item of data.ctoaButtons">
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap"
      *ngIf="item.type === 'Phone Number'"
    >
      <i class="bi-telephone-fill me-2"></i>
      {{ item.text }}
    </button>
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap"
      *ngIf="item.type === 'Visit Website'"
    >
      <i class="bi-box-arrow-up-right me-2"></i>
      {{ item.text }}
    </button>
  </ng-container>
  <!-- Call to action -->

  <!-- Quick Reply -->
  <div class="row justify-content-between m-0">
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap mb-2"
      *ngFor="let item of data.quickreplyBtns"
    >
      {{ item.quickreplyText }}
    </button>
  </div>
  <!-- END Quick Reply -->
</ng-template>
<!-- END Add Sample -->

<!-- Campaign Management -->
<ng-template #campaignManagementTemp let-data="data">
  <div class="preview">
    <ng-container
      *ngIf="data.content.header && data.content.header.header_type === 'text'"
    >
      <p class="m-0">{{ getHeaderText(data.content.header) }}</p>
    </ng-container>
    <ng-container
      *ngIf="data.content.header && data.content.header.header_type === 'media'"
    >
      <div class="preview_header">
        <i
          class="bi fs-1"
          [ngClass]="{
            'bi-image-alt': data.content.header.data[0].type === 'image',
            'bi-play-fill': data.content.header.data[0].type === 'video',
            'bi-file-earmark-text-fill':
              data.content.header.data[0].type === 'document'
          }"
          *ngIf="data.content.header.data[0].url === null"
        >
        </i>
        <img
          class="content_preview"
          alt="Image"
          *ngIf="
            data.content.header.data[0].type === 'image' &&
            data.content.header.data[0].url
          "
          [src]="data.content.header.data[0].url"
        />
        <video
          class="content_preview"
          (click)="togglePlay()"
          (ended)="videoEnded()"
          aria-describedby="uploadvideo"
          *ngIf="
            data.content.header.data[0].type === 'video' &&
            data.content.header.data[0].url
          "
          #videoElement
        >
          <source [src]="data.content.header.data[0].url" type="video/mp4" />
        </video>
        <img
          class="content_preview avatar-sm"
          alt="Image"
          *ngIf="
            data.content.header.data[0].type === 'document' &&
            data.content.header.data[0].url
          "
          src="assets/img/customer/pdf.svg"
        />
      </div>
    </ng-container>
    <div
      class="preview_body small"
      *ngIf="data.content.body"
      [innerHTML]="templateservice.formatToHtml(getBodyText(data.content.body))"
    ></div>
    <p class="preview_footer" *ngIf="data.content.footer">
      {{ data.content.footer.text }}
    </p>
  </div>
  <!-- Call to action -->
  <ng-container
    *ngIf="
      data.content.button_details &&
      data.content.button_details.type === 'call_to_action'
    "
  >
    <ng-container *ngFor="let item of data.content.button_details.buttons">
      <button
        class="btn btn-sm btn-outline-secondary text-nowrap"
        *ngIf="item.type_of_action === 'phone_no'"
      >
        <i class="bi-telephone-fill me-2"></i>
        {{ item.button_text }}
      </button>
      <button
        class="btn btn-sm btn-outline-secondary text-nowrap"
        *ngIf="item.type_of_action === 'website'"
      >
        <i class="bi-box-arrow-up-right me-2"></i>
        {{ item.button_text }}
      </button>
    </ng-container>
  </ng-container>
  <!-- Call to action -->

  <!-- Quick Reply -->
  <div
    class="row justify-content-between m-0"
    *ngIf="
      data.content.button_details &&
      data.content.button_details.type === 'quick_reply'
    "
  >
    <button
      class="btn btn-sm btn-outline-secondary text-nowrap mb-2"
      *ngFor="let item of data.content.button_details.buttons"
    >
      {{ item.button_text }}
    </button>
  </div>
  <!-- END Quick Reply -->
</ng-template>
<!-- END Campaign Management -->
