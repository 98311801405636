<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->

<!-- Empty Template -->
<ng-template #noRoles>
  <!-- Header -->
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Role Management</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- template Content -->
  <div class="card p-3 mt-2">
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="Add New Role" />
      <h4 class="mb-3">There are no roles found in this organization</h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient btn-primary"
        (click)="onCreateRole()"
        *ngIf="permissions.acl_management.can_create"
      >
        <em class="bi-plus-circle-fill me-2"></em> Add New Role
      </button>
    </div>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Empty Template -->

<!-- Show All Roles -->
<ng-template #showRoles let-data="data">
  <!-- Role Template Header -->
  <div class="card card-sticky">
    <div class="top_header_options">
      <h3 class="main_title">
        Roles Management
        <span
          class="badge badge-primary rounded-pill"
          matTooltip="Total Roles Count"
          >{{ totalRolesList }}</span
        >
      </h3>

      <!-- Create Role Button -->
      <button
        class="btn btn-primary"
        (click)="onCreateRole()"
        matTooltip="Create new role"
        *ngIf="permissions.acl_management.can_create"
      >
        <em class="bi-plus-circle-fill me-2"></em> Add New Role
      </button>
      <!-- END Create Role Button -->
    </div>
  </div>
  <!-- END Role Template Header -->

  <!-- template Content -->
  <div class="card mt-2">
    <div class="card-body p-0" *ngIf="!isLoading">
      <!-- Header Options -->
      <div class="row justify-content-between">
        <div class="col-4">
          <!-- SearchBar -->
          <div class="input-group input-group-merge mb-0">
            <div class="input-group-prepend input-group-text">
              <em class="bi bi-search"></em>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
            />
          </div>
          <!-- END SearchBar -->
        </div>

        <div class="col-8 pr-2 d-flex align-items-center justify-content-end d-none">
          <ul class="list-inline mb-0">
            <!-- Calender Date Range Selector -->
            <li class="list-inline-item">
              <app-datepicker></app-datepicker>
            </li>
            <!-- END Calender Date Range Selector -->
          </ul>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <!-- Template Listing Table -->
    <div class="table-wrapper table-responsive mt-3">
      <div class="table-container mb-0"
      >
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name" sticky>
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <h5
                  class="mb-0 pointer text-truncate w-85"
                  (click)="viewRole(element)"
                  matTooltip="{{ element.role_name }}"
                  matTooltipPosition="above"
                >
                  <span *ngIf="element.account_type === 'ROOT'">
                    <em class="bi-star-fill fs-6 text-primary"></em>
                  </span>
                  {{ element.role_name }}
                </h5>
                <!-- More Options -->
                <div class="card-unfold">
                  <button
                    class="btn btn-option"
                    [matMenuTriggerFor]="menu"
                    aria-label="Templates Action Options"
                  >
                    <i class="bi bi-three-dots-vertical"></i>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <div
                      class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                    >
                      <a
                        class="dropdown-item"
                        (click)="onEditRole(element)"
                        *ngIf="permissions.acl_management.can_update"
                      >
                        <i class="bi-pencil-fill text-primary"></i> Edit
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="viewRole(element)"
                        *ngIf="permissions.acl_management.can_view"
                      >
                        <i class="bi-eye-fill text-primary"></i> View
                      </a>
                      <a
                        class="dropdown-item text-danger"
                        (click)="
                          openModal(deleteRoleModal, 'deleteRole', element)
                        "
                        *ngIf="
                          permissions.acl_management.can_delete &&
                          element.account_type !== 'ROOT'
                        "
                      >
                        <i class="bi-trash3-fill"></i> Delete
                      </a>
                    </div>
                  </mat-menu>
                </div>
                <!-- END More Options -->
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.desc }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="controls">
            <mat-header-cell *matHeaderCellDef> Controls </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ getListControls(element.rbac_policies) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.created_at | date : "short" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="update">
            <mat-header-cell *matHeaderCellDef> Updated on </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.updated_at | date : "short" }}
            </mat-cell>
          </ng-container>


          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <!-- Row shown when there is no matching data. -->
          <ng-container *matNoDataRow>
            <div *ngIf="isLoading">
              <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader>
            </div>
            <!-- Opt-Filters Empty Image -->
            <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
              <img
                class="w-35"
                src="assets/img/no_data.svg"
                alt="No data found matching the filters"
              />
              <p>No data found matching the filters</p>
            </div>
            <!-- End Filters Empty Image -->
          </ng-container>
        </mat-table>
      </div>
    </div>
    <!-- END Template Listing Table -->

    <!-- Pagination -->
    <div class="pagination-wrapper mt-3 mb-0" *ngIf="totalRolesList > pageSize">
      <ngb-pagination
        class="ngb-pagination"
        [collectionSize]="totalRolesList"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="onPageEvent($event)"
        aria-label="pagination"
        [rotate]="true"
        [maxSize]="3"
        [ellipses]="true"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <i class="bi-chevron-left"></i>
          Previous</ng-template
        >
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationNext>
          Next
          <i class="bi-chevron-right"></i
        ></ng-template>
      </ngb-pagination>
    </div>
    <!-- END Pagination -->
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Show All Roles -->

<!-- Delete Role Modal -->
<ng-template #deleteRoleModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Delete Role</h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>
        Are you sure want to delete the role?
        <strong class="text-danger">“{{ deleteItem.role_name }}”</strong>
      </h4>

      <p class="warning_text mt-2">
        <i class="bi-exclamation-triangle-fill text-warning mr-2"></i>
        Deleting this Role will impact <strong>‘associated profiles'</strong> using this role. All the Permissions associated with this role will not be available to profiles.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
    <button class="btn btn-outline-danger" (click)="deleteRole(deleteItem)">
      Delete
    </button>
  </div>
</ng-template>
<!-- END Delete Role Modal -->
