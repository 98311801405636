import { Action, createReducer, on } from '@ngrx/store';
import * as consentactions from './consent.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ConsentData } from 'src/app/appdata/consent.model';

export interface ConsentState {
  consentdata: ConsentData[];
  searchresults: any;
  bulkuploadstatus: any;
  updatedatastatus: any;
  queue_status: any;
  isAnalysing: boolean;
  error: HttpErrorResponse;
}

export const initialConsentState: ConsentState = {
  consentdata: null,
  searchresults: null,
  bulkuploadstatus: null,
  updatedatastatus: null,
  queue_status: null,
  isAnalysing: false,
  error: null,
};

const consentDataReducer = createReducer(
  initialConsentState,
  on(
    consentactions.getconsentAction.fetchconsentdataSuccess,
    (state, { data }): ConsentState => ({
      ...state,
      consentdata: data,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: null,
    })
  ),
  on(
    consentactions.getconsentAction.fetchconsentdataFailure,
    (state, { error }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: error,
    })
  ),
  on(
    consentactions.searchMobileNo.searchmobilenoSuccess,
    (state, { data }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: data,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: null,
    })
  ),
  on(
    consentactions.searchMobileNo.searchmobilenoFailure,
    (state, { error }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: error,
    })
  ),
  on(
    consentactions.initiatebulkupload.bulkupload,
    (state): ConsentState => ({
      ...state,
      isAnalysing: true,
    })
  ), 
  on(
    consentactions.initiatebulkupload.bulkuploadSuccess,
    (state, { data }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: data,
      updatedatastatus: null,
      queue_status: null,
      isAnalysing: false,
      error: null,
    })
  ),
  on(
    consentactions.initiatebulkupload.bulkuploadFailure,
    (state, { error }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      isAnalysing: false,
      error: error,
    })
  ),
  on(
    consentactions.queuestatus.getqueuestatusSuccess,
    (state, { data }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: data,
      error: null,
    })
  ),
  on(
    consentactions.queuestatus.getqueuestatusFailure,
    (state, { error }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: error,
    })
  ),
  on(
    consentactions.initiatebulkupdateSuccess,
    (state, { data }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: data,
      queue_status: null,
      error: null,
    })
  ),
  on(
    consentactions.initiatebulkupdateFailure,
    (state, { error }): ConsentState => ({
      ...state,
      consentdata: null,
      searchresults: null,
      bulkuploadstatus: null,
      updatedatastatus: null,
      queue_status: null,
      error: error,
    })
  )
);

export function consentreducer(
  state: ConsentState | undefined,
  action: Action
): any {
  return consentDataReducer(state, action);
}

export const getConsentData = (state: ConsentState) => {
  return {
    consentdata: state.consentdata,
    searchresults: state.searchresults,
    bulkuploadstatus: state.bulkuploadstatus,
    updatedatastatus: state.updatedatastatus,
    queue_status: state.queue_status,
    isAnalysing: state.isAnalysing,
    error: state.error,
  };
};
