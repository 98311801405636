/* eslint-disable no-useless-escape */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { SharedService } from 'src/app/services/shared.service';
import { ChannelCredentials } from 'src/app/appdata/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyProfileComponent implements OnInit, OnDestroy {
  isEdited: boolean = false;
  showAllItems: boolean = false;
  userDetails: any;
  imagePath: FileList;
  channelSub: Subscription;
  channel_credentails: ChannelCredentials;
  activeaccounts: Array<any> = [];
  visibleItems: Array<any> = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly store: Store,
    private router: Router,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          if (abac_policies.waba_policies.length > 0) {
            abac_policies.waba_policies.forEach((t: any) => {
              const presentacc =
                this.userDetails.account.licenses.license_data.find(
                  (m: any) =>
                    m.channel_credentials.waba_number === t.waba_number
                );
              this.activeaccounts.push(presentacc);
              this.visibleItems = this.activeaccounts.slice(0, 1);
            });
          }
        }
      });

    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
      }
    });
  }

  onEdit() {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'my-profile',
      'edit',
    ]);
  }

  onChangePWD() {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'my-profile',
      'edit',
      'pswd',
    ]);
  }

  showAll() {
    this.visibleItems = this.activeaccounts.slice(); // Show all items
    this.showAllItems = true; // Update the flag
  }

  showLess() {
    this.visibleItems = this.activeaccounts.slice(0, 1);
    this.showAllItems = false;
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
