import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class WhiteLabelService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  applyWhiteLabeling(data: any) {
    this._document.documentElement.style.setProperty(
      '--app-primary',
      data.primary_color
    );
    this._document.documentElement.style.setProperty(
      '--app-secondary',
      data.secondary_color
    );
    this._document.documentElement.style.setProperty(
      '--app-primary-rgb',
      this.hexToRgb(data.primary_color)
    );
    this._document.documentElement.style.setProperty(
      '--app-secondary-rgb',
      this.hexToRgb(data.secondary_color)
    );

    if (data.buttons_type === 'pilled') {
      this._document.documentElement.style.setProperty(
        '--btn-border-radius',
        '50rem'
      );
    } else if (data.buttons_type === 'rounded') {
      this._document.documentElement.style.setProperty(
        '--btn-border-radius',
        '0.438rem'
      );
    }
    if (data.formcontrol_type === 'pilled') {
      this._document.documentElement.style.setProperty(
        '--fc-border-radius',
        '50rem'
      );
      this._document.documentElement.style.setProperty(
        '--fc-select-open-border-radius',
        '1.3rem'
      );
    } else if (data.formcontrol_type === 'rounded') {
      this._document.documentElement.style.setProperty(
        '--fc-border-radius',
        '0.438rem'
      );
      this._document.documentElement.style.setProperty(
        '--fc-select-open-border-radius',
        '0.438rem'
      );
    }
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', data.favicon);
  }

  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      return r + ',' + g + ',' + b; //return 23,14,45 -> reformat if needed
    }
    return null;
  }
}
