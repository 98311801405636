import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { CustomValidators } from '../services/CustomValidators';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { selectCommonDataDtls } from '../store/common.selectors';
import { AuthenticationService } from '../services/authentication.service';
import { skeleton_data } from '../appdata/skeleton.data';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
})
export class ResetpasswordComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  resetForm!: FormGroup;
  showPassword = false;
  showCFPassword = false;
  strongPassword = false;

  skeletonData = skeleton_data;
  whiteLabeledData: any;
  urlToken: string;
  displayTemplate!: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('skeletonTemp') private skeletonTemp!: TemplateRef<any>;
  @ViewChild('resetPwd') private resetPwd!: TemplateRef<any>;
  @ViewChild('resetSuccess') private resetSuccess!: TemplateRef<any>;
  @ViewChild('resetExpiry') private resetExpiry!: TemplateRef<any>;

  constructor(
    private router: Router,
    private alertMsg: AlertService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoaderService,
    private cv: CustomValidators,
    private cd: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.initiateForm();
    this.checkRouteIsEdit();
    this.subscribeToWblStateData();
  }

  ngAfterViewInit() {
    this.displayTemplate = this.skeletonTemp;
    this.cd.detectChanges();
  }

  initiateForm() {
    this.resetForm = this.fb.group(
      {
        newPassword: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            this.cv.patternValidator(this.cv.capitalRegex, {
              hasCapitalCase: true,
            }),
            this.cv.patternValidator(this.cv.smallRegex, {
              hasSmallCase: true,
            }),
            this.cv.patternValidator(this.cv.digitRegex, { hasNumber: true }),
            this.cv.patternValidator(this.cv.specialCharRegex, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        confirmPassword: [null, [Validators.required]],
      },
      {
        validators: this.cv.passwordMatchValidator,
      }
    );
  }

  checkRouteIsEdit() {
    if (this.router.url.includes('reset')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        // const id = params['id'];
        // this.loadingService.show();
        this.urlToken = params["id"];
        const options = {
          headers: { "Content-Type": "application/json" },
          observe: "response",
        };
        this.authService.ValidateResetPswd(options, this.urlToken).subscribe({
          next: (res: any) => {
            if (res && res.body.status_code === 200) {
              this.displayTemplate = this.resetPwd;
            }
            else{
              this.displayTemplate = this.resetExpiry;
            }
          },
          error: (err: any) => {
            const dispErr = err.toString().replace(/Error: /g, "");
            if (
              dispErr ===
                "This reset password link is no longer active as it is already utilised" ||
              dispErr === "Invalid token"
            ) {
              this.displayTemplate = this.resetExpiry;
              this.alertMsg.alertDanger('Danger!', dispErr);

            } else {
              this.alertMsg.alertDanger('Danger!', dispErr);

            }
          },
        });
      });
    }
  }

  handleLoginError(msg: string) {
    this.alertMsg.alertDanger('Error', msg);
  }

  subscribeToWblStateData() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.wlbres) {
          this.whiteLabeledData = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.whiteLabeledData === 'string') {
            this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
          }
        } else if (res.error) {
          const dispErr = res.error.error.message;
          this.handleLoginError(dispErr);
        }
      });
  }

  get rpwdFC() {
    return this.resetForm.controls;
  }

  //* Reset Password Method//
  reset(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const password = form.value.newPassword;
    const cfpassword = form.value.confirmPassword;

    if (
      cfpassword === '' &&
      password === '' &&
      cfpassword === null &&
      password === null &&
      typeof cfpassword !== 'string' &&
      typeof password !== 'string'
    ) {
      this.alertMsg.alertWarning('Warning!', 'Invalid Input');
    } else {
      this.loadingService.show();

      const payload = {
        token: this.urlToken,
        password: password,
        reEnteredPassword: cfpassword,
      };
      this.authService.resetPswd(payload).subscribe({
        next: (res: any) => {
          if (res && res.status_code === 200) {
            this.displayTemplate = this.resetSuccess;
            form.reset();
          }
          else{
            this.displayTemplate = this.resetExpiry;
          }

          // this.router.navigate(["/login"]);
          // this.alertMsg.alertDanger('Danger!', res.detail);
        },
        error: (err: any) => {
          this.alertMsg.alertDanger('Danger!', err.error.detail);
        },
      });
    }
  }

  ForgotPswd() {
    this.router.navigate(['/login']);
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
