<!-- Date Picker Container -->
<div
  class="datepicker-dropdown"
  ngbDropdown
  display="dynamic"
  placement="bottom-end"
  #myDrop="ngbDropdown"
>

<div class="input-group input-group-merge">
  <div class="input-group-prepend input-group-text">  
    <i class="bi bi-calendar4"></i>
  </div>
  <input
      #dateInput
      type="text"
      class="form-control col-auto datepicker-dropdown-input"
      [readonly]="true"
      placeholder="Select Date Range"
      [(ngModel)]="datedisplay"
      ngbDropdownToggle
    />
    <div class="input-group-append input-group-text" (click)="adustDateInp()">
      <i class="bi bi-x-circle" *ngIf="datedisplay;"></i>
    </div>
</div>


  <div class="datepicker-dropdown-menu dropdown-menu" ngbDropdownMenu>
    <!-- Dropdown options -->
    <mat-card class="datepicker-dropdown-menu-card">
      <div class="row flex-nowrap">
        <div class="col-auto p-3">
          <ul class="list-group list-group-flush ps-2">
            <!-- <li class="list-group-item list-group-item-action" (click)="selectToday()">
              Today
            </li> -->
            <li
              class="list-group-item list-group-item-action"
              (click)="selectLastDays(7)"
            >
              Last 7 Days
            </li>
            <li
              class="list-group-item list-group-item-action"
              (click)="selectLastDays(30)"
            >
              Last 30 Days
            </li>
            <li
              class="list-group-item list-group-item-action"
              (click)="selectLastDays(60)"
            >
              Last 60 Days
            </li>
            <!-- <li class="list-group-item list-group-item-action" (click)="toLastMonth()">
              Last Month
            </li> -->
            <li
              class="list-group-item list-group-item-action"
              (click)="customDate()"
            >
              Custom Range
            </li>
          </ul>
        </div>
        <div class="col-auto">
          <ngb-datepicker
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            outsideDays="hidden"
            [dayTemplate]="t"
            [maxDate]="maxDate"
            [minDate]="minDate"
          >
          </ngb-datepicker>
          <ng-template
            #t
            let-date="date"
            let-currentMonth="currentMonth"
            let-disabled="disabled"
            let-focused="focused"
          >
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              [class.disabled]="disabled || date.after(today)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<!-- END Date Picker Container -->
