<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

<!-- Create New Role -->
<ng-template #createRoleTemplate>
  <div class="card">
    <div class="top_header_options justify-content-start gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
        <em class="bi bi-chevron-left"></em>
      </button>
      <!-- <span class="go_back" (click)="gotoListing()">
        <em class="bi-chevron-left"></em>
      </span> -->
      <h3 class="main_title" *ngIf="!isUpdateRole">Create New Role</h3>
      <h3 class="main_title" *ngIf="isUpdateRole">Update Role</h3>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-body py-0 px-6">
      <div class="setup_form_sec">
        <form [formGroup]="createRole">
          <div class="row mb-4">
            <!-- Channel Selection -->
            <div class="channel_sec">
              <h4 class="mb-3">Select Channel</h4>
              <div
                class="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-gap-3"
              >
                <div
                  class="col pe-0"
                  *ngFor="let item of channelList; index as i"
                >
                  <div
                    class="ch_item"
                    [ngClass]="{ disabled: !item.isenabled }"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="channel"
                        id="whatsapp_radio{{ i }}"
                        [checked]="item.isdefault"
                      />
                    </div>
                    <img
                      class="img-fluid"
                      [src]="item.ch_icon"
                      alt="WhatsApp Icon"
                    />
                    <span>{{ item.ch_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- END Channel Selection -->
          </div>

          <!-- Role Name -->
          <div class="form-group">
            <label class="input-label" for="roleName"
              >Role Name
              <span class="text-danger" matTooltip="Role Name is mandatory"
                >*</span
              ></label
            >
            <input
              class="form-control"
              id="roleName"
              type="text"
              placeholder="Role Name"
              formControlName="roleName"
            />
            <!-- Role Name Error -->
            <div class="invalid-feedback help-block">
              <div *ngIf="rFC['roleName'].touched || rFC['roleName'].dirty">
                <ng-container
                  *ngIf="
                    rFC['roleName'].value === '' ||
                      rFC['roleName'].value === null;
                    else fcInvalid
                  "
                >
                  Role name can not be empty.
                </ng-container>
                <ng-template #fcInvalid>
                  <span *ngIf="rFC['roleName'].errors">
                    Please enter a valid role name.
                  </span>
                </ng-template>
              </div>
            </div>
            <!-- END Role Name Error -->
          </div>
          <!-- ENd Role Name -->

          <!-- Role Description -->
          <div class="form-group">
            <label class="input-label" for="roleDesc"
              >Role Description
              <span
                class="text-danger"
                matTooltip="Role Description is mandatory"
                >*</span
              >
            </label>
            <textarea
              class="form-control"
              formControlName="roleDesc"
              id="roleDesc"
              rows="3"
              placeholder="Enter Role Description"
              aria-label="Enter Role Description"
            >
            </textarea>

            <!-- Display Description Error -->
            <div class="invalid-feedback help-block">
              <div *ngIf="rFC['roleDesc'].touched || rFC['roleDesc'].dirty">
                <ng-container
                  *ngIf="
                    rFC['roleDesc'].value === '' ||
                      rFC['roleDesc'].value === null;
                    else fcInvalid
                  "
                >
                  Role description can not be empty.
                </ng-container>
                <ng-template #fcInvalid>
                  <span *ngIf="rFC['roleDesc'].errors">
                    Please enter a valid Role description.
                  </span>
                </ng-template>
              </div>
            </div>
            <!-- END Display Description Error -->
          </div>
          <!-- END Role Description -->
        </form>
        <mat-divider class="my-4"></mat-divider>
        <!-- Role Permissions -->
        <div class="form-group">
          <h4 class="mb-3">Role Permissions</h4>
          <div ngbAccordion [closeOthers]="true">
            <!-- Campaign Management -->
            <div ngbAccordionItem *ngFor="let section of rolesAclList | async">
              <div ngbAccordionHeader>
                <button ngbAccordionButton>{{ section | roleCase }}</button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <div
                    *ngIf="
                      isObject(rolePermissions[section]);
                      then objectPermissions;
                      else simplePermission
                    "
                  ></div>
                  <ng-template #objectPermissions>
                    <div class="select_all mb-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [(ngModel)]="rolePermissions[section].full_control"
                          (ngModelChange)="updateFullControl(section, $event)"
                          id="selectall"
                        />
                        <label
                          class="form-check-label input-label"
                          for="selectall"
                        >
                          Select All
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Create Action -->
                      <div class="col">
                        <div class="card">
                          <div class="action_item d-flex align-items-center justify-content-between">
                            <!-- <span>Create</span> -->
                            <mat-slide-toggle
                              [(ngModel)]="rolePermissions[section].can_create"
                              (change)="updateIndividualPermissions(section)"
                            >Create</mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <!-- END Create Action -->

                      <!-- Read Action -->
                      <div class="col">
                        <div class="card">
                          <div class="action_item d-flex align-items-center justify-content-between">
                            <!-- <span>Read</span> -->
                            <mat-slide-toggle
                              [(ngModel)]="rolePermissions[section].can_view"
                              (change)="updateIndividualPermissions(section)"
                            >Read</mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <!-- END Read Action -->

                      <!-- Update Action -->
                      <div class="col">
                        <div class="card">
                          <div class="action_item d-flex align-items-center justify-content-between">
                            <!-- <span>Update</span> -->
                            <mat-slide-toggle
                              [(ngModel)]="rolePermissions[section].can_update"
                              (change)="updateIndividualPermissions(section)"
                            >Update</mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <!-- END Update Action -->

                      <!-- Delete Action -->
                      <div class="col">
                        <div class="card">
                          <div class="action_item d-flex align-items-center justify-content-between">
                            <!-- <span>Delete</span> -->
                            <mat-slide-toggle
                              [(ngModel)]="rolePermissions[section].can_delete"
                              (change)="updateIndividualPermissions(section)"
                            >Delete</mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <!-- END Delete Action -->
                    </div>
                  </ng-template>
                  <ng-template #simplePermission>
                    <div class="select_all mb-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [(ngModel)]="rolePermissions[section]"
                          (change)="
                            checkPermissions(rolePermissions[section], section)
                          "
                          id="selectall"
                        />
                        <label
                          class="form-check-label input-label"
                          for="selectall"
                        >
                          Enable
                        </label>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <!-- END Campaign Management -->
          </div>
        </div>
        <!-- END Role Permissions -->

        <mat-divider class="my-4"></mat-divider>

        <div class="form_action_btns">
          <div class="options_left"></div>
          <div class="options_right">
            <!-- Cancel Button -->
            <button
              class="btn btn-outline-dark"
              type="button"
              (click)="cancelCreateRole()"
            >
              Cancel
            </button>
            <!-- END Cancel Button -->

            <!-- Send for Approval Button -->
            <button
              class="btn btn-gradient"
              type="button"
              (click)="submitCreateRole()"
              [disabled]="createRole.invalid"
              *ngIf="!isUpdateRole"
            >
              Add Role <em class="bi-chevron-right"></em>
            </button>
            <!-- END Send for Approval Button -->

            <!-- Update Role Button -->
            <button
              class="btn btn-gradient"
              type="button"
              (click)="submitUpdateRole()"
              [disabled]="createRole.invalid"
              *ngIf="isUpdateRole"
            >
              Update Role <em class="bi-chevron-right"></em>
            </button>
            <!-- END Update Role Button -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END Create New Role -->

<!-- Create Role Success -->
<ng-template #createRoleSuccess>
  <div class="card">
    <div class="top_header_options justify-content-start gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <!-- <span class="go_back" (click)="gotoListing()">
        <em class="bi-chevron-left"></em>
      </span> -->
      <h3 class="main_title" *ngIf="!isUpdateRole">Create New Role</h3>
      <h3 class="main_title" *ngIf="isUpdateRole">Update Role</h3>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-body py-0 px-6">
      <div class="success_screen">
        <img src="assets/img/success.png" class="w-20" alt="Success" />
        <h4 class="my-2">Success!</h4>
        <p class="sub_title text-light" *ngIf="!isUpdateRole">
          Role has been created Successfully
        </p>
        <p class="sub_title text-light" *ngIf="isUpdateRole">
          Role has been updated Successfully
        </p>

        <!-- Goto Template List Button -->
        <button
          type="button"
          class="btn btn-gradient mt-2"
          (click)="gotoListing()"
        >
          Go to Roles Management
        </button>
        <!-- END Goto Template List Button -->
      </div>
    </div>
  </div>
</ng-template>
<!-- END Create Role Success -->
