import { NgModule } from '@angular/core';
import {
  CommonModule,
  DatePipe,
  TitleCasePipe,
  formatDate,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CustomerRoutingModule } from './customer-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from '../login/login.component';
import { CampaignManagerComponent } from './campaign-manager/campaign-manager.component';
import { ReportsComponent } from './reports/reports.component';
import { TemplatesComponent } from './templates/templates.component';
import { ConsentManagementComponent } from './consent-management/consent-management.component';
import { CustomerComponent } from './customer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbTypeaheadModule,
  NgbCollapseModule,
  NgbProgressbarModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { BusinessSettingsComponent } from './business-settings/business-settings.component';
import { ProductComponent } from './product/product.component';
import { NgChartsModule } from 'ng2-charts';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SharedModule } from '../shared.module';
import { AngularMaterialModule } from '../material.module';
import { SupportComponent } from './support/support.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { SourceFilterPipe } from '../services/sourcefilter.pipe';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  BarChartData,
  LineChartData,
  PieChartData,
} from '../appdata/analytics.data';
import { CampaignsetupComponent } from './campaign-manager/campaignsetup/campaignsetup.component';
import { MediaDriveComponent } from './media-drive/media-drive.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TemplatesetupComponent } from './templates/templatesetup/templatesetup.component';
import { WhiteLabelService } from '../services/whitelabel.service';
import { JoinPipe } from '../services/tags.pipe';
import { CampaignViewComponent } from './campaign-manager/campaignview/campaignview.component';
import { CampaignListComponent } from './campaign-manager/campaignlist/campaignlist.component';
import { TemplatelistComponent } from './templates/templatelist/templatelist.component';
import { CommonService } from '../services/common.service';
import { MyprofileEditComponent } from './my-profile/myprofile-edit/myprofile-edit.component';
import { SummaryReportsComponent } from './reports/summary-reports/summary-reports.component';
import { ReportTemplatesComponent } from './reports/report-templates/report-templates.component';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { ReportCreateComponent } from './reports/report-create/report-create.component';
import { ViewReportsComponent } from './reports/view-reports/view-reports.component';
import { CreateTemplateComponent } from './reports/create-template/create-template.component';
import { OptinComponent } from './consent-management/optin/optin.component';
import { OptoutComponent } from './consent-management/optout/optout.component';
import { BlocklistComponent } from './consent-management/blocklist/blocklist.component';

export const MY_DATE_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

class PickDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd MMM yyyy', this.locale);
    } else {
      return date.toDateString();
    }
  }
}

@NgModule({
  declarations: [
    DashboardComponent,
    LoginComponent,
    CampaignManagerComponent,
    ReportsComponent,
    TemplatesComponent,
    ConsentManagementComponent,
    CustomerComponent,
    BusinessSettingsComponent,
    ProductComponent,
    MyProfileComponent,
    SupportComponent,
    SourceFilterPipe,
    JoinPipe,
    AnalyticsComponent,
    CampaignListComponent,
    CampaignsetupComponent,
    CampaignViewComponent,
    MediaDriveComponent,
    TemplatesetupComponent,
    TemplatelistComponent,
    MyprofileEditComponent,
    SummaryReportsComponent,
    ReportTemplatesComponent,
    ReportsListComponent,
    ReportCreateComponent,
    ViewReportsComponent,
    CreateTemplateComponent,
    OptinComponent,
    OptoutComponent,
    BlocklistComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    CustomerRoutingModule,
    AngularMaterialModule,
    FormsModule,
    SharedModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbNavModule,
    PickerComponent,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbProgressbarModule,
    NgChartsModule,
    NgbTypeaheadModule,
    HttpClientModule,
    PdfViewerModule,
    NgxSkeletonLoaderModule,
    CalendarModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: PickDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    BarChartData,
    LineChartData,
    PieChartData,
    TitleCasePipe,
    WhiteLabelService,
    CommonService,
  ],
})
export class CustomerModule {}
