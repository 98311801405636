import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-settings',
  templateUrl: './sa-settings.component.html',
  styleUrls: ['./sa-settings.component.scss']
})
export class SaSettingsComponent {

}
