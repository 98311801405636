import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerModule } from './customer/customer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ControlCenterModule } from './control-center/control-center.module';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as fromApp from './store/app.reducer';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { PartnerComponent } from './partner/partner.component';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { TemplateEffects } from './customer/templates/store/template.effects';
import { CommonEffects } from './store/common.effects';
import { ConsentEffects } from './customer/consent-management/store/consent.effects';
import { CampaignEffects } from './customer/campaign-manager/store/campaign.effects';
import { RoleManagementEffects } from './control-center/role-management/store/role.effects';
import { CCAnalyticsEffects } from './customer/analytics/store/analytics.effects';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { LoginEffects } from './login/store/login.effects';
import { DashboardEffects } from './customer/dashboard/store/dashboard.effects';
import { ProfileEffects } from './control-center/profiles-management/store/profiles.effects';
import { SharedModule } from './shared.module';
import { ReportsEffects } from './customer/reports/store/reports.effects';
import { FbEmbeddedComponent } from './fb-embedded/fb-embedded.component';

@NgModule({
  declarations: [
    AppComponent,
    ResetpasswordComponent,
    PagenotfoundComponent,
    SuperAdminComponent,
    PartnerComponent,
    FbEmbeddedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomerModule,
    ControlCenterModule,
    SuperAdminModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([
      LoginEffects,
      DashboardEffects,
      TemplateEffects,
      CommonEffects,
      ConsentEffects,
      CampaignEffects,
      ReportsEffects,
      RoleManagementEffects,
      ProfileEffects,
      CCAnalyticsEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
