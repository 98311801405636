import { Template } from 'src/app/appdata/template.model';

export interface ActiveStatus {
  name: string;
  status: string;
  id: number;
}

export interface Content {
  header: Header;
  body: Body;
  footer: Footer;
  button_details: ButtonDetails;
}

export interface Body {
  text: string;
  variables: { [key: string]: string }[];
}

export interface ButtonDetails {
  type: string;
  buttons: Button[];
}

export interface Button {
  type: string;
  type_of_action?: string;
  button_text: string;
  phone_no?: string;
  url_type?: string;
  url?: string;
  variable?: { [key: string]: string };
}

export interface Footer {
  text: string;
}

export interface Header {
  header_type: string;
  data: Datum[];
}

export interface Datum {
  type: string;
  text?: string;
  variables?: { [key: string]: string }[];
  url?: string;
}

export const categories: string[] = ['Authentication', 'Marketing', 'Utility'];

export const types: string[] = ['Image', 'Video', 'Document'];

export const templatebtnactions: string[] = ['Call To Action', 'Quick Reply'];

export const actionTypes: string[] = ['Phone Number', 'Visit Website'];

export const urlTypes: string[] = ['Static', 'Dynamic'];

export const status: ActiveStatus[] = [
  { id: 1, name: 'Approved', status: 'approved' },
  { id: 2, name: 'Rejected', status: 'rejected' },
  { id: 3, name: 'Pending', status: 'pending' },
  { id: 4, name: 'Paused', status: 'paused' },
  { id: 5, name: 'Disabled', status: 'disabled' },
  { id: 6, name: 'Draft', status: 'draft' },
  { id: 7, name: 'Deleted', status: 'deleted' },
];

export const templateSkeletonData: Template[] = [
  {
    id: null,
    account_id: null,
    bsp_id: null,
    waba_number: null,
    template_name: null,
    languages: null,
    category: null,
    type: null,
    is_drafted: null,
    status: null,
    content: null,
    bsp_metadata: null,
    bsp_template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  {
    id: null,
    account_id: null,
    bsp_id: null,
    waba_number: null,
    template_name: null,
    languages: null,
    category: null,
    type: null,
    is_drafted: null,
    status: null,
    content: null,
    bsp_metadata: null,
    bsp_template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  {
    id: null,
    account_id: null,
    bsp_id: null,
    waba_number: null,
    template_name: null,
    languages: null,
    category: null,
    type: null,
    is_drafted: null,
    status: null,
    content: null,
    bsp_metadata: null,
    bsp_template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  {
    id: null,
    account_id: null,
    bsp_id: null,
    waba_number: null,
    template_name: null,
    languages: null,
    category: null,
    type: null,
    is_drafted: null,
    status: null,
    content: null,
    bsp_metadata: null,
    bsp_template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  {
    id: null,
    account_id: null,
    bsp_id: null,
    waba_number: null,
    template_name: null,
    languages: null,
    category: null,
    type: null,
    is_drafted: null,
    status: null,
    content: null,
    bsp_metadata: null,
    bsp_template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
];

export const languages: Array<any> = [
  { langName: 'Afrikaans', langCode: 'af' },
  { langName: 'Albanian', langCode: 'sq' },
  { langName: 'Arabic', langCode: 'ar' },
  { langName: 'Azerbaijani', langCode: 'az' },
  { langName: 'Bengali', langCode: 'bn' },
  { langName: 'Bulgarian', langCode: 'bg' },
  { langName: 'Catalan', langCode: 'ca' },
  { langName: 'Chinese (CHN)', langCode: 'zh_CN' },
  { langName: 'Chinese (HKG)', langCode: 'zh_HK' },
  { langName: 'Chinese (TAI)', langCode: 'zh_TW' },
  { langName: 'Croatian', langCode: 'hr' },
  { langName: 'Czech', langCode: 'cs' },
  { langName: 'Danish', langCode: 'da' },
  { langName: 'Dutch', langCode: 'nl' },
  { langName: 'English', langCode: 'en' },
  { langName: 'English (UK)', langCode: 'en_GB' },
  { langName: 'English (US)', langCode: 'en_US' },
  { langName: 'Estonian', langCode: 'et' },
  { langName: 'Filipino', langCode: 'fil' },
  { langName: 'Finnish', langCode: 'fi' },
  { langName: 'French', langCode: 'fr' },
  { langName: 'Georgian', langCode: 'ka' },
  { langName: 'German', langCode: 'de' },
  { langName: 'Greek', langCode: 'el' },
  { langName: 'Gujarati', langCode: 'gu' },
  { langName: 'Hausa', langCode: 'ha' },
  { langName: 'Hebrew', langCode: 'he' },
  { langName: 'Hindi', langCode: 'hi' },
  { langName: 'Hungarian', langCode: 'hu' },
  { langName: 'Indonesian', langCode: 'id' },
  { langName: 'Irish', langCode: 'ga' },
  { langName: 'Italian', langCode: 'it' },
  { langName: 'Japanese', langCode: 'ja' },
  { langName: 'Kannada', langCode: 'kn' },
  { langName: 'Kazakh', langCode: 'kk' },
  { langName: 'Kinyarwanda', langCode: 'rw_RW' },
  { langName: 'Korean', langCode: 'ko' },
  { langName: 'Kyrgyz (Kyrgyzstan)', langCode: 'ky_KG' },
  { langName: 'Lao', langCode: 'lo' },
  { langName: 'Latvian', langCode: 'lv' },
  { langName: 'Lithuanian', langCode: 'lt' },
  { langName: 'Macedonian', langCode: 'mk' },
  { langName: 'Malay', langCode: 'ms' },
  { langName: 'Malayalam', langCode: 'ml' },
  { langName: 'Marathi', langCode: 'mr' },
  { langName: 'Norwegian', langCode: 'nb' },
  { langName: 'Persian', langCode: 'fa' },
  { langName: 'Polish', langCode: 'pl' },
  { langName: 'Portuguese (BR)', langCode: 'pt_BR' },
  { langName: 'Portuguese (POR)', langCode: 'pt_PT' },
  { langName: 'Punjabi', langCode: 'pa' },
  { langName: 'Romanian', langCode: 'ro' },
  { langName: 'Russian', langCode: 'ru' },
  { langName: 'Serbian', langCode: 'sr' },
  { langName: 'Slovak', langCode: 'sk' },
  { langName: 'Slovenian', langCode: 'sl' },
  { langName: 'Spanish', langCode: 'es' },
  { langName: 'Spanish (ARG)', langCode: 'es_AR' },
  { langName: 'Spanish (SPA)', langCode: 'es_ES' },
  { langName: 'Spanish (MEX)', langCode: 'es_MX' },
  { langName: 'Swahili', langCode: 'sw' },
  { langName: 'Swedish', langCode: 'sv' },
  { langName: 'Tamil', langCode: 'ta' },
  { langName: 'Telugu', langCode: 'te' },
  { langName: 'Thai', langCode: 'th' },
  { langName: 'Turkish', langCode: 'tr' },
  { langName: 'Ukrainian', langCode: 'uk' },
  { langName: 'Urdu', langCode: 'ur' },
  { langName: 'Uzbek', langCode: 'uz' },
  { langName: 'Vietnamese', langCode: 'vi' },
  { langName: 'Zulu', langCode: 'zu' },
];
