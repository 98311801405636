import { DatePipe } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgbDateStruct,
  NgbDate,
  NgbCalendar,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';
import { DateObject, SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, AfterViewChecked {
  hoveredDate: NgbDate | null = null;
  datedisplay: string;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  fromDate!: NgbDate | null;
  toDate: NgbDate | null = null;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  today: NgbDateStruct;
  @Input() requestfrom: string;
  @Input() last: any;
  @ViewChild('dateInput') dateInput!: ElementRef<HTMLInputElement>;
  @ViewChild('myDrop') myDrop: NgbDropdown;

  constructor(
    private calendar: NgbCalendar,
    private shareservice: SharedService,
    private datePipe: DatePipe
  ) {
    this.today = calendar.getToday();
    this.minDate = this.getMinDate();
    this.maxDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.shareservice.datePickerObject.subscribe((value) => {
      if (value) {
        const from_date = new Date(
          value.from?.year,
          value.from?.month - 1,
          value.from?.day
        );
        const to_date = new Date(
          value.to?.year,
          value.to?.month - 1,
          value.to?.day
        );
        if (value.from && value.to === null) {
          this.datedisplay = this.datePipe.transform(from_date, 'longDate');
        } else if (value.from && value.to) {
          this.datedisplay =
            this.datePipe.transform(from_date, 'longDate') +
            ' - ' +
            this.datePipe.transform(to_date, 'longDate');
        }
      } else {
        this.adustDateInp();
        // this.selectLastDays(7);
      }
    });

    this.shareservice.customDateAction.subscribe((value) => {
      if (value) {
        this.selectLastDays(value);
      }
    }); 

    if (this.last) {
      this.selectLastDays(this.last);
    } 
  }

  ngAfterViewChecked(): void {
    this.adjustInputWidth();
  }

  getToday(): NgbDateStruct {
    const today = this.calendar.getToday();
    return { year: today.year, month: today.month, day: today.day };
  }

  getMinDate(): NgbDateStruct {
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() - 90);
    return {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
  }

  adustDateInp() {
    if (this.datedisplay) {
      this.datedisplay = null;
      this.fromDate = null;
      this.toDate = null;
      const payload = {
        from: this.fromDate,
        to: this.toDate,
        action: 'Date Cleared',
        requestfrom: this.requestfrom,
      };
      this.shareservice.datePickerObject.next(payload);
    }
  }

  adjustInputWidth() {
    this.dateInput.nativeElement.style.width = 'auto';
    this.dateInput.nativeElement.style.width = `${Math.max(
      this.dateInput.nativeElement.scrollWidth + 1
    )}px`;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      // this.toDate = date;
      if (date.before(this.fromDate)) {
        this.toDate = this.fromDate;
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    const payload: DateObject = {
      from: this.fromDate,
      to: this.toDate,
      action: 'Date Selected',
      requestfrom: this.requestfrom,
    };
    this.shareservice.datePickerObject.next(payload);
    if (this.fromDate && this.toDate) {
      this.myDrop.close();
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    // this.selectLastDays(7);
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  // isHovered(date: NgbDate) {
  //   return (
  //     this.fromDate &&
  //     !this.toDate &&
  //     this.hoveredDate &&
  //     date.after(this.fromDate) &&
  //     date.before(this.hoveredDate)
  //   );
  // }

  // isInside(date: NgbDate) {
  //   return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return (
  //     date.equals(this.fromDate) ||
  //     (this.toDate && date.equals(this.toDate)) ||
  //     this.isInside(date) ||
  //     this.isHovered(date)
  //   );
  // }

  toLastMonth() {
    const firstDay = new NgbDate(
      this.calendar.getToday().year,
      this.calendar.getToday().month - 1,
      1
    ); //First day of the current month

    //Last day of the current month
    const lastDay = this.calendar.getPrev(
      this.calendar.getNext(firstDay, 'm', 1),
      'd',
      1
    );

    this.fromDate = firstDay;
    this.toDate = lastDay;
    const payload: DateObject = {
      from: this.fromDate,
      to: this.toDate,
      action: 'Date Selected',
      requestfrom: this.requestfrom,
    };
    this.shareservice.datePickerObject.next(payload);
    if (this.fromDate && this.toDate) {
      this.dateInput.nativeElement;
    }
  }

  toCurrentMonth() {
    const firstDay = new NgbDate(
      this.calendar.getToday().year,
      this.calendar.getToday().month,
      1
    ); //First day of the current month

    //Last day of the current month
    const lastDay = this.calendar.getPrev(
      this.calendar.getNext(firstDay, 'm', 1),
      'd',
      1
    );

    this.fromDate = firstDay;
    this.toDate = lastDay;
    const payload: DateObject = {
      from: this.fromDate,
      to: this.toDate,
      action: 'Date Selected',
      requestfrom: this.requestfrom,
    };
    this.shareservice.datePickerObject.next(payload);
  }

  selectToday() {
    this.fromDate = this.calendar.getToday();
    this.toDate = null;
    const payload: DateObject = {
      from: this.fromDate,
      to: this.toDate,
      action: 'Date Selected',
      requestfrom: this.requestfrom,
    };
    this.shareservice.datePickerObject.next(payload);
  }

  selectLastDays(customday: number) {
    this.fromDate = this.calendar.getPrev(
      this.calendar.getToday(),
      'd',
      customday - 1
    );
    this.toDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 0);
    const payload: DateObject = {
      from: this.fromDate,
      to: this.toDate,
      action: 'Date Selected',
      requestfrom: this.requestfrom,
    };
    this.shareservice.datePickerObject.next(payload);
    if (this.myDrop) {
      this.myDrop.close();
    }
  }

  customDate() {
    this.fromDate = null;
    this.toDate = null;
  }
}
