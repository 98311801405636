import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReports from './reports.reducers';

export const selectReportData =
  createFeatureSelector<fromReports.ReportTemplatesState>('reportdata');

export const selectReportDetails = createSelector(
  selectReportData,
  fromReports.getReportTemplateData
);
