import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRole from './role.reducers';

export const selectRoleData = createFeatureSelector<fromRole.State>('roleData');

export const selectRoleDataDtls = createSelector(
  selectRoleData,
  fromRole.getRoleData
);
