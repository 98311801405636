import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as templateActions from './template.actions';
import { TemplateService } from 'src/app/services/template.service';
import { TemplateListData, TemplateData } from 'src/app/appdata/template.model';

@Injectable()
export class TemplateEffects {
  constructor(
    private actions$: Actions,
    private templateservice: TemplateService
  ) {}

  getTemplatesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.fetchtemplateslist),
      exhaustMap((action) =>
        this.templateservice.fetchtemplates(action.payload).pipe(
          map((response: TemplateListData) => {
            return templateActions.fetchtemplateslistSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(templateActions.fetchtemplateslistFailure(error))
          )
        )
      )
    );
  });

  getTemplateDtls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.fetchtemplateDtls),
      exhaustMap((action) =>
        this.templateservice.getTemplateDtls(action.id).pipe(
          map((response: TemplateData) => {
            return templateActions.fetchtemplateDtlsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(templateActions.fetchtemplateDtlsFailure(error))
          )
        )
      )
    );
  });

  initiateCreateTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.createtemplate),
      exhaustMap((action) =>
        this.templateservice.createtemplate(action.data).pipe(
          map((response: any) => {
            return templateActions.createtemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(templateActions.createtemplateFailure(error))
          )
        )
      )
    );
  });

  initiateUpdateTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.updatetemplate),
      exhaustMap((action) =>
        this.templateservice.updatetemplate(action.data, action.id).pipe(
          map((response: any) => {
            return templateActions.updatetemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(templateActions.updatetemplateFailure(error))
          )
        )
      )
    );
  });

  deleteTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(templateActions.deletetemplate),
      exhaustMap((action) =>
        this.templateservice.deletetemplate(action.data).pipe(
          map((response: TemplateListData) => {
            return templateActions.deletetemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(templateActions.deletetemplateFailure(error))
          )
        )
      )
    );
  });
}
