<main role="main" class="main" id="my_profile">
  <div class="content container-fluid">
    <!-- Edit My Profile -->
    <div class="card p-3">
      <div class="top_header_options justify-content-start gap-2">
        <!-- Goto myprofile -->
        <button class="btn btn-sm btn-icon btn-light" (click)="onBack()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <!-- END Goto myprofile -->

        <h3 class="main_title">Edit - My profile</h3>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]="displayTemplate"> </ng-container>
    <ng-template #editmyprofile>
      <div class="card mt-2 px-10">
        <div class="w-80 w-lg-50 m-auto">
          <!-- My profile basic information -->
          <div class="card mb-4">
            <div class="card-body">
              <h4 class="title mb-4">Basic Information</h4>

              <form
                [formGroup]="myProfileFormGroup"
                (ngSubmit)="onSubmit(myProfileFormGroup)"
              >
                <!-- My Profile Avatar Upload -->
                <div class="form-group">
                  <div class="avatar avatar-circle avatar-xl avatar-uploader">
                    <!-- Upload Avatar -->
                    <ng-container
                      *ngIf="
                        !myProfileFormGroup.get('profileImg').value;
                        else profileUploaded
                      "
                    >
                      <img
                        id="avatarImg"
                        class="avatar-img"
                        src="assets/img/customer/default_avatar.jpg"
                        alt="User Avatar"
                      />
                      <label class="avatar-uploader-trigger">
                        <em
                          class="bi-camera-fill avatar-uploader-icon shadow-soft avatar_icon"
                        ></em>
                        <input
                          type="file"
                          accept="image/*"
                          class="d-none"
                          id="mp_upload_input"
                          name="userImage"
                          (change)="onAvatarUpload($event)"
                        />
                      </label>
                    </ng-container>
                    <!-- END Upload Avatar -->

                    <!-- Remove Uploaded Avatar -->
                    <ng-template #profileUploaded>
                      <a
                        class="delete avatar-uploader"
                        (click)="onAvatarRemove()"
                      >
                        <em class="bi-trash-fill"></em>
                      </a>
                      <img
                        id="avatarImg"
                        class="avatar-img"
                        [src]="myProfileFormGroup.get('profileImg').value"
                        alt="profile Avatar"
                      />
                    </ng-template>
                    <!-- END Remove Uploaded Avatar -->
                  </div>
                </div>
                <!-- END My Profile Avatar Upload -->

                <!-- Full Name -->
                <div class="form-group">
                  <label class="input-label" for="firstName"
                    >Full Name
                    <span class="text-danger" matTooltip="Fullname is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="row row-cols-2">
                    <!-- My Profile First Name -->
                    <div class="col">
                      <input
                        class="form-control"
                        id="firstName"
                        type="text"
                        placeholder="First Name"
                        formControlName="firstName"
                      />
                      <!-- My Profile Firstname Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            myProfileFC['firstName'].touched ||
                            myProfileFC['firstName'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              myProfileFC['firstName'].value === '' ||
                                myProfileFC['firstName'].value === null;
                              else fcInvalid
                            "
                          >
                            First name can not be empty.
                          </ng-container>
                          <ng-template #fcInvalid>
                            <span *ngIf="myProfileFC['firstName'].errors">
                              Please enter a valid first name.
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <!-- END My Profile Firstname Error -->
                    </div>
                    <!-- ENd My Profile First Name -->

                    <!-- My Profile Last Name -->
                    <div class="col">
                      <input
                        class="form-control form-control-lg"
                        type="text"
                        placeholder="Last Name"
                        formControlName="lastName"
                      />
                      <!-- My Profile Lastname Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            myProfileFC['lastName'].touched ||
                            myProfileFC['lastName'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              myProfileFC['lastName'].value === '' ||
                                myProfileFC['lastName'].value === null;
                              else fcInvalid
                            "
                          >
                            Last name can not be empty.
                          </ng-container>
                          <ng-template #fcInvalid>
                            <span *ngIf="myProfileFC['lastName'].errors">
                              Please enter a valid last name.
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <!-- END My Profile Lastname Error -->
                    </div>
                    <!-- END My Profile Last Name -->
                  </div>
                </div>
                <!-- END Full Name -->

                <!-- Mobile Number & Designation -->
                <div class="form-group">
                  <div class="row row-cols-2">
                    <!-- Mobile Number -->
                    <div class="col">
                      <label class="input-label" for="profileMobile"
                        >Mobile Number
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        class="form-control"
                        id="profileMobile"
                        type="tel"
                        placeholder="Enter mobile number here"
                        formControlName="mobile"
                      />
                      <!-- My Profile Mobile Number Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            myProfileFC['mobile'].touched ||
                            myProfileFC['mobile'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              myProfileFC['mobile'].value === '' ||
                                myProfileFC['mobile'].value === null;
                              else fcInvalid
                            "
                          >
                            Mobile number can not be empty.
                          </ng-container>
                          <ng-template #fcInvalid>
                            <span *ngIf="myProfileFC['mobile'].errors">
                              Please enter a valid mobile number.
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <!-- END My Profile Mobile Number Error -->
                    </div>
                    <!-- END Mobile Number -->

                    <!-- Designation -->
                    <div class="col">
                      <label class="input-label" for="profileDesignation"
                        >Designation (optional)
                      </label>
                      <input
                        class="form-control"
                        id="profileDesignation"
                        type="text"
                        placeholder="Enter designation here"
                        formControlName="designation"
                      />
                      <!-- My Profile Designation Error -->
                      <div class="invalid-feedback help-block">
                        <div *ngIf="myProfileFC['designation'].errors">
                          <span> Please enter a valid designation. </span>
                        </div>
                      </div>
                      <!-- END My Profile Designation Error -->
                    </div>
                    <!-- END Designation -->
                  </div>
                </div>
                <!-- END Mobile Number & Designation -->

                <mat-divider class="my-4"></mat-divider>

                <div class="form_action_btns">
                  <div class="options_left"></div>
                  <div class="options_right">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      (click)="onBack()"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-gradient"
                      type="submit"
                      [disabled]="myProfileFormGroup.invalid"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- END My profile basic information -->

          <!-- My profile change password -->
          <div class="card mt-4">
            <div class="card-body">
              <h4 class="title mb-4">Change Password</h4>

              <form
                [formGroup]="myProfilePwdFormGroup"
                (ngSubmit)="onPswdChange(myProfilePwdFormGroup)"
              >
                <!-- New Password -->
                <div class="form-group">
                  <label class="input-label" for="newpwd"
                    >New Password
                    <span class="text-danger" matTooltip="Password is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="input-group input-group-merge">
                    <input
                      formControlName="newPass"
                      class="form-control"
                      [type]="showNewPass ? 'text' : 'password'"
                      placeholder="Enter New password"
                      aria-label="password"
                      name="newPassword"
                      id="newpwd"
                      autocomplete="off"
                    />
                    <div
                      class="input-group-append input-group-text changePassTarget"
                    >
                      <em
                        class="changePassIcon bi"
                        [ngClass]="{
                          'bi-eye-fill': !showNewPass,
                          'bi-eye-slash-fill': showNewPass
                        }"
                        (click)="showNewPass = !showNewPass"
                      >
                      </em>
                    </div>
                  </div>
                  <!-- My Profile New Password Error -->
                  <div class="invalid-feedback help-block">
                    <div
                      *ngIf="
                        myProfilePwdFC['newPass'].touched ||
                        myProfilePwdFC['newPass'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          myProfilePwdFC['newPass'].value === '';
                          else fnInvalid
                        "
                      >
                        Please enter valid New Password.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span
                          *ngIf="myProfilePwdFC['newPass'].value.length < 8"
                        >
                          Password length should be minimum 8 characters
                        </span>
                        <div
                          *ngIf="
                            myProfilePwdFC['newPass'].value.length >= 8 &&
                            myProfilePwdFC['newPass'].errors
                          "
                        >
                          Password should contain atleast
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError('hasSmallCase')
                            "
                            >1 Lowercase</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError(
                                'hasCapitalCase'
                              )
                            "
                            >1 Uppercase</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError('hasNumber')
                            "
                            >, 1 Numeric value</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError(
                                'hasSpecialCharacters'
                              )
                            "
                            >, 1 Special Character</span
                          >.
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END My Profile New Password Error -->
                </div>
                <!-- END New Password -->

                <!-- Confirm Password -->
                <div class="form-group">
                  <label class="input-label" for="cnfrmpwd"
                    >Confirm Password
                    <span
                      class="text-danger"
                      matTooltip="Confirm Password is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="input-group input-group-merge">
                    <input
                      formControlName="confNewPass"
                      [type]="showConfPass ? 'text' : 'password'"
                      class="form-control"
                      placeholder="Confirm Password"
                      aria-label="Confirm Password"
                      name="confirmPassword"
                      id="cnfrmpwd"
                      autocomplete="off"
                      data-msg="Your confirm password is invalid. Please try again."
                    />
                    <div
                      class="input-group-append input-group-text changePassTarget"
                    >
                      <em
                        class="changePassIcon bi"
                        [ngClass]="{
                          'bi-eye-fill': !showConfPass,
                          'bi-eye-slash-fill': showConfPass
                        }"
                        (click)="showConfPass = !showConfPass"
                      >
                      </em>
                    </div>
                  </div>
                  <!-- My Profile Confirm New Password Error -->
                  <div class="invalid-feedback help-block">
                    <div
                      *ngIf="
                        myProfilePwdFC['confNewPass'].touched ||
                        myProfilePwdFC['confNewPass'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          myProfilePwdFC['confNewPass'].value === '' ||
                            myProfilePwdFC['confNewPass'].value === null;
                          else fcInvalid
                        "
                      >
                        Please enter valid Confirm Password.
                      </ng-container>
                      <ng-template #fcInvalid>
                        <span
                          *ngIf="
                            myProfilePwdFC['confNewPass'].value !==
                            myProfilePwdFC['newPass'].value
                          "
                        >
                          Password and Confirm Password does not match.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END My Profile Confirm New Password Error -->
                </div>
                <!-- END Confirm Password -->

                <mat-divider class="my-4"></mat-divider>

                <div class="form_action_btns">
                  <div class="options_left"></div>
                  <div class="options_right">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      (click)="onBack()"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-gradient"
                      type="submit"
                      [disabled]="myProfilePwdFormGroup.invalid"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- END My profile change password -->
        </div>
      </div>
    </ng-template>

    <ng-template #editpassword>
      <div class="card mt-2 px-10">
        <div class="w-80 w-lg-50 m-auto">
          <!-- My profile change password -->
          <div class="card mt-4">
            <div class="card-body">
              <h4 class="title mb-4">Change Password</h4>

              <form
                [formGroup]="myProfilePwdFormGroup"
                (ngSubmit)="onPswdChange(myProfilePwdFormGroup)"
              >
                <!-- New Password -->
                <div class="form-group">
                  <label class="input-label" for="newpwd"
                    >New Password
                    <span class="text-danger" matTooltip="Password is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="input-group">
                    <input
                      formControlName="newPass"
                      class="form-control"
                      [type]="showNewPass ? 'text' : 'password'"
                      placeholder="Enter New password"
                      aria-label="password"
                      name="newPassword"
                      id="newpwd"
                      autocomplete="off"
                    />
                    <div class="input-group-text changePassTarget">
                      <em
                        class="changePassIcon bi"
                        [ngClass]="{
                          'bi-eye-fill': !showNewPass,
                          'bi-eye-slash-fill': showNewPass
                        }"
                        (click)="showNewPass = !showNewPass"
                      >
                      </em>
                    </div>
                  </div>
                  <!-- My Profile New Password Error -->
                  <div class="invalid-feedback help-block">
                    <div
                      *ngIf="
                        myProfilePwdFC['newPass'].touched ||
                        myProfilePwdFC['newPass'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          myProfilePwdFC['newPass'].value === '';
                          else fnInvalid
                        "
                      >
                        Please enter valid New Password.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span
                          *ngIf="myProfilePwdFC['newPass'].value.length < 8"
                        >
                          Password length should be minimum 8 characters
                        </span>
                        <div
                          *ngIf="
                            myProfilePwdFC['newPass'].value.length >= 8 &&
                            myProfilePwdFC['newPass'].errors
                          "
                        >
                          Password should contain atleast
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError('hasSmallCase')
                            "
                            >1 Lowercase</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError(
                                'hasCapitalCase'
                              )
                            "
                            >1 Uppercase</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError('hasNumber')
                            "
                            >, 1 Numeric value</span
                          >
                          <span
                            *ngIf="
                              myProfilePwdFC['newPass'].hasError(
                                'hasSpecialCharacters'
                              )
                            "
                            >, 1 Special Character</span
                          >.
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END My Profile New Password Error -->
                </div>
                <!-- END New Password -->

                <!-- Confirm Password -->
                <div class="form-group">
                  <label class="input-label" for="cnfrmpwd"
                    >Confirm Password
                    <span
                      class="text-danger"
                      matTooltip="Confirm Password is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="input-group">
                    <input
                      formControlName="confNewPass"
                      [type]="showConfPass ? 'text' : 'password'"
                      class="form-control"
                      placeholder="confirm password"
                      aria-label="confirm password"
                      name="confirmPassword"
                      id="cnfrmpwd"
                      autocomplete="off"
                      data-msg="Your confirm password is invalid. Please try again."
                    />
                    <div class="input-group-text changePassTarget">
                      <em
                        class="changePassIcon bi"
                        [ngClass]="{
                          'bi-eye-fill': !showConfPass,
                          'bi-eye-slash-fill': showConfPass
                        }"
                        (click)="showConfPass = !showConfPass"
                      >
                      </em>
                    </div>
                  </div>
                  <!-- My Profile Confirm New Password Error -->
                  <div class="invalid-feedback help-block">
                    <div
                      *ngIf="
                        myProfilePwdFC['confNewPass'].touched ||
                        myProfilePwdFC['confNewPass'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          myProfilePwdFC['confNewPass'].value === '' ||
                            myProfilePwdFC['confNewPass'].value === null;
                          else fcInvalid
                        "
                      >
                        Please enter valid Confirm Password.
                      </ng-container>
                      <ng-template #fcInvalid>
                        <span
                          *ngIf="
                            myProfilePwdFC['confNewPass'].value !==
                            myProfilePwdFC['newPass'].value
                          "
                        >
                          Password and Confirm Password does not match.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- END My Profile Confirm New Password Error -->
                </div>
                <!-- END Confirm Password -->

                <mat-divider class="my-4"></mat-divider>

                <div class="form_action_btns">
                  <div class="options_left"></div>
                  <div class="options_right">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      (click)="onBack()"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-gradient"
                      type="submit"
                      [disabled]="myProfilePwdFormGroup.invalid"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- END My profile change password -->
        </div>
      </div>
    </ng-template>

    <!-- END Edit My Profile -->
  </div>
</main>
