import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectProfilesDtlsData } from '../store/common.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private router: Router, private readonly store: Store) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectProfilesDtlsData).pipe(
      map((res) => {
        const isAuth = !!res.token;
        if (isAuth) {
          return true;
        } else {
          return this.router.createUrlTree(['/login']);
        }
      })
    );
  }
}
