import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError } from 'rxjs';

export interface TokenDetails {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private router: Router, private http: HttpClient) {}

  //* Get Token Value //
  public get tokenValue(): TokenDetails {
    const token = localStorage.getItem('authUser');
    return JSON.parse(token);
  }

  //* Login Service //
  login(payload: any) {
    const url = environment.backendurl + `/api/auth/login`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  //* Auto Login Service //
  autoLogin() {
    const user_details = JSON.parse(localStorage.getItem('wabaauthdata'));
    if (!user_details && !user_details?.token) {
      this.router.navigate(['/login']);
    }
  }

  //* Forgotpassword Service //
  forgotpswd(payload: any) {
    const url = environment.backendurl + `/api/auth/forgot-password`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  //* Validate Reset Password Token in URL //

  ValidateResetPswd(httpOptions: any, token: string) {
    const payload = {
      token: token,
    };
    const url = environment.backendurl + `/api/auth/validate-reset-password-token`;


    return this.http
      .post<any>(url,
        payload,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          throw new Error(error.error.detail);
        })
      );
  }

  //* Reset Password //
  resetPswd(payload: any) {
    const url = environment.backendurl + `/api/auth/reset-password`;

    return this.http
      .post<any>(url, payload)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  //* Logout Service //
  logout() {
    localStorage.clear();
  }
}
