import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, map, catchError, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SharedService } from './shared.service';
import { WhiteLabelService } from './whitelabel.service';
import * as commonactions from 'src/app/store/common.actions';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelGuard {
  whiteLabeledData: any;

  constructor(
    private readonly store: Store,
    private whtlbservice: WhiteLabelService,
    private shareservice: SharedService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.shareservice.fetchWlbData().pipe(
      map((res) => {
        this.whiteLabeledData = res.wlb_settings ? res.wlb_settings : res;
        if (typeof this.whiteLabeledData === 'string') {
          this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
        }
        localStorage.setItem('waba_wbl', JSON.stringify(this.whiteLabeledData));
        this.whtlbservice.applyWhiteLabeling(this.whiteLabeledData);
        this.store.dispatch(
          commonactions.setWhiteLabelData({ data: this.whiteLabeledData })
        );
        return true;
      }),
      catchError((error: any) => {
        // Handle and map the error
        console.error('Error fetching wbl:', error);
        return of(true);
      })
    );
  }
}
