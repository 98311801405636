<!-- Show Individual Campaign -->
<div class="card p-3">
  <div class="top_header_options">
    <div class="d-flex align-items-center gap-2">
      <!-- Back to Campaigns Listing -->
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
        <em class="bi bi-chevron-left"></em>
      </button>
      <!-- END Back to Campaigns Listing -->

      <h3 class="main_title">Campaign Details - {{ campaignData?.name }}</h3>
    </div>
  </div>
</div>

<div class="card mt-2">
  <div class="card-body">
    <div class="campaign_view_details">
      <div class="row row-gap-4">
        <div class="col-xl-4 col-md-12">
          <div class="campaign_template">
            <div class="details">

              <!-- Campaign ID -->
              <p>
                Campaign ID - <span class="text-dark me-2">{{ campaignData?.id }}</span>
                <em
                  class="bi bi-clipboard text-primary fs-6 pointer"
                  *ngIf="!isCopied"
                  matTooltip="{{!isCopied?'Copy Campaign ID':''}}"
                  [cdkCopyToClipboard]="campaignData?.id"
                  (click)="copyData()"
                  (keyup)="copyData()"
                ></em>
                <em
                  class="bi bi-clipboard-check text-success fs-6 pointer"
                  matTooltip="{{isCopied?'Campaign ID Copied':''}}"
                  *ngIf="isCopied"
                ></em>
              </p>
              <!-- END Campaign ID -->

              <!-- Campaign Name -->
              <div class="row mt-2">
                <div class="col-12">
                  <p>Campaign Name</p>
                </div>
                <div class="col-12">
                  <span class="h5">{{ campaignData?.name }}</span>
                </div>
              </div>
              <!-- END Campaign Name -->

              <mat-divider class="my-2"></mat-divider>

              <!-- Campaign Created by -->
              <div class="row">
                <div class="col-5">
                  <p>Created By</p>
                </div>
                <div class="col-1">:</div>
                <div class="col-6">
                  <span class="h5">{{!campaignData?.created_by ? 'N/A' : campaignData?.created_by | titlecase}}</span>
                </div>
              </div>
              <!-- END Campaign Created by -->

              <!-- Campaign Created On -->
              <div class="row">
                <div class="col-5">
                  <p>Created On</p>
                </div>
                <div class="col-1">:</div>
                <div class="col-6">
                  <span class="h5">
                    {{ campaignData?.created_at | date : "dd/MM/yy, h:mm a" }}
                    <em class="bi bi-info-circle small"
                      *ngIf="campaignData?.created_at"
                      matTooltip="The time is in IST (India Standard Time, GMT+5:30)."
                    ></em>
                  </span>
                </div>
              </div>
              <!-- END Campaign Created On -->

              <!-- Campaign Category -->
              <div class="campaign_category">
                <div class="row">
                  <div class="col-5">
                    <p>Created Category</p>
                  </div>
                  <div class="col-1">:</div>
                  <div class="col-6">
                    <span class="h5">{{ campaignData?.category | titlecase }}</span>
                  </div>
                </div>
              </div>
              <!-- END Campaign Category -->

              <mat-divider class="my-2"></mat-divider>

              <!-- Template Preview -->
              <div class="campaign_template_preview my-3">
                <h3>Template Preview</h3>

                <p class="mt-3">
                  Template Name :
                  <span class="h5">{{
                    campaignData?.template_payload
                      ? getTemplateName(campaignData?.template_payload)
                      : null
                  }}</span>
                </p>
              </div>
              <!-- END Template Preview -->
            </div>
            <app-whatsapp-preview></app-whatsapp-preview>
          </div>
        </div>

        <div class="col-xl-8 col-md-12">
          <div class="card border mb-3 p-2">
            <!-- Header Options -->
            <div class="row justify-content-between">
              <div class="col-auto">
                <ul class="list-inline list-inline-flex mb-0">
                  <!-- Status Filter -->
                  <li class="list-inline-item">
                    <div
                      ngbDropdown
                      class="d-inline-block filter-dropdown"
                      #myDrop="ngbDropdown"
                    >
                      <button
                        type="button"
                        class="btn btn-lg btn-outline-default"
                        id="CampaignfiltersDropDown"
                        ngbDropdownToggle
                      >
                        <em class="bi bi-funnel me-1"></em>
                        Filters
                      </button>
                      <div
                        class="dropdown-menu"
                        ngbDropdownMenu
                        aria-labelledby="CampaignfiltersDropDown"
                      >
                        <div class="card p-0 shadow-none">
                          <!-- Type Ng-select -->
                          <div class="card-header">
                            <h4 class="title">Filters</h4>
                          </div>
                          <div
                            class="card-body py-2 px-3"
                            (click)="stopPropagation($event)"
                            (keyup)="stopPropagation($event)"
                          >
                            <!-- Campaign Status -->
                            <span class="label">Status</span>
                            <ng-select
                              [items]="campaignStatus"
                              bindLabel="name"
                              bindValue="id"
                              [(ngModel)]="selectedCampaignStatus"
                              [placeholder]="'-- Select --'"
                              [clearable]="false"
                              [searchable]="false"
                            >
                            </ng-select>
                            <!-- END Campaign Status -->
                          </div>

                          <div class="card-footer">
                            <button
                              type="button"
                              class="btn btn-block btn-gradient"
                              (click)="
                                applyFilter();
                                $event.stopPropagation();
                                myDrop.close()
                              "
                              *ngIf="selectedCampaignStatus"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- END Status Filter -->
                </ul>
              </div>

              <div class="col-auto pr-2 d-flex align-items-center">
                <ul class="list-inline list-inline-flex mb-0">
                  <!-- Refresh Campaign Analytics Option -->
                  <li class="list-inline-item">
                    <button
                      type="button"
                      class="btn btn-icon btn-default"
                      matTooltip="Global refresh to get all campaigns status"
                      (click)="refreshCampaignAnalytics()"
                    >
                      <em class="bi bi-arrow-repeat"></em>
                    </button>
                  </li>
                  <!-- END Refresh Campaign Analytics Option -->

                  <!-- Export Button for creating campaign report -->
                  <li class="list-inline-item">
                    <button class="btn btn-outline-primary"
                      [matTooltip]="showExportBtn? 'Export campaign report' : 'Export option will be enabled in 30 sec'"
                      (click)="showExportBtn ? createReport() : ''"
                      [disabled]="!showExportBtn"
                    >
                      <em class="bi bi-box-arrow-up-right"></em> Export
                    </button>
                  </li>
                  <!-- END Export Button for creating campaign report -->
                </ul>
              </div>
            </div>
            <!-- END Header Options -->

            <!-- Filter Selected Values -->
            <div
              class="selected_filters mt-2 p-2 d-flex align-items-center justify-content-between"
              *ngIf="selectedCampaignStatus"
            >
              <!-- Selected Filter Options -->
              <div class="selected_items">
                <mat-chip-listbox aria-label="Selected Campaign Status">
                  <mat-chip>
                    Type: <b>{{ selectedCampaignStatus | titlecase }}</b>
                    <button
                      matChipRemove
                      (click)="clearCampaignAnalyticsFilter()"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-listbox>
              </div>
              <!-- END Selected Filter Options -->

              <!-- Clear Filters Option -->
              <div class="clear_filters">
                <mat-chip-listbox aria-label="Clear selected filters">
                  <mat-chip 
                    (click)="clearCampaignAnalyticsFilter()"
                    (keyup)="clearCampaignAnalyticsFilter()"
                  >
                    Clear All
                  </mat-chip>
                </mat-chip-listbox>
              </div>
              <!-- END Clear Filters Option -->
            </div>
            <!-- END Filter Selected Values -->
          </div>

          <ng-container *ngIf="isRefreshing; else datawaiting">
            <div class="stats_sec">
              <!-- Submitted Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-clock stats_icon submitted"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Submitted</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall
                          .submitted
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Submit option typically refers to the action of creating or preparing a campaign. This excludes Opt-out and Blocklist recipients."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Submitted Stats -->
  
              <!-- Sent Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-check2 stats_icon sent"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Sent</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall.sent
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Sent section usually displays campaigns that have already been dispatched or delivered to the intended recipients"
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Sent Stats -->
  
              <!-- Delivered Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-check2-all stats_icon delivered"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Delivered</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall
                          .delivered
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Delivered status for comprehensive tracking of successfully sent services or messages, ensuring efficient delivery management."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Delivered Stats -->
  
              <!-- Read Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-check2-all stats_icon read"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Read</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall.read
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Messages or notifications that have been viewed or accessed by the user or the system."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Read Stats -->
  
              <!-- Unread Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-eye-slash stats_icon unread"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Unread</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall.unread
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Messages or notifications that haven't been opened or viewed yet, indicating pending or new items requiring attention or action."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Unread Stats -->
  
              <!-- Pending Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-stopwatch-fill stats_icon pending"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Pending</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall.pending
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Pending option usually refers to messages or tasks that are queued or awaiting processing."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Pending Stats -->
  
              <!-- Failed Stats -->
              <div class="stats_item">
                <div class="stats_info_icon">
                  <i class="bi bi-x-circle-fill stats_icon failed"></i>
                </div>
                <div class="stats_data">
                  <h6 class="mb-0 stats_title">Failed</h6>
                  <span class="lead">{{
                    campaignAnalyticsData?.data.analytics_data
                      ? campaignAnalyticsData?.data.status_counts.overall.failed
                      : 0
                  }}</span>
                </div>
                <div class="stats_info_icon">
                  <em
                    class="bi-info-circle"
                    matTooltip="Failed option typically represents messages or tasks that were unsuccessful in delivery. This excludes Opt-out and Blocklist recipients."
                    matTooltipPosition="above"
                  ></em>
                </div>
              </div>
              <!-- END Failed Stats -->
            </div>
  
            <div class="mt-3">
              <!-- Campaigns Listing -->
              <div
                class="table-wrapper"
                *ngIf="campaignAnalyticsData?.data.count > 0; else nodata"
              >
                <table
                  class="table table-striped"
                  aria-describedby="campaigndata"
                >
                  <thead class="table-head">
                    <tr class="table-row">
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Message ID</th>
                      <th scope="col">Sent Date</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr
                      class="table-row"
                      *ngFor="
                        let item of campaignAnalyticsData.data.analytics_data
                          | datafilter : searchData
                      "
                    >
                      <td class="cmp_select">
                        <div class="cmp_name_id pointer">
                          <div class="fw-bold">{{ item.msisdn }}</div>
                        </div>
                      </td>
                      <td>{{ item.message_id }}</td>
                      <td>
                        {{ !item.sent_at ? 'N/A' : item.sent_at | date : "dd/MM/yy, h:mm a"}} 
                        <em class="bi bi-info-circle small"
                          *ngIf="item.sent_at"
                          matTooltip="The time is in IST (India Standard Time, GMT+5:30)."
                        ></em>
                      </td>
                      <td>
                        <div
                          class="status_indicator"
                          [ngClass]="item.status.toLowerCase()"
                        >
                          <em class="status_icon"></em>
                          <span>{{ item.status | titlecase }}</span>
                        </div>
                      </td>
                      <td class="action_options">
                        <!-- Error Info -->
                        <button class="btn btn-option" 
                          *ngIf="item.status.toLowerCase() === 'failed'"
                        >
                          <em class="bi bi-info-circle"
                            [matTooltip]="item.reason"
                          ></em>
                        </button>
                        <!-- END Error Info -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- END Campaigns Listing -->
  
              <ng-template #nodata>
                <!-- Content -->
                <div class="card p-3 mt-2">
                  <!-- template Content -->
                  <div class="empty_template text-center my-4">
                    <img
                      class="w-30"
                      src="assets/img/no_data.svg"
                      alt="No Data"
                    />
                    <p class="fs-5 my-2 text-dark">There is no data available</p>
                  </div>
                  <!-- END template Content -->
                </div>
                <!-- END Content -->
              </ng-template>
  
              <!-- Campaign Analytics Pagination -->
              <div
                class="pagination-wrapper"
                *ngIf="campaignAnalyticsData?.data.count > pageSize"
              >
                <ngb-pagination
                  class="ngb-pagination"
                  [collectionSize]="totalanalyticsdata"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  aria-label="pagination"
                  (pageChange)="onPageEvent($event)"
                  [rotate]="true"
                  [maxSize]="3"
                  [ellipses]="true"
                  [boundaryLinks]="false"
                >
                  <ng-template ngbPaginationPrevious>
                    <i class="bi-chevron-left"></i>
                    Previous</ng-template
                  >
                  <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                  <ng-template ngbPaginationNext>
                    Next
                    <i class="bi-chevron-right"></i
                  ></ng-template>
                </ngb-pagination>
              </div>
              <!-- END Campaign Analytics Pagination -->
            </div>
          </ng-container>

          <!-- Show Data Waiting Skelton Template -->
          <ng-template #datawaiting>
            <ngx-skeleton-loader
              class="skeleton_loader"
              *ngFor="let item of skeletonData"
              [theme]="item"
            ></ngx-skeleton-loader>
          </ng-template>
          <!-- End Show Data Waiting Skelton Template -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END Show Individual Campaign -->

