<app-header></app-header>

<!-- Main Content Container -->
<main role="main" class="main h-100 m-0" id="product">
  <div class="container w-80">
    <div class="welcome_user mt-4">
      <h1 class="welcome_title">
        Welcome {{ userDetails.profile.first_name }}
        {{ userDetails.profile.last_name }}!
      </h1>
      <p class="welcome_sub_title">Let’s setup your workspace</p>
      <mat-divider class="my-2"></mat-divider>
    </div>
    <ng-containter [ngTemplateOutlet]="displayTemplate"></ng-containter>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Channels Selection -->
<ng-template #channels>
  <div class="channel_sec py-4">
    <h4>Channels</h4>
    <p>
      Choose your preferred one as default for a better next login experience.
    </p>
    <div class="row row-cols-lg-3 row-cols-md-2 my-2">
      <div class="col-4 my-2" *ngFor="let item of channelList">
        <!-- Card -->
        <div class="card card-hover-shadow p-4">
          <!-- Body -->
          <div class="card-body p-0">
            <div class="details">
              <!-- Channel Icon -->
              <div class="d-flex justify-content-between mb-2">
                <div class="avatar avatar-circle avatar-md">
                  <img
                    class="avatar-img"
                    src="{{ item.ch_icon }}"
                    alt="{{ item.ch_name }} Icon"
                  />
                </div>
              </div>
              <!-- END Channel Icon -->

              <div class="mb-2">
                <h4 class="mb-2">
                  {{ item.ch_name }}
                  <small class="badge badge-warning" *ngIf="!item.isenabled"
                    >Coming Soon</small
                  >
                </h4>
                <p>{{ item.ch_description }}</p>
              </div>
            </div>
          </div>
          <!-- End Body -->
          <div class="dropdown-divider"></div>
          <!-- Footer -->
          <div class="card-footer p-0 pt-2">
            <div class="d-flex align-items-center justify-content-between">
              <!-- Set as default button -->
              <ng-container *ngIf="item.isdefault; else setDefault">
                <button class="btn btn-primary">
                  <em class="bi-check-circle-fill me-2"></em> Default
                </button>
              </ng-container>

              <ng-template #setDefault>
                <button
                  class="btn btn-default"
                  [disabled]="!item.isenabled"
                  (click)="setDefaultChannel(item)"
                >
                  Set as Default
                </button>
              </ng-template>
              <!-- END Set as default button -->

              <!-- Select channel button -->
              <button
                class="btn btn-default btn-icon"
                (click)="selectChannel(item.ch_name)"
                [disabled]="!item.isenabled"
              >
                <em class="bi-arrow-right"></em>
              </button>
              <!-- END Select channel button -->
            </div>
          </div>
          <!-- End Footer -->
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
</ng-template>
<!-- END Channels Selection -->

<!-- BSPs Selection -->
<ng-template #bsp>
  <div class="bsp_sec py-4">
    <!-- Section Title -->
    <div class="d-flex align-items-center gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoChannel()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <div class="sec_title ml-2">
        <h3 class="main_title">Select BSP</h3>
        <p>
          Consider selecting a Business Service Provider (BSP) as your primary
          option to elevate your experience and enhance service quality.
        </p>
      </div>
    </div>
    <!-- END Section Title -->

    <div class="row row-cols-lg-3 row-cols-md-2 my-2">
      <div class="col-4 my-2">
        <!-- Card -->
        <div class="card card-hover-shadow p-4">
          <!-- Body -->
          <div class="card-body p-0">
            <div class="details">
              <!-- BSP Logo -->
              <div class="d-flex justify-content-between mb-2">
                <h4>Routemobile</h4>
              </div>
              <!-- END BSP Logo -->

              <div class="mb-2">
                <p>
                  Business Numbers: <strong>{{ bspList.length }}</strong>
                </p>
              </div>
            </div>
          </div>
          <!-- End Body -->
          <div class="dropdown-divider"></div>
          <!-- Footer -->
          <div class="card-footer p-0 pt-2">
            <div class="d-flex align-items-center justify-content-between">
              <!-- Set as default button -->
              <ng-container *ngIf="isdefaultBSP(); else setDefault">
                <button class="btn btn-primary">
                  <em class="bi-check-circle-fill me-2"></em> Default
                </button>
              </ng-container>

              <ng-template #setDefault>
                <button
                  class="btn btn-default"
                  [disabled]="true"
                  (click)="setDefaultBSP('RML')"
                >
                  Set as Default
                </button>
              </ng-template>
              <!-- END Set as default button -->

              <!-- Select bsp button -->
              <button
                class="btn btn-default btn-icon"
                (click)="selectBSP('RML')"
                *ngIf="bspList.length > 0"
              >
                <em class="bi-arrow-right"></em>
              </button>
              <!-- END Select bsp button -->
            </div>
          </div>
          <!-- End Footer -->
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
</ng-template>
<!-- END BSPs Selection -->

<!-- Business Accounts Selection -->
<ng-template #bacts>
  <div class="business_act_sec py-4">
    <!-- Section Title -->
    <div class="d-flex align-items-center gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="gotoBsp()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <div class="sec_title ml-2">
        <h3 class="main_title">Business Account Number</h3>
        <p>
          Make option your preferred choice on WhatsApp for an enhanced
          experience
        </p>
      </div>
    </div>
    <!-- END Section Title -->

    <!-- Business Accounts based on BSP Selection -->
    <div class="row row-cols-lg-3 row-cols-md-2 my-2">
      <div class="col-4 my-2" *ngFor="let item of activeaccounts">
        <!-- Card -->
        <div class="card card-hover-shadow ba_item">
          <!-- Body -->
          <div class="card-body p-0">
            <div class="view-bact">
              <!-- Business Account Default Avatar -->
              <div class="d-flex justify-content-between mb-2">
                <div class="avatar avatar-circle avatar-lg">
                  <img
                    class="avatar-img"
                    src="{{ item.channel_credentials.profile_pic }}"
                    alt="{{ item.channel_credentials.business_name }} Icon"
                  />
                </div>
              </div>
              <!-- END Business Account Default Avatar -->

              <div class="mb-2 details">
                <h4 class="d-flex align-items-center gap-1">
                  {{ item.channel_credentials.waba_number }}
                </h4>
                <p>{{ item.channel_credentials.business_name }}</p>
                <small class="text-muted"
                  >Updated on
                  {{
                    item.channel_credentials.updated_at | date : "longDate"
                  }}</small
                >
              </div>
            </div>
          </div>
          <!-- End Body -->
          <div class="dropdown-divider"></div>

          <!-- Footer -->
          <div class="card-footer p-0 pt-2">
            <div
              class="ch_info d-flex align-items-center justify-content-between"
            >
              <!-- Set as default button -->
              <ng-container
                *ngIf="
                  isdefaultNo(item.channel_credentials.waba_number);
                  else setDefault
                "
              >
                <button class="btn btn-primary">
                  <em class="bi-check-circle-fill me-2"></em> Default
                </button>
              </ng-container>

              <ng-template #setDefault>
                <button
                  class="btn btn-default"
                  (click)="setDefaultbact(item)"
                  [matTooltip]="
                    !checkInactiveumber(item.channel_credentials.waba_number)
                      ? 'Number is inactive please contact admin'
                      : null
                  "
                  [disabled]="
                    !checkInactiveumber(item.channel_credentials.waba_number)
                  "
                >
                  Set as Default
                </button>
              </ng-template>
              <!-- END Set as default button -->

              <!-- Select bsp button -->
              <button
                class="btn btn-default btn-icon"
                (click)="showDB(item)"
                [matTooltip]="
                  !checkInactiveumber(item.channel_credentials.waba_number)
                    ? 'Number is inactive please contact admin'
                    : null
                "
                [disabled]="
                  !checkInactiveumber(item.channel_credentials.waba_number)
                "
              >
                <em class="bi-arrow-right"></em>
              </button>
              <!-- END Select bsp button -->
            </div>
          </div>
          <!-- End Footer -->
        </div>
        <!-- End Card -->
      </div>
    </div>
    <!-- End Business Accounts based on BSP Selection -->
  </div>
</ng-template>
<!-- END Business Accounts Selection -->
