<!-- Main Content Container -->
<main role="main" class="main" id="campaign_manager">
  <div class="content container-fluid">
    <div class="card card-sticky p-3">
      <div class="top_header_options">
        <h3 class="main_title">Media Library</h3>
        <button
          type="button"
          class="btn btn-primary"
          (click)="openModal(mediaUpload, 'fileupload')"
          *ngIf="permissions.drive_management.can_create"
        >
          <span class="button_text">
            <em class="bi-upload me-2"></em>Upload Media</span
          >
        </button>
      </div>
    </div>

    <ng-container
      [ngTemplateOutlet]="mainTemplateData?.template"
      [ngTemplateOutletContext]="{ data: mainTemplateData?.data }"
    ></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->

<!-- Empty Template -->
<ng-template #noMedia let-data="data">
  <div class="card mt-2">
    <!-- template Content -->
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="Setup Campaign" />
      <h4 class="mb-3">No Media Files Are Available Here</h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient btn-primary"
        *ngIf="permissions.drive_management.can_create"
        (click)="uploadMedia()"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Upload Media
      </button>
    </div>
    <!-- END template Content -->
  </div>
</ng-template>
<!-- END Empty Template -->

<!-- Media Listing -->
<ng-template #mediaListing let-data="data">
  <div class="card mt-2">
    <div class="media_library">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="active"
        class="nav-tabs"
        (navChange)="onNavChange($event)"
      >
        <!-- All Files -->
        <li [ngbNavItem]="1">
          <button ngbNavLink>All Files</button>
          <ng-template ngbNavContent>
            <div class="media_files">
              <!-- Header Options -->
              <div class="row justify-content-between my-3">
                <div class="col-4">
                  <!-- Search by Media File Name -->
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-search"></em>
                    </div>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search By Name"
                      [(ngModel)]="searchData"
                      [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
                    />
                  </div>
                  <!-- END Search by Media File Name -->
                </div>

                <div
                  class="col-8 pr-2 d-flex align-items-center justify-content-end"
                >
                  <ul class="list-inline list-inline-flex mb-0">
                    <!-- Selected Files -->
                    <li
                      class="list-inline-item"
                      *ngIf="fileselection.hasValue()"
                    >
                      <div
                        class="d-flex align-items-center gap-2 selected_data"
                      >
                        <span
                          >Selected : {{ fileselection.selected.length }}</span
                        >
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          (click)="
                            openModal(
                              deleteFileModal,
                              'deleteFileMultiple',
                              fileselection.selected
                            )
                          "
                          *ngIf="permissions.drive_management.can_delete"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-success"
                          (click)="clearSelection()"
                        >
                          Clear
                        </button>
                      </div>
                    </li>
                    <!-- END Selected Files -->

                    <!-- Calender Date Range Selector -->
                    <li class="list-inline-item">
                      <app-datepicker [last]="'7'"></app-datepicker>
                    </li>
                    <!-- END Calender Date Range Selector -->
                  </ul>
                </div>
              </div>
              <!-- END Header Options -->

              <mat-divider class="mt-2 mb-4"></mat-divider>

              <!-- All Files Uploads Section -->
              <ng-container *ngIf="data.length > 0; else nofilteredMedia">
                <div *ngIf="!isLoader">
                  <div
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 my-2"
                  >
                    <div
                      class="col mb-3 mb-lg-5"
                      *ngFor="let item of data | datafilter : searchData"
                    >
                      <!-- Card -->
                      <div
                        class="card card-sm card-hover-shadow card-header-borderless h-100 text-center p-2"
                        [ngClass]="
                          fileselection.isSelected(item) ? 'media_seleted' : ''
                        "
                      >
                        <div class="card-header card-header-content-between">
                          <mat-checkbox
                            class="list-checkboxes m-0"
                            (click)="$event.stopPropagation()"
                            (keydown)="$event.stopPropagation()"
                            (change)="$event ? fileselection.toggle(item) : null"
                            [checked]="fileselection.isSelected(item)"
                            [aria-label]="filecheckboxLabel(item)"
                            *ngIf="permissions.drive_management.can_delete"
                          ></mat-checkbox>

                          <button
                            class="btn btn-option"
                            matTooltip="Settings"
                            matTooltipPosition="below"
                            [matMenuTriggerFor]="menu"
                          >
                            <em class="bi-three-dots-vertical"></em>
                          </button>

                          <!-- Media Options Dropdown -->
                          <mat-menu #menu="matMenu">
                            <div class="navbar-dropdown-account px-2">
                              <span class="dropdown-header">Settings</span>
                              <a
                                class="dropdown-item"
                                (click)="downloadFile(item)"
                                *ngIf="permissions.drive_management.can_view"
                              >
                                <i class="bi-download dropdown-item-icon"></i>
                                Download
                              </a>

                              <div class="dropdown-divider"></div>

                              <a
                                class="dropdown-item text-danger"
                                (click)="
                                  openModal(
                                    deleteFileModal,
                                    'deleteFileSingle',
                                    item
                                  )
                                "
                                *ngIf="permissions.drive_management.can_delete"
                              >
                                <i
                                  class="bi-trash dropdown-item-icon text-danger"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </mat-menu>
                          <!-- END Media Options Dropdown -->
                        </div>

                        <div class="card-body p-0">
                          <div
                            [ngClass]="
                              item.file_type === 'image' ? 'image_wrapper' : item.mime_type === 'application/pdf'? '':'media_avatar'"
                          >
                            <!-- PDF -->
                            <!-- <img
                              class="avatar-img"
                              src="assets/img/customer/pdf.svg"
                              [alt]="item.file_name"
                              *ngIf="item.mime_type === 'application/pdf'"
                            /> -->
                            <!-- END PDF -->

                            <pdf-viewer
                              *ngIf="item.mime_type === 'application/pdf'"
                              [src]="item.url"
                              [render-text]="true"
                              [render-text-mode]="1"
                              [original-size]="true"
                              [zoom]="0.5"
                              style="width: auto; height: 100px"
                            ></pdf-viewer>

                            <!-- Google sheets -->
                            <img
                              class="avatar-img"
                              src="assets/img/google-sheets.svg"
                              [alt]="item.file_name"
                              *ngIf="item.mime_type === 'text/csv'"
                            />
                            <!-- END Google sheets -->

                            <!-- Video -->
                            <img
                              class="avatar-img"
                              src="assets/img/video_placeholder.svg"
                              [alt]="item.file_name"
                              *ngIf="item.mime_type === 'video/mp4'"
                            />
                            <!-- END Video -->

                            <!-- Image -->
                            <img
                              class="media_avatar_img"
                              [src]="
                                item.url
                                  ? item.url
                                  : 'assets/img/img_placeholder.svg'
                              "
                              [alt]="item.file_name"
                              *ngIf="item.file_type === 'image'"
                            />
                            <!-- END Image -->
                          </div>
                          <div class="media_title m-3">
                            <h5 class="card-title text-truncate">{{ item.file_name }}</h5>
                          </div>
                        </div>

                        <div class="card-footer">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span class="small d-inline-flex flex-column">
                              <strong>Uploaded On:</strong>
                              {{ item.updated_at | date }}
                            </span>
                            <span class="small d-inline-flex flex-column">
                              <strong>File Size:</strong>
                              {{ item.size ? item.size : "N/A" }}
                            </span>
                          </div>
                        </div>
                        
                        <span
                          class="stretched-link btn-link"
                          (click)="openModal(mediaPreview, 'previewMedia', item)"
                          (keydown)="openModal(mediaPreview, 'previewMedia', item)"
                        ></span>
                      </div>
                      <!-- End Card -->
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- Pagination -->
                  <div class="pagination-wrapper">
                    <ngb-pagination
                      *ngIf="searchData === ''"
                      class="ngb-pagination"
                      [collectionSize]="totalmediafiles"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      aria-label="pagination"
                      (pageChange)="onPageEvent($event)"
                      [rotate]="true"
                      [maxSize]="3"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                    >
                      <ng-template ngbPaginationPrevious>
                        <i class="bi-chevron-left"></i>
                        Previous</ng-template
                      >
                      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                      <ng-template ngbPaginationNext>
                        Next
                        <i class="bi-chevron-right"></i
                      ></ng-template>
                    </ngb-pagination>
                  </div>
                  <!-- END Pagination -->
                </div>

                <div *ngIf="isLoader">
                  <ngx-skeleton-loader
                    class="skeleton_loader"
                    *ngFor="let item of skeletonData"
                    [theme]="item"
                  ></ngx-skeleton-loader>
                </div>
              </ng-container>

              <ng-template #nofilteredMedia>
                <!-- No Media Template -->
                <div class="empty_template text-center my-4">
                  <img
                    class="w-30"
                    src="assets/img/no_data.svg"
                    alt="Setup Campaign"
                  />
                  <h4 class="mb-3">No Media Files Are Available Here</h4>
                </div>
                <!-- END No Media Template -->
              </ng-template>
              <!-- END All Images Uploads Section -->
            </div>
          </ng-template>
        </li>
        <!-- All Files -->

        <!-- Documents -->
        <li [ngbNavItem]="2">
          <button ngbNavLink>Documents</button>
          <ng-template ngbNavContent>
            <div class="media_files">
              <!-- Header Options -->
              <div class="row justify-content-between my-3">
                <div class="col-4">
                  <!-- Search by Media File Name -->
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-search"></em>
                    </div>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search By Name"
                      [(ngModel)]="searchData"
                      [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
                    />
                  </div>
                  <!-- END Search by Media File Name -->
                </div>

                <div
                  class="col-8 pr-2 d-flex align-items-center justify-content-end"
                >
                  <ul class="list-inline list-inline-flex mb-0">
                    <!-- Selected Files -->
                    <li
                      class="list-inline-item"
                      *ngIf="fileselection.hasValue()"
                    >
                      <div
                        class="d-flex align-items-center gap-2 selected_data"
                      >
                        <span
                          >Selected : {{ fileselection.selected.length }}</span
                        >
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          (click)="
                            openModal(
                              deleteFileModal,
                              'deleteFileMultiple',
                              fileselection.selected
                            )
                          "
                          *ngIf="permissions.drive_management.can_delete"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-success"
                          (click)="clearSelection()"
                        >
                          Clear
                        </button>
                      </div>
                    </li>
                    <!-- END Selected Files -->

                    <!-- Calender Date Range Selector -->
                    <li class="list-inline-item">
                      <app-datepicker [last]="'7'"></app-datepicker>
                    </li>
                    <!-- END Calender Date Range Selector -->
                  </ul>
                </div>
              </div>
              <!-- END Header Options -->

              <!-- All Files Uploads Section -->
              <mat-divider class="mt-2 mb-4"></mat-divider>

              <ng-container *ngIf="data.length > 0; else noimgs">
                <div *ngIf="!isLoader">
                  <div
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 my-2"
                  >
                    <div
                      class="col mb-3 mb-lg-5"
                      *ngFor="let item of data | datafilter : searchData"
                    >
                      <!-- Card -->
                      <div
                        class="card card-sm card-hover-shadow card-header-borderless h-100 text-center p-1"
                        [ngClass]="mediaSelected ? 'media_seleted' : ''"
                      >
                        <div class="card-header card-header-content-between">
                          <mat-checkbox
                            class="list-checkboxes m-0"
                            (click)="$event.stopPropagation()"
                            (keydown)="$event.stopPropagation()"
                            (change)="$event ? fileselection.toggle(item) : null"
                            [checked]="fileselection.isSelected(item)"
                            [aria-label]="filecheckboxLabel(item)"
                            *ngIf="permissions.drive_management.can_delete"
                          ></mat-checkbox>
  
                          <button
                            class="btn btn-option"
                            matTooltip="Settings"
                            matTooltipPosition="below"
                            [matMenuTriggerFor]="menu"
                          >
                            <em class="bi-three-dots-vertical"></em>
                          </button>
  
                          <!-- Notifications Dropdown -->
                          <mat-menu #menu="matMenu">
                            <div class="navbar-dropdown-account px-2">
                              <span class="dropdown-header">Settings</span>
  
                              <a
                                class="dropdown-item"
                                (click)="downloadFile(item)"
                                *ngIf="permissions.drive_management.can_view"
                              >
                                <i class="bi-download dropdown-item-icon"></i>
                                Download
                              </a>
  
                              <div class="dropdown-divider"></div>
  
                              <a
                                class="dropdown-item text-danger"
                                (click)="
                                  openModal(
                                    deleteFileModal,
                                    'deleteFileSingle',
                                    item
                                  )
                                "
                                *ngIf="permissions.drive_management.can_delete"
                              >
                                <i
                                  class="bi-trash dropdown-item-icon text-danger"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </mat-menu>
                        </div>
  
                        <div class="card-body">
                          <div
                            [ngClass]="item.mime_type === 'application/pdf' ? '':'media_avatar'"
                          >
                            <pdf-viewer
                              *ngIf="item.mime_type === 'application/pdf'"
                              [src]="item.url"
                              [render-text]="true"
                              [render-text-mode]="1"
                              [original-size]="true"
                              [zoom]="0.5"
                              style="width: auto; height: 120px"
                            ></pdf-viewer>
                            <img
                              class="avatar"
                              src="assets/img/google-sheets.svg"
                              [alt]="item.file_name"
                              *ngIf="item.mime_type === 'text/csv'"
                            />
                          </div>
                          <div class="media_title mt-2">
                            <h5 class="card-title text-truncate">{{ item.file_name }}</h5>
                          </div>
                        </div>
  
                        <div class="card-footer">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span class="small d-inline-flex flex-column">
                              <strong>Uploaded On:</strong>
                              {{ item.updated_at | date }}
                            </span>
                            <span class="small d-inline-flex flex-column">
                              <strong>File Size:</strong>
                              {{ item.size ? item.size : "N/A" }}
                            </span>
                          </div>
                        </div>
  
                        <span
                          class="stretched-link btn-link"
                          (click)="openModal(mediaPreview, 'previewMedia', item)"
                          (keydown)="openModal(mediaPreview, 'previewMedia', item)"
                        ></span>
                      </div>
                      <!-- End Card -->
                    </div>
                  </div>
                  <!-- Pagination -->
                  <div class="pagination-wrapper">
                    <ngb-pagination
                      *ngIf="searchData === ''"
                      class="ngb-pagination"
                      [collectionSize]="totalmediafiles"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      aria-label="pagination"
                      (pageChange)="onPageEvent($event)"
                      [rotate]="true"
                      [maxSize]="3"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                    >
                      <ng-template ngbPaginationPrevious>
                        <i class="bi-chevron-left"></i>
                        Previous</ng-template
                      >
                      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                      <ng-template ngbPaginationNext>
                        Next
                        <i class="bi-chevron-right"></i
                      ></ng-template>
                    </ngb-pagination>
                  </div>
                  <!-- END Pagination -->
                </div>

                <div *ngIf="isLoader">
                  <ngx-skeleton-loader
                    class="skeleton_loader"
                    *ngFor="let item of skeletonData"
                    [theme]="item"
                  ></ngx-skeleton-loader>
                </div>
              </ng-container>

              <ng-template #noimgs>
                <!-- No Media Template -->
                <div class="empty_template text-center my-4">
                  <img
                    class="w-30"
                    src="assets/img/no_data.svg"
                    alt="Setup Campaign"
                  />
                  <h4 class="mb-3">No Media Files Are Available Here</h4>
                </div>
                <!-- END No Media Template -->
              </ng-template>
              <!-- END All Images Uploads Section -->
            </div>
          </ng-template>
        </li>
        <!-- Documents -->

        <!-- Images -->
        <li [ngbNavItem]="3">
          <button ngbNavLink>Images</button>
          <ng-template ngbNavContent>
            <div class="media_files">
              <!-- Header Options -->
              <div class="row justify-content-between my-3">
                <div class="col-4">
                  <!-- Search by Media File Name -->
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-search"></em>
                    </div>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search By Name"
                      [(ngModel)]="searchData"
                      [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
                    />
                  </div>
                  <!-- END Search by Media File Name -->
                </div>

                <div
                  class="col-8 pr-2 d-flex align-items-center justify-content-end"
                >
                  <ul class="list-inline list-inline-flex mb-0">
                    <!-- Selected Files -->
                    <li
                      class="list-inline-item"
                      *ngIf="fileselection.hasValue()"
                    >
                      <div
                        class="d-flex align-items-center gap-2 selected_data"
                      >
                        <span
                          >Selected : {{ fileselection.selected.length }}</span
                        >
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          (click)="
                            openModal(
                              deleteFileModal,
                              'deleteFileMultiple',
                              fileselection.selected
                            )
                          "
                          *ngIf="permissions.drive_management.can_delete"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-success"
                          (click)="clearSelection()"
                        >
                          Clear
                        </button>
                      </div>
                    </li>
                    <!-- END Selected Files -->

                    <!-- Calender Date Range Selector -->
                    <li class="list-inline-item">
                      <app-datepicker [last]="'7'"></app-datepicker>
                    </li>
                    <!-- END Calender Date Range Selector -->
                  </ul>
                </div>
              </div>
              <!-- END Header Options -->

              <mat-divider class="mt-2 mb-4"></mat-divider>

              <!-- All Images Uploads Section -->
              <ng-container *ngIf="data.length > 0; else noimgs">
                <div *ngIf="!isLoader">
                  <div
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 my-2"
                  >
                    <div
                      class="col mb-3 mb-lg-5"
                      *ngFor="let item of data | datafilter : searchData"
                    >
                      <!-- Card -->
                      <div
                        class="card card-sm card-hover-shadow card-header-borderless h-100 text-center p-0"
                        [ngClass]="mediaSelected ? 'media_seleted' : ''"
                      >
                        <div class="card-header card-header-content-between">
                          <mat-checkbox
                            class="list-checkboxes m-0"
                            (click)="$event.stopPropagation()"
                            (keydown)="$event.stopPropagation()"
                            (change)="$event ? fileselection.toggle(item) : null"
                            [checked]="fileselection.isSelected(item)"
                            [aria-label]="filecheckboxLabel(item)"
                          ></mat-checkbox>
  
                          <button
                            class="btn btn-option"
                            matTooltip="Settings"
                            matTooltipPosition="below"
                            [matMenuTriggerFor]="menu"
                          >
                            <em class="bi-three-dots-vertical"></em>
                          </button>
  
                          <!-- Notifications Dropdown -->
                          <mat-menu #menu="matMenu">
                            <div class="navbar-dropdown-account px-2">
                              <span class="dropdown-header">Settings</span>
                              <a
                                class="dropdown-item"
                                (click)="downloadFile(item)"
                                *ngIf="permissions.drive_management.can_view"
                              >
                                <i class="bi-download dropdown-item-icon"></i>
                                Download
                              </a>
  
                              <div class="dropdown-divider"></div>
  
                              <a
                                class="dropdown-item text-danger"
                                (click)="
                                  openModal(
                                    deleteFileModal,
                                    'deleteFileSingle',
                                    item
                                  )
                                "
                                *ngIf="permissions.drive_management.can_delete"
                              >
                                <i
                                  class="bi-trash dropdown-item-icon text-danger"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </mat-menu>
                        </div>
  
                        <div class="card-body p-0">
                          <div class="image_wrapper">
                            <img
                              class="media_avatar_img"
                              *ngIf="item.file_type === 'image'"
                              [src]="
                                item.url
                                  ? item.url
                                  : 'assets/img/img_placeholder.svg'
                              "
                              [alt]="item.file_name"
                            />
                          </div>
                          <div class="media_title m-2">
                            <h5 class="card-title text-truncate">{{ item.file_name }}</h5>
                          </div>
                        </div>
  
                        <div class="card-footer">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span class="small d-inline-flex flex-column">
                              <strong>Uploaded On:</strong>
                              {{ item.updated_at | date }}
                            </span>
                            <span class="small d-inline-flex flex-column">
                              <strong>File Size:</strong>
                              {{ item.size ? item.size : "N/A" }}
                            </span>
                          </div>
                        </div>
  
                        <span
                          class="stretched-link btn-link"
                          (click)="openModal(mediaPreview, 'previewMedia', item)"
                          (keydown)="openModal(mediaPreview, 'previewMedia', item)"
                        ></span>
                      </div>
                      <!-- End Card -->
                    </div>
                  </div>
                  <!-- Pagination -->
                  <div class="pagination-wrapper">
                    <ngb-pagination
                      *ngIf="searchData === ''"
                      class="ngb-pagination"
                      [collectionSize]="totalmediafiles"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      aria-label="pagination"
                      (pageChange)="onPageEvent($event)"
                      [rotate]="true"
                      [maxSize]="3"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                    >
                      <ng-template ngbPaginationPrevious>
                        <i class="bi-chevron-left"></i>
                        Previous</ng-template
                      >
                      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                      <ng-template ngbPaginationNext>
                        Next
                        <i class="bi-chevron-right"></i
                      ></ng-template>
                    </ngb-pagination>
                  </div>
                  <!-- END Pagination -->
                </div>

                <div *ngIf="isLoader">
                  <ngx-skeleton-loader
                    class="skeleton_loader"
                    *ngFor="let item of skeletonData"
                    [theme]="item"
                  ></ngx-skeleton-loader>
                </div>
              </ng-container>

              <ng-template #noimgs>
                <!-- No Media Template -->
                <div class="empty_template text-center my-4">
                  <img
                    class="w-30"
                    src="assets/img/no_data.svg"
                    alt="Setup Campaign"
                  />
                  <h4 class="mb-3">No Media Files Are Available Here</h4>
                </div>
                <!-- END No Media Template -->
              </ng-template>
              <!-- END All Images Uploads Section -->
            </div>
          </ng-template>
        </li>
        <!-- Images -->

        <!-- Videos -->
        <li [ngbNavItem]="4">
          <button ngbNavLink>Videos</button>
          <ng-template ngbNavContent>
            <div class="media_files">
              <!-- Header Options -->
              <div class="row justify-content-between my-3">
                <div class="col-4">
                  <!-- Search by Media File Name -->
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-search"></em>
                    </div>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search By Name"
                      [(ngModel)]="searchData"
                      [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
                    />
                  </div>
                  <!-- END Search by Media File Name -->
                </div>

                <div
                  class="col-8 pr-2 d-flex align-items-center justify-content-end"
                >
                  <ul class="list-inline list-inline-flex mb-0">
                    <!-- Selected Files -->
                    <li
                      class="list-inline-item"
                      *ngIf="fileselection.hasValue()"
                    >
                      <div
                        class="d-flex align-items-center gap-2 selected_data"
                      >
                        <span
                          >Selected : {{ fileselection.selected.length }}</span
                        >
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          (click)="
                            openModal(
                              deleteFileModal,
                              'deleteFileMultiple',
                              fileselection.selected
                            )
                          "
                          *ngIf="permissions.drive_management.can_delete"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-success"
                          (click)="clearSelection()"
                        >
                          Clear
                        </button>
                      </div>
                    </li>
                    <!-- END Selected Files -->

                    <!-- Calender Date Range Selector -->
                    <li class="list-inline-item">
                      <app-datepicker [last]="'7'"></app-datepicker>
                    </li>
                    <!-- END Calender Date Range Selector -->
                  </ul>
                </div>
              </div>
              <!-- END Header Options -->

              <mat-divider class="mt-2 mb-4"></mat-divider>

              <!-- All Video Files Section -->
              <ng-container *ngIf="data.length > 0; else noimgs">
                <div *ngIf="isLoader">
                  <div
                    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 my-2"
                  >
                    <div
                      class="col mb-3 mb-lg-5"
                      *ngFor="let item of data | datafilter : searchData"
                    >
                      <!-- Card -->
                      <div
                        class="card card-sm card-hover-shadow card-header-borderless h-100 text-center p-1"
                        [ngClass]="mediaSelected ? 'media_seleted' : ''"
                      >
                        <div class="card-header card-header-content-between">
                          <mat-checkbox
                            class="list-checkboxes m-0"
                            (click)="$event.stopPropagation()"
                            (keydown)="$event.stopPropagation()"
                            (change)="$event ? fileselection.toggle(item) : null"
                            [checked]="fileselection.isSelected(item)"
                            [aria-label]="filecheckboxLabel(item)"
                            *ngIf="permissions.drive_management.can_delete"
                          ></mat-checkbox>
  
                          <button
                            class="btn btn-option"
                            matTooltip="Settings"
                            matTooltipPosition="below"
                            [matMenuTriggerFor]="menu"
                          >
                            <em class="bi-three-dots-vertical"></em>
                          </button>
  
                          <!-- Notifications Dropdown -->
                          <mat-menu #menu="matMenu">
                            <div class="navbar-dropdown-account px-2">
                              <span class="dropdown-header">Settings</span>
                              <a
                                class="dropdown-item"
                                (click)="downloadFile(item)"
                                *ngIf="permissions.drive_management.can_view"
                              >
                                <i class="bi-download dropdown-item-icon"></i>
                                Download
                              </a>
  
                              <div class="dropdown-divider"></div>
  
                              <a
                                class="dropdown-item text-danger"
                                (click)="
                                  openModal(
                                    deleteFileModal,
                                    'deleteFileSingle',
                                    item
                                  )
                                "
                                *ngIf="permissions.drive_management.can_delete"
                              >
                                <i
                                  class="bi-trash dropdown-item-icon text-danger"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </mat-menu>
                        </div>
  
                        <div class="card-body">
                          <div class="media_avatar">
                            <img
                              class="avatar"
                              src="assets/img/video_placeholder.svg"
                              [alt]="item.file_name"
                              *ngIf="item.mime_type === 'video/mp4'"
                            />
                          </div>
                          <div class="media_title mt-2">
                            <h5 class="card-title text-truncate">{{ item.file_name }}</h5>
                          </div>
                        </div>
  
                        <div class="card-footer">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span class="small d-inline-flex flex-column">
                              <strong>Uploaded On:</strong>
                              {{ item.updated_at | date }}
                            </span>
                            <span class="small d-inline-flex flex-column">
                              <strong>File Size:</strong>
                              {{ item.size ? item.size : "N/A" }}
                            </span>
                          </div>
                        </div>
  
                        <span
                          class="stretched-link btn-link"
                          (click)="openModal(mediaPreview, 'previewMedia', item)"
                          (keydown)="openModal(mediaPreview, 'previewMedia', item)"
                        ></span>
                      </div>
                      <!-- End Card -->
                    </div>
                  </div>
                  <!-- Pagination -->
                  <div class="pagination-wrapper">
                    <ngb-pagination
                      *ngIf="searchData === ''"
                      class="ngb-pagination"
                      [collectionSize]="totalmediafiles"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      aria-label="pagination"
                      (pageChange)="onPageEvent($event)"
                      [rotate]="true"
                      [maxSize]="3"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                    >
                      <ng-template ngbPaginationPrevious>
                        <i class="bi-chevron-left"></i>
                        Previous</ng-template
                      >
                      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
                      <ng-template ngbPaginationNext>
                        Next
                        <i class="bi-chevron-right"></i
                      ></ng-template>
                    </ngb-pagination>
                  </div>
                  <!-- END Pagination -->
                </div>

                <div *ngIf="isLoader">
                  <ngx-skeleton-loader
                    class="skeleton_loader"
                    *ngFor="let item of skeletonData"
                    [theme]="item"
                  ></ngx-skeleton-loader>
                </div>
              </ng-container>

              <ng-template #noimgs>
                <!-- No Media Template -->
                <div class="empty_template text-center my-4">
                  <img
                    class="w-30"
                    src="assets/img/no_data.svg"
                    alt="Setup Campaign"
                  />
                  <h4 class="mb-3">No Media Files Are Available Here</h4>
                </div>
                <!-- END No Media Template -->
              </ng-template>
              <!-- END All Video Files Section -->
            </div>
          </ng-template>
        </li>
        <!-- Videos -->
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</ng-template>
<!-- END Media Listing -->

<!-- Delete MediaFile Modal -->
<ng-template #deleteFileModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">
      Delete Selected {{ deleteMultipleItems ? "File/s" : "File" }}
    </h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4 *ngIf="deleteMultipleItems">
        Are you sure want to delete Selected File/s?
      </h4>
      <h4 *ngIf="deleteItem">
        Are you sure want to delete file
        <span class="text-primary">{{ deleteItem.file_name }}</span
        >?
      </h4>

      <p class="warning_text mt-2">
        <i class="bi-exclamation-triangle-fill text-warning mr-2"></i>
        Once deleted cannot be retrived
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="cancelDelete()">Cancel</button>
    <button class="btn btn-outline-danger" (click)="deleteMediaFiles()">
      Delete
    </button>
  </div>
</ng-template>
<!-- END Delete MediaFile Modal -->

<!-- Media Preview Modal -->
<ng-template #mediaPreview let-c="close" let-d="dismiss">
  <div class="media_library">
    <div class="modal-content">
      <div class="modal-header p-3">
        <h4 class="modal-title">Media Preview</h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross click')"
        ></button>
      </div>
      <div class="modal-body text-center">
        <div class="row">
          <div class="col-5">
            <div
              class="media_preview_placeholder"
              [ngClass]="
                previewItem.mime_type === 'application/pdf'
                  ? 'preview_min_height'
                  : ''
              "
            >
              <pdf-viewer
                *ngIf="previewItem.mime_type === 'application/pdf'"
                [src]="previewItem.url"
                [render-text]="true"
                [render-text-mode]="1"
                [original-size]="true"
                [zoom]="0.4"
                style="width: 350px; height: 250px"
              ></pdf-viewer>
              <!-- END PDF Viewer -->

              <!-- Google Sheets Viewer -->
              <div
                class="avatar avatar-xxl"
                *ngIf="previewItem.mime_type === 'text/csv'"
              >
                <img
                  class="avatar-img d-inline-flex"
                  src="assets/img/google-sheets.svg"
                  [alt]="previewItem.file_name"
                />
              </div>
              <!-- END Google Sheets Viewer -->

              <!-- Video Viewer -->
              <div class="video_wrapper" *ngIf="previewItem.mime_type === 'video/mp4'">
                <video
                  controls
                  autoplay
                  src="{{ previewItem.url }}"
                  poster="assets/img/video_placeholder.svg"
                  width="100%"
                  aria-describedby="video"
                >
                  Sorry, your browser doesn't support embedded videos, but don't
                  worry, you can
                  <a href="https://archive.org/details/BigBuckBunny_124"
                    >download it</a
                  >
                  and watch it with your favorite video player!
                </video>
              </div>
              <!-- END Video Viewer -->

              <!-- Image Viewer -->
              <img
                class="img-fluid"
                [src]="
                  previewItem.url
                    ? previewItem.url
                    : 'assets/img/img_placeholder.svg'
                "
                [alt]="previewItem.file_name"
                *ngIf="previewItem.file_type === 'image'"
              />
              <!-- END Image Viewer -->
            </div>

            <!-- Preview Download Button -->
            <div class="preview_download_btn my-4">
              <button
                class="btn btn-sm btn-primary"
                (click)="downloadFile(previewItem)"
                *ngIf="permissions.drive_management.can_view"
              >
                <i class="bi bi-download"></i>
                Download
              </button>
            </div>
            <!-- END Preview Download Button -->
          </div>
          <div class="col-7">
            <dl class="row row-cols-2 m-0 mt-4">
              <dt class="col-3 text-sm-left">Full Name</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.file_name }}</dd>

              <dt class="col-3 text-sm-left">File Size</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">
                {{ previewItem.size ? previewItem.size : "N/A" }}
              </dd>

              <dt class="col-3 text-sm-left">File Type</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.file_type }}</dd>

              <dt class="col-3 text-sm-left">Mime Type</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.mime_type }}</dd>

              <dt class="col-3 text-sm-left">Created On</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">
                {{ previewItem.created_at | date }}
              </dd>
              
              <dt class="col-3 text-sm-left">Uploaded On</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.updated_at }}</dd>

              <dt class="col-3 text-sm-left">Uploaded By</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.created_by }}</dd>

              <dt class="col-3 text-sm-left">Mobile Number</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">
                {{ previewItem.mobile_number }}
              </dd>

              <dt class="col-3 text-sm-left">Media Path</dt>
              <dd class="col-1 text-sm-left">:</dd>
              <dd class="col-8 text-sm-left">{{ previewItem.url }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-light" (click)="d('Cross click')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- END Media Preview Modal -->

<!-- Upload Media Modal -->
<ng-template #mediaUpload let-c="close" let-d="dismiss">
  <div class="media_library">
    <div class="modal-content">
      <div class="modal-header p-3">
        <h4 class="modal-title">Media Upload</h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross click'); uploadfiledata = null"
        ></button>
      </div>
      <div class="modal-body py-2">
        <div class="template_item">
          <div class="upload_img" *ngIf="!uploadfiledata && !isAnalysing">
            <div *ngIf="!isUrlInserted">
              <!-- Image Drag-n-Drop Area -->
              <fieldset
                class="media_upload_dropZone d-flex align-items-center justify-content-center gap-4"
              >
                <legend class="visually-hidden">File uploader</legend>
                <input
                  class="upload_image"
                  type="file"
                  accept="image/jpeg, image/png, image/webp, image/gif, video/mp4, video/3gpp, audio/ogg;codecs=opus, audio/mpeg, audio/amr, audio/aac, application/pdf"
                  (change)="onSelect($event); $any($event.target).value = ''"
                />
                <div class="d-flex align-items-center gap-3">
                  <div class="file_upload_icon">
                    <em class="bi bi-upload fs-1 text-muted"></em>
                  </div>
                  <div class="files_supported text-center">
                    <h4>
                      Drag &amp; Drop file or
                      <a class="text-primary">Browse</a>
                    </h4>
                    <p class="small">
                      Supported formats:<b>.png, .jpeg, .webp, .gif</b>, Maximum
                      file size: <b>5MB</b>
                    </p>
  
                    <p class="small">
                      Supported formats:<b>.pdf, .docx</b>, Maximum file size:
                      <b>100MB</b>
                    </p>
                    <p class="small">
                      Supported formats:<b
                        >.mpeg, .mp4, .3gpp, .amr, .aac, audio/ogg;codecs=opus</b
                      >, Maximum file size:
                      <b>16MB</b>
                    </p>
                  </div>
                </div>
              </fieldset>
              <!-- END Image Drag-n-Drop Area -->            

              <div class="divider">
                <mat-divider class="my-3"></mat-divider>
                <span class="text-muted">OR</span>
              </div>
            </div>

            <!-- Image URL -->
            <div class="form-group">
              <label class="input-label" for="imageUrl"> From URL</label>
              <input
                class="form-control"
                id="imageUrl"
                type="text"
                placeholder="Enter URL here"
                (paste)="checkInsertedUrl($event)"
                (input)="checkInsertedUrl($event)"
              />
            </div>
            <!-- ENd Image URL -->
          </div>

          <!-- Analyzing Loader -->
          <div class="csv_analyze mt-3" *ngIf="isAnalysing">
            <span class="analyze_loader w-100">Uploading</span>
          </div>
          <!-- END Analyzing Loader -->

          <!-- Uploaded Image -->
          <div
            class="upload_gallery my-2 flex-column align-items-start"
            *ngIf="uploadfiledata"
          >
            <div class="uploaded_image">
              <img
                [src]="uploadfiledata.url"
                [alt]="uploadfiledata.file_name"
                class="img-fluid"
                *ngIf="uploadfiledata.file_type === 'image'"
              />
              <img
                src="assets/img/customer/pdf.svg"
                [alt]="uploadfiledata.file_name"
                class="img-fluid"
                *ngIf="uploadfiledata.file_type === 'document'"
              />
              <img
                src="assets/img/video_placeholder.svg"
                [alt]="uploadfiledata.file_name"
                class="img-fluid"
                *ngIf="uploadfiledata.file_type === 'video'"
              />
              <em
                class="bi bi-x-circle-fill img_close"
                (click)="uploadfiledata = null; file = null"
                (keydown)="uploadfiledata = null; file = null"
              >
              </em>
            </div>
            <p>{{ uploadfiledata.file_name }}</p>
          </div>
          <!-- END Uploaded Image -->
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-primary"
          (click)="submitInsertedUrl()"
          *ngIf="isUrlInserted"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-light"
          (click)="d('Cross click'); uploadfiledata = null"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- End of Upload Media Modal -->
