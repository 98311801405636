import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-requests',
  templateUrl: './sa-requests.component.html',
  styleUrls: ['./sa-requests.component.scss']
})
export class SaRequestsComponent {

}
