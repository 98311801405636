import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as profileActions from './profiles.actions';
import { ProfileManagementService } from 'src/app/services/profilemanagement.service';
import { ProfilesData } from 'src/app/appdata/profiledata.model';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileservice: ProfileManagementService
  ) {}

  fetchProfilesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.fetchProfileslist),
      exhaustMap((action) =>
        this.profileservice.getProfilesList(action.payload).pipe(
          map((response: ProfilesData) => {
            return profileActions.fetchProfileslistSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(profileActions.fetchProfileslistFailure(error))
          )
        )
      )
    );
  });

  fetchIndividualProfileData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.fetchProfileData),
      exhaustMap((action) =>
        this.profileservice
          .getProfileById(action.accountid, action.profileid)
          .pipe(
            map((response: any) => {
              return profileActions.fetchProfileDataSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(profileActions.fetchProfileDataFailure(error))
            )
          )
      )
    );
  });

  createProfileData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.createProfile),
      exhaustMap((action) =>
        this.profileservice.createProfile(action.payload).pipe(
          map((response: any) => {
            return profileActions.createProfileSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(profileActions.createProfileFailure(error))
          )
        )
      )
    );
  });

  updateProfileData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdateProfileActions.updateProfile),
      exhaustMap((action) =>
        this.profileservice.updateProfile(action.payload, action.id).pipe(
          map((response: any) => {
            return profileActions.UpdateProfileActions.updateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(profileActions.UpdateProfileActions.updateFailure(error))
          )
        )
      )
    );
  });

  deleteProfileData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.DeleteProfileActions.deleteProfile),
      exhaustMap((action) =>
        this.profileservice
          .deleteProfile(action.accountid, action.profile_id)
          .pipe(
            map((response: any) => {
              return profileActions.DeleteProfileActions.deleteSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(profileActions.DeleteProfileActions.deleteFailure(error))
            )
          )
      )
    );
  });
}
