import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-role-management',
  templateUrl: './sa-role-management.component.html',
  styleUrls: ['./sa-role-management.component.scss']
})
export class SaRoleManagementComponent {

}
