<!-- Report Logs Header Options -->
<div class="row justify-content-between my-3">
  <div class="col-3">
    <!-- Search by name input -->
    <div class="input-group input-group-merge mb-0">
      <div class="input-group-prepend input-group-text">
        <em class="bi bi-search"></em>
      </div>
      <input
        type="search"
        class="form-control"
        placeholder="Search by report name"
        [(ngModel)]="searchData"
        (ngModelChange)="onSearchNameChange($event)"
        (keyup.enter)="searchName(searchData)"
        [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
      />
    </div>
    <!-- END Search by name input -->
  </div>

  <div class="col-9 pr-2 d-flex align-items-center justify-content-end">
    <ul class="list-inline list-inline-flex mb-0">
      <!-- Report List Status Refresh -->
      <li class="list-inline-item">
        <button
          class="btn btn-icon btn-outline-primary"
          matTooltip="Click to get latest reports status"
          (click)="getReportList()"
        >
          <em class="bi bi-arrow-repeat"></em>
        </button>
      </li>
      <!-- END Report List Status Refresh -->

      <!-- Category Filter -->
      <li class="list-inline-item">
        <ng-select
          [items]="allCategories"
          bindLabel="name"
          bindValue="value"
          [closeOnSelect]="true"
          [clearable]="true"
          [clearOnBackspace]="true"
          [(ngModel)]="selectedCategory"
          (ngModelChange)="onCategoryChange($event)"
          [searchable]="true"
          [tabIndex]="2"
          [multiple]="false"
          placeholder="Category"
        >
        </ng-select>
      </li>
      <!-- END Category Filter -->

      <!-- Calender Date Range Selector -->
      <li class="list-inline-item">
        <app-datepicker></app-datepicker>
      </li>
      <!-- END Calender Date Range Selector -->
    </ul>
  </div>
</div>
<!-- END Report Logs Header Options -->

<!-- Show Reports Listing -->
<ng-container *ngIf="!isLoading; else showloader">
  <div class="show_reports_listing">
    <!-- Reports Listing Table -->
    <div class="table-wrapper my-2">
      <div class="table-container mb-0">
        <mat-table [dataSource]="reportsList">
          <!-- Report Name Col -->
          <ng-container matColumnDef="report_name" sticky>
            <mat-header-cell *matHeaderCellDef> Report Name </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="d-flex justify-content-between"
            >
              <div
                class="comp_name fw-bold text-truncate w-95"
                matTooltip="{{ element.report_name }}"
                matTooltipPosition="above"
              >
                {{ element.report_name | titlecase }}
              </div>

              <!-- Download Report Button -->
              <button
                class="btn btn-option"
                matTooltip="Download report"
                *ngIf="element.location_uri"
                (click)="downloadReport(element)"
              >
                <em class="bi bi-download"></em>
              </button>
              <!-- END Download Report Button -->
            </mat-cell>
          </ng-container>
          <!-- END Report Name Col -->

          <!-- Creation Date Col -->
          <ng-container matColumnDef="creation_date">
            <mat-header-cell *matHeaderCellDef> Creation Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.created_at | date }}
            </mat-cell>
          </ng-container>
          <!-- END Creation Date Col -->

          <!-- Date Range Col -->
          <ng-container matColumnDef="date_range">
            <mat-header-cell *matHeaderCellDef> Date Range </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ "N/A" }}
            </mat-cell>
          </ng-container>
          <!-- END Date Range Col -->

          <!-- Report Type Col -->
          <ng-container matColumnDef="report_type">
            <mat-header-cell *matHeaderCellDef> Report Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.report_type | titlecase }}
            </mat-cell>
          </ng-container>
          <!-- END Report Type Col -->

          <!-- Report Category Col -->
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef>
              Report Category
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.category | titlecase }}
            </mat-cell>
          </ng-container>
          <!-- END Report Category Col -->

          <!-- Report Properties Col -->
          <ng-container matColumnDef="report_properties">
            <mat-header-cell *matHeaderCellDef>
              Report Properties
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <ng-container
                  *ngFor="
                    let t of getReportProperties(element.properties)
                      | slice : 0 : 2
                  "
                >
                  {{ t | titlecase }},
                </ng-container>

                <div
                  *ngIf="getReportProperties(element.properties).length > 2"
                  class="ms-2"
                >
                  <span
                    class="badge badge-pill badge-primary"
                    matTooltip="{{getReportProperties(element.properties).slice(2)}}"
                  >
                    <i class="bi-plus"></i>
                    {{ getReportProperties(element.properties).length - 2 }}
                  </span>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <!-- END Report Properties Col -->

          <!-- Campaign IDs Col -->
          <ng-container matColumnDef="campaign_ids">
            <mat-header-cell *matHeaderCellDef> Campaign IDs </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- {{ getCampaignIds(element.campaign_ids) }} -->

              <div class="d-flex align-items-center">
                <ng-container
                  *ngFor="
                    let t of getCampaignIds(element.campaign_ids)
                      | slice : 0 : 1
                  "
                >
                  <div class="w-50 text-truncate" matTooltip="{{ t }}">
                    {{ t }},
                  </div>
                </ng-container>

                <div
                  *ngIf="getCampaignIds(element.campaign_ids).length > 1"
                  class="ms-2"
                >
                  <span
                    class="badge badge-pill badge-primary"
                    matTooltip="{{
                      getCampaignIds(element.campaign_ids).slice(1)
                    }}"
                  >
                    <i class="bi-plus"></i>
                    {{ getCampaignIds(element.campaign_ids).length - 1 }}
                  </span>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <!-- END Campaign IDs Col -->

          <!-- Created By -->
          <ng-container matColumnDef="created_by">
            <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.created_by | titlecase }}
            </mat-cell>
          </ng-container>
          <!-- END Created By -->

          <!-- Report Status -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span
                class="badge p-2"
                [ngClass]="{
                  'badge-success': element.status.toLowerCase() === 'completed',
                  'badge-warning': element.status.toLowerCase() === 'inprogress',
                }"
              >
                {{ element.status.toLowerCase() === 'inprogress' ? 'In-Progress': element.status | titlecase }}
              </span>
            </mat-cell>
          </ng-container>
          <!-- END Report Status -->

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <!-- Row shown when there is no matching data. -->
          <ng-container *matNoDataRow>
            <div *ngIf="isLoading">
              <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader>
            </div>

            <!-- Opt-Filters Empty Image -->
            <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
              <img
                class="w-35"
                src="assets/img/no_data.svg"
                alt="No data found matching the filters"
              />
              <p>No data found matching the filters</p>
            </div>
            <!-- End Filters Empty Image -->
          </ng-container>
        </mat-table>
      </div>
    </div>
    <!-- END Reports Listing Table -->

    <!-- Pagination -->
    <div class="pagination-wrapper mt-5" *ngIf="totalreportscount > pageSize">
      <ngb-pagination
        class="ngb-pagination"
        [collectionSize]="totalreportscount"
        [(page)]="page"
        [pageSize]="pageSize"
        aria-label="pagination"
        (pageChange)="onPageEvent($event)"
        [rotate]="true"
        [maxSize]="3"
        [ellipses]="true"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <i class="bi-chevron-left"></i>
          Previous</ng-template
        >
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationNext>
          Next
          <i class="bi-chevron-right"></i
        ></ng-template>
      </ngb-pagination>
    </div>
    <!-- END Pagination -->
  </div>
</ng-container>
<!-- END Show Reports Listing -->

<!-- Show Skelton Loader -->
<ng-template #showloader>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- END Show Skelton Loader -->
