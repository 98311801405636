import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlCenterRoutingModule } from './control-center-routing.module';
import { AngularMaterialModule } from '../material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbAccordionModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ControlCenterComponent } from './control-center.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { ProfilesManagementComponent } from './profiles-management/profiles-management.component';
import { ControlCenterDashboardComponent } from './dashboard/dashboard.component';
import {
  BarChartData,
  LineChartData,
  PieChartData,
} from '../appdata/controlcenteranalytics.data';
import { NgChartsModule } from 'ng2-charts';
import { BusinessAccountsComponent } from './business-accounts/business-accounts.component';
import { CcSupportComponent } from './cc-support/cc-support.component';
import { BspComponent } from './bsp/bsp.component';
import { RoleCasePipe } from '../services/rolecase.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { WhiteLabelingComponent } from './white-labeling/white-labeling.component';
import { AddProfileComponent } from './profiles-management/add-profile/add-profile.component';
import { ProfileListComponent } from './profiles-management/profile-list/profile-list.component';
import { ViewProfileComponent } from './profiles-management/view-profile/view-profile.component';
import { AddRoleComponent } from './role-management/add-role/add-role.component';
import { RoleListComponent } from './role-management/role-list/role-list.component';
import { EditProfileComponent } from './profiles-management/edit-profile/edit-profile.component';
import { ViewRoleComponent } from './role-management/view-role/view-role.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [
    ControlCenterComponent,
    RoleManagementComponent,
    ProfilesManagementComponent,
    ControlCenterDashboardComponent,
    BusinessAccountsComponent,
    CcSupportComponent,
    BspComponent,
    RoleCasePipe,
    WhiteLabelingComponent,
    AddProfileComponent,
    ProfileListComponent,
    ViewProfileComponent,
    AddRoleComponent,
    RoleListComponent,
    EditProfileComponent,
    ViewRoleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ControlCenterRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbAccordionModule,
    FormsModule,
    AngularMaterialModule,
    NgChartsModule,
    NgxSkeletonLoaderModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  exports: [],
  providers: [BarChartData, LineChartData, PieChartData, RoleCasePipe],
})
export class ControlCenterModule {}
