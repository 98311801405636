import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCampaign from './campaign.reducers';

export const selectCampaignData =
  createFeatureSelector<fromCampaign.State>('campaignActionData');

export const selectCampaignPreview =
  createFeatureSelector<fromCampaign.CampaignPreviewState>('campaignPreview');

export const selectCampaignActionData = createSelector(
  selectCampaignData,
  fromCampaign.getCampaginState
);

export const selectCampaignPreviewData = createSelector(
  selectCampaignPreview,
  fromCampaign.getCampaginPreview
);
