<!-- Main Content Container -->
<main role="main" class="main" id="cc_business_accounts">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate | async"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Skeleton Template -->
<ng-template #skeletonTemp>
  <div class="card mt-2">
    <ngx-skeleton-loader
      class="skeleton_loader"
      *ngFor="let item of skeletonData"
      [theme]="item"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
<!-- END Skeleton Template -->

<!-- Empty Template -->
<ng-template #noTemplates>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <h3 class="main_title">Business Accounts</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- template Content -->
  <div class="card mt-2">
    <div class="empty_template text-center my-4">
      <img
        class="w-30"
        src="assets/img/no_data.svg"
        alt="New Business Account"
      />
      <h4 class="mb-3">
        There are no business accounts added in this organization
      </h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient"
        (click)="onCreateBusinessAccount()"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Add New Business Account
      </button>
    </div>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Empty Template -->

<!-- Business Accounts List Template -->
<ng-template #allBusinessAccountsTemp>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <h3 class="main_title">
        Business Accounts
        <span
          class="badge badge-primary rounded-pill"
          matTooltip="Total Business Accounts Count"
          >{{ channel_credentails.length }}</span
        >
      </h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card mt-2">
    <div class="card-body p-0">
      <!-- Header Options -->
      <div class="row justify-content-between">
        <div class="col-4">
          <!-- SearchBar -->
          <div class="input-group input-group-merge m-0">
            <span class="input-group-prepend input-group-text"
              ><i class="bi-search"></i
            ></span>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
            />
          </div>
          <!-- END SearchBar -->
        </div>

        <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
          <ul class="list-inline list-inline-flex mb-0">
            <!-- BSP Selection -->
            <li class="list-inline-item">
              <ng-select
                class="header_select"
                [items]="bspList"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [clearable]="true"
                placeholder="Select BSP"
                [(ngModel)]="selectedBSP"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span>{{item.bsp_name}}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <span>{{item.bsp_name}}</span>
                </ng-template>
              </ng-select>
            </li>
            <!-- END BSP Selection -->
          </ul>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <div *ngIf="channel_credentails.length > 0; else nodata">
      <!-- Business Accounts List -->
      <div
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-gap-4 mt-3"
      >
        <div
          class="col"
          *ngFor="
            let item of channel_credentails
              | slice
                : (page - 1) * pageSize
                : (page - 1) * pageSize + pageSize;
            let i = index
          "
        >
          <!-- Card -->
          <div class="card card-hover-shadow ba_item">
            <!-- Body -->
            <div class="card-body p-0">
              <div class="view-bact">
                <!-- Business Account Default Avatar -->
                <div class="d-flex justify-content-between mb-2">
                  <div class="avatar avatar-circle avatar-lg">
                    <img
                      class="avatar-img"
                      [src]="item.profile_pic"
                      [alt]="item.business_name"
                    />
                  </div>
                  <div class="info_icon">
                    <i
                      class="bi-info-circle small"
                      matTooltipPosition="above"
                      matTooltip="Description: {{
                        item.business_description
                          ? item.business_description
                          : 'N/A'
                      }}
                    Created On: {{ item.created_at | date : 'longDate' }}"
                    >
                    </i>
                  </div>
                </div>
                <!-- END Business Account Default Avatar -->

                <div class="ba_details">
                  <h4 class="d-flex align-items-center gap-1">
                    {{ item.waba_number }}
                    <img src="assets/img/verified.svg" alt="Verified" />
                  </h4>
                  <p>{{ item.business_name }}</p>
                </div>
              </div>
            </div>
            <!-- End Body -->
            <div class="dropdown-divider"></div>
            <!-- Footer -->
            <div class="card-footer p-0">
              <!-- Stats -->
              <div class="d-flex align-items-center justify-content-between">
                <div
                  class="status_indicators"
                  [ngClass]="
                    item.waba_status === 'Connected' ? 'active' : 'inactive'
                  "
                >
                  <span class="status-icon"></span>
                  <span class="ms-1 text-small">
                    <ng-template
                      [ngIf]="item.waba_status === 'Connected'"
                      [ngIfElse]="inActiveBusinessAccount"
                      >Active</ng-template
                    >
                    <ng-template #inActiveBusinessAccount>Inactive</ng-template>
                  </span>
                </div>

                <!-- Select bact button -->
                <button
                  class="btn btn-default btn-icon btn-sm"
                  (click)="onViewBusinessAccount(item)"
                  *ngIf="
                    permissions.waba_business_account_management.can_update
                  "
                >
                  <em class="bi-arrow-right"></em>
                </button>
                <!-- END Select bact button -->
              </div>
              <!-- End Stats -->
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->
        </div>
      </div>
      <!-- END Business Accounts List -->

      <div
        class="dropdown-divider"
        *ngIf="channel_credentails?.length > 8"
      ></div>

      <!-- Pagination -->
      <div class="pagination-wrapper" *ngIf="channel_credentails?.length > pageSize">
        <ngb-pagination
          class="ngb-pagination"
          [collectionSize]="channel_credentails?.length"
          [(page)]="page"
          [pageSize]="pageSize"
          aria-label="pagination"
          [rotate]="true"
          [maxSize]="3"
          [ellipses]="true"
          [boundaryLinks]="false"
        >
          <ng-template ngbPaginationPrevious
            ><i class="bi-chevron-left"></i>Previous</ng-template
          >
          <ng-template ngbPaginationNext
            >Next<i class="bi-chevron-right"></i
          ></ng-template>
        </ngb-pagination>
      </div>
      <!-- END Pagination -->
    </div>

    <ng-template #nodata>
      <!-- Content -->
      <div class="card p-3 mt-2">
        <!-- template Content -->
        <div class="empty_template text-center my-4">
          <img class="w-30" src="assets/img/no_data.svg" alt="No Data" />
          <p class="fs-5 my-2 text-dark">There is no data available</p>
        </div>
        <!-- END template Content -->
      </div>
      <!-- END Content -->
    </ng-template>
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END Business Accounts List Template -->

<!-- Create Business Account template -->
<ng-template #createBusinessAccountTemp>
  <!-- Header -->
  <div class="card" *ngIf="!isAccountCreationSuccessful">
    <div class="top_header_options justify-content-start gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="onCancelCreate()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <h3 class="main_title">Request for New WABA Number</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card mt-2">
    <!-- Creation of Business Account  -->
    <ng-container [ngSwitch]="isAccountCreationSuccessful">
      <ng-container *ngSwitchCase="false">
        <div class="row m-0 justify-content-between">
          <div class="col-lg-8">
            <div class="setup_form_sec">
              <!-- Request Type Selection -->
              <div class="row">
                <mat-radio-group
                  aria-label="Select an option"
                  [(ngModel)]="selectedRequestType"
                  (ngModelChange)="onSelectionRequestType($event)"
                >
                  <mat-radio-button value="onboarding" checked
                    >Onboarding</mat-radio-button
                  >
                  <mat-radio-button value="migrate">Migrate</mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- END Request Type Selection -->

              <mat-divider class="my-2"></mat-divider>

              <ng-container [ngSwitch]="selectedRequestType">
                <!-- Onboarding Business Account -->
                <ng-container *ngSwitchCase="'Onboarding'">
                  <form
                    [formGroup]="onBoardingForm"
                    (ngSubmit)="onSuccessFulAccountCreation(onBoardingForm)"
                  >
                    <!-- BSP Selection -->
                    <div class="form-group">
                      <label class="input-label" for="bspSelection"
                        >Select BSP
                        <span
                          class="text-danger"
                          matTooltip="BSP Selection is mandatory"
                          >*</span
                        >
                      </label>
                      <ng-select
                        formControlName="bsp"
                        id="bspSelection"
                        class="selection"
                        [items]="bspList"
                        [closeOnSelect]="true"
                        [clearable]="false"
                        [searchable]="false"
                        placeholder="Select BSP's"
                      >
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          <img [src]="item.bspUrl" [alt]="item.bspName" />
                        </ng-template>
                      </ng-select>

                      <!-- BSP Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            onBoardingFC['bsp'].touched ||
                            onBoardingFC['bsp'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['bsp'].value === '' ||
                                onBoardingFC['bsp'].value === null;
                              else fcInvalid
                            "
                            >Please select a BSP.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['bsp'].errors"
                              >Please select a valid BSP.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END BSP Error -->
                    </div>
                    <!-- END BSP Selection -->

                    <!-- Avatar Upload -->
                    <div class="form-group">
                      <label class="input-label" for="avatarImg"
                        >Add Business Logo<span class="text-danger"
                          >*</span
                        ></label
                      >

                      <div
                        class="avatar avatar-xl avatar-circle avatar-uploader"
                      >
                        <ng-template
                          [ngIf]="!onBoardingFC['profileUrl'].value"
                          [ngIfElse]="profileUrlTemp"
                        >
                          <img
                            class="avatar-img"
                            src="assets/img/customer/default_avatar.jpg"
                            alt="Default Avatar"
                          />
                          <label for="upload_input">
                            <input
                              type="file"
                              accept="image/*"
                              class="d-none"
                              id="avatarImg"
                              (change)="
                                onAvatarUpload('createBusinessAccount', $event)
                              "
                              id="upload_input"
                            />
                            <i class="bi-camera avatar_icon"></i>
                          </label>
                        </ng-template>
                        <ng-template #profileUrlTemp>
                          <img
                            class="avatar-img"
                            *ngIf="
                              onBoardingFC['profileUrl'].value &&
                              onBoardingFC['profileUrl'].value !== 'undefined'
                            "
                            [src]="onBoardingFC['profileUrl'].value"
                            alt="profile Avatar"
                          />
                          <i
                            class="bi-trash-fill avatar_icon"
                            (click)="onAvatarRemove('createBusinessAccount')"
                            (keyup)="onAvatarRemove('createBusinessAccount')"
                          ></i>
                        </ng-template>
                      </div>
                    </div>
                    <!-- END Avatar Upload -->

                    <!-- Business Display Name -->
                    <div class="form-group">
                      <label class="input-label" for="displayName"
                        >Business Display Name
                        <span
                          class="text-danger"
                          matTooltip="Business Display Name is mandatory"
                          >*</span
                        >
                        <em
                          class="bi-info-circle"
                          matTooltip="Your Display name is verified by Meta as per display guidelines
                                      Display Guidelines"
                        ></em>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Display Name Here"
                        formControlName="business_name"
                      />

                      <!-- Business Name Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            onBoardingFC['business_name'].touched ||
                            onBoardingFC['business_name'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['business_name'].value === '' ||
                                onBoardingFC['business_name'].value === null;
                              else fcInvalid
                            "
                            >Please enter your business display
                            name.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['business_name'].errors"
                              >Please enter a valid business display name.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Business Name Error -->
                    </div>
                    <!-- END Business Display Name -->

                    <!-- Business WhatsApp Number -->
                    <div class="form-group">
                      <label class="input-label" for="whatsappNo"
                        >WhatsApp Number
                        <span
                          class="text-danger"
                          matTooltip="WhatsApp Number is mandatory"
                          >*</span
                        ></label
                      >

                      <div class="d-flex gap-1">
                        <div class="dial_code">
                          <!-- Dial Code Select -->
                          <ng-select
                            formControlName="country_code"
                            id="whatsappNo"
                            placeholder="Select Country Code"
                            bindLabel="dialCode"
                            [items]="countries"
                            [clearable]="false"
                            [searchable]="true"
                            [closeOnSelect]="true"
                            dropdownPosition="bottom"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <em class="bi-phone"></em> +{{ item.dialCode }}
                              {{ item.name }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <em class="bi-phone gd_icon"></em> +{{
                                item.dialCode
                              }}
                              {{ item.name }}
                            </ng-template>
                          </ng-select>

                          <!-- country_code Error -->
                          <div class="invalid-feedback help-block">
                            <div
                              *ngIf="
                                onBoardingFC['country_code'].touched ||
                                onBoardingFC['country_code'].dirty
                              "
                            >
                              <ng-container
                                *ngIf="
                                  onBoardingFC['country_code'].value === '' ||
                                    onBoardingFC['country_code'].value === null;
                                  else fcInvalid
                                "
                                >Please select a Country Code.</ng-container
                              >
                              <ng-template #fcInvalid>
                                <span
                                  *ngIf="onBoardingFC['country_code'].errors"
                                  >Please select a valid Country Code.</span
                                >
                              </ng-template>
                            </div>
                          </div>
                          <!-- END country_code Error -->
                          <!-- END Dial Code Select -->
                        </div>

                        <div class="whatsapp_no w-100">
                          <!-- WhatsApp Number Input -->
                          <input
                            class="form-control d-block"
                            type="tel"
                            inputmode="numeric"
                            placeholder="Enter whatsapp number"
                            formControlName="business_no"
                            (keydown.arrowup)="(false)"
                            (keydown.arrowdown)="(false)"
                          />
                          <!-- END WhatsApp Number Input -->

                          <!-- WhatsApp Number Error -->
                          <div class="invalid-feedback help-block">
                            <div
                              *ngIf="
                                onBoardingFC['business_no'].touched ||
                                onBoardingFC['business_no'].dirty
                              "
                            >
                              <ng-container
                                *ngIf="
                                  onBoardingFC['business_no'].value === '' ||
                                    onBoardingFC['business_no'].value === null;
                                  else fcInvalid
                                "
                              >
                                Please enter your contact number.
                              </ng-container>
                              <ng-template #fcInvalid>
                                <span
                                  *ngIf="onBoardingFC['business_no'].errors"
                                >
                                  Please enter a valid contact number.
                                </span>
                              </ng-template>
                            </div>
                          </div>
                          <!-- END WhatsApp Number Error -->
                        </div>
                      </div>
                    </div>
                    <!-- END Business WhatsApp Number -->

                    <!-- Business Description -->
                    <div class="form-group">
                      <label class="input-label" for="businessDesc"
                        >Business Description
                        <span
                          class="text-danger"
                          matTooltip="Business description is mandatory"
                          >*</span
                        >
                      </label>

                      <textarea
                        formControlName="business_desc"
                        type="text"
                        rows="4"
                        id="businessDesc"
                        class="form-control"
                        placeholder="Enter Here"
                      ></textarea>

                      <!-- Business Description Error -->
                      <div class="invalid-feedback help-block">
                        <div
                          *ngIf="
                            onBoardingFC['business_desc'].touched ||
                            onBoardingFC['business_desc'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['business_desc'].value === '' ||
                              onBoardingFC['business_desc'].value === null
                            "
                          >
                            Please enter your Business Description.
                          </ng-container>
                        </div>
                      </div>
                      <!-- END Business Description Error -->
                    </div>
                    <!-- END Business Description -->

                    <!-- Facebook Business Manger ID -->
                    <div class="form-group">
                      <label class="input-label" for="fbMID"
                        >Facebook Business Manger ID
                        <span
                          class="text-danger"
                          matTooltip="Facebook business manger ID is mandatory"
                          >*</span
                        >
                      </label>
                      <input
                        class="form-control"
                        id="fbMID"
                        type="text"
                        placeholder="Enter Here"
                        formControlName="manager_id"
                      />

                      <!-- Facebook Business Manger ID Error -->
                      <div class="invalid-feedback help-block mb-4">
                        <div
                          *ngIf="
                            onBoardingFC['manager_id'].touched ||
                            onBoardingFC['manager_id'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['manager_id'].value === '' ||
                                onBoardingFC['manager_id'].value === null;
                              else fcInvalid
                            "
                            >Please enter your Facebook Business Manger
                            ID.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['manager_id'].errors"
                              >Please enter a valid Facebook Business Manger
                              ID.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Facebook Business Manger ID Error -->
                    </div>
                    <!-- END Facebook Business Manger ID -->

                    <!-- Name associated with Facebook Business Manager ID -->
                    <div class="form-group">
                      <label class="input-label" for="fbMIDname"
                        >Name associated with Facebook Business Manager ID
                        <span class="text-danger" matTooltip="Name is mandatory"
                          >*</span
                        >
                      </label>
                      <input
                        formControlName="associated_name"
                        id="fbMIDname"
                        class="form-control"
                        type="text"
                        placeholder="Enter Here"
                      />
                      <!-- Name associated with Facebook Business Manager ID Error -->
                      <div class="invalid-feedback help-block mb-4">
                        <div
                          *ngIf="
                            onBoardingFC['associated_name'].touched ||
                            onBoardingFC['associated_name'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['associated_name'].value === '' ||
                                onBoardingFC['associated_name'].value === null;
                              else fcInvalid
                            "
                            >Please enter your Name associated with Facebook
                            Business Manager ID.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['associated_name'].errors"
                              >Please enter a valid Name associated with
                              Facebook Business Manager ID.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Name associated with Facebook Business Manager ID Error -->
                    </div>
                    <!-- END Name associated with Facebook Business Manager ID -->

                    <!-- divider -->
                    <mat-divider class="my-2"></mat-divider>
                    <!-- END divider -->

                    <!-- Save & Cancel Buttons -->
                    <div class="form_action_btns">
                      <div class="options_left"></div>
                      <div class="options_right">
                        <!-- Cancel Button -->
                        <button class="btn btn-outline-dark" type="button">
                          Cancel
                        </button>
                        <!-- END Cancel Button -->

                        <!-- Request Button -->
                        <button
                          class="btn btn-gradient"
                          type="button"
                          [disabled]="onBoardingForm.invalid"
                        >
                          Request
                          <em class="bi-chevron-right"></em>
                        </button>
                        <!-- END Request Button -->
                      </div>
                    </div>
                    <!-- END Save & Cancel Buttons -->
                  </form>
                </ng-container>
                <!-- END Onboarding Business Account -->

                <!-- Migrate Business Account -->
                <ng-container *ngSwitchCase="'Migrate'">
                  <form
                    [formGroup]="migrateForm"
                    (ngSubmit)="onSuccessFulAccountCreation(migrateForm)"
                  >
                    <!-- Upload Documents Dropzone -->
                    <h4 class="mb-3">Upload Documents</h4>
                    <!-- Csv/Excel Drag-n-Drop Area -->
                    <fieldset
                      class="media_upload_dropZone d-flex align-items-center justify-content-center gap-4"
                    >
                      <legend class="visually-hidden">Image uploader</legend>
                      <input
                        class="upload_image"
                        type="file"
                        accept="text/csv"
                        (change)="
                          onSelect($event); $any($event.target).value = ''
                        "
                      />
                      <div class="d-flex align-items-center gap-3">
                        <div class="file_upload_icon">
                          <em class="bi bi-upload fs-1 text-muted"></em>
                        </div>
                        <div class="files_supported text-center">
                          <h3 class="text-dark">
                            Drag and drop file or
                            <span class="text-primary text-decoration-underline"
                              >Browse</span
                            >
                          </h3>
                          <p>
                            Upload<strong class="mx-2"
                              >"2 Factor Authentication: Disabled"</strong
                            >Screenshot
                          </p>
                        </div>
                      </div>
                    </fieldset>
                    <!-- END Csv/Excel Drag-n-Drop Area -->

                    <!-- Uploaded Files -->
                    <div class="upload_gallery my-2" *ngIf="file">
                      <div class="uploaded_image">
                        <img src="assets/img/nuacem.svg" alt="Nuacem" />
                        <em
                          class="bi bi-x-circle-fill img_close"
                          (click)="file = null"
                          (keyup)="file = null"
                        >
                        </em>
                      </div>
                      <span>{{ file.name }}</span>
                    </div>
                    <!-- END Uploaded Files -->
                    <!-- Divider -->
                    <mat-divider class="my-3"></mat-divider>
                    <!-- END Divider -->
                    <div class="row justify-content-end mx-0">
                      <button
                        type="submit"
                        class="col-auto btn btn-gradient text-white px-4"
                      >
                        Add Now
                      </button>
                    </div>
                  </form>
                </ng-container>
                <!-- END Migrate Business Account -->
              </ng-container>
            </div>
          </div>

          <!-- WhatsApp Setup Preview -->
          <div class="col p-0">
            <div class="whatsapp_preview">
              <div class="box">
                <app-whatsapp-preview></app-whatsapp-preview>
              </div>
            </div>
          </div>
          <!-- END WhatsApp Setup Preview -->
        </div>
      </ng-container>
      <!-- END Creation of Business Account  -->

      <!-- Successfully Created Business Account  -->
      <ng-container *ngSwitchCase="true">
        <div class="py-10 my-10 m-4">
          <div class="text-center py-7 my-7">
            <img
              class="mb-3"
              src="assets/img/success.svg"
              alt="New Business Account"
            />
            <p class="fs-4 fw-bold">
              Your request has been submitted successfully
            </p>
            <button
              type="button"
              class="btn btn-lg btn-gradient btn-primary"
              (click)="onGoToBusinessNumber()"
            >
              <div class="d-flex align-items-center">
                <em class="bi bi-plus me-2"></em>
                Go to business number
              </div>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- END Successfully Created Business Account -->
  </div>
  <!-- Template Content -->
</ng-template>
<!-- END Create Business Account template -->

<!-- View Business Account Template -->
<ng-template #viewBusinessAccountsTemp>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <div class="d-flex align-items-center gap-2">
        <!-- Goto business accounts Listing page -->
        <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <!-- END Goto business accounts Listing page -->

        <div class="bact_view_details">
          <div class="details d-flex align-items-center gap-2">
            <div class="business_avatar">
              <div class="avatar avatar-circle avatar-lg">
                <img
                  class="avatar-img"
                  [src]="businessAccountsData.profile_pic"
                  [alt]="businessAccountsData.business_name"
                />
              </div>
            </div>
            <div class="business_info">
              <h3 class="d-flex align-items-center gap-1 mb-2">
                {{ businessAccountsData.waba_number }}
                <img src="assets/img/verified.svg" alt="Verified" />
              </h3>
              <ul
                class="list-inline-flex list-unstyled list-divider mb-0 text-small text-body text-capitalize"
              >
                <li class="list-inline-item">
                  {{ businessAccountsData.business_name }}
                </li>
                <li class="list-inline-item">
                  WABA ID :
                  {{ businessAccountsData.waba_id }}
                </li>
              </ul>
              <!-- End Row -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card card-vh-100 mt-2">
    <div class="card-body p-2">
      <div class="wabaid_details">
        <div class="nav_tabs">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="active"
            class="nav-tabs mb-4"
            (navChange)="onNavChange($event)"
          >
            <!-- WABA Details -->
            <li [ngbNavItem]="1">
              <button ngbNavLink>WABA Details</button>
              <ng-template ngbNavContent>
                <ng-container [ngSwitch]="isEditMode">
                  <!-- View Business Account -->
                  <ng-container *ngSwitchCase="false">
                    <!-- WABA Details -->
                    <div class="wabaid_details">
                      <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                        <!-- WABA ID -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA ID</span>
                            <h5 class="m-0">
                              {{ businessAccountsData.waba_id }}
                            </h5>
                          </div>
                        </div>
                        <!-- END WABA ID -->

                        <!-- WABA Status -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA Status</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.waba_status.toLowerCase() === 'connected'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{ businessAccountsData.waba_status | titlecase }}
                            </h5>
                          </div>
                        </div>
                        <!-- END WABA Status -->

                        <!-- WABA Quality -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA Quality</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.waba_quality.toLowerCase() === 'high'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{ businessAccountsData.waba_quality | titlecase }}
                            </h5>
                          </div>
                        </div>
                        <!-- ENDWABA Quality -->

                        <!-- END Number Quality -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Number Quality</span>
                            <h5 class="m-0"
                                [ngClass]="
                                  businessAccountsData.number_quality.toLowerCase() === 'high'
                                    ? 'text-success'
                                    : 'text-danger'
                                "
                            >
                              {{
                                businessAccountsData.number_quality | titlecase
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- Number Quality -->

                        <!-- Number Status -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Number Status</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.number_status.toLowerCase() === 'connected'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{ businessAccountsData.number_status | titlecase }}
                            </h5>
                          </div>
                        </div>
                        <!-- END Number Status -->

                        <!-- Messaging Limit -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Messaging Limit</span>
                            <h5 class="m-0">
                              {{ businessAccountsData.messaging_limit }}
                              <em
                                class="bi-info-circle ms-2"
                                matTooltip="Messaging limits determine the daily quota for sending template messages to unique users for your business."
                                matTooltipPosition="above"
                              ></em>
                            </h5>
                          </div>
                        </div>
                        <!-- END Messaging Limit -->
                      </div>
                    </div>
                    <!-- END WABA Details -->

                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->
                    
                    <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                      <!-- Traffic/Volume -->
                      <div class="col-4">
                        <div class="item_detail">
                          <span class="label">Committed Volume</span>
                          <h5 id="traffic">
                            {{ businessAccountsData.traffic_volume }}
                            <em
                              class="bi-info-circle"
                              matTooltip="Committed volume is the volume promised by your organization that shall be utilized through this WABA ID, you may send more messages than committed volume, but will be restricted on the TIER limit of the WABA ID"
                            ></em>
                          </h5>
                        </div>
                      </div>
                      <!-- END Traffic/Volume -->
            
                      <!-- Billing Type -->
                      <div class="col-4">
                        <div class="item_detail">
                          <span class="label">Billing Type</span>
                          <h5 id="traffic">
                            {{ businessAccountsData.billing_type | titlecase }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- END Billing Type -->
                    
                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->

                    <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                      <!-- Avatar -->
                      <div class="col">
                        <div class="business_avatar d-flex flex-column">
                          <span class="label">Display Picture</span>

                          <!-- Options to save/cancel the uploaded image -->
                          <div class="pic_options" *ngIf="businessprofile_pic">
                            <button 
                              class="btn btn-xs btn-primary"
                              (click)="confBusinessProfile_pic()"
                              (keyup)="confBusinessProfile_pic()"
                            >Save</button>
                            <button 
                              class="btn btn-xs btn-outline-danger"
                              (click)="removeUploadPic()"
                              (keyup)="removeUploadPic()"
                            >Cancel</button>
                           </div>
                          <!-- Options to save/cancel the uploaded image -->

                          <div
                            class="avatar avatar-circle avatar-lg avatar-uploader profileImg"
                          >
                            <img
                              class="avatar-img"
                              [src]="businessprofile_pic ? preview : businessAccountsData.profile_pic"
                              [alt]="businessAccountsData.business_name"
                            />

                            <span
                             class="avatar-uploader-trigger"
                           >
                              <em
                                *ngIf="!businessprofile_pic"
                                class="bi bi-pencil-fill avatar-uploader-icon shadow-soft avatar_icon"
                                matTooltip="Change Avatar"
                                (click)="fileinput.click()"
                                (keyup)="fileinput.click()"
                             ></em>
                              <input
                                type="file"
                                accept="image/*"
                                class="d-none"
                                id="mp_upload_input"
                                name="userImage"
                                (change)="onSelect($event)"
                                (click)="$event.target.value = null"
                                #fileinput
                              />
                           </span>
                          </div>
                        </div>
                      </div>
                      <!-- END Avatar -->

                      <!-- Entity Name -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Entity Name</span>
                          <h5 class="m-0" id="asctName">
                            {{
                              businessAccountsData.business_name
                                ? businessAccountsData.business_name
                                : "N/A"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Entity Name -->

                      <!-- Business Description -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Business Description</span>
                          <h5 class="m-0">
                            {{
                              businessAccountsData.business_description
                                ? businessAccountsData.business_description
                                : "N/A"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Business Description -->
                      
                      <!-- About Business -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">About</span>
                          <h5 class="m-0">
                            {{
                              businessAccountsData.about
                                ? businessAccountsData.about
                                : "N/A"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END About Business -->

                      <!-- Business Profiles -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Business Profiles</span>
                          <span>
                            <h5 class="m-0">
                              {{ businessAccountsData.business_name }}
                            </h5>
                            <p class="m-0">
                              {{ businessAccountsData.waba_number }}
                            </p>
                          </span>
                        </div>
                      </div>
                      <!-- END Business Profiles -->

                      <!-- BSP -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">BSP</span>
                          <h5>{{businessAccountsData.bsp_name === 'RML'?'Routemobile':businessAccountsData.bsp_name}}</h5>
                        </div>
                      </div>
                      <!-- END BSP -->

                      <!-- Channel -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Channel</span>
                          <div
                            class="d-block"
                            *ngIf="businessAccountsData.channel_name === 'WABA'"
                          >
                            <img
                              class="w-10"
                              src="assets/img/whatsapp.svg"
                              [alt]="businessAccountsData.channel_name"
                            />
                            <span class="ms-2">Whatsapp</span>
                          </div>
                        </div>
                      </div>
                      <!-- END Channel -->

                      <!-- Facebook Manager ID -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Facebook Manager ID</span>
                          <h5 class="m-0">
                            {{ businessAccountsData.facebook_manager_id }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Facebook Manager ID -->

                      <!-- Callback URL -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Callback URL</span>
                          <h6 class="m-0 text-decoration-underline">
                            {{ businessAccountsData.callback_url }}
                          </h6>
                        </div>
                      </div>
                      <!-- END Callback URL -->

                      <!-- Created on -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Created on</span>
                          <h5 class="m-0">
                            {{
                              businessAccountsData.created_at
                                | date : "longDate"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Created on -->

                      <!-- Credentials -->
                      <div class="col">
                        <div class="authorization">
                          <span class="label">Credentials</span>
                          <h5 id="auth_type">
                            Username :
                            {{
                              AuthDetails(businessAccountsData.credential_meta, "username")
                            }}
                          </h5>
                          <h5>
                            Password :
                            <span>
                              <ng-container *ngIf="!isCopied; else pwdVisible">
                                {{
                                  AuthDetails(
                                    businessAccountsData.credential_meta,
                                    "password"
                                  ) | maskText
                                }}
                              </ng-container>
                              <ng-template #pwdVisible>
                                {{
                                  AuthDetails(
                                    businessAccountsData.credential_meta,
                                    "password"
                                  )
                                }}
                              </ng-template>
                            </span>
            
                            <em
                              class="ms-1 fs-6 pointer"
                              [ngClass]="{
                                'bi-eye-fill': !isCopied,
                                'bi-eye-slash-fill': isCopied
                              }"
                              (click)="copyCode()"
                              (keyup)="copyCode()"
                            >
                            </em>
                          </h5>
                        </div>
                      </div>
                      <!-- END Credentials -->
                    </div>

                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->

                    <!-- Webhook details -->
                    <div class="d-none">
                      <h3 class="mb-4">Webhook Details</h3>
                      <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                        <!-- Delivery URL -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Delivery URL</span>
                            <h6 class="m-0 text-decoration-underline">
                              nb5.botjet.ai/whatsappbync/generic/8831b44c-f3fe-74ed-2ca3-486b0ed869b8
                            </h6>
                          </div>
                        </div>
                        <!-- END Delivery URL -->

                        <!-- Delivery Header -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Delivery Header</div>
                            <h5 class="m-0">
                              0e5ca03ac23da78614e4e6ad917712f3
                            </h5>
                          </div>
                        </div>
                        <!-- END Delivery Header -->

                        <!-- Incoming webhook URL -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Incoming webhook URL</div>
                            <h6 class="m-0 text-decoration-underline">
                              n5t.convojet.ai.whatsapp/genric/87681b44c
                            </h6>
                          </div>
                        </div>
                        <!-- END Incoming webhook URL -->

                        <!-- Incoming webhiik header -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Incoming webhook header</div>
                            <h5 class="m-0">null</h5>
                          </div>
                        </div>
                        <!-- END Incoming webhiik header -->
                      </div>
                    </div>
                    <!-- END Webhook details -->

                    <div class="row d-none">
                      <!-- Edit Button -->
                      <div class="col-12 text-end">
                        <button
                          type="button"
                          class="btn btn-gradient"
                          (click)="onBusinessAccountEditMode()"
                          *ngIf="
                            permissions.waba_business_account_management
                              .can_update
                          "
                        >
                          Edit
                        </button>
                      </div>
                      <!-- END Edit Button -->
                    </div>
                  </ng-container>
                  <!-- END View Business Account -->

                  <!-- Edit Business Account  -->
                  <ng-container *ngSwitchCase="true">
                    <form [formGroup]="editBusinessAccountForm">
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <!-- Avatar Upload -->
                          <div class="business_logo d-flex flex-column mb-4">
                            <label for="avatarImg">Display Picture</label>
                            <!-- Business Profile Avatar Upload -->
                            <div
                              class="avatar avatar-circle avatar-xl avatar-uploader businssProfileImg"
                              id="avatarImg"
                            >
                              <ng-template
                                [ngIf]="!imgURL"
                                [ngIfElse]="profileIconTemp"
                              >
                                <img
                                  class="avatar-img"
                                  src="assets/img/customer/default_avatar.jpg"
                                  alt="User Avatar"
                                />
                                <label for="mp_upload_input" *ngIf="!isEdited">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    class="d-none"
                                    id="mp_upload_input"
                                    (change)="
                                      onAvatarUpload(
                                        'editBusinessAccount',
                                        $event
                                      )
                                    "
                                  />
                                  <i class="bi-camera-fill avatar_icon"></i>
                                </label>
                              </ng-template>
                              <ng-template #profileIconTemp>
                                <img
                                  class="avatar-img"
                                  *ngIf="
                                    bpFC['profileImg'].value &&
                                    bpFC['profileImg'].value !== 'undefined'
                                  "
                                  [src]="allBusinessSettingsData.profileUrl"
                                  alt="profile Avatar"
                                />
                                <em
                                  class="bi-trash-fill avatar_icon"
                                  (click)="onAvatarRemove('editBusinessAccount')"
                                  (keyup)="onAvatarRemove('editBusinessAccount')"
                                ></em>
                              </ng-template>
                            </div>
                            <!-- END Business Profile Avatar Upload -->
                          </div>
                          <!-- END Avatar Upload -->

                          <!-- Display Name -->
                          <div class="form-group">
                            <label class="input-label" for="displayName"
                              >Display Name
                              <span
                                class="text-danger"
                                matTooltip="Display Name is mandatory"
                                >*</span
                              >
                            </label>
                            <input
                              class="form-control"
                              id="displayName"
                              type="text"
                              placeholder="Enter display name"
                              formControlName="display_name"
                            />
                            <!-- Dispaly Name Error -->
                            <div class="invalid-feedback help-block">
                              <div
                                *ngIf="
                                  editBAFC['display_name'].touched ||
                                  editBAFC['display_name'].dirty
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    editBAFC['display_name'].value === '' ||
                                      editBAFC['display_name'].value === null;
                                    else fcInvalid
                                  "
                                  >Display name can not be empty.
                                </ng-container>
                                <ng-template #fcInvalid>
                                  <span *ngIf="editBAFC['display_name'].errors">
                                    Please enter a valid display name.
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <!-- END DIsplay Name Error -->
                          </div>
                          <!-- END Display Name -->

                          <!-- Business Number -->
                          <div class="form-group">
                            <label class="input-label" for="businessNo"
                              >Business Number
                              <span
                                class="text-danger"
                                matTooltip="Business Number is mandatory"
                                >*</span
                              >
                            </label>
                            <input
                              class="form-control"
                              id="businessNo"
                              type="tel"
                              placeholder="Enter business number"
                              formControlName="business_no"
                              (keydown.arrowup)="(false)"
                              (keydown.arrowdown)="(false)"
                            />
                            <!-- Business Number Error -->
                            <div class="invalid-feedback help-block">
                              <div
                                *ngIf="
                                  editBAFC['business_no'].touched ||
                                  editBAFC['business_no'].dirty
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    editBAFC['business_no'].value === '' ||
                                      editBAFC['business_no'].value === null;
                                    else fcInvalid
                                  "
                                  >Business number can not be empty.
                                </ng-container>
                                <ng-template #fcInvalid>
                                  <span *ngIf="editBAFC['business_no'].errors">
                                    Please enter a valid Business number.
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <!-- END Business Number Error -->
                          </div>
                          <!-- END Business Number -->
                        </div>

                        <div class="col">
                          <!-- Business Description -->
                          <div class="form-group">
                            <label class="input-label" for="businessDesc"
                              >Business Description
                              <span
                                class="text-danger"
                                matTooltip="Business Description is mandatory"
                                >*</span
                              >
                            </label>
                            <textarea
                              class="form-control h-100"
                              type="text"
                              id="businessDesc"
                              rows="4"
                              placeholder="Enter Business Description"
                              aria-label="Enter Business Description"
                              formControlName="business_desc"
                            ></textarea>
                            <!-- Business Number Error -->
                            <div
                              *ngIf="
                                editBAFC['business_desc'].touched ||
                                editBAFC['business_desc'].dirty
                              "
                            >
                              <div class="invalid-feedback help-block">
                                <ng-container
                                  *ngIf="
                                    editBAFC['business_desc'].value === '' ||
                                      editBAFC['business_desc'].value === null;
                                    else fcInvalid
                                  "
                                  >Business description can not be
                                  empty.</ng-container
                                >
                                <ng-template #fcInvalid>
                                  <span
                                    *ngIf="editBAFC['business_desc'].errors"
                                  >
                                    Please enter a valid business
                                    description.</span
                                  >
                                </ng-template>
                              </div>
                            </div>
                            <!-- END Business Number Error -->
                          </div>
                          <!-- END Business Description -->
                        </div>

                        <!-- Divider -->
                        <mat-divider class="my-4"></mat-divider>
                        <!-- END Divider -->

                        <div class="form_action_btns">
                          <div class="options_left"></div>
                          <div class="options_right">
                            <!-- Cancel Button -->
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              (click)="isEditMode = false"
                            >
                              Cancel
                            </button>
                            <!-- END Cancel Button -->

                            <!-- Save Button -->
                            <button type="submit" class="btn btn-gradient">
                              Save
                            </button>
                            <!-- END Save Button -->
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-container>
                  <!-- END Edit Business Account  -->
                </ng-container>
              </ng-template>
            </li>
            <!-- END WABA Details -->
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END View Business Account Template -->
