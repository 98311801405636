import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryData } from 'src/app/appdata/countries.data';
import { AlertService } from 'src/app/alert/alert.service';
import {
  BSP,
  WabaID,
  bsp,
  wabaid,
  AllBusinessSettings,
  allBusinessSettings,
} from 'src/app/appdata/shared.data';
import { Store } from '@ngrx/store';
import { SharedService } from 'src/app/services/shared.service';
import { Subject, Subscription, takeUntil, BehaviorSubject } from 'rxjs';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { LicenseDatum, RbacPolicies } from 'src/app/appdata/auth.model';
import { CustomValidators } from 'src/app/services/CustomValidators';
import * as dashboardactions from 'src/app/customer/dashboard/store/dashboard.actions';
import { selectChannelCredentials } from 'src/app/customer/dashboard/store/dashboard.selectors';
import { skeletonData } from 'src/app/appdata/analytics.data';

export interface SliderValues {
  color: string;
  checked: boolean;
  disabled: boolean;
  labelPosition: 'before' | 'after';
  status: string[];
}

@Component({
  selector: 'app-business-accounts',
  templateUrl: './business-accounts.component.html',
  styleUrls: ['./business-accounts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessAccountsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isAccountCreationSuccessful: boolean = false;
  editBusinessAccountForm: FormGroup;
  onBoardingForm: FormGroup;
  migrateForm: FormGroup;
  displayTemplate = new BehaviorSubject<TemplateRef<string>>(null);
  businessAccountsList: WabaID[] = wabaid;
  businessAccountsData: any;
  allBusinessSettingsData: AllBusinessSettings = allBusinessSettings;
  bspList: BSP[] = bsp;
  countries: Array<any> = [];
  selectedDialCode: any;
  searchData: string = '';
  active = 1;
  page = 1;
  pageSize = 10;
  isEditMode: boolean = false;
  isEdited: boolean = false;
  businessprofile_pic: boolean = false;
  fileUploadSuccess: boolean = false;
  imgURL: string;
  imagePath: FileList;
  requestTypes: string[] = ['Onboarding', 'Migrate'];
  selectedRequestType: string;
  file: File;
  selectedBSP: any;
  original_img: string;
  preview: any = '';
  sliderValue: SliderValues = {
    color: 'accent',
    checked: true,
    disabled: false,
    labelPosition: 'before',
    status: ['Active', 'Inactive'],
  };
  channel_credentails: LicenseDatum[] = [];
  skeletonData = skeletonData;
  permissions: RbacPolicies;
  channelSub: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    businessname: null,
  };

  loggedInUserDetails: any;

  @ViewChild('noTemplates') private noTemplates: TemplateRef<any>;
  @ViewChild('skeletonTemp') private skeletonTemp: TemplateRef<any>;
  @ViewChild('allBusinessAccountsTemp')
  private allBusinessAccountsTemp: TemplateRef<any>;
  @ViewChild('createBusinessAccountTemp')
  private createBusinessAccountTemp: TemplateRef<any>;
  @ViewChild('viewBusinessAccountsTemp')
  private viewBusinessAccountsTemp: TemplateRef<any>;
  @ViewChild('dropzoneRef', { static: false }) dropzoneRef: any;
  isCopied: boolean;

  constructor(
    private readonly store: Store,
    private shareservice: SharedService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private cv: CustomValidators,
    private countrydata: CountryData,
    private alertMsg: AlertService
  ) {}

  // Life-cycle Hooks
  ngOnInit(): void {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials.account_id;
        this.account_dtls.bsp_id = value.channel_credentials.bsp_id;
        // this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();
      }
    });
    this.countries = this.countrydata.CountryDialCodes;
    this.selectedDialCode = this.countries.find((t) => t.dialCode === '91');

    this.selectedRequestType = this.requestTypes[0];

    this.editBusinessAccountForm = this.fb.group({
      profileUrl: [null],
      display_name: [null, Validators.required],
      business_no: [
        null,
        [Validators.required, Validators.pattern(this.cv.bnameRegex)],
      ],
      business_desc: [null, Validators.required],
    });

    this.onBoardingForm = this.fb.group({
      profileUrl: [null],
      bsp: [null, Validators.required],
      country_code: [null, Validators.required],
      business_no: [
        null,
        [Validators.required, Validators.pattern(this.cv.bnameRegex)],
      ],
      business_name: [null, Validators.required],
      business_desc: [null, Validators.required],
      manager_id: [null, Validators.required],
      associated_name: [null, Validators.required],
    });

    this.migrateForm = this.fb.group({
      uploadMedia: [null],
    });

    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.permissions =
            res.loggedInUserDetails.profile.acl.rbac.rbac_policies;
            this.loggedInUserDetails = res.loggedInUserDetails;
          // this.channel_credentails =
          //   res.loggedInUserDetails.account.licenses.license_data;
          this.selectedBSP = this.bspList[0];
          this.store.dispatch(
            dashboardactions.getChannelCredentials({
              payload: this.account_dtls,
            })
          );
        }
      });

    this.store
      .select(selectChannelCredentials)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.dashboarddata && res.dashboarddata.status_code === 200) {

          this.channel_credentails = res.dashboarddata.data;
          this.channel_credentails.length > 0
            ? this.displayTemplate.next(this.allBusinessAccountsTemp)
            : this.displayTemplate.next(this.noTemplates);
        } else if (res.error) {
          if (res.error && res.error.status_code === 404) {
            this.channel_credentails = [];
          }
        }
      });
  }

  get bpFC() {
    return this.editBusinessAccountForm.controls;
  }

  ngAfterViewInit(): void {
    this.displayTemplate.next(this.skeletonTemp);
    this.cd.detectChanges();
  }
  // END Life-cycle Hooks

  gotoListing() {
    this.channel_credentails.length !== 0
      ? this.displayTemplate.next(this.allBusinessAccountsTemp)
      : this.displayTemplate.next(this.noTemplates);
    this.cd.detectChanges();
  }

  // Getters
  get onBoardingFC() {
    return this.onBoardingForm.controls;
  }

  get editBAFC() {
    return this.editBusinessAccountForm.controls;
  }
  // END Getters

  // Methods
  onCreateBusinessAccount() {
    this.displayTemplate.next(this.createBusinessAccountTemp);
  }

  //Method to search
  onSearchNameChange(event: any) {
    if (!event) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.businessname = null;
      this.store.dispatch(
        dashboardactions.getChannelCredentials({ payload: this.account_dtls })
      );
    } else {
      this.searchName(event);
    }
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.businessname = data.trim();
      this.store.dispatch(
        dashboardactions.getChannelCredentials({ payload: this.account_dtls })
      );
    }
  }

  onCancelCreate() {
    this.selectedRequestType = this.requestTypes[0];
    this.businessAccountsList.length !== 0
      ? this.displayTemplate.next(this.allBusinessAccountsTemp)
      : this.displayTemplate.next(this.noTemplates);
  }

  onViewBusinessAccount(accdata: any) {
    this.businessAccountsData = accdata;
    this.displayTemplate.next(this.viewBusinessAccountsTemp);
  }

  onSelectionRequestType(event: string) {
    if (event === 'onboarding') {
      this.selectedRequestType = this.requestTypes[0];
    } else if (event === 'migrate') {
      this.selectedRequestType = this.requestTypes[1];
    }
  }

  onAvatarUpload(data: string, event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files || files.length === 0) return;
    const mimeType = files[0].type;
    if (!mimeType.startsWith('image/')) return;
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (data === 'createBusinessAccount') {
        this.onBoardingForm.controls['profileUrl'].setValue(reader.result);
      } else if (data === 'editBusinessAccount') {
        this.editBusinessAccountForm.controls['profileUrl'].setValue(
          reader.result
        );
      }
    };
  }

  onAvatarRemove(data: string) {
    if (data === 'createBusinessAccount') {
      this.onBoardingForm.controls['profileUrl'].setValue(null);
    } else if (data === 'editBusinessAccount') {
      this.editBusinessAccountForm.controls['profileUrl'].setValue(null);
    }
  }

  onSuccessFulAccountCreation(form: FormGroup) {
    this.isAccountCreationSuccessful = true;
  }

  onBusinessAccountEditMode() {
    this.isEditMode = true;
  }

  onGoToBusinessNumber() {
    this.isAccountCreationSuccessful = false;
    this.selectedRequestType = this.requestTypes[0];
    this.displayTemplate.next(this.allBusinessAccountsTemp);
  }

  onSlideToggleChange(event: any) {
    if (event) {
      this.sliderValue.checked = !this.sliderValue.checked;
    }
  }

  removeUploadPic()
  {
    this.businessAccountsData = JSON.parse(JSON.stringify(this.businessAccountsData));
    this.businessAccountsData.profile_pic = this.original_img;
    this.file = null;
    this.businessprofile_pic = false;
  }

  onSelect(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.alertMsg.alertWarning(
          'Warning!',
          "You can't upload documents which are greater than 2MB"
        );
        return;
      } else {
        this.original_img = this.businessAccountsData.profile_pic;
         
        this.file = event.target.files[0];
        if(this.file)
        {
          this.businessprofile_pic = true
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.preview = e.target.result;
          };
          reader.readAsDataURL(this.file);
        }
      }
    }
  }

  confBusinessProfile_pic() {
    this.shareservice
    .fileUpload(
      this.file,
      this.account_dtls.account_id,
      this.businessAccountsData.waba_number,
      // this.account_dtls.waba_no,
      this.account_dtls.channel,
      this.loggedInUserDetails.profile.id,
      false
      // this.account_dtls.createdby,
    )
    .subscribe({
      next: (response) => {
        if (response.status_code === 200) {
          this.businessAccountsData = JSON.parse(
            JSON.stringify(this.businessAccountsData)
          );
          this.businessAccountsData.profile_pic = response.data.url;
          this.fileUploadSuccess = true;

          // API call for saving the confimed image upload to media drive
          if(this.fileUploadSuccess)
          {
            const payload = {
              id: this.account_dtls.bsp_id,
              profile_pic: this.businessAccountsData.profile_pic,
            };
      
            this.shareservice.updatechannelCredentials(payload).subscribe({
              next: (response) => {
                if (response.status_code === 200) {
                  this.alertMsg.alertSuccess('Success', response.data.message);
                  this.store.dispatch(
                    dashboardactions.getChannelCredentials({
                      payload: this.account_dtls,
                    })
                  );
                  this.businessprofile_pic = false;
                }
              },
              error: (error) => {
                if (error.message) {
                  const dispErr = error.message;
                  this.alertMsg.alertDanger('Error', dispErr);
                }
              },
            });
          }
        }
      },
      error: (error) => {
        if (error.message) {
          const dispErr = error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      },
    });
  }
 

  AuthDetails(data: any, action: string) {
    let credentialmeta = JSON.parse(data);
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (action === 'username') {
      return credentialmeta.metaData.username;
    } else if (action === 'password') {
      return credentialmeta.metaData.password;
    }
  }

  copyCode() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) =>
        resolve(e.target?.result);
      reader.onerror = () => reject(null);
      file ? reader.readAsDataURL(file) : reject(null);
    });
  }

  onNavChange(event: any) {
    return;
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
