<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<ng-container
  [ngTemplateOutlet]="mainTemplateData?.template"
  [ngTemplateOutletContext]="{ data: mainTemplateData?.data }"
></ng-container>

<!-- Empty Template -->
<ng-template #noTemplates>
  <!-- Template Header -->
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Templates</h3>
    </div>
  </div>
  <!-- END Template Header -->

  <!-- template Content -->
  <div class="card p-3 mt-2">
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="Create Template" />
      <h4 class="mb-3">No Templates Here</h4>
      <button
        type="button"
        class="btn btn-gradient btn-primary"
        *ngIf="permissions.template_management.can_create"
        (click)="onCreateTemplate()"
      >
        <i class="bi bi-plus"></i> Create Template
      </button>
    </div>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Empty Template -->

<!-- Skeleton Template -->
<ng-template #skeletonTemp>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- END Skeleton Template -->

<!-- All Templates Listing -->
<ng-template #showTemplates let-data="data">
  <!-- Template Header -->
  <div class="card card-sticky p-3">
    <div class="top_header_options">
      <h3 class="main_title">
        Templates
        <span
          class="badge badge-primary rounded-pill"
          matTooltip="Total Templates Count"
          >{{ totalTemplateList }}</span
        >
      </h3>

      <!-- Create Template Button -->
      <button
        class="btn btn-primary"
        (click)="onCreateTemplate()"
        matTooltip="Create new template"
        *ngIf="permissions.template_management.can_create"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Create Template
      </button>
      <!-- END Create Template Button -->
    </div>
  </div>
  <!-- END Template Header -->

  <!-- Quick guide alert -->
  <div class="alert alert-dismissible alert-soft-dark fade mt-3 show"
    role="alert"
  >
    <h5 class="main_title">Quick Guide</h5>
    <ul class="list-pointer mb-0">
      <li class="list-pointer-item">
        Note: WhatsApp typically take 12-24 hours to review the templates.
      </li>
    </ul>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
  <!-- END Quick guide alert -->

  <!-- template Content -->
  <div class="card mt-2">
    <div class="card-body p-0">
      <!-- Header Options -->
      <div class="row justify-content-between">
        <div class="col-4">
          <!-- SearchBar -->
          <div class="input-group input-group-merge mb-0">
            <div class="input-group-prepend input-group-text">
              <em class="bi bi-search" 
                (click)="searchName(searchData)"
                (keydown)="searchName(searchData)"
              ></em>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              [ngClass]="searchInput ? 'invalid-input': ''"
            />
          </div>
          <!-- END SearchBar -->
        </div>

        <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
          <ul class="list-inline list-inline-flex mb-0">
            <!-- Tempalte Download Option -->
            <li
              class="list-inline-item"
            >
              <div class="d-flex align-items-center gap-2">
                <button
                  type="button"
                  class="btn btn-icon btn-outline-primary gradient-outline"
                  matTooltip="Download all templates to CSV"
                  (click)="downloadAllTemp()"
                  *ngIf="templatesList?.length > 0"
                >
                  <em class="bi-download fs-5"></em>
                </button>
              </div>
            </li>
            <!-- END Tempalte Download Option -->

            <!-- Global Refresh Option -->
            <li class="list-inline-item">
              <button
                type="button"
                class="btn btn-icon btn-default"
                matTooltip="Global refresh to get all templates status"
                (click)="refreshTemplatesList()"
                *ngIf="templatesList?.length > 0"
              >
                <em class="bi bi-arrow-repeat"></em>
              </button>
            </li>
            <!-- END Global Refresh Option -->

            <!-- Filters -->
            <li class="list-inline-item">
              <div
                ngbDropdown
                class="d-inline-block filter-dropdown"
                #myDrop="ngbDropdown"
              >
                <button
                  type="button"
                  class="btn btn-default"
                  id="CampaignfiltersDropDown"
                  ngbDropdownToggle
                >
                  <i class="bi-funnel me-1"></i>
                  Filters
                </button>

                <div
                  class="dropdown-menu"
                  ngbDropdownMenu
                  aria-labelledby="CampaignfiltersDropDown"
                >
                  <div class="card p-0 shadow-none">
                    <div class="card-header">
                      <h4 class="title">Filters</h4>
                    </div>
                    <div
                      class="card-body py-2 px-3"
                      (click)="stopPropagation($event)"
                      (keydown)="stopPropagation($event)"
                    >
                      <!-- Template Category Selection -->
                      <div class="label">Template Category</div>
                      <ng-select
                        [items]="allCategories"
                        id="templatecategory"
                        [(ngModel)]="filterCriteria.temp_category"
                        [placeholder]="'-- Select --'"
                        [clearable]="false"
                        [searchable]="false"
                      >
                      </ng-select>
                      <!-- END Template Category Selection -->

                      <!-- Template Type Selection -->
                      <div class="label mt-3">Template Type</div>
                      <ng-select
                        [items]="templateTypes"
                        id="templatetype"
                        [(ngModel)]="filterCriteria.temp_type"
                        [placeholder]="'-- Select --'"
                        [clearable]="false"
                        [searchable]="false"
                      >
                      </ng-select>
                      <!-- END Template Type Selection -->

                      <!-- Template Language -->
                      <div class="label mt-3">Template Language</div>
                      <ng-select
                        [items]="template_languages"
                        id="templateLanguage"
                        bindLabel="langName"
                        id="templatelang"
                        [(ngModel)]="filterCriteria.temp_lang"
                        [placeholder]="'-- Select --'"
                        [clearable]="false"
                        [searchable]="true"
                      >
                      </ng-select>
                      <!-- END Template Language -->

                      <!-- Template Status -->
                      <div class="label mt-3">Status</div>
                      <ng-select
                        [items]="allStatus"
                        id="templateStatus"
                        bindLabel="name"
                        bindValue="name"
                        id="templatestatus"
                        [(ngModel)]="filterCriteria.temp_status"
                        [placeholder]="'-- Select --'"
                        [clearable]="false"
                        [searchable]="false"
                      >
                      </ng-select>
                      <!-- END Template Status -->
                    </div>
                    <div class="card-footer">
                      <button
                        type="button"
                        class="btn btn-block btn-gradient"
                        (click)="
                          applyFilter();
                          $event.stopPropagation();
                          myDrop.close()
                        "
                        *ngIf="
                          filterCriteria.temp_category ||
                          filterCriteria.temp_type ||
                          filterCriteria.temp_lang ||
                          filterCriteria.temp_status
                        "
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- END Filters -->

            <!-- Calender Date Range Selector -->
            <li class="list-inline-item">
              <app-datepicker></app-datepicker>
            </li>
            <!-- END Calender Date Range Selector -->
          </ul>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <!-- Filter Selected Values -->
    <div class="selected_filters mt-2 p-2 d-flex align-items-center justify-content-between"
      *ngIf="!checkFilteredItems()"
    >
      <!-- Selected Filter Options -->
      <div class="selected_items">
        <mat-chip-listbox aria-label="Selected Type and Status">
          <mat-chip *ngIf="filterCriteria.temp_category">
            Category: <b>{{ filterCriteria.temp_category | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('category')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="filterCriteria.temp_type">
            Type: <b>{{ filterCriteria.temp_type | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('type')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="filterCriteria.temp_lang">
            Lang: <b>{{ filterCriteria.temp_lang.langName | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('lang')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="filterCriteria.temp_status">
            Status: <b>{{ filterCriteria.temp_status | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('status')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Selected Filter Options -->

      <!-- Clear Filters Option -->
      <div class="clear_filters">
        <mat-chip-listbox aria-label="Clear selected filters">
          <mat-chip (click)="clearFilter('clearfilter')" (keydown)="clearFilter('clearfilter')"> Clear All </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Clear Filters Option -->
    </div>
    <!-- END Filter Selected Values -->

    <!-- Show Template Listing -->
    <div class="show_template_listing mt-2">
      <!-- Template Listing Table -->
      <div class="table-wrapper table-responsive">
        <div class="table-container mb-0">
          <mat-table [dataSource]="templatesList">
            <!-- Template Name and Options Menu -->
            <ng-container matColumnDef="name" sticky>
              <mat-header-cell *matHeaderCellDef>
                <!-- <mat-checkbox
                  class="list-checkboxes m-0"
                  (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                  *ngIf="templatesList.length > 0"
                >
                </mat-checkbox> -->
                Name
              </mat-header-cell>

              <mat-cell *matCellDef="let element">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div class="d-flex w-80 align-items-center">
                    <!-- <mat-checkbox
                      class="list-checkboxes m-0"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(element) : null"
                      [checked]="selection.isSelected(element)"
                      [aria-label]="checkboxLabel(element)"
                    >
                    </mat-checkbox> -->
                    <div
                      class="temp_name_id pointer"
                      (click)="openModal(viewTemplateModal, 'viewTemplate', element)"
                      (keydown)="openModal(viewTemplateModal, 'viewTemplate', element)"
                      [matTooltip]="
                        'View ' + element.template_name + ' Template Details'
                      "
                    >
                      <div class="temp_name fw-bold text-truncate w-95">
                        {{ element.template_name }}
                      </div>
                    </div>
                  </div>

                  <!-- More Options -->
                  <div class="card-unfold">
                    <button
                      class="btn btn-sm btn-icon btn-option"
                      [matMenuTriggerFor]="menu"
                      aria-label="Templates Action Options"
                    >
                      <em class="bi bi-three-dots-vertical"></em>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <div
                        class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                      >
                        <a
                          class="dropdown-item"
                          matTooltip="Edit template"
                          matTooltipPosition="right"
                          (click)="openEditTemplate(element)"
                          *ngIf="
                            permissions.template_management.can_update &&
                            element.status === 'DRAFTED'
                          "
                        >
                          <i class="bi-pencil-fill text-primary"></i> Edit
                        </a>
                        <a
                          class="dropdown-item"
                          matTooltip="View template"
                          matTooltipPosition="right"
                          (click)="
                            openModal(
                              viewTemplateModal,
                              'viewTemplate',
                              element
                            )
                          "
                          *ngIf="permissions.template_management.can_view"
                        >
                          <i class="bi-eye-fill text-primary"></i> View
                        </a>
                        <a
                          class="dropdown-item text-danger"
                          matTooltip="Delete template"
                          matTooltipPosition="right"
                          *ngIf="permissions.template_management.can_delete"
                          (click)="
                            openModal(
                              deleteTemplateModal,
                              'deleteTemplate',
                              element
                            )
                          "
                        >
                          <i class="bi-trash3-fill"></i> Delete
                        </a>
                      </div>
                    </mat-menu>
                  </div>
                  <!-- END More Options -->
                </div>
              </mat-cell>
            </ng-container>
            <!-- END Template Name and Options Menu -->

            <!-- Template Category -->
            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.category | titlecase }}
              </mat-cell>
            </ng-container>
            <!-- END Template Category -->

            <!-- Template Type -->
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.type }}
              </mat-cell>
            </ng-container>
            <!-- END Template Type -->

            <!-- Template Status -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="status_indicator"
                  [ngClass]="element.status.toLowerCase()"
                >
                  <em class="status_icon"></em>
                  <span class="status_text">{{
                    element.is_drafted ? "Draft" : element.status.toLowerCase()
                  }}</span>
                </div>
              </mat-cell>
            </ng-container>
            <!-- Template Status -->

            <!-- Template Created On -->
            <ng-container matColumnDef="created">
              <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.created_at | date : "dd/MM/yyyy, h:mm a" }}
              </mat-cell>
            </ng-container>
            <!-- Template Created On -->

            <!-- Template Language -->
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef> Language </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ getLangData(element.languages) }}
              </mat-cell>
            </ng-container>
            <!-- END Template Language -->

            <!-- Template Remarks/Reason -->
            <ng-container matColumnDef="reason">
              <mat-header-cell *matHeaderCellDef> Remarks </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ getContentData(element.bsp_metadata) }}
              </mat-cell>
            </ng-container>
            <!-- Template Remarks/Reason -->

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>
            
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            <!-- Row shown when there is no matching data. -->
            <ng-container *matNoDataRow>
              <div *ngIf="isLoading">
                <ngx-skeleton-loader
                  class="skeleton_loader"
                  *ngFor="let item of skeletonData"
                  [theme]="item"
                ></ngx-skeleton-loader>
              </div>

              <!-- Opt-Filters Empty Image -->
              <div
                class="d-flex flex-column align-items-center text-center my-4"
                *ngIf="!isLoading"
              >
                <img
                  class="w-20"
                  src="assets/img/no_data.svg"
                  alt="No data found matching the filters"
                />
                <p>No data found matching the filters</p>
              </div>
              <!-- End Filters Empty Image -->
            </ng-container>
          </mat-table>
        </div>
      </div>
      <!-- END Template Listing Table -->

      <!-- Pagination -->
      <div class="pagination-wrapper mb-0 mt-3" *ngIf="totalTemplateList > pageSize">
        <ngb-pagination
          class="ngb-pagination"
          [collectionSize]="totalTemplateList"
          [(page)]="page"
          [pageSize]="pageSize"
          aria-label="pagination"
          (pageChange)="onPageEvent($event)"
          [rotate]="true"
          [maxSize]="3"
          [ellipses]="true"
          [boundaryLinks]="false"
        >
          <ng-template ngbPaginationPrevious>
            <i class="bi-chevron-left"></i>
            Previous</ng-template
          >
          <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
          <ng-template ngbPaginationNext>
            Next
            <i class="bi-chevron-right"></i
          ></ng-template>
        </ngb-pagination>
      </div>
      <!-- END Pagination -->
    </div>
    <!-- END Show Template Listing -->

    <ng-template #nodata>
      <!-- Content -->
      <div class="card p-3 mt-2">
        <!-- template Content -->
        <div class="empty_template text-center my-4">
          <img class="w-30" src="assets/img/no_data.svg" alt="No Data" />
          <p class="fs-5 my-2 text-dark">There is no data available</p>
        </div>
        <!-- END template Content -->
      </div>
      <!-- END Content -->
    </ng-template>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END All Templates Listing -->

<!-- Modals -->
<!-- View Template Modal -->
<ng-template #viewTemplateModal let-modal let-c="close" let-d="dismiss">
  <div class="modal-body p-0">
    <div class="row m-0">
      <div class="col col-lg-5 temp_preview_data">
        <div class="p-4">
          <h1 class="modal-title mb-6">Template preview</h1>
          <div class="temp_name_date">
            <span class="modal_label">Template Name :</span>
            <h4 class="temp_title">{{ editItem.template_name }}</h4>
            <span class="modal_label"
              >Created on : {{ editItem.created_at | date : "short" }}</span>
          </div>
          <mat-divider class="my-2"></mat-divider>

          <div class="temp_status d-flex align-items-center justify-content-between">
            <span class="modal_label mb-0">Status :</span>
            <span
              class="status_indicator"
              [ngClass]="editItem.status.toLowerCase()"
            >
              <em class="status_icon"></em>
              <span>{{ editItem.status }}</span>
            </span>
          </div>

          <mat-divider class="my-2"></mat-divider>

          <div class="template_preview_details">
            <div class="row my-2">
              <label class="modal_label col" for="templatecategory"
                >Template Category</label
              >
              <span class="col fw-bold" id="templatecategory">: {{
                editItem.category | titlecase
              }}</span>
            </div>
            <div class="row my-2">
              <label class="modal_label col" for="templatetype"
                >Template Type</label
              >
              <span class="col fw-bold" id="templatetype">: {{
                editItem.type | titlecase
              }}</span>
            </div>
            <div class="row my-2">
              <label class="modal_label col" for="language">Language</label>
              <span class="col fw-bold" id="language">: {{
                getLangData(editItem.languages)
              }}</span>
            </div>
          </div>

          <mat-divider class="my-2"></mat-divider>

          <div
            class="reason_details"
            *ngIf="
              editItem.status.toLowerCase() === 'rejected' ||
              editItem.status.toLowerCase() === 'failed'
            "
          >
            <h4>Error Reason</h4>
            <div class="error_message">
              {{ getContentData(editItem.bsp_metadata) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-7 p-0">
        <div class="whatsapp_preview">
          <div class="box">
            <div class="close_option">
              <button
                type="button"
                class="btn-close p-3"
                aria-label="Close"
                (click)="closeViewModal()"
              ></button>
            </div>
            <app-whatsapp-preview></app-whatsapp-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END View Template Modal -->

<!-- Delete Template Modal -->
<ng-template #deleteTemplateModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Delete Template</h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>
        Are you sure want to delete
        <strong class="text-danger">“{{ deleteItem.template_name }}”</strong>?
      </h4>

      <p class="warning_text mt-2">
        <i class="bi-exclamation-triangle-fill text-warning mr-2"></i>
        Deleting this template will impact associated campaigns.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
    <button class="btn btn-outline-danger" (click)="deleteTemplate()">
      Delete
    </button>
  </div>
</ng-template>
<!-- END Delete Template Modal -->
<!-- END Modals -->
