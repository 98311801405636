import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as campaignactions from './campaign.actions';
import { CampaignService } from 'src/app/services/campaign.service';
import { CampaginListData } from 'src/app/appdata/campaign.model';
import { AnalyticsData } from 'src/app/appdata/analytics.model';

@Injectable()
export class CampaignEffects {
  constructor(
    private actions$: Actions,
    private campaignservice: CampaignService
  ) {}

  getCampaignServicesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.fetchcampaignslist),
      exhaustMap((action) =>
        this.campaignservice.fetchcampaigns(action.payload).pipe(
          map((response: CampaginListData) => {
            return campaignactions.fetchcampaignslistSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(campaignactions.fetchcampaignslistFailure(error))
          )
        )
      )
    );
  });

  createCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.createCampaign),
      exhaustMap((action) =>
        this.campaignservice.createcampaign(action.datapayload).pipe(
          map((response: any) => {
            return campaignactions.createCampaignSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(campaignactions.createCampaignFailure(error))
          )
        )
      )
    );
  });

  updateCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.updateCampaignAction.updateCampaign),
      exhaustMap((action) =>
        this.campaignservice.updatecampaign(action.datapayload).pipe(
          map((response: any) => {
            return campaignactions.updateCampaignAction.updateCampaignSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.updateCampaignAction.updateCampaignFailure(error)
            )
          )
        )
      )
    );
  });

  saveCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.saveCampaignAction.saveCampaign),
      exhaustMap((action) =>
        this.campaignservice.savecampaign(action.datapayload).pipe(
          map((response: any) => {
            return campaignactions.saveCampaignAction.saveCampaignSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(campaignactions.saveCampaignAction.saveCampaignFailure(error))
          )
        )
      )
    );
  });

  getCampaginById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.FetchCampaignDetails),
      exhaustMap((action) =>
        this.campaignservice.getCampaginById(action.id).pipe(
          map((response: any) => {
            return campaignactions.FetchCampaignDetailsSuccess({
              data: response,
              payload: action,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.FetchCampaignDetailsFailure({
                error,
              })
            )
          )
        )
      )
    );
  });

  fetchCampaginAnalyticsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.FetchCampaignAnalyticsDtls),
      exhaustMap((action) =>
        this.campaignservice.getCampaignAnalyticsData(action.data).pipe(
          map((response: AnalyticsData) => {
            return campaignactions.FetchCampaignAnalyticsDtlsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.FetchCampaignAnalyticsDtlsFailure({
                error,
              })
            )
          )
        )
      )
    );
  });

  fetchCampaginViewDataById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.CampaignViewDtls),
      exhaustMap((action) =>
        this.campaignservice.getCampaginById(action.id).pipe(
          map((response: any) => {
            return campaignactions.CampaignViewDtlsSuccess({
              data: response,
              payload: action,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.CampaignViewDtlsFailure({
                error,
              })
            )
          )
        )
      )
    );
  });

  fetchAnalyticsViewDataById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.CampaignViewDtlsSuccess),
      exhaustMap((action) =>
        this.campaignservice.getCampaignAnalyticsData(action.payload).pipe(
          map((response: AnalyticsData) => {
            return campaignactions.FetchCampaignAnalyticsDtlsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.FetchCampaignAnalyticsDtlsFailure({
                error,
              })
            )
          )
        )
      )
    );
  });

  getCampaginValidationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.FetchCampaignValidation),
      exhaustMap((action) =>
        this.campaignservice.campaignValidataionStatus(action.id).pipe(
          map((response: any) => {
            return campaignactions.FetchCampaignValidationSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(campaignactions.FetchCampaignValidationFailure(error))
          )
        )
      )
    );
  });

  fetchCampaignPreprocessing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.campaignPreprocessingData),
      exhaustMap((action) =>
        this.campaignservice.savecampaign(action.datapayload).pipe(
          map((response: any) => {
            return campaignactions.campaignPreprocessingDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(campaignactions.campaignPreprocessingDataFailure(error))
          )
        )
      )
    );
  });

  deleteCampaigns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignactions.deleteCampaignAction.deleteCampaign),
      exhaustMap((action) =>
        this.campaignservice.deleteCampaigns(action.datapayload).pipe(
          map((response: any) => {
            return campaignactions.deleteCampaignAction.deleteCampaignSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(
              campaignactions.deleteCampaignAction.deleteCampaignFailure(error)
            )
          )
        )
      )
    );
  });
}
