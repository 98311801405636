import { Action, createReducer, on } from '@ngrx/store';
import * as profileActions from './profiles.actions';
import { ProfilesData } from 'src/app/appdata/profiledata.model';

export interface State {
  profilesList: ProfilesData;
  profiledata: any;
  update_profile: any;
  delete_res: any;
  error: any;
}

export const initialState: State = {
  profilesList: null,
  profiledata: null,
  update_profile: null,
  delete_res: null,
  error: null,
};

const profileDataReducer = createReducer(
  initialState,
  on(
    profileActions.fetchProfileslistSuccess,
    (state, { data }): State => ({
      ...state,
      profilesList: data,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: null,
    })
  ),
  on(
    profileActions.fetchProfileslistFailure,
    (state, { error }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: error,
    })
  ),
  on(
    profileActions.fetchProfileDataSuccess,
    (state, { data }): State => ({
      ...state,
      profilesList: null,
      profiledata: data,
      update_profile: null,
      delete_res: null,
      error: null,
    })
  ),
  on(
    profileActions.fetchProfileDataFailure,
    (state, { error }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: error,
    })
  ),
  on(
    profileActions.createProfileSuccess,
    (state, { data }): State => ({
      ...state,
      profilesList: null,
      profiledata: data,
      update_profile: null,
      delete_res: null,
      error: null,
    })
  ),
  on(
    profileActions.createProfileFailure,
    (state, { error }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: error,
    })
  ),
  on(
    profileActions.UpdateProfileActions.updateSuccess,
    (state, { data }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: data,
      delete_res: null,
      error: null,
    })
  ),
  on(
    profileActions.UpdateProfileActions.updateFailure,
    (state, { error }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: error,
    })
  ),
  on(
    profileActions.DeleteProfileActions.deleteSuccess,
    (state, { data }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: data,
      error: null,
    })
  ),
  on(
    profileActions.DeleteProfileActions.deleteFailure,
    (state, { error }): State => ({
      ...state,
      profilesList: null,
      profiledata: null,
      update_profile: null,
      delete_res: null,
      error: error,
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return profileDataReducer(state, action);
}

export const getProfileData = (state: State) => {
  return {
    profilesList: state.profilesList,
    profiledata: state.profiledata,
    update_profile: state.update_profile,
    delete_res: state.delete_res,
    error: state.error,
  };
};
