import { createAction, props } from '@ngrx/store';
import { RolesDataList } from 'src/app/appdata/roles.model';
import { RoleVariables } from 'src/app/services/shared.service';

export const fetchRoleslist = createAction(
  '[Roles] Fetch Roles List',
  props<{ payload: RoleVariables }>()
);
export const fetchRoleslistSuccess = createAction(
  '[Roles] Fetch Roles List Success',
  props<{ data: RolesDataList }>()
);
export const fetchRoleslistFailure = createAction(
  '[Roles] Fetch Roles List Failure',
  props<{ error: any }>()
);

export const initiateCreateRole = createAction(
  '[Roles] Initiate Create Role',
  props<{ data: any }>()
);
export const initiateCreateRoleSuccess = createAction(
  '[Roles] Initiate Create Role Success',
  props<{ data: any }>()
);
export const initiateCreateRoleFailure = createAction(
  '[Roles] Initiate Create Role Failure',
  props<{ error: any }>()
);

export const viewRoleDetails = createAction(
  '[Roles] View Role Details',
  props<{ id: string }>()
);
export const viewRoleDetailsSuccess = createAction(
  '[Roles] View Role Details Success',
  props<{ data: any }>()
);
export const viewRoleDetailsFailure = createAction(
  '[Roles] View Role Details Failure',
  props<{ error: any }>()
);

export const updateRoleDetails = createAction(
  '[Roles] Update Role Details',
  props<{ id: string; payload: any }>()
);
export const updateRoleDetailsSuccess = createAction(
  '[Roles] Update Role Details Success',
  props<{ data: any }>()
);
export const updateRoleDetailsFailure = createAction(
  '[Roles] Update Role Details Failure',
  props<{ error: any }>()
);

export const initiateDeleteRole = createAction(
  '[Roles] Initiate Delete Role',
  props<{ roleid: string }>()
);
export const initiateDeleteRoleSuccess = createAction(
  '[Roles] Initiate Delete Role Success',
  props<{ data: any }>()
);
export const initiateDeleteRoleFailure = createAction(
  '[Roles] Initiate Delete Role Failure',
  props<{ error: any }>()
);
