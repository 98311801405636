import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as reportActions from './reports.actions';
import {
  ReportListData,
  ReportsService,
  ViewSummaryReportsData,
} from 'src/app/services/reports.service';

@Injectable()
export class ReportsEffects {
  constructor(
    private actions$: Actions,
    private reportservice: ReportsService
  ) {}

  getReportTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reporttemplates.reportTemplatesList),
      exhaustMap((action) =>
        this.reportservice.fetchReportTemplates(action.payload).pipe(
          map((response: any) => {
            return reportActions.reporttemplates.reportTemplatesListSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reporttemplates.reportTemplatesListFailure(error))
          )
        )
      )
    );
  });

  createReportTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reporttemplates.createReportTemplate),
      exhaustMap((action) =>
        this.reportservice.createReportTemplate(action.payload).pipe(
          map((response: any) => {
            return reportActions.reporttemplates.createReportTemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reporttemplates.createReportTemplateFailure(error))
          )
        )
      )
    );
  });

  updateReportTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reporttemplates.editReportTemplate),
      exhaustMap((action) =>
        this.reportservice.updateReportTemplate(action.id, action.payload).pipe(
          map((response: any) => {
            return reportActions.reporttemplates.editReportTemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reporttemplates.editReportTemplateFailure(error))
          )
        )
      )
    );
  });

  deleteReportTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reporttemplates.deleteReportTemplate),
      exhaustMap((action) =>
        this.reportservice.deleteReportTemplate(action.payload).pipe(
          map((response: any) => {
            return reportActions.reporttemplates.deleteReportTemplateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reporttemplates.deleteReportTemplateFailure(error))
          )
        )
      )
    );
  });

  getReportLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reportdetails.reportList),
      exhaustMap((action) =>
        this.reportservice.fetchReport(action.payload).pipe(
          map((response: ReportListData) => {
            return reportActions.reportdetails.reportListSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reportdetails.reportListFailure(error))
          )
        )
      )
    );
  });

  createReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reportdetails.createReport),
      exhaustMap((action) =>
        this.reportservice.createReport(action.payload).pipe(
          map((response: any) => {
            return reportActions.reportdetails.createReportSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reportdetails.createReportFailure(error))
          )
        )
      )
    );
  });

  updateReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reportdetails.editReport),
      exhaustMap((action) =>
        this.reportservice.updateReport(action.id, action.payload).pipe(
          map((response: any) => {
            return reportActions.reportdetails.editReportSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reportdetails.editReportFailure(error))
          )
        )
      )
    );
  });

  deleteReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.reportdetails.deleteReport),
      exhaustMap((action) =>
        this.reportservice.deleteReports(action.payload).pipe(
          map((response: any) => {
            return reportActions.reportdetails.deleteReportSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.reportdetails.deleteReportFailure(error))
          )
        )
      )
    );
  });

  getSummaryReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.summaryreports.viewSummaryReports),
      exhaustMap((action) =>
        this.reportservice.viewSummaryReports(action.payload).pipe(
          map((response: ViewSummaryReportsData) => {
            return reportActions.summaryreports.viewSummaryReportsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(reportActions.summaryreports.viewSummaryReportsFailure(error))
          )
        )
      )
    );
  });

  deleteSummaryReportsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportActions.summaryreports.deleteSummaryReportFile),
      exhaustMap((action) =>
        this.reportservice.deleteSummaryReport(action.fiename).pipe(
          map((response: any) => {
            return reportActions.summaryreports.deleteSummaryReportFileSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(
              reportActions.summaryreports.deleteSummaryReportFileFailure(error)
            )
          )
        )
      )
    );
  });
}
