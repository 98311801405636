import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError } from 'rxjs';

export interface TemplateVariables {
  accountid: string;
  bspid: string;
  wabano: string;
  page: number | null;
  size: number | null;
  category: string | null;
  type: string | null;
  status: string | null;
  template_lang: string | null;
  template_name: string | null;
  startdate: string | null;
  enddate: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  account_dtls = {
    account_id: 'c567aebe-b2aa-4c64-8419-89c9fabd7d95',
    bsp_id: 'be6409ad-1043-4c7e-acb2-9fc03a8b48cd',
    waba_no: '+919852087314',
    channel: 'waba',
    createdby: 'naveen',
  };

  samplepayload: any = {
    account_id: 'c567aebe-b2aa-4c64-8419-89c9fabd7d95',
    bsp_id: 'be6409ad-1043-4c7e-acb2-9fc03a8b48cd',
    waba_number: '+919852087314',
    template_name: null,
    languages: [],
    category: null,
    type: null,
    is_drafted: false,
    content: {},
  };

  constructor(private http: HttpClient) {}

  fetchtemplates(payload: TemplateVariables) {
    const url =
      environment.backendurl +
      `/api/templates/${payload.accountid}/${payload.bspid}/${payload.wabano}`;

    let params = new HttpParams();
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.category) {
      params = params.set('category', payload.category);
    }
    if (payload.type) {
      params = params.set('type', payload.type);
    }
    if (payload.status) {
      params = params.set('status', payload.status);
    }
    if (payload.template_lang) {
      params = params.set('language', payload.template_lang);
    }
    if (payload.template_name) {
      params = params.set('template_name', payload.template_name);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getTemplateDtls(id: string) {
    const url = environment.backendurl + `/api/templates/` + id;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createtemplate(data: any) {
    const url = environment.backendurl + `/api/templates`;
    return this.http.post(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updatetemplate(data: any, template_id: string) {
    const url = environment.backendurl + `/api/templates/` + template_id;
    return this.http.put(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deletetemplate(data: any) {
    const url = environment.backendurl + `/api/templates/` + data;
    return this.http.delete(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createTemplatePayload(data: any) {
    this.samplepayload.template_name = data.steponeform.tempName;
    this.samplepayload.category = data.steponeform.tempCategory.toUpperCase();
    this.samplepayload.languages = [data.steponeform.tempLanguage];
    this.samplepayload.type =
      data.steponeform.tempType === 'None'
        ? 'text'
        : data.steponeform.tempType.toLowerCase();

    if (this.samplepayload.type === 'text' && data.steptwoform.tempHeaderText) {
      this.samplepayload.content['header'] = {};
      this.samplepayload.content['header']['header_type'] = 'text';
      this.samplepayload.content['header']['data'] = [];
      this.samplepayload.content['header']['data'].push({
        type: 'text',
        text: data.steptwoform.tempHeaderText,
      });
      const variables =
        data.steptwoform.tempHeaderText?.match(/{{\d+}}/g) || [];
      if (variables.length > 0) {
        this.samplepayload.content['header']['variables'] = [{ '1': 'var1' }];
        this.samplepayload.content['header']['examples'] = [];
      }
      data.sampledataform.headerVariables.forEach((t: any) => {
        this.samplepayload.content['header']['examples'].push({
          '1': t.variableName,
        });
      });
    }
    if (
      this.samplepayload.type === 'media' &&
      data.steptwoform.tempHeaderMedia
    ) {
      this.samplepayload.content['header'] = {};
      this.samplepayload.content['header']['header_type'] = 'media';
      this.samplepayload.content['header']['data'] = [];
      this.samplepayload.content['header']['data'].push({
        type: data.steptwoform.tempHeaderMedia.toLowerCase(),
      });
      this.samplepayload.content['header']['examples'] = [
        { url: data.sampledataform.uploadMedia },
      ];
    }
    if (data.steptwoform.tempBody) {
      this.samplepayload.content['body'] = {};
      this.samplepayload.content['body']['text'] =
        data.steptwoform.tempBody.replace(/(<([^>]+)>)/gi, '');
      const variables =
        this.samplepayload.content.body.text?.match(/{{\d+}}/g) || [];
      if (variables.length > 0) {
        const body_variables: any = [];
        const body_exp: any = [];
        data.sampledataform.bodyVariables.forEach((t: any) => {
          const number = t.variable.match(/\d+/); // Extract the number from '{{N}}'
          if (number) {
            const variableObj: any = {};
            const expObj: any = {};
            variableObj[number[0]] = 'var' + number[0];
            expObj[number[0]] = t.variableName;
            body_variables.push(variableObj);
            body_exp.push(expObj);
          }
        });
        this.samplepayload.content['body']['variables'] = body_variables;
        this.samplepayload.content['body']['examples'] = body_exp;
      }
    }
    if (data.steptwoform.enabletempFooter && data.footerText) {
      this.samplepayload.content['footer'] = {};
      this.samplepayload.content['footer']['text'] =
        data.steptwoform.footerText;
    }
    if (
      data.steptwoform.buttontype === 'Call To Action' &&
      data.steptwoform.ctoaButtons.length > 0
    ) {
      this.samplepayload.content['button_details'] = {};
      this.samplepayload.content['button_details']['type'] = 'call_to_action';
      const buttons_data: any = [];
      data.sampledataform.ctoaButtons.forEach((t: any) => {
        if (t.type === 'Phone Number') {
          buttons_data.push({
            type_of_action: 'phone_no',
            button_text: t.text,
            phone_no: t.country + t.phno,
          });
        } else if (t.type === 'Visit Website' && t.urlType === 'Dynamic') {
          buttons_data.push({
            type_of_action: 'website',
            button_text: 'Click here',
            url_type: t.urlType.toLowerCase(),
            url: t.url,
            website_example: t.urlvariable,
          });
        } else if (t.type === 'Visit Website' && t.urlType === 'Static') {
          buttons_data.push({
            type_of_action: 'website',
            button_text: t.text,
            url_type: t.urlType.toLowerCase(),
            url: t.url,
          });
        }
      });
      this.samplepayload.content['button_details']['buttons'] = buttons_data;
    }

    if (
      data.steptwoform.buttontype === 'Quick Reply' &&
      data.steptwoform.quickreplyBtns.length > 0
    ) {
      const buttons_data: any = [];
      data.sampledataform.quickreplyBtns.forEach((t: any) => {
        buttons_data.push({
          button_text: t.quickreplyText,
        });
      });
      this.samplepayload.content['button_details'] = {
        type: 'quick_reply',
        buttons: buttons_data,
      };
    }
  }

  getTemplatePayload() {
    return this.samplepayload;
  }

  formatToHtml(text: string): string {
    const htmlText = text
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>') // Bold
      .replace(/_([^_]+)_/g, '<em>$1</em>') // Italic
      .replace(/~([^~]+)~/g, '<s>$1</s>') // Strikethrough
      .replace(/```([^`]+)```/g, '<code>$1</code>') // Monospace
      .replace(/\n/g, '<br>') // Line break
      .replace(/^\* (.*)$/gm, '<li>$1</li>') // Bullet points
      .replace(/^(\d+)\. (.*)$/gm, '<li>$2</li>') // Numbered list
      .replace(/^> (.*)$/gm, '<blockquote>$1</blockquote>') // Quote
      .replace(/\n\n+/g, '</p><p>');

    // Replace consecutive spaces with &nbsp;
    const stringWithNonBreakingSpaces = htmlText.replace(/ {2,}/g, (match) => {
      return '&nbsp;'.repeat(match.length);
    });

    // Wrap bullet points and numbered lists with ul and ol tags
    const formattedHtml = stringWithNonBreakingSpaces
      .replace(/\* (.*)/g, '<ul><li>$1</li></ul>')
      .replace(/(\d+)\. (.*)/g, '<ol><li>$2</li></ol>');

    return `<p>${formattedHtml}</p>`;
  }

  formatData(
    data: {
      insert: string;
      attributes?: {
        bold?: boolean;
        italic?: boolean;
        strike?: boolean;
        underline?: boolean;
      };
    }[]
  ): string {
    let result = '';
    data.forEach((item) => {
      let text = item.insert || '';
      const attributes = item.attributes || {};
      if (attributes.bold) {
        text = `*${text}*`;
      }
      if (attributes.italic) {
        text = `_${text}_`;
      }
      if (attributes.strike) {
        text = `~${text}~`;
      }
      if (attributes.underline) {
        text = `_${text}_`; // Underline in WhatsApp is represented by Italics
      }
      result += text;
    });
    result = result.replace(/\n{3,}/g, '\n\n');
    return result;
  }

  sortString(inputString: string): string {
    // Regular expression to match numbers enclosed within {{}} brackets
    const regex = /{{(\d+)}}/g;

    // Extracting numbers from the string and storing them in an array with their index
    const numbers: { index: number; number: number }[] = [];
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      numbers.push({ index: match.index, number: parseInt(match[1]) });
    }

    // Sorting the array of numbers in ascending order
    const sortedNumbers = numbers
      .map((obj) => obj.number)
      .sort((a, b) => a - b);

    // Replacing the numbers in the original string with the sorted numbers
    let outputString = inputString.replace(
      regex,
      () => `{{${sortedNumbers.shift()}}}`
    );

    // Creating a consecutive sequence starting from 1 and ending with the length of the array
    const length = numbers.length;
    const consecutiveSequence = Array.from({ length }, (_, i) => i + 1);

    // Replacing the sorted numbers with the consecutive sequence
    outputString = outputString.replace(
      regex,
      () => `{{${consecutiveSequence.shift()}}}`
    );

    return outputString;
  }

  isCorrectOrder(inputString: string): boolean {
    // Regular expression to match numbers enclosed within {{}} brackets
    const regex = /{{(\d+)}}/g;

    // Extracting numbers from the string and storing them in an array
    const numbers: number[] = [];
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      numbers.push(parseInt(match[1]));
    }

    // Checking if the numbers are in ascending order
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] !== numbers[i - 1] + 1) {
        return false;
      }
    }

    return true;
  }

  validateUserText(input: string): boolean {
    const placeholderRegex = /\{\{\d+\}\}/g;
    const matches = input.match(placeholderRegex);
 
    if (!matches) {
      return true; // No placeholders found, input is valid
    }
 
    let lastIndex = 0;
    for (let i = 0; i < matches.length; i++) {
      const currentIndex = input.indexOf(matches[i], lastIndex);
      if (i > 0 && input.substring(lastIndex, currentIndex).trim() === '') {
        return false; // Side-by-side placeholders found
      }
      lastIndex = currentIndex + matches[i].length;
    }
    return true; // Valid input with placeholders correctly separated by text
  }
 
}
