import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError } from 'rxjs';

export interface ReportTemplateCreatePayload {
  account_id: string;
  bsp_id: string;
  channel: string;
  waba_number: string;
  created_by: string;
  template_name: string;
  category: string;
  report_type: string;
  properties: string[];
}

export interface ReportTemplateVariables {
  account_id: string;
  channel: string;
  wabano: string;
  template_name?: string;
  report_name?: string;
  category?: string;
  startdate?: string;
  enddate?: string;
  page?: number;
  size?: number;
}

export interface ReportListVariables {
  account_id: string;
  channel: string;
  wabano: string;
  template_name?: string;
  report_name?: string;
  category?: string;
  startdate?: string;
  enddate?: string;
  page?: number;
  size?: number;
}

export interface ReportTemplatesListPayload {
  status_code: number;
  message: string;
  data: ReportTemplatesListData;
}

export interface ReportTemplatesListData {
  count: number;
  templates_data: TemplatesDatum[];
}

export interface TemplatesDatum {
  id: string;
  account_id: string;
  channel: string;
  bsp_id: string;
  waba_number: string;
  template_name: string;
  report_type: string;
  created_by: string;
  category: string;
  status: string;
  properties: string;
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at: Date | string;
}

export interface ReportListPayload {
  status_code: number;
  message: string;
  data: ReportListData;
}

export interface ReportListData {
  count: number;
  reports_data: ReportsDatum[];
}

export interface ReportsDatum {
  id: string;
  account_id: string;
  channel: string;
  bsp_id: string;
  waba_number: string;
  report_name: string;
  report_type: string;
  created_by: string;
  category: string;
  status: string;
  properties: string;
  location_uri: string;
  campaign_ids: string;
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at: Date | string;
}

export interface ViewSummaryReportsData {
  status_code: number;
  message: string;
  data: SummaryData;
}

export interface SummaryData {
  count: number;
  analytics_data: number;
  status_counts: StatusCounts;
}

export interface StatusCounts {
  promotional: StatusInfo;
  marketing: StatusInfo;
  utility: StatusInfo;
}

export interface StatusInfo {
  submitted: number;
  sent: number;
  optout: number;
  delivered: number;
  read: number;
  failed: number;
  pending: number;
  unread: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  createReportTemplate(payload: ReportTemplateCreatePayload) {
    const url = environment.backendurl + `/api/reports/create-template`;

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fetchReportTemplates(payload: ReportTemplateVariables) {
    const url =
      environment.backendurl +
      `/api/reports/view-templates/${payload.account_id}/${payload.channel}`;

    let params = new HttpParams();
    if (payload.template_name) {
      params = params.set('template_name', payload.template_name);
    }
    if (payload.wabano) {
      params = params.set('waba_number', payload.wabano);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.category) {
      params = params.set('category', payload.category);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateReportTemplate(id: string, payload: any) {
    const url = environment.backendurl + `/api/reports/update-templates/${id}`;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteReportTemplate(payload: any) {
    const url = environment.backendurl + `/api/reports/delete-templates`;

    return this.http.delete(url, { body: payload }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createReport(payload: any) {
    const url = environment.backendurl + `/api/reports/create-report`;

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fetchReport(payload: ReportListVariables) {
    const url =
      environment.backendurl +
      `/api/reports/view-reports/${payload.account_id}/${payload.channel}`;
    let params = new HttpParams();
    if (payload.wabano) {
      params = params.set('waba_number', payload.wabano);
    }
    if (payload.report_name) {
      params = params.set('report_name', payload.report_name);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.category) {
      params = params.set('category', payload.category);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateReport(id: string, payload: any) {
    const url = environment.backendurl + `/api/reports/update-reports/${id}`;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteReports(payload: any) {
    const url = environment.backendurl + `/api/reports/delete-reports`;

    return this.http.delete(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  viewSummaryReports(payload: ReportTemplateVariables) {
    const url =
      environment.backendurl +
      `/api/analytics/view-summary-reports/${payload.account_id}`;

    let params = new HttpParams();
    if (payload.wabano) {
      params = params.set('waba_number', payload.wabano);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteSummaryReport(filename: string) {
    const url = environment.backendurl + `/api/analytics/delete/${filename}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
