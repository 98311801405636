import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTemplate from './template.reducers';

export const selectActions =
  createFeatureSelector<fromTemplate.State>('templateActions');

export const selectTemplatePreviewPayload =
  createFeatureSelector<fromTemplate.TemplatePreviewState>(
    'templatePreviewPayload'
  );

export const selectActionsData = createSelector(
  selectActions,
  fromTemplate.getTemplateData
);

export const selectTemplatePreviewPayloadData = createSelector(
  selectTemplatePreviewPayload,
  fromTemplate.gettemplatePreviewPayload
);
