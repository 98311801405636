import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Channels, channels } from 'src/app/appdata/shared.data';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, BehaviorSubject, Subscription } from 'rxjs';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';
import { AlertService } from 'src/app/alert/alert.service';
import { Role } from 'src/app/appdata/roles.model';
import { customerPermissionsData } from 'src/app/control-center/role-management/role.data';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharedService, RoleVariables } from 'src/app/services/shared.service';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import { Router } from '@angular/router';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { selectRoleDataDtls } from '../store/role.selectors';
import { RbacPolicies } from 'src/app/appdata/auth.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
})
export class RoleListComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  rolePermissions: any = JSON.parse(JSON.stringify(customerPermissionsData));
  inactivateItem: any;
  deleteItem: any;
  rolesAclList = new BehaviorSubject(Object.keys(this.rolePermissions));
  channelList: Channels[] = channels;
  isLoading = true;
  page = 1;
  pageSize = 10;
  searchData: string = '';
  totalRolesList: number;
  updateRoleItem: any;
  rolesdata: Role[] = [];
  templateData: any;
  isUpdateRole: boolean = false;
  permissions: RbacPolicies;
  skeletonData = skeleton_data;
  userDetails: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  RoleDateFilterSubscription: Subscription;
  displayedColumns: string[] = [
    'name',
    'description',
    // 'users',
    'controls',
    'created',
    'update',
    // 'actions',
  ];

  role_payload_var: RoleVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    page: this.page,
    size: this.pageSize,
    name: null,
    startdate: null,
    enddate: null,
  };

  dataSource = new MatTableDataSource(this.rolesdata);

  @ViewChild('noRoles') private noRoles: TemplateRef<any>;
  @ViewChild('showRoles') private showRoles: TemplateRef<any>;
  @ViewChild('datawaiting') private datawaiting: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private readonly store: Store,
    private alertMsg: AlertService,
    private router: Router,
    private shareservice: SharedService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.role_payload_var.accountid = value.channel_credentials.account_id;
        this.role_payload_var.bspid = value.channel_credentials.bsp_id;
        this.role_payload_var.wabano = value.channel_credentials.waba_number;
        this.role_payload_var.channel =
          value.channel_credentials.channel_name.toLowerCase();
      }
    });
    this.store.dispatch(
      roleactions.fetchRoleslist({ payload: this.role_payload_var })
    );

    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
        }
      });

    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.roleList) {
          this.isLoading = false;
          this.rolesdata = JSON.parse(JSON.stringify(res.roleList.data.rbacs));
          this.dataSource.data = this.rolesdata;
          this.totalRolesList = res.roleList.data.count;
        } else if (res.deleteRoleRes && res.deleteRoleRes.status_code === 200) {
          this.modalService.dismissAll();
          this.store.dispatch(
            roleactions.fetchRoleslist({ payload: this.role_payload_var })
          );
          this.alertMsg.alertDanger('Success', res.deleteRoleRes.message);
        } else if (res.error) {
          this.isLoading = false;
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });

    this.RoleDateFilterSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          this.role_payload_var = JSON.parse(
            JSON.stringify(this.role_payload_var)
          );
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;

            this.role_payload_var.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.role_payload_var.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;

            this.store.dispatch(
              roleactions.fetchRoleslist({ payload: this.role_payload_var })
            );
          }
          if (value.action === 'Date Cleared') {
            this.role_payload_var.startdate = null;
            this.role_payload_var.enddate = null;

            this.store.dispatch(
              roleactions.fetchRoleslist({ payload: this.role_payload_var })
            );
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.showRoles;
    this.cd.detectChanges();
  }

  //Method to search
  onSearchNameChange(event: any) {
    if (!event) {
      this.role_payload_var = JSON.parse(JSON.stringify(this.role_payload_var));
      this.role_payload_var.name = null;
      this.store.dispatch(
        roleactions.fetchRoleslist({ payload: this.role_payload_var })
      );
    } else {
      this.searchName(event);
    }
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      this.role_payload_var = JSON.parse(JSON.stringify(this.role_payload_var));
      this.role_payload_var.name = data.trim();
      this.store.dispatch(
        roleactions.fetchRoleslist({ payload: this.role_payload_var })
      );
    }
  }

  isObject(val: any): boolean {
    return typeof val === 'object';
  }

  onCreateRole() {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'roles',
      'create',
    ]);
  }

  onEditRole(data: any) {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'roles',
      data.id,
      'edit',
    ]);
  }

  updaterolePermissions() {
    // Array of keys to include in the new object
    const keysToInclude = this.rolesAclList.value;

    // Create a new object with only the specified keys
    const modifiedObject: any = {};

    keysToInclude.forEach((key) => {
      if (
        Object.prototype.hasOwnProperty.call(
          this.rolePermissions.hasOwnProperty,
          key
        )
      ) {
        modifiedObject[key] = this.rolePermissions[key];
      }
    });

    return modifiedObject;
  }

  viewRole(data: any) {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'roles',
      data.id,
      'view',
    ]);
  }

  getListControls(data: any) {
    // const payload = JSON.parse(data);
    const payload = data;
    const permissionObj = [];
    for (const key in payload) {
      if (
        Object.prototype.hasOwnProperty.call(payload, key) &&
        Object.prototype.hasOwnProperty.call(payload[key], 'full_control') &&
        payload[key].full_control === true
      ) {
        permissionObj.push(key);
      }
      if (
        Object.prototype.hasOwnProperty.call(payload, key) &&
        payload[key] === true
      ) {
        permissionObj.push(key);
      }
    }
    const totalcount = Object.keys(payload).length;
    const permissionscount = permissionObj.length;
    return `${permissionscount}/${totalcount}`;
  }

  deleteRole(data: any) {
    this.store.dispatch(
      roleactions.initiateDeleteRole({
        roleid: data.id,
      })
    );
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    const sizes: any = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };
    if (data === 'inactivateRole') {
      this.inactivateItem = item;
    } else if (data === 'deleteRole') {
      this.deleteItem = item;
    }

    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  inactivate(item: any) {
    const updatepayload = {
      role_name: item.role_name,
      desc: item.desc,
      account_id: item.account_id,
      account_type: item.account_type,
      is_active: item.is_active,
      rbac_policies: this.rolePermissions,
    };

    this.store.dispatch(
      roleactions.updateRoleDetails({
        id: item.id,
        payload: updatepayload,
      })
    );
  }

  //Pagination Event Method
  onPageEvent(event: any) {
    this.page = event;
    this.role_payload_var = JSON.parse(JSON.stringify(this.role_payload_var));
    this.role_payload_var.page = this.page;
    this.role_payload_var.size = this.pageSize
    this.store.dispatch(
      roleactions.fetchRoleslist({ payload: this.role_payload_var })
    
    );
  }
  

  restoreStatus(item: any) {
    this.inactivateItem.is_active = !item.is_active;
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.RoleDateFilterSubscription.unsubscribe();
    this.shareservice.datePickerObject.next(null);
  }
}
