import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  sideNavAnimation,
  sideNavContainerAnimation,
} from './sidenav.animation';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { channels } from '../appdata/shared.data';
import { selectProfilesDtlsData } from '../store/common.selectors';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [sideNavAnimation, sideNavContainerAnimation],
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  isOpen = true;
  userDetails: any;
  selectedChannel: any;
  @Input() ForModule: string;
  permissions: any;
  channels = channels;

  displayTemplate!: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('showCustomer') private showCustomer!: TemplateRef<any>;
  @ViewChild('showControlCenter') private showControlCenter!: TemplateRef<any>;
  @ViewChild('showSuperAdmin') private showSuperAdmin!: TemplateRef<any>;
  @ViewChild('showPartner') private showPartner!: TemplateRef<any>;

  constructor(
    config: NgbModalConfig,
    private readonly store: Store,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions =
            res.loggedInUserDetails.profile.acl.rbac.rbac_policies;

          if (this.userDetails.account.ocpm_channel === 'WABA') {
            const indx = this.channels.findIndex(
              (t) => t.ch_name === 'WhatsApp Business API'
            );
            this.channels[indx].disabled = false;
            this.selectedChannel = this.channels[indx];
          }
        }
      });
  }

  ngAfterViewInit() {
    if (this.ForModule === 'Customer') {
      this.displayTemplate = this.showCustomer;
    } else if (this.ForModule === 'Control-Center') {
      this.displayTemplate = this.showControlCenter;
    } else if (this.ForModule === 'Super-Admin') {
      this.displayTemplate = this.showSuperAdmin;
    } else {
      this.displayTemplate = this.showPartner;
    }
    this.cd.detectChanges();
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, backdrop: true });
  }

  compareFn(item: any, selected: any) {
    return item.ch_name === selected.ch_name;
  }

  istoggle() {
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
