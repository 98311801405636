export interface NotificationMsg {
  image: string;
  name: string;
  designation: string;
  message: string;
  time: string | Date;
}

export const notificationMessages: NotificationMsg[] = [
  {
    image: 'assets/img/u1.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Added new "<strong>User</strong>" in "<strong>Sales Department</strong>" at "<strong>Tresmlabs</strong>"`,
    time: '20 min',
  },
  {
    image: 'assets/img/u2.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Created "<strong>Sales Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: '20 min',
  },
  {
    image: 'assets/img/u3.jpg',
    name: 'Ravi Raj',
    designation: 'Super Admin',
    message: `Added new "<strong>Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: '1D ago',
  },
  {
    image: 'assets/img/u4.jpg',
    name: 'Ravi Raj',
    designation: 'Super Admin',
    message: `Created "<strong>Sales Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: '1D ago',
  },
  {
    image: 'assets/img/u5.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Added new "<strong>User</strong>" in "<strong>Sales Department</strong>" at "<strong>Tresmlabs</strong>"`,
    time: '2D ago',
  },
  {
    image: 'assets/img/u1.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Created "<strong>Sales Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: '5D ago',
  },
  {
    image: 'assets/img/u2.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Added new "<strong>User</strong>" in "<strong>Sales Department</strong>" at "<strong>Tresmlabs</strong>"`,
    time: '2D ago',
  },
  {
    image: 'assets/img/u3.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Created "<strong>Sales Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: '5D ago',
  },
  {
    image: 'assets/img/u4.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Added new "<strong>User</strong>" in "<strong>Sales Department</strong>" at "<strong>Tresmlabs</strong>"`,
    time: '2D ago',
  },
  {
    image: 'assets/img/u4.jpg',
    name: 'Srinivas Sistla',
    designation: 'Role Manager',
    message: `Created "<strong>Sales Department</strong>" in "<strong>Tresmlabs</strong>"`,
    time: 'Ds ago',
  },
];
