import { Action, createReducer, on } from '@ngrx/store';
import * as templateactions from './template.actions';
import { TemplateData, TemplateListData } from 'src/app/appdata/template.model';

export interface State {
  templateList: TemplateListData;
  createtemplatestatus: any;
  updatetemplatestatus: any;
  deletetemplateres: any;
  templatedtls: TemplateData;
  error: any;
}

export const initialState: State = {
  templateList: null,
  createtemplatestatus: null,
  updatetemplatestatus: null,
  deletetemplateres: null,
  templatedtls: null,
  error: null,
};

export interface TemplatePreviewState {
  templatePayload: any;
  viewTemplateData: any;
  addSamplePayload: any;
  error: any;
}

export const initialTemplatePreviewState: TemplatePreviewState = {
  templatePayload: null,
  viewTemplateData: null,
  addSamplePayload: null,
  error: null,
};

const templateReducer = createReducer(
  initialState,
  on(
    templateactions.fetchtemplateslistSuccess,
    (state, { data }): State => ({
      ...state,
      templateList: data,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: null,
    })
  ),
  on(
    templateactions.fetchtemplateslistFailure,
    (state, { error }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: error,
    })
  ),
  on(
    templateactions.updatetemplateSuccess,
    (state, { data }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: data,
      deletetemplateres: null,
      templatedtls: null,
      error: null,
    })
  ),
  on(
    templateactions.updatetemplateFailure,
    (state, { error }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: error,
    })
  ),
  on(
    templateactions.createtemplateSuccess,
    (state, { data }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: data,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: null,
    })
  ),
  on(
    templateactions.createtemplateFailure,
    (state, { error }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: error,
    })
  ),
  on(
    templateactions.deletetemplateSuccess,
    (state, { data }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: data,
      templatedtls: null,
      error: null,
    })
  ),
  on(
    templateactions.deletetemplateFailure,
    (state, { error }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: error,
    })
  ),
  on(
    templateactions.resetTemplateData,
    (state): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: null,
    })
  ),
  on(
    templateactions.fetchtemplateDtlsSuccess,
    (state, { data }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: data,
      error: null,
    })
  ),
  on(
    templateactions.fetchtemplateDtlsFailure,
    (state, { error }): State => ({
      ...state,
      templateList: null,
      createtemplatestatus: null,
      updatetemplatestatus: null,
      deletetemplateres: null,
      templatedtls: null,
      error: error,
    })
  )
);

const templatepreviewReducer = createReducer(
  initialTemplatePreviewState,
  on(
    templateactions.createtemplatePayload,
    (state, { data }): TemplatePreviewState => ({
      ...state,
      templatePayload: data,
      viewTemplateData: null,
      addSamplePayload: null,
    })
  ),
  on(
    templateactions.templatePreviewData,
    (state, { data }): TemplatePreviewState => ({
      ...state,
      templatePayload: null,
      viewTemplateData: data,
      addSamplePayload: null,
    })
  ),
  on(
    templateactions.addsamplePreview,
    (state, { data }): TemplatePreviewState => ({
      ...state,
      templatePayload: null,
      viewTemplateData: null,
      addSamplePayload: data,
    })
  ),
  on(
    templateactions.resetcreatetemplatePayload,
    (state): TemplatePreviewState => ({
      ...state,
      templatePayload: null,
      viewTemplateData: null,
      addSamplePayload: null,
    })
  ),
  on(
    templateactions.resetTemplateData,
    (state): TemplatePreviewState => ({
      ...state,
      templatePayload: null,
      viewTemplateData: null,
      addSamplePayload: null,
    })
  ),
  on(
    templateactions.resettemplatePreviewData,
    (state): TemplatePreviewState => ({
      ...state,
      templatePayload: null,
      viewTemplateData: null,
      addSamplePayload: null,
    })
  )
);

export function reducer(state: State | undefined, action: Action): any {
  return templateReducer(state, action);
}

export function templatepreviewreducer(
  state: TemplatePreviewState | undefined,
  action: Action
): any {
  return templatepreviewReducer(state, action);
}

export const getTemplateData = (state: State) => {
  return {
    templateList: state.templateList,
    createtemplatestatus: state.createtemplatestatus,
    updatetemplatestatus: state.updatetemplatestatus,
    deletetemplateres: state.deletetemplateres,
    templatedtls: state.templatedtls,
    error: state.error,
  };
};

export const gettemplatePreviewPayload = (state: TemplatePreviewState) => {
  return {
    templatePayload: state.templatePayload,
    viewTemplateData: state.viewTemplateData,
    addSamplePayload: state.addSamplePayload,
  };
};
