import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AllBSPs,
  allBSPs,
  allRoles,
} from 'src/app/control-center/profiles-management/profiles-management.data';
import { MatStepper } from '@angular/material/stepper';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as profileactions from 'src/app/control-center/profiles-management/store/profiles.actions';
import { Role } from 'src/app/appdata/roles.model';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';
import * as commonactions from 'src/app/store/common.actions';
import { AlertService } from 'src/app/alert/alert.service';
import { RoleVariables, SharedService } from 'src/app/services/shared.service';
import { selectRoleDataDtls } from 'src/app/control-center/role-management/store/role.selectors';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { ProfileVariables } from 'src/app/services/profilemanagement.service';
import { Licenses } from 'src/app/appdata/auth.model';
import { Router } from '@angular/router';
import { selectProfileActionsData } from '../store/profiles.selectors';

export interface SliderValues {
  color: string;
  checked: boolean;
  disabled: boolean;
  labelPosition: string;
  status: string[];
}

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
})
export class AddProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  isLinear: boolean = true;
  basicDetailsForm: FormGroup;
  isCopied: boolean = false;
  showNewPass: boolean = false;
  showConfPass: boolean = false;
  isLoading: boolean = false;
  roleList: Role[] = [];
  imagePath: FileList;
  bspsList: AllBSPs[] = allBSPs;
  profileLicenses: Licenses;
  rolesList: string[] = allRoles;
  sel_bsps: Array<any> = [];
  sel_bas: Array<any> = [];
  bsps: Array<any> = [];
  wabanos: Array<any> = [];
  selectedProfile: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userDetails: any;
  sliderValue: SliderValues = {
    color: 'accent',
    checked: true,
    disabled: false,
    labelPosition: 'before',
    status: ['Active', 'Inactive'],
  };

  filterCriteria: any = {
    bsp: null,
    bs_acc_no: null,
    role: null,
  };

  account_dtls: ProfileVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    role: null,
    createdby: null,
    profileName: null,
    page: null,
    size: null,
    startdate: null,
    enddate: null,
  };

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('createForm') private createForm: TemplateRef<any>;
  @ViewChild('createSuccess') private createSuccess: TemplateRef<any>;

  constructor(
    private readonly store: Store,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private cv: CustomValidators,
    private alertMsg: AlertService,
    private router: Router,
    public shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.initiateFormSub();
    this.initiateUserProfileSub();
    this.initiateRoleDataSub();
    this.initiateCommonDataSub();
    this.initiateProfileDataSub();
    this.onCreateProfile();
  }

  initiateFormSub() {
    this.basicDetailsForm = this.fb.group(
      {
        profileImg: [null],
        firstName: [
          null,
          [Validators.required, Validators.pattern(this.cv.nameRegex)],
        ],
        lastName: [
          null,
          [Validators.required, Validators.pattern(this.cv.nameRegex)],
        ],
        email: [
          null,
          [Validators.required, Validators.pattern(this.cv.emailRegex)],
        ],
        mob_no: [
          null,
          [Validators.required, Validators.pattern(this.cv.contactRegex)],
        ],
        username: [null, [Validators.required]],
        designation: [null],
        role: [null, Validators.required],
        activerole: [null, Validators.required],
        channel: [null, Validators.required],
        bsp: [null, Validators.required],
        ban: [null, Validators.required],
        pass: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            this.cv.patternValidator(this.cv.capitalRegex, {
              hasCapitalCase: true,
            }),
            this.cv.patternValidator(this.cv.smallRegex, {
              hasSmallCase: true,
            }),
            this.cv.patternValidator(this.cv.digitRegex, { hasNumber: true }),
            this.cv.patternValidator(this.cv.specialCharRegex, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        cnfm_pass: [null, [Validators.required]],
      },
      {
        validators: this.cv.passwordMatchValidator,
      }
    );
  }

  initiateUserProfileSub() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          let activeaccount: any;
          if (abac_policies.waba_policies.length > 0) {
            activeaccount = this.userDetails.account.licenses.license_data.find(
              (t: any) =>
                t.channel_credentials.waba_number ===
                abac_policies.waba_policies[0].waba_number
            );
          }
          this.account_dtls.accountid = activeaccount.account_id;
          this.account_dtls.channel = activeaccount.channel.toLowerCase();
          this.account_dtls.bspid = activeaccount.channel_credentials.bsp_id;
          this.account_dtls.wabano =
            activeaccount.channel_credentials.waba_number;
          this.account_dtls.createdby = this.userDetails.profile.id;
        }
      });
  }

  initiateRoleDataSub() {
    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.roleList) {
          this.roleList = JSON.parse(JSON.stringify(res.roleList.data.rbacs));
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateCommonDataSub() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.uploadRes && res.uploadRes.status_code === 200) {
          this.basicDetailsForm
            .get('profileImg')
            .setValue(res.uploadRes.data.url);
        } else if (res.profilelicensedata) {
          if (res.profilelicensedata.status_code === 200) {
            this.profileLicenses = res.profilelicensedata.data;
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateProfileDataSub() {
    this.store
      .select(selectProfileActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.profiledata && res.profiledata.status_code === 201) {
          this.displayTemplate = this.createSuccess;
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  get profileFC() {
    return this.basicDetailsForm.controls;
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.createForm;
    this.cd.detectChanges();
  }

  onCreateProfile() {
    const rolePayload: RoleVariables = {
      accountid: this.account_dtls.accountid,
      bspid: null,
      wabano: null,
      channel: null,
      page: 1,
      size: 20,
      name: null,
      startdate: null,
      enddate: null,
    };
    this.store.dispatch(roleactions.fetchRoleslist({ payload: rolePayload }));
    this.userDetails.account.licenses.license_data.forEach((t: any) => {
      if (this.bsps.indexOf(t.bsp) === -1) {
        this.bsps.push(t.bsp);
      }
    });
    this.wabanos = this.userDetails.account.licenses.license_data;
  }

  onCancelCreateProfile() {
    this.goToProfileList();
  }

  goToProfileList() {
    this.router.navigate(['cc', this.userDetails.profile.id, 'profiles']);
    this.basicDetailsForm.reset();
  }

  onAvatarUpload(event: any) {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    if (files.length > 0) {
      this.shareservice.validateUploadImage(files);
    }
  }

  onAvatarRemove() {
    this.basicDetailsForm.get('profileImg').setValue(null);
    this.isLoading = false;
  }

  selectedBsps(event: any) {
    if (event.checked) {
      this.sel_bsps.push(event.source.value);
    } else {
      this.sel_bsps = this.sel_bsps.filter(
        (t: any) => t !== event.source.value
      );
    }
    if (this.sel_bsps.length > 0) {
      this.basicDetailsForm.get('bsp').setValue(this.sel_bsps);
    }
  }

  selectedBAs(event: any) {
    if (event.checked) {
      const acc = this.userDetails.account.licenses.license_data.find(
        (t: any) => t.channel_credentials.waba_number === event.source.value
      );
      this.sel_bas.push(acc);
    } else {
      this.sel_bas = this.sel_bas.filter(
        (t: any) => t.channel_credentials.waba_number !== event.source.value
      );
    }
    if (this.sel_bas.length > 0) {
      this.basicDetailsForm.get('ban').setValue(this.sel_bas);
    }
  }

  onRolesSelect(event: any) {
    if (event.length > 0) {
      this.basicDetailsForm.get('channel').setValue('waba');
    }
  }

  onAddProfile() {
    const wabapolices: any = [];
    this.basicDetailsForm.get('ban').value.forEach((t: any) => {
      if (t.channel === 'WABA') {
        wabapolices.push({
          account_id: t.channel_credentials.account_id,
          bsp_id: t.channel_credentials.bsp_id,
          bsp_name: t.channel_credentials.bsp_name,
          waba_id: t.channel_credentials.waba_id,
          waba_number: t.channel_credentials.waba_number,
          is_default: false,
        });
      }
    });

    const create_payload: any = {
      account_id: this.account_dtls.accountid,
      first_name: this.basicDetailsForm.get('firstName').value,
      last_name: this.basicDetailsForm.get('lastName').value,
      profile_image_url: this.basicDetailsForm.get('profileImg').value,
      username: this.basicDetailsForm.get('username').value,
      email: this.basicDetailsForm.get('email').value,
      mobile_number: this.basicDetailsForm.get('mob_no').value,
      password: this.basicDetailsForm.get('cnfm_pass').value,
      designation: this.basicDetailsForm.get('designation').value,
      acl: {
        rbac: this.basicDetailsForm.get('activerole').value,
        rbac_list: this.basicDetailsForm.get('role').value,
        abac: {
          abac_policies: {
            waba_policies: wabapolices,
            rcs_policies: [],
            telegram_policies: [],
            viber_policies: [],
            instagram_policies: [],
          },
        },
      },
    };
    this.store.dispatch(
      profileactions.createProfile({ payload: create_payload })
    );
  }

  getRoles(data: Array<any>) {
    const rolenames: any = [];
    data.forEach((t: any) => {
      rolenames.push(t.role_name);
    });
    return rolenames.toString();
  }

  copyCode() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.store.dispatch(commonactions.clearuploadFile());
  }
}
