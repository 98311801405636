import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

export const allCharts: string[] = ['Bar Graph', 'Line Graph'];
// export const allCharts: string[] = ['Bar Graph', 'Pie Chart', 'Line Graph'];

// Line-Chart Graph
export class LineChartData {
  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: { tension: 0, borderWidth: 2 },
      point: { 
        radius: 4, 
        hoverRadius: 8,
        borderWidth: 2,
        backgroundColor: '#00D1BD',
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { display: true },
        reverse: true,
      },
      y: {
        min: 0,
        title: {
          display: true,
          text: 'Counts',
          color: '#00D1BD',
          font: { weight: 'bold' },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 20,
          boxHeight: 20,
        },
      },
    },
  };
  lineChartLabels: string[] = [];
  lineChartType: ChartType = 'line';
  lineChartData: ChartData<'line'> = {
    labels: [],
    datasets: [],
  };
}
// END Line-Chart Graph

// Pie-Chart Graph
export class PieChartData {
  pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: { boxWidth: 20, boxHeight: 20 },
      },
    },
  };
  pieChartPlugins = [DatalabelsPlugin];
  pieChartType: ChartType = 'pie';
  pieChartData: ChartData<'pie'> = {
    labels: [
      'Submitted',
      'Opt-out',
      'Sent',
      'Delivered',
      'Read',
      'Unread',
      'Pending',
      'Failed',
    ],
    datasets: [
      {
        data: [260, 60, 260, 200, 100, 50, 50, 60],
        backgroundColor: [
          '#00A8BA',
          '#F89C29',
          '#65758A',
          '#9EFF97',
          '#1184D3',
          '#00D1BD',
          '#D463F0',
          '#FA5948',
        ],
      },
    ],
  };
}
// END Pie-Chart Graph

// Bar-Chart Graph
export class BarChartData {
  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    datasets: {
      bar: {
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderRadius: 5,
        borderSkipped: 'bottom',
        maxBarThickness: 40,
        minBarLength: 10,
      }
    },
    elements: { bar: { borderRadius: 10 } },
    scales: {
      x: { stacked: true, grid: { display: false }, ticks: { display: true }, reverse: true },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Counts',
          color: '#00D1BD',
          font: { weight: 'bold' },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: { boxWidth: 20, boxHeight: 20, borderRadius: 10 },
      },
    },
  };
  barChartType: ChartType = 'bar';
  barChartLabels: string[] = [];
  barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [],
  };
}
// END Bar-Chart Graph

export const skeletonData = [
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
  {
    'border-radius': '25px',
    height: '36px',
    'margin-top': '24px',
  },
];
