// Iterfaces
export interface AllChannels {
    channelUrl: string;
    channelName: string;
}

export interface AllBSPs {
    bspUrl: string;
    bspName: string;
}

export interface AllProfiles {
    name: string;
    profileUrl: string;
    email: string;
    mob_no?: number,
    designation?: string,
    role: string[];
    bsp: string[];
    businessAccounts: AllBusinessAccount[];
    created_on: string;
    isChecked: boolean,

}

export interface AllBusinessAccount {
    ba_profileUrl: string,
    ba_name: string;
    ba_number: number;
    isActive: boolean;
}
// END Iterfaces



// Data
export const allChannels: AllChannels[] = [
    {
        channelUrl: 'assets/img/whatsapp.svg',
        channelName: 'Whatsapp'
    },
    {
        channelUrl: 'assets/img/rcs.svg',
        channelName: 'RCS'
    },
    {
        channelUrl: 'assets/img/telegram.svg',
        channelName: 'Telegram'
    },
    {
        channelUrl: 'assets/img/viber.svg',
        channelName: 'Viber'
    },
];

export const allBSPs: AllBSPs[] = [
    {
        bspUrl: 'assets/img/rml.svg',
        bspName: 'RML'
    }
];


export const allBusinessAccounts: AllBusinessAccount[] = [
    {
        ba_profileUrl: 'assets/img/chola.svg',
        ba_name: 'Cholamandalam Finance',
        ba_number: +919912341590,
        isActive: true
    },
    {
        ba_profileUrl: 'assets/img/chola.svg',
        ba_name: 'Cholamandalam Finance 2',
        ba_number: +919912341691,
        isActive: false
    },
    {
        ba_profileUrl: 'assets/img/chola.svg',
        ba_name: 'Cholamandalam Homeloan',
        ba_number: +919912341122,
        isActive: false
    },
    {
        ba_profileUrl: 'assets/img/chola.svg',
        ba_name: 'Cholamandalam Vehicle Finance',
        ba_number: 9000090000,
        isActive: false
    },
    {
        ba_profileUrl: 'assets/img/chola.svg',
        ba_name: 'Cholamandalam Personal Loans',
        ba_number: +919913415920,
        isActive: false
    },

];

export const allRoles: string[] = [
    'Developer', 'Campaign Manager', 'Template Manager', 'Reports Manager', 'Business Admin', 'Business Analyst'
]


export const allProfiles: AllProfiles[] = [
    {
        name: 'Supriya K',
        profileUrl: 'assets/img/default_avatar.jpg',
        email: 'supriya.k@nuacem.com',
        mob_no: 9000090000,
        designation: 'Product Manager',
        role: ['Business Admin'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Finance',
                ba_number: 9000090000,
                isActive: true
            }
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
    {
        name: 'Arpit Awasthi',
        profileUrl: 'assets/img/default_avatar.jpg',
        mob_no: 9000090000,
        designation: 'Product Manager',
        email: 'arpit@nuacem.com',
        role: ['Business Analyst'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Homeloan',
                ba_number: 9000090000,
                isActive: false
            },
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
    {
        name: 'Pramod',
        profileUrl: 'assets/img/default_avatar.jpg',
        email: 'pramod@nuacem.com',
        mob_no: 9000090000,
        designation: 'Product Manager',
        role: ['Campaign Manager', 'Developer', 'Template Manager'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Vehicle Finance',
                ba_number: 9000090000,
                isActive: false
            }
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
    {
        name: 'Praneeth P',
        profileUrl: 'assets/img/default_avatar.jpg',
        email: 'praneeth.p@nuacem.com',
        mob_no: 9000090000,
        designation: 'Product Manager',
        role: ['Reports Manager'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Finance 2',
                ba_number: 9000090000,
                isActive: false
            }
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
    {
        name: 'Ravi Raj',
        profileUrl: 'assets/img/default_avatar.jpg',
        email: 'ravi@nuacem.com',
        mob_no: 9000090000,
        designation: 'Product Manager',
        role: ['Campaign Manager'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Personal Loans',
                ba_number: 9000090000,
                isActive: false
            }
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
    {
        name: 'Naveen A',
        profileUrl: 'assets/img/default_avatar.jpg',
        email: 'naveen.a@nuacem.com',
        mob_no: 9000090000,
        designation: 'Product Manager',
        role: ['Reports Manager'],
        bsp: ['assets/img/rml.svg'],
        businessAccounts: [
            {
                ba_profileUrl: 'assets/img/chola.svg',
                ba_name: 'Cholamandalam Personal Loans 2',
                ba_number: 9000090000,
                isActive: false
            }
        ],
        created_on: '20-08-2023',
        isChecked: false
    },
];
// END Data