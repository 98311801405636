import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from './login.reducers';

export const selectAuthUser = createFeatureSelector<fromAuth.State>('loginRes');

export const selectAuthUserData = createSelector(
  selectAuthUser,
  fromAuth.loginUserData
);
