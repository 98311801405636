import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { selectProfilesDtlsData } from '../store/common.selectors';
import { AuthUserData, RbacPolicies } from '../appdata/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
})
export class PagenotfoundComponent implements OnInit, OnDestroy, AfterViewInit {
  Url: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  user_profile: AuthUserData;
  permissions: RbacPolicies;
  displayTemplate = new BehaviorSubject<TemplateRef<string>>(null);

  @ViewChild('invalidPage') private invalidPage: TemplateRef<string>;
  @ViewChild('noAccess') private noAccess: TemplateRef<string>;

  constructor(
    private router: Router,
    private readonly store: Store,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.user_profile = res.loggedInUserDetails;
          this.permissions = this.user_profile.profile.acl.rbac.rbac_policies;
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.router.url.includes('noaccess')) {
      this.displayTemplate.next(this.noAccess);
    } else {
      this.displayTemplate.next(this.invalidPage);
    }
    this.cd.detectChanges();
  }

  navigateBasedOnPermissions(
    module_permissions: AuthUserData,
    profileId: string
  ) {
    if (module_permissions.account.account_type === 'CUSTOMER') {
      if (module_permissions.account.ocpm_channel === 'WABA') {
        const channeldata =
          module_permissions.profile.acl.abac.abac_policies.waba_policies.find(
            (t: any) => t.is_default === true
          );
        if (channeldata) {
          this.router.navigate(['customer/' + profileId + '/dashboard']);
        } else {
          this.router.navigate(['customer/' + profileId + '/product']);
        }
      }
    }
  }

  navigateTo() {
    if(this.user_profile && this.user_profile.profile && this.user_profile.profile.id)
    {
      const profileId = this.user_profile.profile.id;
      this.navigateBasedOnPermissions(this.user_profile, profileId);
    }
    else{
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
