import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-profile-management',
  templateUrl: './sa-profile-management.component.html',
  styleUrls: ['./sa-profile-management.component.scss']
})
export class SaProfileManagementComponent {

}
