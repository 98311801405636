<div class="alert-wrapper">
    <div *ngFor="let dataPayload of alertList" class="alert" [ngClass]="{
      'alert-success': dataPayload?.alertType === 'success',
      'alert-error': dataPayload?.alertType === 'danger',
      'alert-warning': dataPayload?.alertType === 'warning'
    }">
        <i class="alert-icon" [ngClass]="{
        'bi-check-circle': dataPayload?.alertType === 'success',
        'bi-x-circle': dataPayload?.alertType === 'danger',
        'bi-exclamation-circle': dataPayload?.alertType === 'warning'
      }">
        </i>
        <div class="alert-details">
            <h5 class="alert-title">{{ dataPayload?.title }}</h5>
            <p class="alert-sub-title">{{ dataPayload?.subTitle }}</p>
        </div>
        <i class="bi bi-x-lg alert-cancel" (click)="closeAlert(dataPayload)"></i>
    </div>
</div>