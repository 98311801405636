<div class="error-page h-100">
  <div class="content container-fluid align-items-center py-10 m-auto">
    <ng-container [ngTemplateOutlet]="displayTemplate | async"></ng-container>
    <ng-template #invalidPage>
      <div class="logo-er p-2" (click)="navigateTo()">
        <img class="w-75" src="assets/img/main_logo.png" alt="Smartping AI" />
      </div>
      <div class="row align-items-sm-center py-10">
        <div class="text-center px-3">
          <h1 class="title mb-0">404</h1>
          <h4 class="title-secondary mb-0">OOPs! Something went wrong.</h4>
          <p class="title-3">
            We can't seem to find the page you're looking for.
          </p>
          <button
            type="button"
            class="btn btn-lg btn-light"
            (click)="navigateTo()"
          >
            Back to Home
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template #noAccess>
      <div class="logo-er p-2">
        <img class="w-75" src="assets/img/main_logo.png" alt="Smartping AI" />
      </div>
      <div class="row align-items-sm-center py-10">
        <div class="text-center px-3">
          <h1 class="title mb-0">No Access</h1>
          <h4 class="title-secondary mb-0">OOPs! You Do not have Access.</h4>
          <p class="title-3">Contact Admin for Access.</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
