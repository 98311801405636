import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  AllBusinessSettings,
  allBusinessSettings,
} from 'src/app/appdata/shared.data';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Datum } from 'src/app/appdata/channeldata.model';
import { SharedService } from 'src/app/services/shared.service';
import { skeleton_data } from '../whatsapp-preview/whatsapp-preview.data';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  allBusinessSettingsData: AllBusinessSettings = allBusinessSettings;
  displayTemplate!: TemplateRef<any>;
  isCopied: boolean = false;
  active = 1;
  channel_credentails: Datum;
  destroy$: Subject<boolean> = new Subject<boolean>();
  waba_policies: Array<any> = [];
  bspslist: Array<any> = [];
  channelSub: Subscription;
  skeletonData = skeleton_data;
  userDetails: any;

  @ViewChild('showDetails') private showDetails!: TemplateRef<any>;

  constructor(
    private readonly store: Store,
    private cd: ChangeDetectorRef,
    public loader: LoaderService,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
        }
      });

    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
      }
    });
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.showDetails;
    this.cd.detectChanges();
  }

  AuthDetails(data: any, action: string) {
    let credentialmeta = JSON.parse(data);
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (action === 'username') {
      return credentialmeta.metaData.username;
    } else if (action === 'password') {
      return credentialmeta.metaData.password;
    }
  }

  copyCode() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
