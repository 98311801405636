import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectCommonDataDtls } from '../store/common.selectors';
import { AlertService } from '../alert/alert.service';
import { WhitelabelingData } from '../appdata/initialwlb.data';

declare let FB: any;

@Component({
  selector: 'app-fb-embedded',
  templateUrl: './fb-embedded.component.html',
  styleUrls: ['./fb-embedded.component.scss']
})
export class FbEmbeddedComponent implements OnInit, AfterViewInit, OnDestroy{

  showLoginLoader: boolean = false;
  whiteLabeledData: any = WhitelabelingData;
  displayTemplate!: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('showLogin') private showLogin!: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    private readonly store: Store
  ) {}
  ngOnInit(): void {
    this.subscribeToWblStateData();
    this.initFacebookSDK();
  }

  subscribeToWblStateData() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.wlbres) {
          this.whiteLabeledData = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.whiteLabeledData === 'string') {
            this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
          }
        } else if (res.error) {
          const dispErr = res.error.error.message;
          this.handleLoginError(dispErr);
        }
      });
  }

  handleLoginError(msg: string) {
    this.alertMsg.alertDanger('Error', msg);
  }

  ngAfterViewInit() {
    this.displayTemplate = this.showLogin;
    this.cd.detectChanges();
  }

  fbSignUp()
  {
    this.launchWhatsAppSignup();
    window.addEventListener('message', this.sessionInfoListener);
  }
  initFacebookSDK() {
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: '851978463043361', // Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v18.0' // Graph API version
      });
    };
    (function(d, s, id){
      let js:any = d.getElementsByTagName(s)[0];
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  // Facebook Login with JavaScript SDK
	launchWhatsAppSignup() {
    // Launch Facebook login
		FB.login((response:any) => {
			if (response.authResponse) {
				//sessionInfoListener();
				const accessToken = response.authResponse.accessToken;
        //Use this token to call the debug_token API and get the shared WABA's ID
			} else {
        //sessionInfoListener();
				console.log('User cancelled login or did not fully authorize.');
			}
		}, {
			scope: 'whatsapp_business_management, whatsapp_business_messaging',
      config_id: '709959344325710', // configuration ID goes here
      response_type: 'code',    // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        feature: 'whatsapp_embedded_signup',
        version: 2,
        sessionInfoVersion: 2,
        setup: {
          solutionID: '909888600763336'
        }
      }
    });
	}
  sessionInfoListener (event: MessageEvent) {
		if (event.origin !== "https://www.facebook.com") return;
		try {
			const data = JSON.parse(event.data);
			if (data.type === 'WA_EMBEDDED_SIGNUP') {
				// if user finishes the Embedded Signup flow
				if (data.event === 'FINISH') {
					const {phone_number_id, waba_id} = data.data;
					
					const bodyData = {'phone_number_id': data.data.phone_number_id, 'waba_id': data.data.waba_id};
					this.postJSON(bodyData);
				}
				// if user cancels the Embedded Signup flow
				else {
					const{current_step} = data.data;
					console.log("Cancel event",{current_step});
				}
			}
		} catch {
			// Don’t parse info that’s not a JSON
			console.log('Non JSON Response', event.data);
		}
	}
  async postJSON(data: any) {
    try {
      const response = await fetch('https://api.nuacem.com/waba/sp/register', {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify(data),
        cache: 'default'
      });
      const result = await response.json();
    } catch (error) {
      return error;
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.showLoginLoader = false;
  }
}
