import { Action, createReducer, on } from '@ngrx/store';
import * as authactions from './login.actions';

export interface State {
  loginres: any;
  forgotPswdRes: any;
  error: any;
}

export const initialState: State = {
  loginres: null,
  forgotPswdRes: null,
  error: null,
};

const authReducer = createReducer(
  initialState,
  on(
    authactions.authenticateUserSuccess,
    (state, { data }): State => ({
      ...state,
      loginres: data,
      forgotPswdRes: null,
      error: null,
    })
  ),
  on(
    authactions.authenticateUserFailure,
    (state, { error }): State => ({
      ...state,
      loginres: null,
      forgotPswdRes: null,
      error: error,
    })
  ),
  on(
    authactions.logoutUser,
    (state): State => ({
      ...state,
      loginres: null,
      forgotPswdRes: null,
      error: null,
    })
  ),
  on(
    authactions.forgotPasswordSuccess,
    (state, { data }): State => ({
      ...state,
      loginres: null,
      forgotPswdRes: data,
      error: null,
    })
  ),
  on(
    authactions.forgotPasswordFailure,
    (state, { error }): State => ({
      ...state,
      loginres: null,
      forgotPswdRes: null,
      error: error,
    })
  )
);

export function loginreducer(state: State | undefined, action: Action): any {
  return authReducer(state, action);
}

export const loginUserData = (state: State) => {
  return {
    loginres: state.loginres,
    forgotPswdRes: state.forgotPswdRes,
    error: state.error,
  };
};
