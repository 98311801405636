import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { Router } from '@angular/router';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as commonactions from 'src/app/store/common.actions';
import { AuthUserData } from 'src/app/appdata/auth.model';
import { AlertService } from 'src/app/alert/alert.service';
import { ProfileVariables } from 'src/app/services/profilemanagement.service';
import * as profileactions from 'src/app/control-center/profiles-management/store/profiles.actions';
import * as authactions from 'src/app/login/store/login.actions';
import { selectProfileActionsData } from 'src/app/control-center/profiles-management/store/profiles.selectors';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-myprofile-edit',
  templateUrl: './myprofile-edit.component.html',
  styleUrls: ['./myprofile-edit.component.scss'],
})
export class MyprofileEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  myProfileFormGroup: FormGroup;
  myProfilePwdFormGroup: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showPass: boolean = false;
  imagePath: FileList;
  showNewPass: boolean = false;
  showConfPass: boolean = false;
  userDetails: AuthUserData;
  displayTemplate: TemplateRef<any>;
  isPswdChange: boolean = false;

  account_dtls: ProfileVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    role: null,
    profileName: null,
    createdby: null,
    page: null,
    size: null,
    startdate: null,
    enddate: null,
  };

  @ViewChild('editmyprofile') private editmyprofile: TemplateRef<any>;
  @ViewChild('editpassword') private editpassword: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private cv: CustomValidators,
    private router: Router,
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    private readonly store: Store,
    public shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.myProfileFormGroup = this.fb.group({
      profileImg: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      mobile: [null, Validators.required],
      designation: [null],
    });

    this.myProfilePwdFormGroup = this.fb.group({
      newPass: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          this.cv.patternValidator(this.cv.capitalRegex, {
            hasCapitalCase: true,
          }),
          this.cv.patternValidator(this.cv.smallRegex, {
            hasSmallCase: true,
          }),
          this.cv.patternValidator(this.cv.digitRegex, { hasNumber: true }),
          this.cv.patternValidator(this.cv.specialCharRegex, {
            hasSpecialCharacters: true,
          }),
        ],
      ],
      confNewPass: [null, [Validators.required]],
    });

    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.onEdit();
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          let activeaccount: any;
          if (abac_policies.waba_policies.length > 0) {
            activeaccount = this.userDetails.account.licenses.license_data.find(
              (t: any) =>
                t.channel_credentials.waba_number ===
                abac_policies.waba_policies[0].waba_number
            );
          }
          this.account_dtls.accountid = activeaccount.account_id;
          this.account_dtls.channel = activeaccount.channel.toLowerCase();
          this.account_dtls.bspid = activeaccount.channel_credentials.bsp_id;
          this.account_dtls.wabano =
            activeaccount.channel_credentials.waba_number;
          this.account_dtls.createdby = this.userDetails.profile.id;
        }
      });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.uploadRes && res.uploadRes.status_code === 200) {
          this.myProfileFormGroup
            .get('profileImg')
            .setValue(res.uploadRes.data.url);
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });

    this.store
      .select(selectProfileActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.update_profile && res.update_profile.status_code === 201) {
          this.alertMsg.alertSuccess('Success!', res.update_profile.message);
          this.myProfilePwdFormGroup.reset();
          if (!this.isPswdChange) {
            this.store.dispatch(
              profileactions.fetchProfileData({
                accountid: this.account_dtls.accountid,
                profileid: res.update_profile.data.id,
              })
            );
          } else {
            this.store.dispatch(authactions.logoutUser());
            this.alertMsg.alertSuccess(
              'Success',
              'Login with the new Password'
            );
          }
        } else if (res.profiledata && res.profiledata.status_code === 200) {
          if (res.profiledata.data.id === this.userDetails.profile.id) {
            const auth_data = JSON.parse(localStorage.getItem('wabaauthdata'));
            auth_data.profile = res.profiledata.data;
            localStorage.setItem('wabaauthdata', JSON.stringify(auth_data));
            this.store.dispatch(commonactions.userdetails({ data: auth_data }));
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.router.url.includes('pswd')) {
      this.displayTemplate = this.editpassword;
    } else {
      this.displayTemplate = this.editmyprofile;
    }
    this.cd.detectChanges();
  }

  onBack() {
    this.myProfileFormGroup.reset();
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'my-profile',
    ]);
  }

  get myProfileFC() {
    return this.myProfileFormGroup.controls;
  }

  get myProfilePwdFC() {
    return this.myProfilePwdFormGroup.controls;
  }

  onEdit() {
    this.myProfileFormGroup.patchValue({
      profileImg: this.userDetails.profile.profile_image_url,
      firstName: this.userDetails.profile.first_name,
      lastName: this.userDetails.profile.last_name,
      mobile: this.userDetails.profile.mobile_number,
      designation: this.userDetails.profile.designation,
    });

    this.myProfilePwdFormGroup.patchValue({
      newPass: null,
      confNewPass: null,
    });
  }

  onAvatarUpload(event: any) {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    
    if (files.length > 0) {
      this.shareservice.validateUploadImage(files);
    }
  }

  onAvatarRemove() {
    this.myProfileFormGroup.get('profileImg').setValue(null);
  }

  //Form Submit method for changing the user basic details
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const basicinfo_payload = {
      id: this.userDetails.profile.id,
      profile_image_url: form.value.profileImg,
      first_name: form.value.firstName,
      last_name: form.value.lastName,
      mobile_number: form.value.mobile,
      designation: form.value.designation,
    };

    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: basicinfo_payload,
        id: this.userDetails.profile.id,
      })
    );
  }

  //Form Submit method for changing the user password
  onPswdChange(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const pswd_payload = {
      id: this.userDetails.profile.id,
      email: this.userDetails.profile.email,
      password: form.value.confNewPass,
      account_id: this.account_dtls.accountid,
    };

    this.isPswdChange = true;

    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: pswd_payload,
        id: this.userDetails.profile.id,
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.store.dispatch(commonactions.clearuploadFile());
  }
}
