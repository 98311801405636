import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-bsp',
  templateUrl: './sa-bsp.component.html',
  styleUrls: ['./sa-bsp.component.scss']
})
export class SaBspComponent {

}
