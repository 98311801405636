import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import * as consentactions from './consent.actions';
import { ConsentService } from 'src/app/services/consent.service';
import { ConsentData } from 'src/app/appdata/consent.model';

@Injectable()
export class ConsentEffects {
  constructor(
    private actions$: Actions,
    private consentservice: ConsentService
  ) {}

  getConsentData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.getconsentAction.fetchconsentdata),
      exhaustMap((action) =>
        this.consentservice.fetchallconsents(action.payload).pipe(
          map((response: ConsentData[]) => {
            return consentactions.getconsentAction.fetchconsentdataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(consentactions.getconsentAction.fetchconsentdataFailure(error))
          )
        )
      )
    );
  });

  bulkUploadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.initiatebulkupload.bulkupload),
      exhaustMap((action) =>
        this.consentservice
          .bulkUpload(
            action.file,
            action.consent_status,
            action.accountid,
            action.wabano,
            action.created,
            action.bsp,
            action.channel,
            action.bspstatus,
            action.bspid
          )
          .pipe(
            map((response: ConsentData[]) => {
              return consentactions.initiatebulkupload.bulkuploadSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(consentactions.initiatebulkupload.bulkuploadFailure(error))
            )
          )
      )
    );
  });

  uploadStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.queuestatus.getqueuestatus),
      exhaustMap((action) =>
        this.consentservice.queueStatus(action.id).pipe(
          map((response: any) => {
            return consentactions.queuestatus.getqueuestatusSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(consentactions.queuestatus.getqueuestatusFailure(error))
          )
        )
      )
    );
  });

  bulkUpdateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.initiatebulkupdate),
      exhaustMap((action) =>
        this.consentservice.bulkUpdate(action.updatedata).pipe(
          map((response: ConsentData[]) => {
            return consentactions.initiatebulkupdateSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(consentactions.initiatebulkupdateFailure(error))
          )
        )
      )
    );
  });

  getSearchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.searchdata.sendsearchdata),
      exhaustMap((action) =>
        this.consentservice.searchData(action.payload).pipe(
          map((response: ConsentData[]) => {
            return consentactions.searchdata.searchdataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(consentactions.searchdata.searchdataFailure(error))
          )
        )
      )
    );
  });

  getSearchMobileNo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(consentactions.searchMobileNo.searchmobileno),
      exhaustMap((action) =>
        this.consentservice.searchMobile(action.payload).pipe(
          map((response: ConsentData[]) => {
            return consentactions.searchMobileNo.searchmobilenoSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(consentactions.searchMobileNo.searchmobilenoFailure(error))
          )
        )
      )
    );
  });
}
