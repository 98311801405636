import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatafilterPipe } from './services/datafilter.pipe';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert/alert.service';
import { WhatsappPreviewComponent } from './customer/whatsapp-preview/whatsapp-preview.component';
import { MaskTextPipe } from './services/masktext.pipe';
import { EditorModule } from 'primeng/editor';
import { CustomValidators } from './services/CustomValidators';


@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    DatepickerComponent,
    DatafilterPipe,
    MaskTextPipe,
    AlertComponent,
    WhatsappPreviewComponent,
  ],
  imports: [
    RouterModule,
    NgSelectModule,
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    EditorModule,
    
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    DatepickerComponent,
    DatafilterPipe,
    MaskTextPipe,
    AlertComponent,
    WhatsappPreviewComponent,
    NgbDropdownModule,
    EditorModule,
  ],
  providers: [AlertService, CustomValidators],
})
export class SharedModule {}
