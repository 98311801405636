import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertPayload } from './alert.data';
import { AlertService } from './alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  alertList: AlertPayload[] = [];
  alertSuccessSubscription: Subscription;
  alertWarningSubscription: Subscription;
  alertDangerSubscription: Subscription;

  constructor(private alertservice: AlertService) {}

  ngOnInit(): void {
    this.alertSuccessSubscription =
      this.alertservice.showSuccessAlert.subscribe((value: AlertPayload) => {
        if (value) {
          this.showSuccessAlert(value);
        }
      });
    this.alertWarningSubscription =
      this.alertservice.showWarningAlert.subscribe((value: AlertPayload) => {
        if (value) {
          this.showWarningAlert(value);
        }
      });
    this.alertDangerSubscription = this.alertservice.showDangerAlert.subscribe(
      (value: AlertPayload) => {
        if (value) {
          this.showErrorAlert(value);
        }
      }
    );
  }

  showSuccessAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showSuccessAlert.next(null);
    }, 4500);
  }

  showWarningAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showWarningAlert.next(null);
    }, 4500);
  }

  showErrorAlert(value: AlertPayload) {
    this.alertList.push(value);
    setTimeout(() => {
      this.alertList = this.alertList.filter((t) => t !== value);
      this.alertservice.showDangerAlert.next(null);
    }, 4500);
  }

  closeAlert(value: AlertPayload) {
    this.alertList = this.alertList.filter((t) => t !== value);
    this.alertservice.showSuccessAlert.next(null);
    this.alertservice.showWarningAlert.next(null);
    this.alertservice.showDangerAlert.next(null);
  }

  ngOnDestroy(): void {
    this.alertSuccessSubscription.unsubscribe();
    this.alertWarningSubscription.unsubscribe();
    this.alertDangerSubscription.unsubscribe();
  }
}
