import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AllBSPs,
  allBSPs,
  allRoles,
} from 'src/app/control-center/profiles-management/profiles-management.data';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as profileactions from 'src/app/control-center/profiles-management/store/profiles.actions';
import { selectProfileActionsData } from 'src/app/control-center/profiles-management/store/profiles.selectors';
import { Role } from 'src/app/appdata/roles.model';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';
import * as commonactions from 'src/app/store/common.actions';
import * as authactions from 'src/app/login/store/login.actions';
import { AlertService } from 'src/app/alert/alert.service';
import { RoleVariables, SharedService } from 'src/app/services/shared.service';
import { selectRoleDataDtls } from 'src/app/control-center/role-management/store/role.selectors';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { ProfileVariables } from 'src/app/services/profilemanagement.service';
import { Licenses, ProfileData } from 'src/app/appdata/auth.model';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  basicDetailsForm: FormGroup;
  changePwdForm: FormGroup;
  aclPermForm: FormGroup;
  isPswdChange: boolean = false;

  isLinear: boolean = true;
  isCopied: boolean = false;
  showNewPass: boolean = false;
  showConfPass: boolean = false;
  isEditMode: boolean = false;

  imagePath: FileList;
  profileLicenses: Licenses;
  selectedProfile: ProfileData;
  userDetails: any;
  shortName: any;

  roleList: Role[];
  bspsList: AllBSPs[] = allBSPs;
  rolesList: string[] = allRoles;
  sel_bsps: Array<any> = [];
  sel_bas: Array<any> = [];
  bsps: Array<any> = [];
  wabanos: Array<any> = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  account_dtls: ProfileVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    role: null,
    profileName: null,
    createdby: null,
    page: null,
    size: null,
    startdate: null,
    enddate: null,
  };

  @ViewChild('editProfileForm') private editProfileForm: TemplateRef<any>;
  @ViewChild('editSuccess') private editSuccess: TemplateRef<any>;

  constructor(
    private readonly store: Store,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private cv: CustomValidators,
    private alertMsg: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public shareservice: SharedService
  ) {
    this.shortName = this.shareservice.getShortName
  }

  ngOnInit(): void {
    this.initiateFormSub();
    this.initiateUserProfileSub();
    this.initiateRoleDataSub();
    this.initiateCommonDataSub();
    this.initiateProfileDataSub();
  }

  initiateFormSub() {
    this.basicDetailsForm = this.fb.group({
      profileImg: [null],
      firstName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      lastName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      email: [
        null,
        [Validators.required, Validators.pattern(this.cv.emailRegex)],
      ],
      mob_no: [
        null,
        [Validators.required, Validators.pattern(this.cv.contactRegex)],
      ],
      username: [null, [Validators.required]],
      designation: [null],
    });

    this.changePwdForm = this.fb.group(
      {
        pass: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            this.cv.patternValidator(this.cv.capitalRegex, {
              hasCapitalCase: true,
            }),
            this.cv.patternValidator(this.cv.smallRegex, {
              hasSmallCase: true,
            }),
            this.cv.patternValidator(this.cv.digitRegex, { hasNumber: true }),
            this.cv.patternValidator(this.cv.specialCharRegex, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        cnfm_pass: [null, [Validators.required]],
      },
      {
        validators: this.cv.passwordMatchValidator,
      }
    );

    this.aclPermForm = this.fb.group({
      role: [null, Validators.required],
      activerole: [null, Validators.required],
      channel: [null, Validators.required],
      bsp: [null, Validators.required],
      ban: [null, Validators.required],
    });
  }

  initiateUserProfileSub() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          let activeaccount: any;
          if (abac_policies.waba_policies.length > 0) {
            activeaccount = this.userDetails.account.licenses.license_data.find(
              (t: any) =>
                t.channel_credentials.waba_number ===
                abac_policies.waba_policies[0].waba_number
            );
          }
          this.account_dtls.accountid = activeaccount.account_id;
          this.account_dtls.channel = activeaccount.channel.toLowerCase();
          this.account_dtls.bspid = activeaccount.channel_credentials.bsp_id;
          this.account_dtls.wabano =
            activeaccount.channel_credentials.waba_number;
          this.account_dtls.createdby = this.userDetails.profile.id;
          this.activatedRoute.params.subscribe((params: Params) => {
            const sel_id = params['id'];
            this.store.dispatch(
              profileactions.fetchProfileData({
                accountid: this.account_dtls.accountid,
                profileid: sel_id,
              })
            );
          });
        }
      });
  }

  initiateRoleDataSub() {
    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.roleList) {
          this.roleList = JSON.parse(JSON.stringify(res.roleList.data.rbacs));
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateCommonDataSub() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.uploadRes && res.uploadRes.status_code === 200) {
          this.basicDetailsForm
            .get('profileImg')
            .setValue(res.uploadRes.data.url);
        } else if (res.profilelicensedata) {
          if (res.profilelicensedata.status_code === 200) {
            this.profileLicenses = res.profilelicensedata.data;
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateProfileDataSub() {
    this.store
      .select(selectProfileActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.profiledata && res.profiledata.status_code === 200) {
          this.selectedProfile = res.profiledata.data;
          this.bsps = [];
          this.patchProfileData(res.profiledata.data);
          if (this.selectedProfile.id === this.userDetails.profile.id) {
            const auth_data = JSON.parse(localStorage.getItem('wabaauthdata'));
            auth_data.profile = res.profiledata.data;
            localStorage.setItem('wabaauthdata', JSON.stringify(auth_data));
            this.store.dispatch(commonactions.userdetails({ data: auth_data }));
          }
        } else if (
          res.update_profile &&
          res.update_profile.status_code === 201
        ) {
          this.alertMsg.alertSuccess('Success', res.update_profile.message);
          // this.basicDetailsForm.reset();
          this.changePwdForm.reset();
          // this.aclPermForm.reset();
          this.bsps = [];
          if (!this.isPswdChange) {
            this.store.dispatch(
              profileactions.fetchProfileData({
                accountid: this.account_dtls.accountid,
                profileid: res.update_profile.data.id,
              })
            );
          } else {
            this.isPswdChange = false;

            if (this.selectedProfile.email === this.userDetails.profile.email) {
              this.store.dispatch(authactions.logoutUser());
              this.alertMsg.alertSuccess(
                'Success',
                'Login with the new Password'
              );
            }
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  get epBasicFC() {
    return this.basicDetailsForm.controls;
  }

  get epPwdFC() {
    return this.changePwdForm.controls;
  }

  get epAclFC() {
    return this.aclPermForm.controls;
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.editProfileForm;
    this.cd.detectChanges();
  }

  onCancelEditProfile() {
    this.goToProfileList();
  }

  //Method to go to Profile List View
  goToProfileList() {
    this.router.navigate(['cc', this.userDetails.profile.id, 'profiles']);
    this.basicDetailsForm.reset();
    this.changePwdForm.reset();
    this.aclPermForm.reset();
  }

  //Method to Patch the Profile Data during Edit Profile
  patchProfileData(data: any) {
    this.isEditMode = true;
    const rolePayload: RoleVariables = {
      accountid: this.account_dtls.accountid,
      bspid: null,
      wabano: null,
      channel: null,
      page: 1,
      size: 20,
      name: null,
      startdate: null,
      enddate: null,
    };
    this.store.dispatch(roleactions.fetchRoleslist({ payload: rolePayload }));
    this.bsps.push({ name: 'RML', isChecked: false });
    this.wabanos = JSON.parse(
      JSON.stringify(this.userDetails.account.licenses.license_data)
    );
    this.wabanos.forEach((t) => (t.isChecked = false));
    this.basicDetailsForm.patchValue({
      profileImg: data.profile_image_url,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      mob_no: data.mobile_number,
      username: data.username,
      designation: data.designation,
    });
    this.aclPermForm.patchValue({
      role:
        data.acl.rbac_list && data.acl.rbac_list[0] === null
          ? []
          : data.acl.rbac_list,
      activerole: data.acl.rbac,
      channel: 'waba',
      bsp: 'RML',
      ban: this.userDetails.account.licenses.license_data,
    });
    this.basicDetailsForm.get('email').disable();
    this.bsps.forEach((t) => {
      const foundItem = data.acl.abac.abac_policies.waba_policies.find(
        (m: any) => m.bsp_name === t.name
      );
      if (foundItem) {
        t.isChecked = true;
      }
    });
    this.wabanos.forEach((t) => {
      const foundItem = data.acl.abac.abac_policies.waba_policies.find(
        (m: any) => m.waba_number === t.channel_credentials.waba_number
      );
      if (foundItem) {
        t.isChecked = true;
      }
    });
    this.sel_bas = this.wabanos.filter((t) => t.isChecked === true);
    if (this.sel_bas.length > 0) {
      this.aclPermForm.get('ban').setValue(this.sel_bas);
    }
  }

  //Method for Upload Profile pic
  onAvatarUpload(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files || files.length === 0) return;

    if (files.length > 0) {
      this.shareservice.validateUploadImage(files);
    }
  }

  //Method to Remove Avatar
  onAvatarRemove() {
    this.basicDetailsForm.get('profileImg').setValue(null);
  }

  //Method Triggered on Basic Details Form Submit
  onBasicDtlsSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const basicinfo_payload = {
      id: this.selectedProfile.id,
      profile_image_url: form.value.profileImg,
      first_name: form.value.firstName,
      last_name: form.value.lastName,
      username: form.value.username,
      mobile_number: form.value.mobile,
      designation: form.value.designation,
    };

    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: basicinfo_payload,
        id: this.selectedProfile.id,
      })
    );
  }

  //Method for Acl Update
  onAclSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const wabapolices: any = [];
    this.aclPermForm.get('ban').value.forEach((t: any) => {
      if (t.channel === 'WABA') {
        wabapolices.push({
          account_id: t.channel_credentials.account_id,
          bsp_id: t.channel_credentials.bsp_id,
          bsp_name: t.channel_credentials.bsp_name,
          waba_id: t.channel_credentials.waba_id,
          waba_number: t.channel_credentials.waba_number,
          is_default: false,
        });
      }
    });

    const acl_payload: any = {
      id: this.selectedProfile.id,
      account_id: this.selectedProfile.account_id,
      acl_id: this.selectedProfile.acl.id,
      acl: {
        id: this.selectedProfile.acl.id,
        profile_id: this.selectedProfile.id,
        account_id: this.selectedProfile.account_id,
        rbac_id: this.aclPermForm.get('role').value[0].id,
        abac_id: this.selectedProfile.acl.abac.id,
        rbac_ids_list: this.getIds(this.aclPermForm.get('role').value),
        rbac: this.aclPermForm.get('activerole').value,
        rbac_list: this.aclPermForm.get('role').value,
        abac: {
          id: this.selectedProfile.acl.abac.id,
          profile_id: this.selectedProfile.id,
          account_id: this.selectedProfile.account_id,
          abac_policies: {
            waba_policies: wabapolices,
            rcs_policies: [],
            telegram_policies: [],
            viber_policies: [],
            instagram_policies: [],
          },
          acl_id: this.selectedProfile.acl.id,
        },
      },
    };

    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: acl_payload,
        id: this.selectedProfile.id,
      })
    );
  }

  getIds(data: any) {
    const ids: any = [];
    data.forEach((t: any) => ids.push(t.id));
    return ids;
  }

  //Method to change password
  onPswdSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const pswd_payload = {
      id: this.selectedProfile.id,
      // email: this.userDetails.profile.email,
      email: this.basicDetailsForm.get('email').value,
      password: form.value.cnfm_pass,
      account_id: this.account_dtls.accountid,
    };

    this.isPswdChange = true;

    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: pswd_payload,
        id: this.selectedProfile.id,
      })
    );
  }

  selectedBAs(event: any) {
    if (event.checked) {
      const acc = this.userDetails.account.licenses.license_data.find(
        (t: any) => t.channel_credentials.waba_number === event.source.value
      );
      this.sel_bas.push(acc);
    } else {
      this.sel_bas = this.sel_bas.filter(
        (t: any) => t.channel_credentials.waba_number !== event.source.value
      );
    }
    if (this.sel_bas.length > 0) {
      this.aclPermForm.get('ban').setValue(this.sel_bas);
    }
  }

  onRolesSelect(event: any) {
    if (event.length > 0) {
      this.aclPermForm.get('channel').setValue('waba');
    }
  }

  //Method to Copy
  copyCode() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.store.dispatch(commonactions.clearuploadFile());
  }
}
