import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromConsent from './constent.reducers';

export const selectConsentData =
  createFeatureSelector<fromConsent.ConsentState>('consentdata');

export const selectConsentDataList = createSelector(
  selectConsentData,
  fromConsent.getConsentData
);
