import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { WhitelabelingData } from 'src/app/appdata/initialwlb.data';
import { selectCommonDataDtls } from 'src/app/store/common.selectors';
import * as commonactions from 'src/app/store/common.actions';
import { SharedService } from 'src/app/services/shared.service';
import { CustomValidators } from 'src/app/services/CustomValidators';

@Component({
  selector: 'app-white-labeling',
  templateUrl: './white-labeling.component.html',
  styleUrls: ['./white-labeling.component.scss'],
})
export class WhiteLabelingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  displayTemplate: TemplateRef<any>;
  whitelabelingForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  file_url: string;
  uploadAction: string;
  primary_rgb: string;
  secondary_rgb: string;
  showBtnLoader: boolean = false;
  wlb_data = WhitelabelingData;
  updated_wlb_data: any;
  file: File;
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
  };

  @ViewChild('whiteLabelSettings') private whiteLabelSettings: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private cv: CustomValidators,
    private readonly store: Store,
    private alertMsg: AlertService,
    private shareservice: SharedService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.whitelabelingForm = this.fb.group({
      logo: [null],
      favicon: [null],
      loginbackgroundimg: [null],
      primary_color: [null, Validators.required],
      secondary_color: [null, Validators.required],
      buttons_type: [null, Validators.required],
      formcontrol_type: [null, Validators.required],
      product_name: [null, Validators.required],
      company_name: [null, Validators.required],
      sprtEmail: [null, [Validators.required, Validators.pattern(this.cv.emailRegex)]],
      sprtNumber: [null, [Validators.required, Validators.pattern(this.cv.contactRegex)]],
      website: [null, Validators.required],
      terms_url: [null, Validators.required],
      poweredbyimg: [null],
    });

    this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials.account_id;
        this.account_dtls.bsp_id = value.channel_credentials.bsp_id;
        this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();
        this.store.dispatch(
          commonactions.getWhiteLabelData({
            accountid: this.account_dtls.account_id,
          })
        );
      }
    });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.fileres) {
          if (res.fileres.status_code === 200) {
            this.file_url = res.fileres.data.file_url;
            switch (this.uploadAction) {
              case 'logo':
                this.whitelabelingForm.get('logo').setValue(this.file_url);
                this.file = null;
                this.uploadAction = null;
                break;
              case 'favicon':
                this.whitelabelingForm.get('favicon').setValue(this.file_url);
                this.file = null;
                this.uploadAction = null;
                break;
              case 'loginbackgroundimg':
                this.whitelabelingForm
                  .get('loginbackgroundimg')
                  .setValue(this.file_url);
                this.file = null;
                this.uploadAction = null;
                break;
              case 'poweredbyimg':
                this.whitelabelingForm
                  .get('poweredbyimg')
                  .setValue(this.file_url);
                this.file = null;
                this.uploadAction = null;
                break;
              default:
                null;
            }
          }
        } else if (res.wlbres) {
          this.showBtnLoader = false;

          this.updated_wlb_data = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.updated_wlb_data === 'string') {
            this.updated_wlb_data = JSON.parse(this.updated_wlb_data);
          }
          localStorage.setItem(
            'waba_wbl',
            JSON.stringify(this.updated_wlb_data)
          );
          this.patchData(this.updated_wlb_data);
        } else if (res.error) {
          this.showBtnLoader = false;
          const dispErr = res.error.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });

    // this.patchData(this.wlb_data);
  }

  get wFC(){
    return this.whitelabelingForm.controls;
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.whiteLabelSettings;
    this.cd.detectChanges();
  }

  patchData(data: any) {
    if (data) {
      this.whitelabelingForm.patchValue({
        logo: data.logo,
        favicon: data.favicon,
        loginbackgroundimg: data.loginbackgroundimg,
        primary_color: data.primary_color,
        secondary_color: data.secondary_color,
        buttons_type: data.buttons_type,
        formcontrol_type: data.formcontrol_type,
        product_name: data.product_name,
        company_name: data.company_name,
        website: data.website,
        terms_url: data.terms_url,
        poweredbyimg: data.poweredbyimg,
      });
    }
  }

  restoreToDefault() {
    const settings_payload = this.wlb_data;

    this.store.dispatch(
      commonactions.updateWhiteLabelData({
        payload: settings_payload,
        accountid: this.account_dtls.account_id,
      })
    );
  }

  restoreElement(action: string) {
    if (action) {
      this.whitelabelingForm.get(action).setValue(this.wlb_data[action]);
    }
  }

  importImg(event: any, action: string) {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.type;
    const fileSizeMB = file.size / 1024 / 1024;

    const fileTypes: any = {
      'image/jpeg': {
        maxSizeMB: 1,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
      'image/png': {
        maxSizeMB: 1,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
      'image/svg+xml': {
        maxSizeMB: 1,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
      'image/x-icon': {
        maxSizeMB: 1,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
    };

    const allowedTypes = Object.keys(fileTypes);

    if (!allowedTypes.includes(fileType)) {
      this.alertMsg.alertWarning('Warning!', 'Unsupported file type');
      return;
    }

    const fileConfig = fileTypes[fileType];
    const allowedSize = fileConfig.maxSizeMB;

    if (fileSizeMB > allowedSize) {
      this.alertMsg.alertWarning('Warning!', fileConfig.errorMessage);
      return;
    }

    this.uploadAction = action;
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
    this.store.dispatch(
      commonactions.uploadFileData({
        file: this.file,
        accountid: this.account_dtls.account_id,
      })
    );
  }

  onSelect(event: any) {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.type;
    const fileSizeMB = file.size / 1024 / 1024;

    const fileTypes: any = {
      'text/csv': {
        maxSizeMB: 2,
        errorMessage: "You can't upload CSV files which are greater than 2MB",
      },
      'video/mp4': {
        maxSizeMB: 16,
        errorMessage:
          "You can't upload video files which are greater than 16MB",
      },
      'video/3gpp': {
        maxSizeMB: 16,
        errorMessage:
          "You can't upload video files which are greater than 16MB",
      },
      'application/pdf': {
        maxSizeMB: 100,
        errorMessage: "You can't upload documents which are greater than 100MB",
      },
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        {
          maxSizeMB: 100,
          errorMessage:
            "You can't upload documents which are greater than 100MB",
        },
      'image/jpeg': {
        maxSizeMB: 5,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
      'image/png': {
        maxSizeMB: 5,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
    };

    const allowedTypes = Object.keys(fileTypes);

    if (!allowedTypes.includes(fileType)) {
      this.alertMsg.alertWarning('Warning!', 'Unsupported file type');
      return;
    }

    const fileConfig = fileTypes[fileType];

    if (fileSizeMB > fileConfig.maxSizeMB) {
      this.alertMsg.alertWarning('Warning!', fileConfig.errorMessage);
      return;
    }

    this.file = file;
  }

  onSubmit(form: FormGroup) {
    // if (form.invalid) {
    //   return;
    // }


    const settings_payload = {
      logo: form.value.logo,
      favicon: form.value.favicon,
      loginbackgroundimg: form.value.loginbackgroundimg,
      primary_color: form.value.primary_color,
      secondary_color: form.value.secondary_color,
      buttons_type: form.value.buttons_type,
      formcontrol_type: form.value.formcontrol_type,
      product_name: form.value.product_name,
      company_name: form.value.company_name,
      website: form.value.website,
      terms_url: form.value.terms_url,
      poweredbyimg: form.value.poweredbyimg,
    };

    if (this.updated_wlb_data) {
      this.showBtnLoader = true;

      this.store.dispatch(
        commonactions.updateWhiteLabelData({
          payload: settings_payload,
          accountid: this.account_dtls.account_id,
        })
      );
    } else {
      this.store.dispatch(
        commonactions.createWhiteLabelData({
          payload: settings_payload,
          accountid: this.account_dtls.account_id,
        })
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
