<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<!-- Main Content Container -->
<main role="main" class="main" id="cust_dashboard">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Show Details -->
<ng-template #showDetails>
  <div *ngIf="channel_credentails; else nocredentials">
    <!-- Top Header Options/Details -->
    <div class="card card-sticky">
      <div class="header">
        <div class="top_header_options">
          <div class="business_details d-flex align-items-center gap-3">
            <!-- Business Avatar Image/Icon -->
            <div class="avatar avatar-lg avatar-circle">
              <img
                class="avatar-img"
                [src]="channel_credentails.profile_pic"
                [alt]="channel_credentails.business_name"
              />
            </div>
            <!-- END Business Avatar Image/Icon -->

            <!-- Business Waba Number and Name -->
            <div class="details">
              <h1 class="d-flex align-items-center gap-1">
                {{ channel_credentails.waba_number }}
              </h1>
              <p>{{ channel_credentails.business_name }}</p>
            </div>
            <!-- END Business Waba Number and Name -->
          </div>
        </div>
      </div>
    </div>
    <!-- END Top Header Options/Details -->


    <div class="card card-vh-100 px-6 py-4 mt-2">
      <div class="card-body">
        <!-- WABA Details -->
        <div class="wabaid_details">
          <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
            <!-- WABA ID -->
            <div class="col">
              <div class="item_detail">
                <span class="label">WABA ID</span>
                <h5 class="bold" id="wabaid">
                  {{ channel_credentails.waba_id }}
                </h5>
              </div>
            </div>
            <!-- END WABA ID -->

            <!-- WABA Status -->
            <div class="col">
              <div class="item_detail">
                <span class="label">WABA Status</span>
                <h5 id="wabaStatus"
                  [ngClass]="channel_credentails.waba_status.toLowerCase() === 'connected'? 'text-success':'text-warning'"
                >
                  {{ channel_credentails.waba_status | titlecase }}
                </h5>
              </div>
            </div>
            <!-- END WABA Status -->

            <!-- WABA Quality -->
            <div class="col">
              <div class="item_detail">
                <span class="label">WABA Quality</span>
                <h5 id="wabaquality"
                  [ngClass]="{
                    'text-success':channel_credentails.waba_quality.toLowerCase() === 'high',
                    'text-warning':channel_credentails.waba_quality.toLowerCase() === 'medium',
                    'text-danger':channel_credentails.waba_quality.toLowerCase() === 'low',
                  }"
                >
                  {{ channel_credentails.waba_quality | titlecase }}
                </h5>
              </div>
            </div>
            <!-- END WABA Quality -->

            <!-- WABA Number Quality -->
            <div class="col">
              <div class="item_detail">
                <span class="label">Number Quality</span>
                <h5 id="numberQuality"
                  [ngClass]="channel_credentails.number_quality.toLowerCase() === 'high'? 'text-success':'text-warning'"
                >
                  {{ channel_credentails.number_quality | titlecase }}
                </h5>
              </div>
            </div>
            <!-- END WABA Number Quality -->

            <!-- WABA Number Status -->
            <div class="col">
              <div class="item_detail">
                <span class="label">Number Status</span>
                <h5 id="numberStatus"
                  [ngClass]="channel_credentails.number_status.toLowerCase() === 'connected'? 'text-success':'text-warning'"
                >
                  {{ channel_credentails.number_status | titlecase }}
                </h5>
              </div>
            </div>
            <!-- END WABA Number Status -->

            <!-- Messaging Limit -->
            <div class="col">
              <div class="item_detail">
                <span class="label">Messaging Limit</span>
                <h5 id="messageLimit">
                  {{ channel_credentails.messaging_limit }}
                  <em
                    class="bi-info-circle"
                    matTooltip="Messaging limits determine the daily quota for sending template messages to unique users for your business."
                  ></em>
                </h5>
              </div>
            </div>
            <!-- END Messaging Limit -->
          </div>
        </div>
        <!-- END WABA Details -->

        <mat-divider class="my-4"></mat-divider>

        <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
          <!-- Traffic/Volume -->
          <div class="col-4">
            <div class="item_detail">
              <span class="label">Committed Volume</span>
              <h5 id="traffic">
                {{ channel_credentails.traffic_volume }}
                <em
                    class="bi-info-circle"
                    matTooltip="Committed volume is the volume promised by your organization that shall be utilized through this WABA ID, you may send more messages than committed volume, but will be restricted on the TIER limit of the WABA ID"
                  ></em>
              </h5>
            </div>
          </div>
          <!-- END Traffic/Volume -->

          <!-- Billing Type -->
          <div class="col-4">
            <div class="item_detail">
              <span class="label">Billing Type</span>
              <h5 id="traffic">
                {{ channel_credentails.billing_type | titlecase }}
              </h5>
            </div>
          </div>
          <!-- END Billing Type -->
        </div>

        <mat-divider class="my-4"></mat-divider>

        <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4 mb-4">
          <div class="col-4">
            <!-- Display Picture -->
            <div class="business_logo d-flex flex-column">
              <span class="label">Display Picture</span>
              <div class="avatar avatar-xl avatar-circle" id="avatarImg">
                <img
                  class="avatar-img"
                  [src]="channel_credentails.profile_pic"
                  [alt]="channel_credentails.business_name"
                />
              </div>
            </div>
            <!-- END Display Picture -->
          </div>

          <div class="col-4">
            <!-- Entity Name -->
            <div class="business_name">
              <span class="label">Entity Name</span>
              <h5 id="asctName">{{ channel_credentails.business_name }}</h5>
            </div>
            <!-- END Entity Name -->
          </div>

          <div class="col-4">
            <!-- Business Description -->
            <div class="business_desc d-flex justify-content-between gap-2">
              <div class="desc_data">
                <span class="label">Business Description</span>
                <h5 id="bsDesc">
                  {{
                    channel_credentails.business_description
                      ? channel_credentails.business_description
                      : "N / A"
                  }}
                </h5>
              </div>
            </div>
            <!-- END Business Description -->
          </div>
        </div>

        <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4 mb-4">
          <div class="col-4 d-none">
            <!-- Facebook Manager ID -->
            <div class="fb_mid">
              <span class="label">Facebook Manager ID</span>
              <h5 id="fbmid">{{ channel_credentails.facebook_manager_id }}</h5>
            </div>
            <!-- END Facebook Manager ID -->
          </div>

          <div class="col-4">
            <!-- About -->
            <div class="business_desc d-flex justify-content-between gap-2">
              <div class="desc_data">
                <span class="label">About</span>
                <h5 id="bsDesc">
                  {{
                    channel_credentails.about
                      ? channel_credentails.about
                      : "N / A"
                  }}
                </h5>
              </div>
            </div>
            <!-- END About -->
          </div>

          <div class="col-4 d-none">
            <!-- Callback URL -->
            <div class="callback">
              <span class="label">Callback URL</span>
              <h5 id="cb_url">{{ channel_credentails.callback_url }}</h5>
              <small>(Onetime generate, not editable)</small>
            </div>
            <!-- END Callback URL -->
          </div>

          <div class="col-4 d-none">
            <!-- Credentials -->
            <div class="authorization">
              <span class="label">Credentials</span>
              <h5 id="auth_type">
                Username :
                {{
                  AuthDetails(channel_credentails.credential_meta, "username")
                }}
              </h5>
              <h5>
                Password :
                <span>
                  <ng-container *ngIf="!isCopied; else pwdVisible">
                    {{
                      AuthDetails(
                        channel_credentails.credential_meta,
                        "password"
                      ) | maskText
                    }}
                  </ng-container>
                  <ng-template #pwdVisible>
                    {{
                      AuthDetails(
                        channel_credentails.credential_meta,
                        "password"
                      )
                    }}
                  </ng-template>
                </span>

                <em
                  class="ms-1 fs-6 pointer"
                  [ngClass]="{
                    'bi-eye-fill': !isCopied,
                    'bi-eye-slash-fill': isCopied
                  }"
                  (click)="copyCode()"
                  (keydown)="copyCode()"
                >
                </em>
              </h5>
            </div>
            <!-- END Credentials -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #nocredentials>
    <ngx-skeleton-loader
      class="skeleton_loader"
      *ngFor="let item of skeletonData"
      [theme]="item"
    ></ngx-skeleton-loader>
  </ng-template>
</ng-template>
<!-- END Show Details -->
