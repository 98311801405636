import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as profileactions from 'src/app/control-center/profiles-management/store/profiles.actions';
import * as commonactions from 'src/app/store/common.actions';
import { selectProfileActionsData } from 'src/app/control-center/profiles-management/store/profiles.selectors';
import { SharedService } from 'src/app/services/shared.service';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { Licenses, ProfileData } from 'src/app/appdata/auth.model';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AlertService } from 'src/app/alert/alert.service';

export interface SliderValues {
  color: string;
  checked: boolean;
  disabled: boolean;
  labelPosition: string;
  status: string[];
}

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
})
export class ViewProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  active = 1;
  isCopied: boolean = false;
  displayTemplate: TemplateRef<any>;
  profileLicenses: Licenses;
  skeletonData = skeleton_data;
  selectedProfile: ProfileData;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userDetails: any;
  channelSub: Subscription;

  sliderValue: SliderValues = {
    color: 'accent',
    checked: true,
    disabled: false,
    labelPosition: 'before',
    status: ['Active', 'Inactive'],
  };
  shortName: any;

  @ViewChild('profiledatatemp') profiledatatemp: TemplateRef<any>;
  @ViewChild('datawaiting') datawaiting: TemplateRef<any>;

  constructor(
    private readonly store: Store,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public shareservice: SharedService,
    private alertMsg: AlertService
  ) {
    this.shortName = this.shareservice.getShortName;
  }

  ngOnInit(): void {
    this.initiateChannelSub();
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
        }
      });

    this.store
      .select(selectProfileActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.profiledata && res.profiledata.status_code === 200) {
          this.selectedProfile = res.profiledata.data;
          this.sliderValue.checked = this.selectedProfile.is_active;
        }
        else if (
          res.update_profile &&
          res.update_profile.status_code === 201
        ) {
          this.alertMsg.alertSuccess('Success', res.update_profile.message);
          this.displayTemplate = this.profiledatatemp;
          this.sliderValue.checked = res.update_profile.data.is_active;
          this.sliderValue.disabled = false;
        } else if (res.error) {
          this.sliderValue.checked = this.selectedProfile.is_active;
          this.displayTemplate = this.profiledatatemp;
          this.sliderValue.disabled = false;
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
 
      });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (
          res.profilelicensedata &&
          res.profilelicensedata.status_code === 200
        ) {
          this.profileLicenses = res.profilelicensedata.data;
          this.displayTemplate = this.profiledatatemp;
        }
      });
  }

  initiateChannelSub() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        if (this.router.url.includes('view')) {
          this.activatedRoute.params.subscribe((params: Params) => {
            const profile_id = params['id'];
            this.store.dispatch(
              profileactions.fetchProfileData({
                accountid: value.channel_credentials.account_id,
                profileid: profile_id,
              })
            );
          });
          this.store.dispatch(
            commonactions.getLicenseDetails({
              accountid: value.channel_credentials.account_id,
            })
          );
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.datawaiting;
    this.cd.detectChanges();
  }

  goToProfileList() {
    this.router.navigate(['cc', this.userDetails.profile.id, 'profiles']);
  }

  onUpdateProfile() {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'profiles',
      this.selectedProfile.id,
      'edit',
    ]);
  }

  getRoles(data: Array<any>) {
    const rolenames: any = [];
    data.forEach((t: any) => {
      rolenames.push(t.role_name);
    });
    return rolenames.toString();
  }

  getBusinessAccounts(data: any) {
    const abac_policies = data;
    const activeaccount: Array<any> = [];
    if (abac_policies.waba_policies.length > 0) {
      abac_policies.waba_policies.forEach((t: any) => {
        const presentacct = this.userDetails.account.licenses.license_data.find(
          (m: any) => m.channel_credentials.waba_number === t.waba_number
        );
        if (presentacct) {
          activeaccount.push(presentacct);
        }
      });
    }
    return activeaccount;
  }

  profileActiveStatus() {
    this.sliderValue.disabled = true;
    this.displayTemplate = this.datawaiting;
    this.store.dispatch(
      profileactions.UpdateProfileActions.updateProfile({
        payload: {
          id: this.selectedProfile.id,
          is_active: this.sliderValue.checked,
        },
        id: this.selectedProfile.id,
      })
    );
  }
 

  // getShortName(fullName:any) {
  //   return fullName
  //     .split(' ')
  //     .map((n:any) => n[0])
  //     .join('');
  // }

  onNavChange(event: any) {
    return;
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
