import { createAction, props } from '@ngrx/store';
import { UploadFileResponse } from '../appdata/fileupload.model';
import { MediaServerList } from '../appdata/fileserver.model';
import { AnalyticsData } from '../appdata/analytics.model';
import { MediaDriveVariables } from '../services/shared.service';
import { AuthUserData } from '../appdata/auth.model';

export const userdetails = createAction(
  '[User] Fetch User Details',
  props<{ data: AuthUserData }>()
);

export const tokendetails = createAction(
  '[User] Fetch Token Details',
  props<{ data: any }>()
);

export const initiateuploadFile = createAction(
  '[FileUpload] File Upload',
  props<{
    file: File;
    accountid: string;
    mobileno: string;
    channel: string;
    createdby: string;
    is_active: boolean;
  }>()
);
export const initiateuploadFileSuccess = createAction(
  '[FileUpload] File Upload Success',
  props<{ data: UploadFileResponse }>()
);
export const initiateuploadFileFailure = createAction(
  '[FileUpload] File Upload Failure',
  props<{ error: any }>()
);

export const initiateuploadUrl = createAction(
  '[UrlUpload] URL Upload',
  props<{
    url: string;
    accountid: string;
    mobileno: string;
    channel: string;
    createdby: string;
  }>()
);
export const initiateuploadUrlSuccess = createAction(
  '[UrlUpload] URL Upload Success',
  props<{ data: UploadFileResponse }>()
);
export const initiateuploadUrlFailure = createAction(
  '[UrlUpload] URL Upload Failure',
  props<{ error: any }>()
);

export const fetchMediaServerData = createAction(
  '[FileServer] Fetch Media Server',
  props<{
    payload: MediaDriveVariables;
  }>()
);
export const fetchMediaServerDataSuccess = createAction(
  '[FileServer] Fetch Media Server Success',
  props<{ data: MediaServerList }>()
);
export const fetchMediaServerDataFailure = createAction(
  '[FileServer] Fetch Media Server Failure',
  props<{ error: any }>()
);

export const deleteMediaServerData = createAction(
  '[FileServer] Delete Media Server',
  props<{
    payload: any;
  }>()
);
export const deleteMediaServerDataSuccess = createAction(
  '[FileServer] Delete Media Server Success',
  props<{ data: any }>()
);
export const deleteMediaServerDataFailure = createAction(
  '[FileServer] Delete Media Server Failure',
  props<{ error: any }>()
);

export const fetchanalyticsdata = createAction(
  '[Analytics] Fetch Analytics Data',
  props<{
    accountid: string;
    bspid?: string;
    startdate?: string;
    enddate?: string;
    page?: number;
    size?: number;
    campaign_id?: string;
  }>()
);
export const fetchanalyticsdataSuccess = createAction(
  '[Analytics] Fetch Analytics Data Success',
  props<{ data: AnalyticsData }>()
);
export const fetchanalyticsdataFailure = createAction(
  '[Analytics] Fetch Analytics Data Failure',
  props<{ error: any }>()
);

export const uploadFileData = createAction(
  '[WLB] Upload File Data',
  props<{ file: File; accountid: string }>()
);
export const uploadFileDataSuccess = createAction(
  '[WLB] Upload File Data Success',
  props<{ data: any }>()
);
export const uploadFileDataFailure = createAction(
  '[WLB] Upload File Data Failure',
  props<{ error: any }>()
);

export const fetchWhiteLabelData = createAction('[WLB] Fetch Whitelabel Data');
export const fetchWhiteLabelDataSuccess = createAction(
  '[WLB] Fetch Whitelabel Data Success',
  props<{ data: any }>()
);
export const fetchWhiteLabelDataFailure = createAction(
  '[WLB] Fetch Whitelabel Data Failure',
  props<{ error: any }>()
);
export const setWhiteLabelData = createAction(
  '[WLB] Set Whitelabel Data',
  props<{ data: any }>()
);

export const getWhiteLabelData = createAction(
  '[WLB] Get Whitelabel Data',
  props<{ accountid: string }>()
);
export const getWhiteLabelDataSuccess = createAction(
  '[WLB] Get Whitelabel Data Success',
  props<{ data: any }>()
);
export const getWhiteLabelDataFailure = createAction(
  '[WLB] Get Whitelabel Data Failure',
  props<{ error: any }>()
);

export const createWhiteLabelData = createAction(
  '[WLB] Create Whitelabel Data',
  props<{ payload: any; accountid: string }>()
);
export const createWhiteLabelDataSuccess = createAction(
  '[WLB] Create Whitelabel Data Success',
  props<{ data: any }>()
);
export const createWhiteLabelDataFailure = createAction(
  '[WLB] Create Whitelabel Data Failure',
  props<{ error: any }>()
);

export const updateWhiteLabelData = createAction(
  '[WLB] Update Whitelabel Data',
  props<{ payload: any; accountid: string }>()
);
export const updateWhiteLabelDataSuccess = createAction(
  '[WLB] Update Whitelabel Data Success',
  props<{ data: any }>()
);
export const updateWhiteLabelDataFailure = createAction(
  '[WLB] Update Whitelabel Data Failure',
  props<{ error: any }>()
);

export const resetcommondata = createAction('[Common] Reset Common Data');

export const clearuploadFile = createAction('[UrlUpload] Reset Upload Data');

export const changeDefault = createAction(
  '[Product] change Default',
  props<{
    profileid: string;
    payload: any;
  }>()
);
export const changeDefaultSuccess = createAction(
  '[Product] change Default Success',
  props<{ data: any }>()
);
export const changeDefaultFailure = createAction(
  '[Product] change Default Failure',
  props<{ error: any }>()
);

export const getLicenseDetails = createAction(
  '[License] Get License Details',
  props<{
    accountid: string;
  }>()
);
export const getLicenseDetailsSuccess = createAction(
  '[License] Get License Details Success',
  props<{ data: any }>()
);
export const getLicenseDetailsFailure = createAction(
  '[License] Get License Details Failure',
  props<{ error: any }>()
);

export const clearError = createAction('[clear] Clear Error');
