import { createAction, createActionGroup, props } from '@ngrx/store';
import { ProfilesData } from 'src/app/appdata/profiledata.model';
import { ProfileVariables } from 'src/app/services/profilemanagement.service';

export const fetchProfileslist = createAction(
  '[Profiles] Fetch Profiles List',
  props<{
    payload: ProfileVariables;
  }>()
);
export const fetchProfileslistSuccess = createAction(
  '[Profiles] Fetch Profiles List Success',
  props<{ data: ProfilesData }>()
);
export const fetchProfileslistFailure = createAction(
  '[Profiles] Fetch Profiles List Failure',
  props<{ error: any }>()
);

export const fetchProfileData = createAction(
  '[Profile] Fetch Profile Data',
  props<{
    accountid: string;
    profileid: string;
  }>()
);
export const fetchProfileDataSuccess = createAction(
  '[Profile] Fetch Profile Data Success',
  props<{ data: any }>()
);
export const fetchProfileDataFailure = createAction(
  '[Profile] Fetch Profile Data Failure',
  props<{ error: any }>()
);

export const createProfile = createAction(
  '[Profiles] Create Profile',
  props<{
    payload: any;
  }>()
);
export const createProfileSuccess = createAction(
  '[Profiles] Create Profile Success',
  props<{ data: any }>()
);
export const createProfileFailure = createAction(
  '[Profiles] Create Profile Failure',
  props<{ error: any }>()
);

export const UpdateProfileActions = createActionGroup({
  source: 'Profile',
  events: {
    'Update Profile': props<{ payload: any; id: string }>(),
    UpdateSuccess: props<{ data: any }>(),
    UpdateFailure: props<{ error: any }>(),
  },
});

export const DeleteProfileActions = createActionGroup({
  source: 'Profile',
  events: {
    'Delete Profile': props<{ accountid: string; profile_id: string }>(),
    DeleteSuccess: props<{ data: any }>(),
    DeleteFailure: props<{ error: any }>(),
  },
});
