<!-- Main Content Container -->
<main role="main" class="main" id="cc_business_accounts">
  <div class="content container-fluid">
    <router-outlet></router-outlet>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Modals -->
<!-- In-Activate Role Modal -->
<ng-template #inactivateRoleModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Inactivate Role</h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>
        Are you sure you want to inactivate
        <strong class="text-danger">“{{ inactivateItem.role_name }}”</strong>
        role ?
      </h4>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="restoreStatus(inactivateItem)">
      Cancel
    </button>
    <button class="btn btn-outline-danger" (click)="inactivate(inactivateItem)">
      Inactivate
    </button>
  </div>
</ng-template>
<!-- END In-Activate Role Modal -->
