import { Action, createReducer, on } from '@ngrx/store';
import * as reportactions from './reports.actions';
import {
  ReportListData,
  ViewSummaryReportsData,
} from 'src/app/services/reports.service';

export interface ReportTemplatesState {
  reporttemplateslist: any;
  createreporttemplate: any;
  updatereporttemplate: any;
  deletereporttemplates: any;
  reportlist: ReportListData;
  createreport: any;
  updatereport: any;
  deletereport: any;
  summaryreport: ViewSummaryReportsData;
  error: any;
}

export const initialReportTemplatesState: ReportTemplatesState = {
  reporttemplateslist: null,
  createreporttemplate: null,
  updatereporttemplate: null,
  deletereporttemplates: null,
  reportlist: null,
  createreport: null,
  updatereport: null,
  deletereport: null,
  summaryreport: null,
  error: null,
};

const reportsTemplateReducer = createReducer(
  initialReportTemplatesState,
  on(
    reportactions.reporttemplates.reportTemplatesListSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: data,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reporttemplates.reportTemplatesListFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reporttemplates.createReportTemplateSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: data,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reporttemplates.createReportTemplateFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reporttemplates.editReportTemplateSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: data,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reporttemplates.editReportTemplateFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reporttemplates.deleteReportTemplateSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: data,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reporttemplates.deleteReportTemplateFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reportdetails.reportListSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: data,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reportdetails.reportListFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reportdetails.createReportSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: data,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reportdetails.createReportFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reportdetails.editReportSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: data,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reportdetails.editReportFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.reportdetails.deleteReportSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: data,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.reportdetails.deleteReportFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.summaryreports.viewSummaryReportsSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: data,
      error: null,
    })
  ),
  on(
    reportactions.summaryreports.viewSummaryReportsFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  ),
  on(
    reportactions.clearreportsdata,
    (state): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.summaryreports.deleteSummaryReportFileSuccess,
    (state, { data }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: data,
      summaryreport: null,
      error: null,
    })
  ),
  on(
    reportactions.summaryreports.deleteSummaryReportFileFailure,
    (state, { error }): ReportTemplatesState => ({
      ...state,
      reporttemplateslist: null,
      createreporttemplate: null,
      updatereporttemplate: null,
      deletereporttemplates: null,
      reportlist: null,
      createreport: null,
      updatereport: null,
      deletereport: null,
      summaryreport: null,
      error: error,
    })
  )
);

export function reducer(
  state: ReportTemplatesState | undefined,
  action: Action
): any {
  return reportsTemplateReducer(state, action);
}

export const getReportTemplateData = (state: ReportTemplatesState) => {
  return {
    reporttemplateslist: state.reporttemplateslist,
    createreporttemplate: state.createreporttemplate,
    updatereporttemplate: state.updatereporttemplate,
    deletereporttemplates: state.deletereporttemplates,
    reportlist: state.reportlist,
    createreport: state.createreport,
    updatereport: state.updatereport,
    deletereport: state.deletereport,
    summaryreport: state.summaryreport,
    error: state.error,
  };
};
