<!-- Summary Reports Header Options -->
<div class="row align-items-center justify-content-between my-3">
  <div class="col-auto">
    <h4>Business Initiated Conversations</h4>
  </div>

  <div class="col-8 d-flex justify-content-end">
    <ul class="list-inline list-inline-flex mb-0">
      <!-- Download Option -->
      <li class="list-inline-item">
        <button
          class="btn btn-icon btn-outline-primary"
          matTooltip="Download summary reports"
          (click)="downloadData()"
          *ngIf="showDownloadOption"
        >
          <em class="bi bi-download"></em>
        </button>
      </li>
      <!-- END Download Option -->

      <!-- Summary Data Refresh -->
      <li class="list-inline-item">
        <button
          class="btn btn-icon btn-outline-primary"
          matTooltip="Click to get latest summary data"
          (click)="getSummaryData()"
        >
          <em class="bi bi-arrow-repeat"></em>
        </button>
      </li>
      <!-- END Summary Data Refresh -->

      <!-- Calender Date Range Selector -->
      <li class="list-inline-item">
        <app-datepicker></app-datepicker>
      </li>
      <!-- END Calender Date Range Selector -->
    </ul>
  </div>
</div>
<!-- END Summary Reports Header Options -->

<!-- Business Initiated Conversations Table -->
<ng-container *ngIf="!isLoading; else showloader">
  <div class="table-wrapper">
    <table class="table table-striped" aria-describedby="Consent Opt-In">
      <thead class="table-head">
        <tr class="table-row">
          <th scope="col">Conversations</th>
          <th scope="col">Submitted</th>
          <th scope="col">Sent</th>
          <th scope="col">Delivered</th>
          <th scope="col">Read</th>
          <th scope="col">Failed</th>
          <th scope="col">Pending</th>
          <th scope="col">Unread</th>
        </tr>
      </thead>
      <tbody class="table-body" *ngIf="reportdata">
        <tr
          *ngFor="
            let category of ['authentication', 'marketing', 'utility', 'total']
          "
        >
          <td>{{ category }}</td>
          <td>{{ reportdata[category]?.submitted }}</td>
          <td>{{ reportdata[category]?.sent }}</td>
          <td>{{ reportdata[category]?.delivered }}</td>
          <td>{{ reportdata[category]?.read }}</td>
          <td>{{ reportdata[category]?.failed }}</td>
          <td>{{ reportdata[category]?.pending }}</td>
          <td>{{ reportdata[category]?.unread }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-template #showloader>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- END Business Initiated Conversations Table -->
