import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'tags',
})
export class JoinPipe implements PipeTransform {
  transform(input: any, sep = ','): any {
    // return JSON.parse(input).join(sep);

    input = JSON.parse(input);
    const code = [];
    if (input.length > 0) {
      if (input.length > 1) {
        const inp =[...input];
        inp.shift();
        code.push(
          { init: input[0] + sep },
          { count: inp.length, tooltip: inp.join(',') }
        );
      } else {
        code.push({ init: input[0] });
      }
    }
    return code;
  }
}
