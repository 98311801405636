import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CustomValidators {
  public nameRegex = new RegExp(/^([A-z]+\s)*[A-z]+$/);
  public bnameRegex = new RegExp(/^[^\s].*[^\s]$/);

  // Regex to Don't allow spaces at start and end of the input text
  public checkSpacesRegex = new RegExp(/^[^\s].*[^\s]$/);

  public descRegex = new RegExp(/^[0-9a-zA-Z]+/);
  public reviewRegex = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
  public otherRegex = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
  public countRegex = new RegExp(/^([1-9]\d*)$/);
  public contactRegex = new RegExp(/^[0-9]{10,16}$/);
  public editorMaxChar = new RegExp(/^[0-9a-zA-Z]{1,1024}$/);
  // public contactRegex = new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/);
  public emailRegex = new RegExp(/^([\w-\\.]+@(?!gmail.com)(?!gmail.co)(?!gmail.co.in)(?!googlemail.com)(?!aim.com)(?!aol.com)(?!abc.com)(?!bigpond.com)(?!bigpond.net.au)(?!bol.com.br)(?!cox.net)(?!comcast.net)(?!free.fr)(?!gawab.com)(?!gmx.de)(?!gmx.com)(?!hotmail.com)(?!hotmail.co.uk)(?!hotmail.fr)(?!hotmail.it)(?!inbox.com)(?!in.com)(?!ig.com.br)(?!live.com)(?!live.fr)(?!live.nl)(?!live.co.uk)(?!libero.it)(?!mail.com)(?!mailinator.com)(?!mail.ru)(?!me.com)(?!msn.com)(?!neuf.fr)(?!outlook.com)(?!pqr.com)(?!rediffmail.com)(?!sbcglobal.net)(?!sfr.fr)(?!terra.com.br)(?!uol.com.br)(?!verizon.net)(?!wanadoo.fr)(?!xyz.com)(?!yahoo.com)(?!yahoo.co)(?!yahoo.co.in)(?!yahoo.co.uk)(?!yahoo.com.br)(?!yahoo.fr)(?!yahoo.it)(?!yahoo.es)(?!yahoo.de)(?!ymail.com)(?!yandex.com)(?!yandex.ru)([\w-]+\.)+[\w-]{2,4})?$/);
  public capitalRegex = new RegExp(/[A-Z]/);
  public smallRegex = new RegExp(/[a-z]/);
  public digitRegex = new RegExp(/\d/);
  // eslint-disable-next-line no-useless-escape
  public specialCharRegex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
  public websiteRegex = new RegExp(/^([https?://])?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*[/?]/);
  public mediaURLRegex = new RegExp(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/g);


  
  static onlyChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == "") return null;

      const re = new RegExp("^[a-zA-Z ]*$");
      if (re.test(control.value)) {
        return null;
      } else {
        return { onlyChar: true };
      }
    };
  }

  

  flNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^([A-z]+\\s)*[A-z]+$');
      // const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
      const valid = regex.test(control.value);

      // const regex = /^([A-z]+\s)*[A-z]+$/;
      // const lowerLetters = /[a-z]+/.test(password);
      // const upperLetters = /[A-Z]+/.test(password);
      // const numbers = /[0-9]+/.test(password);
      // const symbols = regex.test(password);

      return valid ? null : { errorMsg: 'No unwanted space, numeric and special characters allowed.' };
    };
  }

  emailValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const emailRegex = '^([\\w-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)(?!in.com)([\\w-]+\\.)+[\\w-]{2,4})?$';
      const regex = new RegExp(emailRegex);
      const valid = regex.test(control.value);

      return valid ? null : error;
      // return valid ? null : { errorMsg: 'Please enter a valid email format(ex:abc@domain.com).'};
    };
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
  
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
  
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('newPassword')?.value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword')?.value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
    }
  }

  // MatchPassword(password: string, confirmPassword: string) {
  //   return (formGroup: FormGroup) => {
  //     const passwordControl = formGroup.controls[password];
  //     const confirmPasswordControl = formGroup.controls[confirmPassword];

  //     if (!passwordControl || !confirmPasswordControl) {
  //       return;
  //     }

  //     if (
  //       confirmPasswordControl.errors &&
  //       !confirmPasswordControl.errors["passwordMismatch"]
  //     ) {
  //       return null;
  //     }

  //     if (passwordControl.value !== confirmPasswordControl.value) {
  //       confirmPasswordControl.setErrors({ passwordMismatch: true });
  //     } else {
  //       confirmPasswordControl.setErrors(null);
  //     }
  //     return null;
  //   };
  // }

  userNameValidator(userControl: AbstractControl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.validateUserName(userControl.value)) {
          resolve({ userNameNotAvailable: true });
        } else {
          resolve(null);
        }
      }, 1000);
    });
  }

  noWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid =
        control.value && control.value.trim().length === control.value.length;
      return isValid
        ? null
        : { whitespace: 'Value has leading or trailing whitespace' };
    };
  } 

  validateUserName(userName: string) {
    const UserList = ["ankit", "admin", "user", "superuser"];
    return UserList.indexOf(userName) > -1;
  }
}
