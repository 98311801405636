import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import {
  Channels,
  WabaID,
  channels,
  userSelected,
  wabaid,
} from 'src/app/appdata/shared.data';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { SharedService } from 'src/app/services/shared.service';
import * as commonactions from 'src/app/store/common.actions';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {
  channelList: Channels[] = channels;
  bspList: Array<any> = [];
  bActList: WabaID[] = wabaid;
  selectedBSP: any;
  userDetails: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayTemplate: TemplateRef<any>;
  activeaccounts: Array<any> = [];

  @ViewChild('channels') private channels: TemplateRef<any>;
  @ViewChild('bsp') private bsp: TemplateRef<any>;
  @ViewChild('bacts') private bacts: TemplateRef<any>;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private shareservice: SharedService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          
          this.activeaccounts = [];
          if (abac_policies.waba_policies.length > 0) {
            abac_policies.waba_policies.forEach((t: any) => {
              const presentacc =
                this.userDetails.account.licenses.license_data.find(
                  (m: any) =>
                    m.channel_credentials.waba_number === t.waba_number
                );
              this.activeaccounts.push(presentacc);
            });
            if (this.activeaccounts.length > 0) {
              this.bspList = this.activeaccounts.filter((t) => t.bsp === 'RML');
            }
          }
        }
      });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.defaultSettings) {
          if (res.defaultSettings.status_code === 200) {
            const stored_data = JSON.parse(
              localStorage.getItem('wabaauthdata')
            );
            stored_data.profile.acl.abac.abac_policies =
              res.defaultSettings.data.abac_policies;
            localStorage.setItem('wabaauthdata', JSON.stringify(stored_data));
            this.store.dispatch(
              commonactions.userdetails({ data: stored_data })
            );
          }
        } else if (res.error) {
          alert(res.error);
        }
      });
  }

  checkInactiveumber(data: any) {
    const isInactive = this.userDetails.account.licenses.license_data.find(
      (m: any) => m.channel_credentials.waba_number === data
    );

    if (isInactive.is_active) {
      return true;
    } else {
      return false;
    }
  }

  isdefaultBSP() {
    const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
    // abac_policies = JSON.parse(abac_policies);
    const defaultsatus = abac_policies.waba_policies.find(
      (m: any) => m.is_default === true
    );
    if (defaultsatus) {
      return true;
    } else {
      return false;
    }
  }

  isdefaultNo(data: any) {
    if (data) {
      const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
      // abac_policies = JSON.parse(abac_policies);
      const defaultsatus = abac_policies.waba_policies.find(
        (m: any) => m.waba_number === data
      );
      return defaultsatus.is_default;
    }
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.channels;
    this.cd.detectChanges();
  }

  gotoChannel() {
    this.displayTemplate = this.channels;
  }
  gotoBsp() {
    this.displayTemplate = this.bsp;
  }

  setDefaultChannel(item: any) {
    // this.channelList.forEach((t) => (t.isdefault = false));
    // item.isdefault = true;
  }

  setDefaultBSP(item: any) {
    this.bspList.forEach((t) => (t.isdefault = false));
    item.isdefault = true;
  }

  setDefaultbact(item: any) {
    const abacdetails = this.userDetails.profile.acl.abac;
    const abac_policies = JSON.parse(
      JSON.stringify(this.userDetails.profile.acl.abac.abac_policies)
    );
    abac_policies.waba_policies.forEach((t: any) => {
      t.is_default = false;
    });
    const updated_policy = abac_policies.waba_policies.findIndex(
      (t: any) => t.waba_number === item.channel_credentials.waba_number
    );
    abac_policies.waba_policies[updated_policy].is_default = true;
    const Payload = {
      id: this.userDetails.profile.acl.abac.id,
      profile_id: this.userDetails.profile.id,
      abac_policies: abac_policies,
      account_id: this.userDetails.profile.account_id,
      updated_at: abacdetails.updatedAt,
      created_at: abacdetails.createdAt,
    };
    this.store.dispatch(
      commonactions.changeDefault({
        profileid: this.userDetails.profile.acl.abac.id,
        payload: Payload,
      })
    );
  }

  selectChannel(item: any) {
    this.displayTemplate = this.bsp;
    // To Filter the BSP List based on the channel selected
    this.selectedBSP = this.bspList.filter((t) => t.linked_channel == item);
    userSelected.channel = item;
  }

  selectBSP(item: any) {
    userSelected.bsp = item;
    this.displayTemplate = this.bacts;
  }

  showDB(item: any) {
    const data = JSON.parse(JSON.stringify(item));
    this.shareservice.selectedBA$.next(data);
    localStorage.setItem('sel_ba', JSON.stringify(item));
    this.router.navigate([
      '/customer/' + this.userDetails.profile.id + '/dashboard',
    ]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
