import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { skeleton_data } from './whatsapp-preview.data';
import { SharedService } from 'src/app/services/shared.service';
import { selectTemplatePreviewPayloadData } from '../templates/store/template.selectors';
import { AlertService } from 'src/app/alert/alert.service';
import { selectCampaignPreviewData } from '../campaign-manager/store/campaign.selectors';
import { ChannelCredentials } from 'src/app/appdata/auth.model';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-whatsapp-preview',
  templateUrl: './whatsapp-preview.component.html',
  styleUrls: ['./whatsapp-preview.component.scss'],
})
export class WhatsappPreviewComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  previewTemplateData = new BehaviorSubject<TemplateRef<any>>(null);
  skeletonData = skeleton_data;
  videoPlaying = false;
  mediaType: string;
  createtemplate_payload: any;
  addsample_payload: any;
  tempType: string | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  channelSub: Subscription;
  channel_credentails: ChannelCredentials;

  @ViewChild('videoElement') videoElement!: ElementRef;
  @ViewChild('createTemp') private createTemp!: TemplateRef<Element>;
  @ViewChild('skeletonTemp') private skeletonTemp!: TemplateRef<Element>;
  @ViewChild('addSampleTemp') private addSampleTemp!: TemplateRef<Element>;
  @ViewChild('viewTemp') private viewTemp!: TemplateRef<Element>;
  @ViewChild('campaignManagementTemp')
  private campaignManagementTemp!: TemplateRef<Element>;
  currentTemplateData: object | null;

  constructor(
    private cd: ChangeDetectorRef,
    public shareservice: SharedService,
    private readonly store: Store,
    public templateservice: TemplateService,
    private alertMsg: AlertService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCampaignPreviewData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaignTemplateData) {
          this.previewTemplateData.next(this.viewTemp);
          let template_data = res.campaignTemplateData;
          if (typeof template_data === 'string') {
            template_data = JSON.parse(template_data);
          }
          this.currentTemplateData = template_data.content;
        } else if (res.campaignPreview) {
          this.previewTemplateData.next(this.campaignManagementTemp);
          this.currentTemplateData = res.campaignPreview;
        }
      });

    this.shareservice.tempType.subscribe((type) => {
      this.tempType = type;
    });

    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
      }
    });
  }

  ngAfterViewInit() {
    this.previewTemplateData.next(this.skeletonTemp);
    this.store
      .select(selectTemplatePreviewPayloadData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.addSamplePayload) {
          const sm_data = JSON.parse(JSON.stringify(res.addSamplePayload));
          let header_text = sm_data.headertext;
          sm_data.headerVariables.forEach((data: any) => {
            if (data.variableName) {
              const m_val = data.variable.match(/{{\d+}}/g);
              header_text = header_text.replace(m_val[0], data.variableName);
              sm_data.headertext = header_text;
            }
          });
          let text = sm_data.bodyText;
          sm_data.bodyVariables.forEach((data: any) => {
            if (data.variableName) {
              const m_val = data.variable.match(/{{\d+}}/g);
              text = text.replace(m_val[0], data.variableName);
              sm_data.bodyText = text;
            }
          });
          this.currentTemplateData = sm_data;
          this.previewTemplateData.next(this.addSampleTemp);
        } else if (res.templatePayload) {
          this.previewTemplateData.next(this.createTemp);
          this.currentTemplateData = res.templatePayload;
        } else if (res.viewTemplateData) {
          this.currentTemplateData = res.viewTemplateData;
          this.previewTemplateData.next(this.viewTemp);
        }
      });
    this.cd.detectChanges();
  }

  getBodyText(data: any) {
    let text = data.text;
    const pattern = /{{(.*?)}}/g;
    if (text.match(pattern)?.length > 0) {
      data.variables.forEach((item: any) => {
        for (const key in item) {
          const placeholder = `{{${key}}}`;
          if (item[key] === `var${key}`) {
            text = data.text;
          } else if (item[key] === '') {
            text = text.split(placeholder).join(`{{${key}}}`);
          } else {
            text = text.split(placeholder).join(item[key]);
          }
        }
      });
      return text;
    } else {
      return text;
    }
  }

  getHeaderText(data: any) {
    let text = data.data[0].text;
    const pattern = /{{(.*?)}}/g;
    if (text.match(pattern)?.length > 0) {
      data.variables.forEach((item: any) => {
        for (const key in item) {
          const placeholder = `{{${key}}}`;
          if (item[key] === `var${key}`) {
            text = data.text;
          } else if (item[key] === '') {
            text = text.split(placeholder).join(`{{${key}}}`);
          } else {
            text = text.split(placeholder).join(item[key]);
          }
        }
      });
      return text;
    } else {
      return text;
    }
  }

  togglePlay() {
    this.videoPlaying = !this.videoPlaying;
    this.videoElement.nativeElement[this.videoPlaying ? 'play' : 'pause']();
  }

  videoEnded() {
    this.videoPlaying = false;
    this.videoElement.nativeElement.currentTime = 0;
  }

  getMediaType(data: any) {
    const strings = data.split(',');
    switch (
      strings[0] //check image's extension
    ) {
      case 'data:video/mp4;base64':
        this.mediaType = 'video';
        break;
      case 'data:video/ogg;base64':
        this.mediaType = 'video';
        break;
      case 'data:image/png;base64':
        this.mediaType = 'image';
        break;
      case 'data:image/jpg;base64':
        this.mediaType = 'image';
        break;
      case 'data:image/jpeg;base64':
        this.mediaType = 'image';
        break;
      case 'data:image/svg;base64':
        this.mediaType = 'image';
        break;

      default: //should write cases for more images types
        break;
    }
  }

  ngOnDestroy() {
    this.previewTemplateData.next(null);
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
