import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperAdminComponent } from './super-admin.component';
import { SaDashboardComponent } from './sa-dashboard/sa-dashboard.component';
import { SaBspComponent } from './sa-bsp/sa-bsp.component';
import { SaChannelsComponent } from './sa-channels/sa-channels.component';
import { SaRequestsComponent } from './sa-requests/sa-requests.component';
import { SaReportsComponent } from './sa-reports/sa-reports.component';
import { SaProfileManagementComponent } from './sa-profile-management/sa-profile-management.component';
import { SaRoleManagementComponent } from './sa-role-management/sa-role-management.component';
import { SaSupportComponent } from './sa-support/sa-support.component';
import { SaSettingsComponent } from './sa-settings/sa-settings.component';
import { PagenotfoundComponent } from '../pagenotfound/pagenotfound.component';
import { AuthGuard } from '../services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: SuperAdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id/dashboard',
        component: SaDashboardComponent,
      },
      {
        path: ':id/bsp',
        component: SaBspComponent,
      },
      {
        path: ':id/channels',
        component: SaChannelsComponent,
      },
      {
        path: ':id/requests',
        component: SaRequestsComponent,
      },
      {
        path: ':id/reports',
        component: SaReportsComponent,
      },
      {
        path: ':id/profiles',
        component: SaProfileManagementComponent,
      },
      {
        path: ':id/roles',
        component: SaRoleManagementComponent,
      },
      {
        path: ':id/support',
        component: SaSupportComponent,
      },
      {
        path: ':id/settings',
        component: SaSettingsComponent,
      },
      { path: ':id/page-not-found', component: PagenotfoundComponent },
      {
        path: '**',
        redirectTo: ':id/page-not-found',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
