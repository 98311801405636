import { Component } from '@angular/core';

@Component({
  selector: 'app-sa-channels',
  templateUrl: './sa-channels.component.html',
  styleUrls: ['./sa-channels.component.scss']
})
export class SaChannelsComponent {

}
