import { createAction, createActionGroup, props } from '@ngrx/store';
import {
  ReportListData,
  ReportListVariables,
  ReportTemplateCreatePayload,
  ReportTemplateVariables,
  ReportTemplatesListPayload,
  ViewSummaryReportsData,
} from 'src/app/services/reports.service';

export const reporttemplates = createActionGroup({
  source: 'Report Templates',
  events: {
    'Report Templates List': props<{ payload: ReportTemplateVariables }>(),
    'Create Report Template': props<{ payload: ReportTemplateCreatePayload }>(),
    'Edit Report Template': props<{
      id: string;
      payload: ReportTemplateVariables;
    }>(),
    'Delete Report Template': props<{ payload: Array<string> }>(),
    'Report Templates List Success': props<{
      data: ReportTemplatesListPayload;
    }>(),
    'Report Templates List Failure': props<{ error: any }>(),
    'Create Report Template Success': props<{
      data: ReportTemplateCreatePayload;
    }>(),
    'Create Report Template Failure': props<{ error: any }>(),
    'Edit Report Template Success': props<{ data: any }>(),
    'Edit Report Template Failure': props<{ error: any }>(),
    'Delete Report Template Success': props<{ data: any }>(),
    'Delete Report Template Failure': props<{ error: any }>(),
  },
});

export const reportdetails = createActionGroup({
  source: 'Report',
  events: {
    'Report List': props<{ payload: ReportListVariables }>(),
    'Create Report': props<{ payload: any }>(),
    'Edit Report': props<{ id: string; payload: any }>(),
    'Delete Report': props<{ payload: Array<string> }>(),
    'Report List Success': props<{ data: ReportListData }>(),
    'Report List Failure': props<{ error: any }>(),
    'Create Report Success': props<{ data: any }>(),
    'Create Report Failure': props<{ error: any }>(),
    'Edit Report Success': props<{ data: any }>(),
    'Edit Report Failure': props<{ error: any }>(),
    'Delete Report Success': props<{ data: any }>(),
    'Delete Report Failure': props<{ error: any }>(),
  },
});

export const summaryreports = createActionGroup({
  source: 'Summary',
  events: {
    'View Summary Reports': props<{ payload: ReportTemplateVariables }>(),
    'View Summary Reports Success': props<{ data: ViewSummaryReportsData }>(),
    'View Summary Reports Failure': props<{ error: any }>(),
    'Delete Summary Report File': props<{ fiename: string }>(),
    'Delete Summary Report File Success': props<{ data: any }>(),
    'Delete Summary Report File Failure': props<{ error: any }>(),
  },
});

export const clearreportsdata = createAction('[Report] Clear Report Details');
