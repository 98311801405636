import { createAction, props } from '@ngrx/store';
import {
  CreateTemplateData,
  TemplateListData,
  TemplateData,
} from 'src/app/appdata/template.model';
import { TemplateVariables } from 'src/app/services/template.service';

export const fetchtemplateslist = createAction(
  '[Templates] Fetch Templates List',
  props<{
    payload: TemplateVariables;
  }>()
);
export const fetchtemplateslistSuccess = createAction(
  '[Templates] Fetch Templates List Success',
  props<{ data: TemplateListData }>()
);
export const fetchtemplateslistFailure = createAction(
  '[Templates] Fetch Templates List Failure',
  props<{ error: any }>()
);

export const createtemplate = createAction(
  '[Templates] Create Template',
  props<{ data: CreateTemplateData }>()
);
export const createtemplateSuccess = createAction(
  '[Templates] Create Template Success',
  props<{ data: any }>()
);
export const createtemplateFailure = createAction(
  '[Templates] Create Template Failure',
  props<{ error: any }>()
);

export const updatetemplate = createAction(
  '[Templates] Update Template',
  props<{ data: any; id: string }>()
);
export const updatetemplateSuccess = createAction(
  '[Templates] Update Template Success',
  props<{ data: any }>()
);
export const updatetemplateFailure = createAction(
  '[Templates] Update Template Failure',
  props<{ error: any }>()
);

export const deletetemplate = createAction(
  '[Templates] Delete Template',
  props<{ data: string }>()
);
export const deletetemplateSuccess = createAction(
  '[Templates] Delete Template Success',
  props<{ data: any }>()
);
export const deletetemplateFailure = createAction(
  '[Templates] Delete Template Failure',
  props<{ error: any }>()
);

export const resetTemplateData = createAction(
  '[Templates] Reset Template Data'
);

export const fetchtemplateDtls = createAction(
  '[Templates] Fetch Templates Details',
  props<{ id: string }>()
);
export const fetchtemplateDtlsSuccess = createAction(
  '[Templates] Fetch Templates Details Success',
  props<{ data: TemplateData }>()
);
export const fetchtemplateDtlsFailure = createAction(
  '[Templates] Fetch Templates Details Failure',
  props<{ error: any }>()
);

export const createtemplatePayload = createAction(
  '[Templates] Create Templates Payload',
  props<{ data: any }>()
);

export const addsamplePreview = createAction(
  '[Templates] Add Sample Preview Template',
  props<{ data: any }>()
);

export const templatePreviewData = createAction(
  '[Templates] Template Preview Data',
  props<{ data: any }>()
);

export const resetcreatetemplatePayload = createAction(
  '[Templates] Reset Create Templates Payload'
);

export const resetaddsamplePreview = createAction(
  '[Templates] Reset Add Sample Preview Template'
);

export const resettemplatePreviewData = createAction(
  '[Templates] Reset Template Preview Data'
);
