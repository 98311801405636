<!-- Main Content Container -->
<main role="main" class="main" id="my_profile">
  <div class="content container-fluid">
    <!-- View My Profile -->
    <!-- My Profile Header -->
    <div class="card p-3">
      <div class="top_header_options">
        <h3 class="main_title">My Profile</h3>

        <!-- Edit My Profile Button -->
        <button class="btn btn-secondary" (click)="onEdit()">
          <em class="bi-pencil-fill"></em> Edit
        </button>
        <!-- END Edit My Profile Button -->
      </div>
    </div>
    <!-- END My Profile Header -->

    <div class="card card-vh-100 p-0 mt-2">
      <div class="card-body">
        <div class="text-center">
          <!-- My Profile Avatar Upload -->
          <div class="avatar avatar-circle avatar-xxl">
            <img
              class="avatar-img"
              [src]="
                userDetails.profile.profile_image_url
                  ? userDetails.profile.profile_image_url
                  : 'assets/img/customer/default_avatar.jpg'
              "
              alt="Profile Avatar"
            />
          </div>
          <!-- END My Profile Avatar Upload -->
        </div>

        <dl class="row row-cols-2 m-0 mt-4">
          <dt class="col-sm-6 text-sm-right">Full Name :</dt>
          <dd class="col-sm-6">
            {{ userDetails.profile.first_name }}
            {{ userDetails.profile.last_name }}
          </dd>

          <dt class="col-sm-6 text-sm-right">Business Email ID :</dt>
          <dd class="col-sm-6">{{ userDetails.profile.email }}</dd>

          <dt class="col-sm-6 text-sm-right">Mobile Number :</dt>
          <dd class="col-sm-6">{{ userDetails.profile.mobile_number }}</dd>

          <dt class="col-sm-6 text-sm-right">Designation :</dt>
          <dd class="col-sm-6">
            {{
              userDetails.profile.designation
                ? userDetails.profile.designation
                : "N/A"
            }}
          </dd>

          <dt class="col-sm-6 text-sm-right">Role :</dt>
          <dd class="col-sm-6">{{ userDetails.profile.acl.rbac.role_name }}</dd>

          <dt class="col-sm-6 text-sm-right">Password :</dt>
          <dd class="col-sm-6">
            <span
              class="change-pass btn-link pointer text-primary"
              (click)="onChangePWD()"
              >Change Password</span
            >
          </dd>

          <dt class="col-sm-6 text-sm-right">Assigned Channel :</dt>
          <dd class="col-sm-6">
            <div
              class="channel_list"
              *ngIf="userDetails.account.ocpm_channel === 'WABA'"
            >
              <img
                class="img-fluid"
                src="assets/img/whatsapp.svg"
                alt="WhatsApp Icon"
              />
              WhatsApp
            </div>
          </dd>

          <dt class="col-sm-6 text-sm-right">Assigned BSP :</dt>
          <dd class="col-sm-6" *ngIf="channel_credentails.bsp_name === 'RML'">
            {{channel_credentails.bsp_name === 'RML' ? 'Routemobile':channel_credentails.bsp_name}}
          </dd>

          <dt class="col-sm-6 text-sm-right">
            Assigned Bussiness Account Number :
          </dt>
          <dd class="col-sm-6 mp_details">
            <div class="row row-cols-1">
              <div class="col mb-3" *ngFor="let item of visibleItems">
                <div class="mp_aban_items">
                  <div class="avatar avatar-sm avatar-circle">
                    <img
                      class="avatar-img"
                      [src]="item.channel_credentials.profile_pic"
                      [alt]="item.channel_credentials.business_name"
                    />
                  </div>
                  <div class="ms-2">
                    <p class="mp_aban_name">
                      {{ item.channel_credentials.business_name }}
                    </p>
                    <span class="mp_aban_number">{{
                      item.channel_credentials.waba_number
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-link"
              type="button"
              (click)="showAll()"
              *ngIf="visibleItems.length > 1 && !showAllItems"
            >
              View more
            </button>
            <button
              class="btn btn-link"
              type="button"
              (click)="showLess()"
              *ngIf="showAllItems"
            >
              View less
            </button>
          </dd>
        </dl>
      </div>
    </div>
    <!-- END View My Profile -->
  </div>
</main>
<!-- END Main Content Container -->
