import { Action, createReducer, on } from '@ngrx/store';
import * as analyticsactions from './analytics.actions';

export interface State {
  cust_analytics: any;
  error: any;
}

export const initialState: State = {
  cust_analytics: null,
  error: null,
};

const custAnalyticsReducer = createReducer(
  initialState,
  on(
    analyticsactions.fetchccanalyticsSuccess,
    (state, { data }): State => ({
      ...state,
      cust_analytics: data,
      error: null,
    })
  ),
  on(
    analyticsactions.fetchccanalyticsFailure,
    (state, { error }): State => ({
      ...state,
      cust_analytics: null,
      error: error,
    })
  )
);

export function analyticsreducer(
  state: State | undefined,
  action: Action
): any {
  return custAnalyticsReducer(state, action);
}

export const getCustAnalytics = (state: State) => {
  return {
    cust_analytics: state.cust_analytics,
    error: state.error,
  };
};
