<!-- Opt-Out Header Options -->
<div class="row justify-content-between my-3">
  <div class="col-4">
    <!-- Opt-Out SearchBar -->
    <div class="input-group input-group-merge mb-0">
      <div class="input-group-prepend input-group-text">
        <em class="bi bi-search"></em>
      </div>
      <!-- (keyup.enter)="searchMobile(searchPhNo)" -->
      <input
        type="search"
        class="form-control"
        tabindex="0"
        placeholder="Search by mobile number"
        [(ngModel)]="searchPhNo"
        (ngModelChange)="onMobleChange($event)"
        [ngClass]="searchInput ? 'invalid-input': ''"
      />
    </div>
    <!-- END Opt-Out SearchBar -->
  </div>

  <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
    <ul class="list-inline list-inline-flex mb-0">
      <!-- Source Filter -->
      <!-- <li class="list-inline-item">
        <ng-select
          [items]="allSources"
          bindLabel="name"
          bindValue="value"
          [(ngModel)]="selectedSource"
          (ngModelChange)="onSourceSelect(selectedSource)"
          [closeOnSelect]="true"
          [clearable]="true"
          [clearOnBackspace]="true"
          [searchable]="true"
          [tabIndex]="2"
          placeholder="Select source"
        >
        </ng-select>
      </li> -->
      <!-- END Source Filter -->

      <!-- Calender Date Range Selector -->
      <li class="list-inline-item">
        <app-datepicker></app-datepicker>
      </li>
      <!-- END Calender Date Range Selector -->
    </ul>
  </div>
</div>
<!-- END Opt-Out Header Options -->

<!-- Show Consent Listing -->
<div class="show_consent_listing" *ngIf="totaloptoutlist > 0; else nodata">
  <!-- Opt-Out Consent Listing -->
  <div class="table-wrapper">
    <table class="table table-striped" aria-describedby="Consent Blocklist">
      <thead class="table-head">
        <tr class="table-row">
          <th scope="col">
            <span>Mobile Number</span>
          </th>
          <th scope="col">Updated On</th>
          <th scope="col">Source</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-row" *ngFor="let item of alloptoutlist">
          <td>
            <div class="fw-bold">{{ item.mobile }}</div>
          </td>
          <td>{{ item.created_at | date : "short" }}</td>
          <td>
            <div
              class="source d-inline-flex align-items-center justify-content-center gap-1"
            >
              <ng-container *ngIf="getSource(item.source); else noSource">
                <img
                  class="img-fluid"
                  [src]="consentSource.icon"
                  alt="{{ consentSource.name }}"
                />
                {{ consentSource.name }}
              </ng-container>
              <ng-template #noSource>N/A</ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- END Opt-Out Consent Listing -->

  <!-- Pagination -->
  <div class="pagination-wrapper" *ngIf="totaloptoutlist > pageSize">
    <ngb-pagination
      class="ngb-pagination"
      [collectionSize]="totaloptoutlist"
      [(page)]="page"
      [pageSize]="pageSize"
      aria-label="pagination"
      (pageChange)="onPageEvent($event)"
      [rotate]="true"
      [maxSize]="3"
      [ellipses]="true"
      [boundaryLinks]="false"
    >
      <ng-template ngbPaginationPrevious>
        <i class="bi-chevron-left"></i>
        Previous</ng-template
      >
      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
      <ng-template ngbPaginationNext>
        Next
        <i class="bi-chevron-right"></i
      ></ng-template>
    </ngb-pagination>
  </div>
  <!-- END Pagination -->
</div>
<!-- END Consent Opt-In Listing -->

<ng-template #nodata>
  <div *ngIf="isLoading">
    <ngx-skeleton-loader
      class="skeleton_loader"
      *ngFor="let item of skeletonData"
      [theme]="item"
    ></ngx-skeleton-loader>
  </div>
  <!-- Opt-Out Empty Image -->
  <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
    <img
      class="w-25"
      src="assets/img/no_data.svg"
      alt="No Opt-Out data found"
    />
    <p>No Opt-Out data found</p>
  </div>
  <!-- End Opt-Out Empty Image -->
</ng-template>
