import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError, Observable } from 'rxjs';

export interface ProfileVariables {
  accountid: string | null;
  bspid: string | null;
  wabano: string | null;
  channel: string | null;
  profileName: string | null;
  role: string | null;
  createdby: string | null;
  page: number | null;
  size: number | null;
  startdate: string | null;
  enddate: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileManagementService {
  constructor(private http: HttpClient) {}

  private apiUrl(endpoint?: string): string {
    return `${environment.backendurl}/api/profiles/${endpoint}`;
  }

  private profileapiUrl(): string {
    return `${environment.backendurl}/api/profiles`;
  }

  private handleHttpError(error: any): Observable<never> {
    return throwError(() => error);
  }

  private handleHttpSuccess(res: any): any {
    return res;
  }

  getProfilesList(payload: ProfileVariables): Observable<any> {
    const url = this.apiUrl(`${payload.accountid}`);

    let params = new HttpParams();
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.bspid) {
      params = params.set('bsp_id', payload.bspid);
    }
    if (payload.role) {
      params = params.set('role', payload.role);
    }
    if (payload.wabano) {
      params = params.set('waba_number', payload.wabano);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.profileName) {
      params = params.set('first_name', payload.profileName);
    }

    return this.http
      .get(url, { params })
      .pipe(map(this.handleHttpSuccess), catchError(this.handleHttpError));
  }

  createProfile(payload: any): Observable<any> {
    const url = this.profileapiUrl();
    return this.http
      .post(url, payload)
      .pipe(map(this.handleHttpSuccess), catchError(this.handleHttpError));
  }

  updateProfile(payload: any, id: string): Observable<any> {
    const url = this.apiUrl(`${id}`);
    return this.http
      .put(url, payload)
      .pipe(map(this.handleHttpSuccess), catchError(this.handleHttpError));
  }

  getProfileById(accountid: string, profile_id: string): Observable<any> {
    const url = this.apiUrl(`${accountid}/${profile_id}`);
    return this.http
      .get(url)
      .pipe(map(this.handleHttpSuccess), catchError(this.handleHttpError));
  }

  deleteProfile(accountid: string, profile_id: string): Observable<any> {
    const url = this.apiUrl(`${accountid}/${profile_id}`);
    return this.http
      .delete(url)
      .pipe(map(this.handleHttpSuccess), catchError(this.handleHttpError));
  }
}
