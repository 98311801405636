import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourceFilter',
})
export class SourceFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter((item) => {
      const name = item.source.toLowerCase();
      return name.includes(searchText);
    });
  }
}
