<!-- Main Content Container -->
<main role="main" class="main" id="cc_business_accounts">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- BSPs List Template -->
<ng-template #bspListing let-data="data">
  <!-- Header -->
  <div class="card mb-2">
    <div
      class="top_header_options d-flex align-items-center justify-content-between"
    >
      <h3 class="stat_main_title">Business Solution Providers</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card content-card">
    <!-- BSPs List -->
    <div class="w-80 mx-auto">
      <div class="row row-cols-1 row-cols-md-2 row-gap-4 bsp_cards">
        <div class="col">
          <div class="card border shadow-none" (click)="navigateToBA()" (keydown)="navigateToBA()">
            <div class="card-body p-0">
              <!-- BSP Logo -->
              <div class="bsp_logo">
                <span>Routemobile</span>
              </div>
              <!-- END BSP Logo -->

              <!-- About BSP -->
              <p class="my-3">N/A</p>
              <!-- END About BSP -->

              <!-- BSP Business Numbers -->
              <p>
                Business Numbers : <b>{{ allbspList.length }}</b>
              </p>
              <!-- END BSP Business Numbers -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END BSPs List -->
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END BSPs List Template -->
