import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { RoleManagementService } from 'src/app/services/rolemanagement.service';
import * as roleactions from './role.actions';
import { RolesDataList } from 'src/app/appdata/roles.model';

@Injectable()
export class RoleManagementEffects {
  constructor(
    private actions$: Actions,
    private roleservice: RoleManagementService
  ) {}

  getRolesListData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleactions.fetchRoleslist),
      exhaustMap((action) =>
        this.roleservice.getRolesList(action.payload).pipe(
          map((response: RolesDataList) => {
            return roleactions.fetchRoleslistSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(roleactions.fetchRoleslistFailure(error))
          )
        )
      )
    );
  });

  createRoleData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleactions.initiateCreateRole),
      exhaustMap((action) =>
        this.roleservice.createRole(action.data).pipe(
          map((response: any) => {
            return roleactions.initiateCreateRoleSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(roleactions.initiateCreateRoleFailure(error))
          )
        )
      )
    );
  });

  viewRoleData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleactions.viewRoleDetails),
      exhaustMap((action) =>
        this.roleservice.viewRoleDtls(action.id).pipe(
          map((response: any) => {
            return roleactions.viewRoleDetailsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(roleactions.viewRoleDetailsFailure(error))
          )
        )
      )
    );
  });

  updateRoleData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleactions.updateRoleDetails),
      exhaustMap((action) =>
        this.roleservice.updateRoleDtls(action.id, action.payload).pipe(
          map((response: any) => {
            return roleactions.updateRoleDetailsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(roleactions.updateRoleDetailsFailure(error))
          )
        )
      )
    );
  });

  deleteRoleData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleactions.initiateDeleteRole),
      exhaustMap((action) =>
        this.roleservice.deleteRole(action.roleid).pipe(
          map((response: any) => {
            return roleactions.initiateDeleteRoleSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(roleactions.initiateDeleteRoleFailure(error))
          )
        )
      )
    );
  });
}
