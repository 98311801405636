<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

<!-- View Profile -->
<ng-template #profiledatatemp>
  <!-- Header -->
  <div class="card p-3">
    <div class="top_header_options" *ngIf="selectedProfile">
      <div class="d-flex align-items-center gap-2">
        <!-- Goto Profiles List -->
        <button class="btn btn-sm btn-icon btn-light" (click)="goToProfileList()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <!-- END Goto Profiles List -->

        <div class="view_details">
          <div class="details d-flex align-items-center gap-2">
            <div class="profile_avatar">
              <div class="avatar avatar-circle avatar-lg">
                <img
                  class="avatar-img"
                  [src]="
                    selectedProfile.profile_image_url
                      ? selectedProfile.profile_image_url
                      : 'assets/img/customer/default_avatar.jpg'
                  "
                  [alt]="shortName(selectedProfile.first_name+' '+selectedProfile.last_name) | uppercase"
                />
              </div>
            </div>
            <div class="profile_info">
              <h3 class="d-flex align-items-center gap-2 mb-2">
                {{ selectedProfile.first_name | titlecase }}
                {{ selectedProfile.last_name }}
                <div class="btn btn-sm btn-icon" (click)="onUpdateProfile()">
                  <em class="bi-pencil-fill"></em>
                </div>
                <!-- <span
                  class="btn btn-xs btn-icon btn-default rounded-circle"
                  matTooltip="Edit profile"
                >
                  <em class="bi-pencil-fill"></em>
                </span> -->
              </h3>
              <div class="profile_details">
                <ul class="list-inline-flex list-unstyled list-divider mb-0 text-small text-body text-capitalize" >
                  <li class="list-inline-item">Email ID : {{ selectedProfile.email }}</li>
                  <li class="list-inline-item">Role : {{ selectedProfile.acl.rbac.role_name }}</li>
                  <li class="list-inline-item">
                    Created On:
                    {{
                      selectedProfile.created_at | date : "dd/MM/yyyy, h:mm a"
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="channel_selection">
            <ng-select
              class="header_select"
              [multiple]="false"
              [closeOnSelect]="true"
              [searchable]="true"
              placeholder="Select channel"
              [clearable]="true"
            >
              <ng-template ng-option-tmp>
                <img src="assets/img/whatsapp.svg" alt="whatsApp Channel" />
              </ng-template>
              <ng-template ng-label-tmp>
                <img src="assets/img/whatsapp.svg" alt="whatsApp Channel" />
              </ng-template>
            </ng-select>
          </div> -->

      <div class="act_status">
        <!-- <span class="me-2" *ngIf="sliderValue.checked">Active</span>
        <span class="me-2" *ngIf="!sliderValue.checked">Inactive</span> -->
        <!-- Mat-slide-Toggle - -->
        <!-- [labelPosition]="sliderValue.labelPosition" -->
        <!-- Mat-slide-Toggle - -->
        <mat-slide-toggle
          [color]="sliderValue.color"
          (click)="profileActiveStatus()"
          [(ngModel)]="sliderValue.checked"
          [disabled]="sliderValue.disabled"
        >
          {{
            sliderValue.checked ? sliderValue.status[0] : sliderValue.status[1]
          }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card mt-2">
    <div class="card-body">
      <div class="wabaid_details">
        <div class="nav_tabs">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="active"
            class="nav-tabs mb-4"
            (navChange)="onNavChange($event)"
          >
            <!-- WABA Details -->
            <li [ngbNavItem]="1">
              <button ngbNavLink>
                <span>Routemobile</span>
              </button>
              <ng-template ngbNavContent>
                <!-- View Business Account -->
                <div class="row row-cols-lg-4 row-cols-md-3 row-gap-4">
                  <div
                    class="col mb-4"
                    *ngFor="
                      let item of profileLicenses?.license_data;
                      let i = index
                    "
                  >
                    <!-- Card -->
                    <div class="card card-hover-shadow ba_item">
                      <!-- Body -->
                      <div class="card-body p-0">
                        <div class="view-bact">
                          <!-- Business Account Default Avatar -->
                          <div class="d-flex justify-content-between mb-2">
                            <div
                              class="avatar avatar-circle avatar-lg avatar_bg"
                            >
                              <img
                                class="avatar-img"
                                src="{{ item.channel_credentials.profile_pic }}"
                                alt="{{
                                  item.channel_credentials.business_name
                                }} Icon"
                              />
                            </div>
                            <div class="info_icon">
                              <i
                                class="bi-info-circle small"
                                matTooltipPosition="above"
                                matTooltip="Description: {{
                                  item.channel_credentials.business_description
                                }}.
                                                Created On: {{
                                  item.created_at | date : 'dd/MM/yyyy, h:mm a'
                                }}"
                              >
                              </i>
                            </div>
                          </div>
                          <!-- END Business Account Default Avatar -->

                          <div class="ba_details">
                            <h4 class="d-flex align-items-center gap-1">
                              {{ item.channel_credentials.waba_number }}
                              <!-- <img
                                    src="assets/img/verified.svg"
                                    alt="Verified"
                                  /> -->
                            </h4>
                            <p>{{ item.channel_credentials.business_name }}</p>
                          </div>
                        </div>
                      </div>
                      <!-- End Body -->
                      <div class="dropdown-divider"></div>
                      <!-- Footer -->
                      <div class="card-footer p-0">
                        <!-- Stats -->
                        <div
                          class="ch_info d-flex align-items-center justify-content-between"
                        >
                          <div
                            class="status_indicators"
                            [ngClass]="
                              item.channel_credentials.waba_status ===
                              'Connected'
                                ? 'active'
                                : 'inactive'
                            "
                          >
                            <span class="status-icon"></span>
                            <span class="ms-1">
                              <ng-template
                                [ngIf]="
                                  item.channel_credentials.waba_status ===
                                  'Connected'
                                "
                                [ngIfElse]="inActiveBusinessAccount"
                                >Active</ng-template
                              >
                              <ng-template #inActiveBusinessAccount
                                >Inactive</ng-template
                              >
                            </span>
                          </div>

                          <!-- Select bact button -->
                          <!-- <button class="btn btn-default w_icon">
                                <em class="bi-arrow-right"></em>
                              </button> -->
                          <!-- END Select bact button -->
                        </div>
                        <!-- End Stats -->
                      </div>
                      <!-- End Footer -->
                    </div>
                    <!-- End Card -->
                  </div>
                </div>
                <!-- END View Business Account -->
              </ng-template>
            </li>
            <!-- END WABA Details -->
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END View Profile -->

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->
